import {defineComponent, onMounted, ref, watch} from 'vue';

export default defineComponent({
  props: ['modelValue', 'label', 'type', 'disabled', 'rejected', 'customId'],
  components: {},
  setup: function (props) {
    let innerModelValue: any = ref(undefined);

    let activeArray = ref(false);
    let newValueForArrayRef = ref();
    let isActive = ref(false);

    watch(props, () => {
      innerModelValue.value = props.modelValue;
    });

    onMounted(() => {
      innerModelValue.value = props.modelValue;
    });

    return {
      innerModelValue,
      activeArray,
      newValueForArrayRef,
      isActive,
    };
  },
});

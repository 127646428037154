<template>
  <div
    class="col-menu-color bg-tt-white border-radius-16"
    style="
      width: 100%;
      box-shadow: 20px 20px 50px;
      margin-right: 24px;
      border-color: transparent;
    "
  >
    <div
      style="
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div
        class="col-dark-blue"
        style="
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          font-weight: 600;
        "
      >
        {{$t("addAsset.previewTestvideo")}}
      </div>
      <div
        class="col-dark-blue"
        style="
          min-height: 30px;
          font-size: 16px;
          font-weight: 300;
          text-align: center;
        "
      >
        {{$t("addAsset.checkAllLayersRecognized")}}
      </div>
      <div
        id="addAssetPreviewVideoContainer"
        style="
          width: 100%;
          height: calc(100vh - 290px);
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: auto;
        "
      >
        <div style="
            width: 70%;
            margin-left: 15px;
            height: calc(100vh - 300px);"
        >
          <div
            class="video-container border-radius-12 bg-black bor-dark-blue box-shadow-2"
            style="
						display: flex;
						flex-direction: column;
            align-items: center;
            margin-bottom: 8px;
          "
            :style="'height: calc(100vh - ' + (308 + fieldVisualizerHeight) + 'px);'"
          >
            <video
              class="video-js"
              ref="videoPlayerRef"
              style="
              width: 100%;
              height: inherit;
              background: transparent;
            "
            />
          </div>
          <FieldVisualizer v-if="asset && assetItem && isVideoLoaded"
                           :asset-id="asset.id"
                           :field-ids="assetItem.fields"
                           :video-length="videoPlayerRef.duration"/>
        </div>
        
        <div style="width: 30%; margin: 0 20px; padding: 20px 40px; height: calc(100vh - 300px)"
             class="border-radius-12 bor-dark-blue col-dark-blue box-shadow-2">
          <div style="font-size: 20px">
            {{$t('general.summary')}}
          </div>
          <div v-for="(prop, index) in propsValues" style="margin-top: 15px">
            <div v-if="prop && index === 'currentId'" class="info-item">
              <div>
                {{$t('general.id')}}
              </div>
              <div>
                {{prop}}
              </div>
            </div>
            <div v-if="prop && index === 'currentProjectName'" class="info-item">
              <div>
                {{$t('general.name')}}
              </div>
              <div>
                {{prop}}
              </div>
            </div>
            <div v-if="prop && index === 'currentLanguage'" class="info-item">
              <div>
                {{$t('general.language')}}
              </div>
              <div>
                {{prop.name}}
              </div>
            </div>
            <div v-if="prop && index === 'currentGroups'" class="info-item">
              <div>
                {{$t('general.groups')}}
              </div>
              <div>
                {{prop.join(', ')}}
              </div>
            </div>
            
            <div v-if="prop && index === 'customerMetadata'" class="info-item">
              <div>
                {{$t('general.metadata')}}
              </div>
              
              <div v-for="item in prop">
                <div style="font-size: 14px; font-weight: 400; line-height: 18px">
                  - {{item.name}}
                </div>
                
                <div
                  v-if="item.type === 'STRING'"
                  class="col-dark-blue metadata-item"
                >
                  {{item.typeValueString !== undefined ? item.typeValueString : 'No Value'}}
                </div>
                
                <div
                  v-if="item.type === 'BOOLEAN'"
                  class="col-dark-blue metadata-item"
                >
                  {{item.typeValueBoolean !== undefined ? item.typeValueBoolean : 'No Value'}}
                </div>
                
                <div
                  v-if="item.type === 'INTEGER'"
                  class="col-dark-blue metadata-item"
                >
                  {{item.typeValueInteger ? item.typeValueInteger : 'No Value'}}
                </div>
                
                <div
                  v-if="item.type === 'DOUBLE'"
                  class="col-dark-blue metadata-item"
                >
                  {{item.typeValueDouble !== undefined ? item.typeValueDouble : 'No Value'}}
                </div>
              </div>
            </div>
          
          </div>
        </div>
      
      </div>
      <!-- FOOTER -->
      
      <div
        style="
          width: 90%;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div>
          <Button
            @click="
              $emit('update:transitionDir', 'slide-left');
              $emit(
                'update:currentPage',
                currentPage - (hasAssetItemFields ? 1 : 2)
              );
              $router.push({
                query: {
                  id: $route.query.id,
                  page:
                    parseInt($route.query.page) - (hasAssetItemFields ? 1 : 2),
                },
              });
            "
            :label="$t('general.back')"
            class="border-radius-8 col-dark-blue bor-transparent bg-tt-white"
            style="width: 64px; height: 40px; padding: 7px 6px 6px 8px"
          />
          <Button
            @click="onClickCancel"
            :label="$t('general.cancel')"
            class="border-radius-8 col-white bor-transparent bg-dark-blue"
            style="width: 64px; height: 40px; padding: 7px 6px 6px 8px"
          />
        </div>
        <div
          style="
            width: 300px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <i :class="'col-dark-blue pi ' + getCircleClass(1, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(2, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(3, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(4, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(5, currentPage)"></i>
        </div>
        <div
          style="
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          "
        >
          <Button
            @click="onClickFinish"
            :label="$t('general.finish')"
            :disabled="clickedFinish"
            class="border-radius-8 col-white bor-transparent bg-dark-blue"
            style="width: 64px; height: 40px; padding: 7px 6px 6px 8px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.info-item {
  gap: 5px;
  display: flex;
  flex-direction: column;
  color: var(--dark-blue);
}

.info-item > div:first-child {
  font-size: 17px;
  text-decoration: underline;
}

.metadata-item {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: 350px;
  padding-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<script src="./AddAssetViewPreview.ts"></script>

import {InjectionKey} from 'vue';
import main from '../main';
import {UserApi} from '../../target/api';
import {CustomerMetadata, UserNotification, WsMessage, WsTopic} from '../../target/api/de/moovit/titletoolserver/model';
import {ActionTree, createStore, MutationTree, Store, useStore as baseUseStore} from 'vuex';
import {useRouter} from "vue-router";

export interface State {
  sidebarVisible: boolean;
  dialog: any | undefined;
  addFilterDialog: any | undefined;
  snackbar: any | undefined;
  activityBreadcrumbs: any | undefined;
  filterBreadcrumbs: any | undefined;
  toast: any | undefined;
  i18n: any | undefined;
  languages: {
    name: string;
    value: string;
    autoTranslate: boolean;
    autoTranslateAvailable: boolean;
  }[] | undefined;
  countries: any | undefined;
  settings: any | undefined;
  notifications: any | undefined;
  experimentalFeatures: boolean | undefined;
  sessionId: any | undefined;
  socket: any | undefined;
}

let snackBarTimer: ReturnType<typeof setTimeout>;

const actions = <ActionTree<State, any>>{};

const userApi: UserApi = new UserApi();

let getCustomerMetadataValue = (metadata: CustomerMetadata): string => {
  switch (metadata.type) {
    case 'BOOLEAN':
      return '' + metadata.typeValueBoolean;
    case 'STRING':
      return '' + metadata.typeValueString;
    case 'INTEGER':
      return '' + metadata.typeValueInteger;
    case 'DOUBLE':
      return '' + metadata.typeValueDouble;
    default:
      return "";
  }
}

const mutations = <MutationTree<State>>{
  SOCKET_ONOPEN(state, event) {
    console.debug("socket connected... ");
    main.config.globalProperties.$socket = event.currentTarget;

    state.socket.isConnected = true;
    state.socket.heartBeatTimer = setInterval(() => {
      const message = "hb";
      state.socket.isConnected &&
      main.config.globalProperties.$socket.sendObj({
        code: 200,
        type: "heartbeat",
        sessionId: state.sessionId,
        msg: message,
      });
    }, state.socket.heartBeatInterval);
  },
  SOCKET_ONCLOSE(state, event) {
    state.socket.isConnected = false;
    // Stop the heartbeat message when the connection is closed
    clearInterval(state.socket.heartBeatTimer);
    state.socket.heartBeatTimer = 0;
    console.debug("The line is disconnected: " + new Date());
    console.debug("Current event", event);
  },
  SOCKET_ONERROR(state, event) {
    console.error(state, event);
  },
  async SOCKET_ONMESSAGE(state, message: WsMessage) {
    if (message.topic !== WsTopic.RENDERNODEINFORMATION) {
      console.debug("Socket message: " + JSON.stringify(message));
    }

    if (message.topic == WsTopic.USERNOTIFICATION) {
      if (localStorage.loginData) {
        state.notifications = (await userApi.getNotifications(JSON.parse(localStorage.loginData).user.id)).data;
      }
    } else {
      if (message.sessionId === localStorage.sessionId) {
        main.config.globalProperties.$emitter.emit(
          "ws" + message.topic,
          message.payload
        );

        if (message.topic === WsTopic.ASSETITEMPROGRESS) {
          main.config.globalProperties.$emitter.emit(
            "wsHeader" + message.topic,
            message.payload
          );
        }
      }
    }
  },
  SOCKET_RECONNECT(state, count) {
    console.info("Reconnect...", state, count);
  },
  SOCKET_RECONNECT_ERROR(state) {
    state.socket.reconnectError = true;
  },
  SET_SIDEBAR_VISIBLE(state, sidebarVisible) {
    state.sidebarVisible = sidebarVisible;
  },
  SET_ACTIVITY_BREADCRUMBS(state, breadcrumbs) {
    console.debug("SET_ACTIVITY_BREADCRUMBS", breadcrumbs);
    state.activityBreadcrumbs = breadcrumbs;
  },
  SET_FILTER_BREADCRUMBS(state, breadcrumbs) {
    state.filterBreadcrumbs = breadcrumbs;
  },
  ADD_FILTER_BREADCRUMB(state, breadcrumb) {
    for (let i in state.filterBreadcrumbs) {
      let filterBreadcrumb = state.filterBreadcrumbs[i];
      if (breadcrumb.type === "CUSTOM" && filterBreadcrumb.type == "CUSTOM") {
        if (filterBreadcrumb.name.name == breadcrumb.name.name
          && getCustomerMetadataValue(filterBreadcrumb.name) == getCustomerMetadataValue(breadcrumb.name)) {
          return;
        }
      } else if (filterBreadcrumb.name == breadcrumb.name &&
        filterBreadcrumb.type == breadcrumb.type) {
        return;
      }
    }

    if (!state.filterBreadcrumbs.includes(breadcrumb)) {
      state.filterBreadcrumbs.push(breadcrumb);
    }
  },
  SET_LANGUAGES(state, languages) {
    state.languages = languages;
  },
  SET_COUNTRIES(state, countries) {
    state.countries = countries;
  },
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },
  SET_TOAST(state, toast) {
    state.toast = toast;
  },
  SET_EXPERIMENTAL_FEATURES(state, bool) {
    state.experimentalFeatures = bool;
  },
  SET_I18N(state, i18n) {
    state.i18n = i18n;
  },
  ADD_SETTINGS_VALUE(state, value) {
    //value.id    => which setting object
    //value.item => item to be added to object
  },
  SHOW_INFO_DIALOG(state, input) {
    state.dialog.visible = true;
    state.dialog.title = "";
    state.dialog.content = {t: "", e: ""};
    state.dialog.btnConfirmText = "general.ok";
    state.dialog.btnRejectText = "general.cancel";
    state.dialog.confirmCallback = () => {
    };
    state.dialog.rejectCallback = () => {
    };
    if (input.dialogTitle) state.dialog.title = input.dialogTitle;
    if (input.dialogContent) state.dialog.content = input.dialogContent;
    if (input.btnConfirmText)
      state.dialog.btnConfirmText = input.btnConfirmText;
    if (input.confirmCallback)
      state.dialog.confirmCallback = input.confirmCallback;
  },
  SHOW_CONFIRM_DIALOG(state, input) {
    state.dialog.visible = true;
    state.dialog.title = "";
    state.dialog.content = {t: "", e: ""};
    state.dialog.btnConfirmText = "general.ok";
    state.dialog.btnRejectText = "general.cancel";
    state.dialog.confirmCallback = () => {
    };
    state.dialog.rejectCallback = () => {
    };
    if (input.dialogTitle) state.dialog.title = input.dialogTitle;
    if (input.dialogContent) {
      state.dialog.content.t = input.dialogContent.t;
      state.dialog.content.e = input.dialogContent.e;
    }
    if (input.dialogContentT) state.dialog.content.t = input.dialogContentT;
    if (input.dialogContentE) state.dialog.content.e = input.dialogContentE;
    if (input.btnConfirmText)
      state.dialog.btnConfirmText = input.btnConfirmText;
    if (input.btnRejectText) state.dialog.btnRejectText = input.btnRejectText;
    if (input.confirmCallback)
      state.dialog.confirmCallback = input.confirmCallback;
    if (input.rejectCallback)
      state.dialog.rejectCallback = input.rejectCallback;
  },
  SHOW_ADD_FILTER_DIALOG(state, input) {
    main.config.globalProperties.$emitter.emit("show-add-filter-dialog");
    state.addFilterDialog.visible = true;
    state.addFilterDialog.showStatus = true;
    state.addFilterDialog.showLanguage = true;
    state.addFilterDialog.showGroup = true;
    state.addFilterDialog.showTranslator = true;
    state.addFilterDialog.showCustomDropdown = true;
    state.addFilterDialog.showLocked = true;
    state.addFilterDialog.showRoleDropdown = true;
    state.addFilterDialog.showCostCenter = true;
    state.addFilterDialog.showDefaultLanguage = true;
    if (input && input.hideStatus) state.addFilterDialog.showStatus = false;
    if (input && input.hideLanguage) state.addFilterDialog.showLanguage = false;
    if (input && input.hideGroup) state.addFilterDialog.showGroup = false;
    if (input && input.hideTranslator)
      state.addFilterDialog.showTranslator = false;
    if (input && input.hideRoleDropdown)
      state.addFilterDialog.showRoleDropdown = false;
    if (input && input.hideCustomDropdown)
      state.addFilterDialog.showCustomDropdown = false;
    if (input && input.hideLocked) state.addFilterDialog.showLocked = false;
    if (input && input.hideCostCenter)
      state.addFilterDialog.showCostCenter = false;
    if (input && input.hideDefaultLanguage)
      state.addFilterDialog.showDefaultLanguage = false;
  },
  HIDE_DIALOG(state) {
    state.dialog.visible = false;
    state.addFilterDialog.visible = false;
  },
  SHOW_SNACKBAR(state, input) {
    if (state.toast) {

      if (input.snackbarBtnCallback) {
        state.snackbar.btnCallback = input.snackbarBtnCallback;
        state.snackbar.group = 'callback';
      } else {
        state.toast.removeGroup('callback');
        state.snackbar.btnCallback = undefined;
        state.snackbar.group = 'info';
      }

      if (input.snackbarBtnText) {
        state.snackbar.btnText = input.snackbarBtnText;
      } else {
        state.snackbar.btnText = '';
      }

      if (input.snackbarLife) {
        state.snackbar.life = input.snackbarLife;
      } else {
        state.snackbar.life = 2000;
      }

      if (input.snackbarType) { // success / info / warn / error
        state.snackbar.type = input.snackbarType;
      } else {
        state.snackbar.type = 'success';
      }

      let toastConfig = {
        severity: state.snackbar.type,
        summary: state.i18n.t("snackbar." + state.snackbar.type.toLowerCase()).toUpperCase(),
        detail: state.i18n.t(input.snackbarContent.t, input.snackbarContent.e),
        group: state.snackbar.group,
        life: state.snackbar.life
      };
      state.toast.add(toastConfig);
    }
  },
  HIDE_SNACKBAR(state) {
    state.toast.removeAllGroups();
  },
};

export const store = createStore<State>({
  state: {
    sidebarVisible: true,
    sessionId: localStorage.sessionId,
    dialog: {
      visible: false,
      title: "",
      content: {t: "", e: ""},
      btnConfirmText: "",
      btnRejectText: "",
      confirmCallback: () => {
      },
      rejectCallback: () => {
      },
    },
    socket: {
      isConnected: false,
      message: "",
      reconnectError: true,
      heartBeatInterval: 5000,
      heartBeatTimer: 0,
    },
    addFilterDialog: {
      visible: false,
    },
    snackbar: {
      content: {t: "", e: ""},
      btnText: "",
      type: "",
      group: "",
      life: 0,
      btnCallback: () => {
      }
    },
    activityBreadcrumbs: ["Jobs"],
    filterBreadcrumbs: [],
    languages: [],
    countries: [],
    settings: new Map<string, object>(),
    notifications: new Array<UserNotification>(),
    experimentalFeatures: false,
    toast: undefined,
    i18n: undefined,
  },
  mutations: mutations,
  actions: actions,
});

export const key: InjectionKey<Store<State>> = Symbol();

export function useStore(): Store<State> {
  return baseUseStore(key);
}

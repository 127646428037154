import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router';
import JobsView from '../views/jobs/JobsView.vue';
import JobApprovalView from '../views/approvals/JobApprovalsView.vue';
import AssetsView from '../views/assets/AssetsView.vue';
import AddAssetView from '../views/add_asset/AddAssetView.vue';
import Uploader from '../views/uploader/Uploader.vue';
import DeveloperView from '../views/dev/DeveloperView.vue';
import EditAssetView from '../views/assets/utils/EditAssetView.vue';
import AssetInfoView from '../views/assets/utils/AssetInfoView.vue';
import ManageUsersView from '../views/manage_users/ManageUsersView.vue';
import JobPage from '../views/jobs/JobPage.vue';
import EditJobView from '../views/jobs/utils/EditJobView.vue';
import AddJobView from '../views/assets/add_job/AddJobView.vue';
import LoginView from '../views/login/LoginView.vue';
import ApproveJobView from '../views/approvals/ApproveJobView.vue';
import AdminControlView from "../views/admin_control/AdminControlView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: JobsView,
  },
  {
    path: '/jobApproval',
    name: 'jobApproval',
    component: JobApprovalView,
  },
  {
    path: '/assets',
    name: 'assets',
    component: AssetsView,
  },
  {
    path: '/addAsset',
    name: 'addAsset',
    component: AddAssetView,
  },
  {
    path: '/editAsset',
    name: 'editAsset',
    component: EditAssetView,
  },
  {
    path: '/assetInfo',
    name: 'assetInfo',
    component: AssetInfoView,
  },
  {
    path: '/manageUsers',
    name: 'manageUsers',
    component: ManageUsersView,
  },
  {
    path: '/admin',
    name: 'adminControl',
    component: AdminControlView,
  },
  {
    path: '/job',
    name: 'job',
    component: JobPage,
  },
  {
    path: '/addJob',
    name: 'addJob',
    component: AddJobView,
  },
  {
    path: '/editJob',
    name: 'editJob',
    component: EditJobView,
  },
  {
    path: '/approveJob',
    name: 'approveJob',
    component: ApproveJobView,
  },
  {
    path: '/uploader',
    name: 'uploader',
    component: Uploader,
  },
  {
    path: '/dev',
    name: 'developer',

    component: DeveloperView,
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

let hasPermission = (permission: string) => {
  if (localStorage.loginData) return JSON.parse(localStorage.loginData).user.permissions.permissions[permission];
};

router.beforeEach((to, from) => {
  let permString: string = "";

  switch (to.fullPath) {
    case "/assets":
      permString = "assets_view/visibility";
      break;
    case "/jobs":
      permString = "jobs_view/visibility";
      break;
    case "/jobApproval":
      permString = "approval_view/visibility";
      break;
    case "/addAsset":
      permString = "add_asset_view/visibility";
      break;
    case "/manageUsers":
      permString = "manage_users/visibility";
      break;
    case "/admin":
      permString = "admin_control/visibility";
      break;
    case "/approveJob":
      return true;
  }

  if (permString) {
    return hasPermission(permString);
  }

  return true;
})

export default router;

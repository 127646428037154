<template>
	<Transition name="fade">
		<div v-if="showReplacement">
			<div style="margin: 15px; display: flex; align-items: center"
			     class="border-radius-16 bg-background box-shadow-2"
			     :class="template.id !== 'temporaryTemplate' ? 'hover-highlight cur-pointer' : ''"
			>
				<div
					class="col-dark-blue"
					style="width: 99%; height: 90px; padding-left: 10px; display: flex; align-items: center; justify-content: space-between"
					@click="toggleDialog($event)">
					
					<div style="height: 90%; width: 150px" class="bg-dark-blue border-radius-16 flex-center">
						<img :src="getThumbnail(template.path)"
						     :id="template.path"
						     style="width: 100%; height: 100%"
						     :class="'template-img' + template.id"
						     class="box-shadow border-radius-8 bor-menu-color"/>
					</div>
					
					<div style="justify-content: space-between; width: 50%" class="flex-center">
						{{ template.name }}
						
						<div style="width: 200px">
							<RoundedLabel :text="template.mimeType" :noHover="true"
							              class="handle-font-overflow border-radius-16 bor-dark-blue bg-tt-white"/>
						</div>
					</div>
					<div></div>
					<div></div>
					<div></div>
					
					<div>
						<i v-if="template.id !== 'temporaryTemplate'"
						   class="pi pi-trash hover-icon"
						   style="margin: 5px; padding: 5px"
						   @click="removeTemplate(template.id)"></i>
						<i v-if="template.id !== 'temporaryTemplate'"
						   class="pi pi-undo hover-icon"
						   style="margin: 5px; padding: 5px"
						   @click="fileReplace.click()"></i>
						<i v-if="template.id === 'temporaryTemplate'"
						   class="pi pi-spin pi-spinner"
						   style="margin: 15px; padding: 15px"></i>
						
						<input
							type="file"
							style="display: none"
							ref="fileReplace"
							@change="replaceTemplate($event)"
						/>
					</div>
				</div>
			</div>
		</div>
	</Transition>
	
	
	<pDialog :header="$t('general.replaceMedia')"
	         v-model:visible="isActive"
	         :maximizable="false"
	         :draggable="false"
	         :modal="true"
	         id="replaceMedia"
	         style="background-color: white; height: 80%; width: 70%">
		
		<div style="width: auto; height: auto">
			
			<div
				style="height: 150px; background-color: #e2e7ec; display: flex; align-items: center; justify-content: space-between; padding: 10px; margin-bottom: 10px;"
				class="border-radius-16">
				<div>
					{{ template.name }}
					{{ template.dataformat }}
				</div>
				
				<div style="display: flex; flex-direction: column">
					<small>Add alternative names</small>
					<Chips v-model="currentNames" style="max-width: 400px; max-height: 130px"/>
				
				</div>
				
				<input
					type="file"
					style="display: none"
					ref="fileInput"
					@change="onFilePicked"
				/>
				
				<div style="display: flex; gap: 5px">
					<RoundedLabel class="bg-dark-blue col-white border-radius-8"
					              style="padding: 8px"
					              @click="fileInput.click()"
					              rightIcon="pi pi-upload"
					              :text="$t('replaceMedia.uploadMediaToReplace')"/>
				</div>
			</div>
			
			<DataTable class="p-datatable-replace-media"
			           :value="replacements"
			           scrollable
			           scrollHeight="flex"
			           removableSort
			           sortMode="multiple"
			           rowExpandMode="single"
			           v-model:expandedRows="expandedRows"
			           @rowExpand="onRowExpand"
			           @rowCollapse="onRowCollapse()"
			           :rows="8">
				<Column expander/>
				
				<Column sortable field="name" :header="$t('general.name')">
					<template #body="slotProps">
            <span class="transitions flex-center" style="width: 100%">
              {{slotProps.data.name}}
            </span>
          </template>
        </Column>
        
        <Column sortable field="languages" :header="$t('general.languages')">
          <template #body="slotProps">
            <span class="transitions flex-center" style="width: 100%">
              
              <div v-for="(language, index) in slotProps.data.languages"
                   style="display: flex; align-items: center">
                <div v-if="index < 3" style="margin: 2px">
                  <Flag :countryFlag="language" style="margin-top: 2px"/>
                </div>
                <RoundedLabel v-if="index === 3"
                              class="border-radius-16 bor-menu-color"
                              style="height: 25px; margin: 2px"
                              :text="slotProps.data.languages.length" :noHover="true"/>
              </div>
            </span>
          </template>
        </Column>
        
        <Column sortable field="groupIds" :header="$t('general.groupIds')">
          <template #body="slotProps">
            <span class=" transitions flex-center" style="width: 100%; justify-content: space-between">
              <div></div>
              <div style="display: flex; flex-direction: row">
                <div v-for="(groupId, index) in slotProps.data.groupIds"
                     style="display: flex; align-items: center">
                  <div v-if="index < 3" style="margin: 2px">
                    {{groupId}}
                  </div>
                  <RoundedLabel v-if="index === 3"
                                class="border-radius-16 bor-menu-color"
                                style="height: 25px; margin: 2px"
                                :text="slotProps.data.groupIds.length" :noHover="true"/>
                </div>
              </div>
              <div>
                <i v-if="slotProps.data.id !== 'temporaryReplacement'"
                   class="pi pi-trash hover-icon"
                   style="margin: 5px; padding: 5px"
                   @click="removeReplacement(slotProps.data)"></i>
            
              </div>
            </span>
          </template>
        </Column>
        
        <template #expansion="slotProps">
          <DataTable class="p-datatable-replace-media"
                     :value="[slotProps.data]"
                     scrollHeight="flex"
                     style="width: 100%"
          >
            
            <Column field="name">
              <template #body="slotProps">
                <div class="template-row flex-center" style="height: 200px; padding-left: 50px">
                  <div>
                    <img :src="getThumbnail(slotProps.data.path)"
                         :id="slotProps.data.path"
                         style="width: 250px; height: 160px"
                         :class="'replacement-img' + template.id"
                         class="box-shadow border-radius-8 bor-menu-color"/>
                    <RoundedLabel :text="$t('general.updateFile')"
                                  class="border-radius-16 bg-dark-blue col-white"
                                  rightIcon="pi pi-upload"
                                  @click="fileUpdate.click()"/>
                    <input
                      type="file"
                      style="display: none"
                      ref="fileUpdate"
                      @change="onClickUpdateFile(slotProps.data.id, $event)"
                    />
                  </div>
                </div>
              </template>
            </Column>
            
            <Column></Column>
            
            <Column field="languages">
              <template #body="slotProps">
                <div class="template-row"
                     style="display: flex; align-items: center; flex-direction: column">
                  <div style="padding-bottom: 5px">
                    <AutoCompleteLanguage v-model:model-value="currentLanguage"
                                          style="width: 240px"
                                          :dropdown-height="150"
                                          :fSelected="() => addLanguage(slotProps.data.id)"
                                          class="border-radius-8 bor-menu-color"/>
                  </div>
                  
                  <div v-for="(language, index) in slotProps.data.languages"
                       style="display: flex; align-items: center; justify-content: space-between; gap: 5px; height: 30px; min-width: 270px; margin-bottom: 5px; padding-left: 5px; padding-right: 5px"
                       class="border-radius-16 bg-menu-color">
                    
                    <Flag :countryFlag="language" style="margin-top: 2px"/>
                    {{language.split(" ")[0]}} {{language.split(" ")[1]}}
                    <i class="pi pi-times hover-icon"
                       style="font-size: 12px; padding: 4px"
                       @click="slotProps.data.languages.splice(index, 1); save()"></i>
                  </div>
                </div>
              </template>
            </Column>
            
            <Column field="groupIds">
              <template #body="slotProps">
                <div class="template-row"
                     style="display: flex; align-items: center; flex-direction: column">
                  <div class="flex-center" style="padding-right: 25px; padding-bottom: 5px">
                    <AutoCompleteGroup v-model:model-value="currentGroup"
                                       style="width: 240px"
                                       :dropdown-height="150"
                                       :fSelected="() => addGroup(slotProps.data.id)"
                                       class="border-radius-8 bor-menu-color"/>
                  </div>
                  
                  <div>
                    <div v-for="(group, index) in slotProps.data.groupIds"
                         style="display: flex; align-items: center; justify-content: space-between; width: 250px; gap: 5px; height: 30px; margin-bottom: 5px; margin-right: 25px; padding-left: 5px; padding-right: 5px"
                         class="border-radius-16 bg-menu-color">
                      {{group}}
                      <i class="pi pi-times hover-icon"
                         style="font-size: 12px; padding: 4px"
                         @click="slotProps.data.groupIds.splice(index, 1); save()"></i>
                    </div>
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
        </template>
      </DataTable>
    </div>
  </pDialog>
</template>

<style>
.p-datatable-replace-media .p-datatable-wrapper .p-datatable-table .p-datatable-tbody > tr > td {
  
  padding: 10px 0 !important;
}

.p-datatable-replace-media .p-datatable-wrapper .p-datatable-table .p-datatable-thead > tr > td {
  color: var(--menu-color) !important;
  background-color: transparent !important;
  border: none !important;
}

.p-datatable-replace-media .p-sortable-column:focus {
  box-shadow: none !important;
}

.p-datatable-replace-media .p-datatable-tbody > tr > td .p-row-toggler:focus {
  box-shadow: none !important;
}

.p-datatable .p-datatable-thead > tr > th {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background) !important;
  border: none !important;
}

.p-column-header-content, .p-sortable-column-icon {
  color: var(--dark-blue);
}

.template-row {
  height: 100%;
}

.hover-icon {
  cursor: pointer;
  border-radius: 16px;
  transition: all 0.2s;
}

.hover-icon:hover {
  transform: scale(1.1);
  box-shadow: 0 0 4px 1px var(--dark-blue);
}

.cur-pointer {
  cursor: pointer;
}

</style>

<script src="./ReplaceMediaDialog.ts"></script>

<template>
  <div
    style="cursor: pointer;"
    @click="
      getCountryFlags().length <= 1
        ? $emitter.emit('download-job', [item, getCountryFlags()[0]])
        : ''
    "
  >
    <RoundedLabel
      v-if="getCountryFlags().length <= 1"
      leftIcon="pi pi-download"
      style="width: 48px;cursor-events:none;"
    />
    <Dropdown
      id="roundedLabel"
      v-if="getCountryFlags().length > 1"
      class="border-radius-24 col-dark-blue bor-menu-color bg-transparent"
      style="
          display: flex;
          height: 32px;
          width: 38px;
          padding-right: 10px;cursor-events:none;
          "
      :loading="showDownloadIcon"
      loading-icon="pi pi-download"
      v-model="innerModelValue"
      :options="getCountryFlags()"
      @mouseenter="showDownloadIcon = false"
      @mouseleave="showDownloadIcon = true"
      @change="$emitter.emit('download-job', [item, innerModelValue])"
    />
  </div>
</template>

<script src="./DownloadJobDropdown.ts"></script>

import {defineComponent, onMounted, ref, watch} from 'vue';
import {CustomerMetadata} from "../../../target/api/de/moovit/titletoolserver/model";
import {SystemApi} from "../../../target/api";

export default defineComponent({
  props: ['metadata', 'checkForRequired', 'autoSave', 'bigString', 'bigStringHeight'],
  components: {},
  setup: function (props, context) {
    const systemApi: SystemApi = new SystemApi();

    let innerMetadata = ref<CustomerMetadata>({});

    let isInvalid = ref<boolean>(false);

    let onChangeMetadata = (metadata: CustomerMetadata) => {
      innerMetadata.value = metadata;
      isInvalid.value = checkRequired(innerMetadata.value.type!);
      context.emit('update:metadata', innerMetadata.value);

      if (props.autoSave) {
        systemApi.addCustomerMetadata(innerMetadata.value);
      }
    }

    let checkRequired = (type: string): boolean => {
      if (props.checkForRequired && innerMetadata.value.required) {
        switch (type) {
          case "STRING":
            return innerMetadata.value.typeValueString === undefined;
          case "INTEGER":
            return innerMetadata.value.typeValueInteger === undefined;
          case "DOUBLE":
            return innerMetadata.value.typeValueDouble === undefined;
          case "BOOLEAN":
            return innerMetadata.value.typeValueBoolean === undefined;
        }
      }
      return false;
    }

    watch(props, () => {
      innerMetadata.value = props.metadata;
      isInvalid.value = checkRequired(innerMetadata.value.type!);
    });

    onMounted(() => {
      innerMetadata.value = props.metadata;
    });

    return {
      innerMetadata,
      isInvalid,
      onChangeMetadata,
      checkRequired
    };
  },
});

import {defineComponent, getCurrentInstance, onMounted, onUnmounted, ref} from 'vue';
import {useRouter} from 'vue-router';
import {useI18n} from 'vue-i18n';
import {
  AssetItem,
  NotificationDto, Progress,
  RendernodeInformation, RendernodeMessage,
  UserNotification,
  WorkflowEngineMessageType, WsTopic
} from '../../../target/api/de/moovit/titletoolserver/model';
import {AssetItemApi, SystemApi, UserApi} from '../../../target/api';
import {useStore} from 'vuex';

import {LineChart} from 'vue-chart-3';

export default defineComponent({
  components: {LineChart},
  setup: function () {
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;
    const router = useRouter();
    const store = useStore();
    const i18n: any = useI18n();
    const systemApi: SystemApi = new SystemApi();
    const userApi: UserApi = new UserApi();
    const assetItemApi: AssetItemApi = new AssetItemApi();

    let darkmodeActive = ref<boolean>();

    let notificationPanel = ref();
    let serverStatusPanel = ref();
    let notificationTab = ref<number>(2);

    let language: any = ref(undefined);

    let rendernodeMessages = ref<Array<RendernodeMessage>>([]);
    let intervalId = ref();
    let progressTimeoutId = ref();

    let assetItemRenderProgress = ref<Progress>();
    let progressAssetItem = ref<AssetItem>();
    let chartOptions = ref<any>({
      responsive: true,
      plugins: {
        legend: false,
        tooltip: false
      },
      scales: {
        y: {
          title: {
            display: true,
            text: 'Jobs Queued'
          },
          suggestedMin: 0,
          suggestedMax: 5
        }
      }
    });

    let onChangeLanguage = (e: any) => {
      let newLanguageObj = e.value;
      let newLanguage = e.value.name;
      console.debug("onChangeLanguage", newLanguage);

      i18n.locale.value = newLanguage;
      localStorage.language = JSON.stringify(newLanguageObj);
      store.commit("SHOW_SNACKBAR", {
        snackbarContent: {t: "snackbar.changedLanguage"},
      });
    };

    let isUserAdmin = () => {
      return localStorage.loginData && JSON.parse(localStorage.loginData).user.role === "ADMIN";
    }

    let logout = () => {
      let data: any = JSON.stringify(localStorage.getItem("loginData"));

      let notifyDto: NotificationDto = {
        topic: WorkflowEngineMessageType.LOGOUT,
      };
      notifyDto.user = data.user;
      systemApi.sendMessageToWorkflowEngine(notifyDto.topic, notifyDto);

      router.push("/");

      setTimeout(() => {
        localStorage.removeItem("loginData");
      }, 100);
    };

    let toggleDarkmode = () => {
      let darkmode = localStorage.getItem("darkmodeVisible");
      if (darkmode == "active") {
        localStorage.setItem("darkmodeVisible", "inactive");
        darkmodeActive.value = false;
      } else {
        localStorage.setItem("darkmodeVisible", "active");
        darkmodeActive.value = true;
      }


      let element = document.getElementById('app');
      element!.classList.toggle("darkMode")

      //let body = document.body;
      //body.classList.toggle("darkMode")
    };

    let toggleNotifications = (event: Event) => {
      notificationPanel.value.toggle(event);
      notificationTab.value = 2;
    }

    let toggleServerStatus = (event: Event) => {
      serverStatusPanel.value.toggle(event);
    }

    let getLatestRendernodeInformation = (): RendernodeInformation | undefined => {
      if (rendernodeMessages.value && rendernodeMessages.value.length >= 1) {
        return rendernodeMessages.value[rendernodeMessages.value.length - 1].rendernodeInformation;
      } else {
        return {}
      }
    }

    let getChartInformation = (address: string) => {
      let chart: Array<number> = [];
      const labels = [];
      const documentStyle = getComputedStyle(document.documentElement);

      for (const rendernode of rendernodeMessages.value) {
        if (rendernode.rendernodeInformation) {
          if (rendernode.rendernodeInformation.address == address) {
            chart.push(rendernode.rendernodeInformation.queueSize!);
          }
        }
      }

      for (const key in chart) {
        if (key == "0") {
          labels.push("Past");
        } else if (key == ("" + (chart.length - 1))) {
          labels.push("Now");
        } else {
          labels.push("");
        }
      }

      return {
        labels: labels,
        datasets: [
          {
            label: "",
            data: chart,
            borderColor: documentStyle.getPropertyValue('--dark-blue'),
            fill: false,
            tension: 0.0,
            cubicInterpolationMode: 'monotone',
          },
        ],
      };
    }

    let isNotificationUnread = (): boolean => {
      if (!store.state.notifications) return false;
      return (store.state.notifications.filter((notify: UserNotification) => notify.read !== true).length > 0)
    }

    let markAllAsRead = () => {
      store.state.notifications.forEach((notification: UserNotification) => {
        if (!notification.read) {
          notification.read = true;
          userApi.updateNotification(notification);
        }
      })
    }

    let handleAssetItemProgress = (progress: Progress) => {
      assetItemRenderProgress.value = progress;

      if (progressTimeoutId.value) {
        clearTimeout(progressTimeoutId.value);
      }

      progressTimeoutId.value = setTimeout(() => {
        assetItemRenderProgress.value = undefined;
      }, 10000);

      if (assetItemRenderProgress.value && assetItemRenderProgress.value.assetItemId) {
        setAssetItem(assetItemRenderProgress.value.assetItemId);
      }
    }

    let setAssetItem = (assetItemId: string) => {
      if (progressAssetItem.value && assetItemId !== progressAssetItem.value.id) {
        assetItemApi.getAssetItemById(assetItemId).then(assetItem => {
          progressAssetItem.value = assetItem.data;
        });
      } else if (!progressAssetItem.value) {
        assetItemApi.getAssetItemById(assetItemId).then(assetItem => {
          progressAssetItem.value = assetItem.data;
        });
      }
    }

    let onClickGoToJob = () => {
      if (progressAssetItem.value && progressAssetItem.value.assetID) {
        router.push({
          name: 'job',
          query: {id: progressAssetItem.value.assetID},
        });
        if (progressTimeoutId.value) {
          clearTimeout(progressTimeoutId.value);
          assetItemRenderProgress.value = undefined;
        }
      }
    }

    onMounted(() => {
      darkmodeActive.value = localStorage.getItem("darkmodeVisible") === "active";

      if (localStorage.language) {
        language.value = JSON.parse(localStorage.language);
      } else {
        language.value = {name: "en", value: "United Kingdom"};
      }
      i18n.locale.value = language.value.name;


      emitter.on("ws" + WsTopic.RENDERNODEINFORMATION, (rendernodeMessageArray: RendernodeMessage[]) => {
        rendernodeMessages.value = rendernodeMessageArray;
      });


      // intervalId.value = setInterval(() => {
      //   _updateServerStatusList();
      //
      // }, 5000);

      // emitter.on("wsHeader" + WsTopic.ASSETITEMPROGRESS, handleAssetItemProgress)
    });

    onUnmounted(() => {
      if (intervalId.value) {
        clearInterval(intervalId.value);
      }

      emitter.off("ws" + WsTopic.RENDERNODEINFORMATION);
      // emitter.off("wsHeader" + WsTopic.ASSETITEMPROGRESS)
    })

    return {
      logout,
      toggleDarkmode,
      onChangeLanguage,
      toggleNotifications,
      toggleServerStatus,
      isNotificationUnread,
      markAllAsRead,
      isUserAdmin,
      getLatestRendernodeInformation,
      getChartInformation,
      onClickGoToJob,
      assetItemRenderProgress,
      progressAssetItem,
      chartOptions,
      notificationTab,
      notificationPanel,
      serverStatusPanel,
      language,
      darkmodeActive,
      rendernodeMessages,
    };
  },
});

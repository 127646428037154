import {defineComponent, onMounted, ref} from 'vue';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';
import {AssetApi, AssetItemApi, FieldApi, SystemApi} from '../../../../target/api';
import {
  Asset,
  AssetItem,
  CustomerMetadata,
  Field
} from '../../../../target/api/de/moovit/titletoolserver/model';
import main from '../../../main';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  components: {},
  setup: function (props) {
    const route = useRoute();
    const store = useStore();
    const i18n: any = useI18n();

    const assetApi: AssetApi = new AssetApi();
    const assetItemApi: AssetItemApi = new AssetItemApi();
    const systemApi: SystemApi = new SystemApi();
    const fieldApi: FieldApi = new FieldApi();

    let currentAsset = ref<Asset>({});
    let currentLanguage = ref<{ name: string, value: string }>();
    let currentGroup = ref<string>();
    let currentAssetName = ref<string>();
    let currentThirdPartyID = ref<string>();

    let headerItem: any = ref(undefined);
    let updateJobs = ref(false);

    let customerMetadata = ref<Array<CustomerMetadata>>();

    let fields = ref<Array<Field>>();

    let showFieldDialog = ref<boolean>(false);

    let updateAsset = async () => {
      currentAsset.value.name = currentAssetName.value;
      currentAsset.value.thirdPartyID = currentThirdPartyID.value;
      currentAsset.value.groupID = currentGroup.value;
      if (currentLanguage.value) {
        currentAsset.value.masterAssetItem!.language = currentLanguage.value.name;
      }
      console.debug('updateAsset - ITEM', JSON.parse(JSON.stringify(currentAsset.value)));
      await assetApi.updateAsset({asset: currentAsset.value, updateJobs: updateJobs.value});
      main.config.globalProperties.$emitter.emit('search-assets', null);

      if (customerMetadata.value) {
        for (let metadata of customerMetadata.value) {
          systemApi.addCustomerMetadata(metadata);
        }
      }

      if (fields.value) {
        for (let field of fields.value) {
          fieldApi.updateField(field.id!, field);
        }
      }

      store.commit('SHOW_SNACKBAR', {
        snackbarContent: {t: 'snackbar.editAssetSaved'},
      });
    };

    let getUpdateJobText = () => {
      return i18n.t('general.updateJob', 2);
    };

    onMounted(async () => {
      assetApi.getAssetById('' + route.query.id).then(result => {
        let item: Asset = result.data;

        console.debug('EditAsset - Item', item);

        let tAssets = {
          t: 'activityBreadcrumbs.assets',
          e: undefined,
        };
        let tEditAsset = {
          t: 'activityBreadcrumbs.editAsset',
          e: [item.name],
        };
        store.commit('SET_ACTIVITY_BREADCRUMBS', [tAssets, tEditAsset]);

        currentAsset.value = item;
        headerItem.value = item;

        currentAssetName.value = item.name;
        currentThirdPartyID.value = item.thirdPartyID;
        currentGroup.value = item.groupID;

        if (item && item.masterAssetItem && item.masterAssetItem.language) {
          currentLanguage.value = {
            name: item.masterAssetItem.language,
            value: item.masterAssetItem.language,
          };
        }
      });

      assetItemApi.getAssetItemById('' + route.query.id).then(result => {
        let assetItem: AssetItem = result.data;

        if (!assetItem) {
          return;
        }

        if (!assetItem.customerMetadata) {
          assetItem.customerMetadata = [];
        }
        systemApi.getCustomMetadataByIdList(assetItem.customerMetadata).then(result => {
          customerMetadata.value = result.data;
        })

        if (!assetItem.fields) {
          assetItem.fields = [];
        }
        fieldApi.getFieldsByIds(assetItem.fields).then(result => {
          fields.value = result.data;
        });
      });
    });

    return {
      headerItem,
      updateAsset,
      getUpdateJobText,
      customerMetadata,
      currentLanguage,
      currentGroup,
      currentAssetName,
      currentThirdPartyID,
      updateJobs,
      showFieldDialog,
      fields,
    };
  },
});

import {defineComponent, nextTick, onMounted, ref, watch} from 'vue';

export default defineComponent({
  components: {},
  props: ['modelValue', 'leftLabel', 'rightLabel', 'disabled', 'tooltip'],
  setup: function (props, context) {
    let innerModelValue = ref(false);

    watch(props, () => {
      innerModelValue.value = props.modelValue;
    });

    onMounted(() => {
      nextTick(() => {
        innerModelValue.value = props.modelValue;
      });
    });

    return {innerModelValue};
  },
});

// tslint:disable
/**
 * titletool-nextgen-server
 * TitleTool NextGen Server
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: r.hutter@moovit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { Asset } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { AssetItem } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { AssetItemStatus } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { CreateJobObject } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { FullAssetItem } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { Progress } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { SearchAssetsObject } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { SimpleAsset } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { UpdateAssetObject } from '../../../../de/moovit/titletoolserver/model';
/**
 * AssetApi - axios parameter creator
 * @export
 */
export const AssetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addAsset
         * @param {Asset} [asset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAsset: async (asset?: Asset, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof asset !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(asset !== undefined ? asset : {}) : (asset || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addSimpleAsset
         * @param {SimpleAsset} [simpleAsset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSimpleAsset: async (simpleAsset?: SimpleAsset, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/approval`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof simpleAsset !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(simpleAsset !== undefined ? simpleAsset : {}) : (simpleAsset || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary calculateAssetStatus
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAssetStatus: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling calculateAssetStatus.');
            }
            const localVarPath = `/asset/calculate/status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cancelAssetUpload
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAssetUpload: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling cancelAssetUpload.');
            }
            const localVarPath = `/asset/{id}/import`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary checkAssetRejected
         * @param {string} [comment] 
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAssetRejected: async (comment?: string, assetItem?: AssetItem, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/approval/reject`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof assetItem !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(assetItem !== undefined ? assetItem : {}) : (assetItem || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create job for asset
         * @param {string} id 
         * @param {CreateJobObject} [createJobObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJob: async (id: string, createJobObject?: CreateJobObject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createJob.');
            }
            const localVarPath = `/asset/{id}/createJob`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createJobObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createJobObject !== undefined ? createJobObject : {}) : (createJobObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAsset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAsset.');
            }
            const localVarPath = `/asset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteSimpleAsset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSimpleAsset: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSimpleAsset.');
            }
            const localVarPath = `/asset/approval/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary destroyAsset
         * @param {string} id 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyAsset: async (id: string, sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling destroyAsset.');
            }
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId','Required parameter sessionId was null or undefined when calling destroyAsset.');
            }
            const localVarPath = `/asset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (sessionId !== undefined) {
                localVarQueryParameter['sessionId'] = sessionId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadAssetProject
         * @param {string} id 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAssetProject: async (id: string, sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadAssetProject.');
            }
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId','Required parameter sessionId was null or undefined when calling downloadAssetProject.');
            }
            const localVarPath = `/asset/item/download/project`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (sessionId !== undefined) {
                localVarQueryParameter['sessionId'] = sessionId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generateAssetFromJob
         * @param {string} assetItemId 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAssetFromJob: async (assetItemId: string, assetId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetItemId' is not null or undefined
            if (assetItemId === null || assetItemId === undefined) {
                throw new RequiredError('assetItemId','Required parameter assetItemId was null or undefined when calling generateAssetFromJob.');
            }
            // verify required parameter 'assetId' is not null or undefined
            if (assetId === null || assetId === undefined) {
                throw new RequiredError('assetId','Required parameter assetId was null or undefined when calling generateAssetFromJob.');
            }
            const localVarPath = `/asset/generate/job`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (assetItemId !== undefined) {
                localVarQueryParameter['assetItemId'] = assetItemId;
            }

            if (assetId !== undefined) {
                localVarQueryParameter['assetId'] = assetId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAssetById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAssetById.');
            }
            const localVarPath = `/asset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAssetItemThumbnailById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetItemThumbnailById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAssetItemThumbnailById.');
            }
            const localVarPath = `/asset/item/{id}/thumbnail`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAssetProgress
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetProgress: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAssetProgress.');
            }
            const localVarPath = `/asset/{id}/progress`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAssetThumbnailById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetThumbnailById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAssetThumbnailById.');
            }
            const localVarPath = `/asset/{id}/thumbnail`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSimpleAssetById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleAssetById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSimpleAssetById.');
            }
            const localVarPath = `/asset/approval/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getZipAssetProject
         * @param {string} sessionId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZipAssetProject: async (sessionId: string, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId','Required parameter sessionId was null or undefined when calling getZipAssetProject.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getZipAssetProject.');
            }
            const localVarPath = `/asset/item/zipped/project/{sessionId}/{name}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ingestAsset
         * @param {string} [assetId] 
         * @param {FullAssetItem} [fullAssetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ingestAsset: async (assetId?: string, fullAssetItem?: FullAssetItem, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/ingest`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (assetId !== undefined) {
                localVarQueryParameter['assetId'] = assetId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof fullAssetItem !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fullAssetItem !== undefined ? fullAssetItem : {}) : (fullAssetItem || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lockAsset
         * @param {string} id 
         * @param {boolean} [lock] 
         * @param {string} [reason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockAsset: async (id: string, lock?: boolean, reason?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling lockAsset.');
            }
            const localVarPath = `/asset/{id}/lock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lock !== undefined) {
                localVarQueryParameter['lock'] = lock;
            }

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reset all values to the initial value
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetFieldValues: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling resetFieldValues.');
            }
            const localVarPath = `/asset/{id}/resetFieldValues`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchAssets
         * @param {SearchAssetsObject} [searchAssetsObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAssets: async (searchAssetsObject?: SearchAssetsObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof searchAssetsObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(searchAssetsObject !== undefined ? searchAssetsObject : {}) : (searchAssetsObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sendSimpleAsset
         * @param {string} [email] 
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSimpleAsset: async (email?: string, assetItem?: AssetItem, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/approval/send`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof assetItem !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(assetItem !== undefined ? assetItem : {}) : (assetItem || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateAsset
         * @param {UpdateAssetObject} [updateAssetObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAsset: async (updateAssetObject?: UpdateAssetObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateAssetObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateAssetObject !== undefined ? updateAssetObject : {}) : (updateAssetObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateMasterAssetStatus
         * @param {string} id 
         * @param {AssetItemStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterAssetStatus: async (id: string, status: AssetItemStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateMasterAssetStatus.');
            }
            // verify required parameter 'status' is not null or undefined
            if (status === null || status === undefined) {
                throw new RequiredError('status','Required parameter status was null or undefined when calling updateMasterAssetStatus.');
            }
            const localVarPath = `/asset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateSimpleAsset
         * @param {SimpleAsset} [simpleAsset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSimpleAsset: async (simpleAsset?: SimpleAsset, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/approval`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof simpleAsset !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(simpleAsset !== undefined ? simpleAsset : {}) : (simpleAsset || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary verifyAsset
         * @param {string} sessionId 
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAsset: async (sessionId: string, assetItem?: AssetItem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId','Required parameter sessionId was null or undefined when calling verifyAsset.');
            }
            const localVarPath = `/asset/verify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (sessionId !== undefined) {
                localVarQueryParameter['sessionId'] = sessionId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof assetItem !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(assetItem !== undefined ? assetItem : {}) : (assetItem || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetApi - functional programming interface
 * @export
 */
export const AssetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addAsset
         * @param {Asset} [asset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAsset(asset?: Asset, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).addAsset(asset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary addSimpleAsset
         * @param {SimpleAsset} [simpleAsset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSimpleAsset(simpleAsset?: SimpleAsset, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleAsset>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).addSimpleAsset(simpleAsset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary calculateAssetStatus
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateAssetStatus(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).calculateAssetStatus(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary cancelAssetUpload
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelAssetUpload(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).cancelAssetUpload(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary checkAssetRejected
         * @param {string} [comment] 
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAssetRejected(comment?: string, assetItem?: AssetItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).checkAssetRejected(comment, assetItem, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary create job for asset
         * @param {string} id 
         * @param {CreateJobObject} [createJobObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createJob(id: string, createJobObject?: CreateJobObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).createJob(id, createJobObject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary deleteAsset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAsset(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).deleteAsset(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary deleteSimpleAsset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSimpleAsset(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).deleteSimpleAsset(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary destroyAsset
         * @param {string} id 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyAsset(id: string, sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).destroyAsset(id, sessionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary downloadAssetProject
         * @param {string} id 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAssetProject(id: string, sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).downloadAssetProject(id, sessionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary generateAssetFromJob
         * @param {string} assetItemId 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateAssetFromJob(assetItemId: string, assetId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).generateAssetFromJob(assetItemId, assetId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getAssetById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).getAssetById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getAssetItemThumbnailById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetItemThumbnailById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).getAssetItemThumbnailById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getAssetProgress
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetProgress(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Progress>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).getAssetProgress(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getAssetThumbnailById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetThumbnailById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).getAssetThumbnailById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getSimpleAssetById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimpleAssetById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleAsset>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).getSimpleAssetById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getZipAssetProject
         * @param {string} sessionId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZipAssetProject(sessionId: string, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).getZipAssetProject(sessionId, name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ingestAsset
         * @param {string} [assetId] 
         * @param {FullAssetItem} [fullAssetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ingestAsset(assetId?: string, fullAssetItem?: FullAssetItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).ingestAsset(assetId, fullAssetItem, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary lockAsset
         * @param {string} id 
         * @param {boolean} [lock] 
         * @param {string} [reason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockAsset(id: string, lock?: boolean, reason?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).lockAsset(id, lock, reason, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary reset all values to the initial value
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetFieldValues(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).resetFieldValues(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary searchAssets
         * @param {SearchAssetsObject} [searchAssetsObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAssets(searchAssetsObject?: SearchAssetsObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Asset>>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).searchAssets(searchAssetsObject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary sendSimpleAsset
         * @param {string} [email] 
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSimpleAsset(email?: string, assetItem?: AssetItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleAsset>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).sendSimpleAsset(email, assetItem, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateAsset
         * @param {UpdateAssetObject} [updateAssetObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAsset(updateAssetObject?: UpdateAssetObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).updateAsset(updateAssetObject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateMasterAssetStatus
         * @param {string} id 
         * @param {AssetItemStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMasterAssetStatus(id: string, status: AssetItemStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).updateMasterAssetStatus(id, status, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateSimpleAsset
         * @param {SimpleAsset} [simpleAsset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSimpleAsset(simpleAsset?: SimpleAsset, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleAsset>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).updateSimpleAsset(simpleAsset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary verifyAsset
         * @param {string} sessionId 
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyAsset(sessionId: string, assetItem?: AssetItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AssetApiAxiosParamCreator(configuration).verifyAsset(sessionId, assetItem, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AssetApi - factory interface
 * @export
 */
export const AssetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary addAsset
         * @param {Asset} [asset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAsset(asset?: Asset, options?: any): AxiosPromise<Asset> {
            return AssetApiFp(configuration).addAsset(asset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary addSimpleAsset
         * @param {SimpleAsset} [simpleAsset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSimpleAsset(simpleAsset?: SimpleAsset, options?: any): AxiosPromise<SimpleAsset> {
            return AssetApiFp(configuration).addSimpleAsset(simpleAsset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary calculateAssetStatus
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAssetStatus(id: string, options?: any): AxiosPromise<void> {
            return AssetApiFp(configuration).calculateAssetStatus(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cancelAssetUpload
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAssetUpload(id: string, options?: any): AxiosPromise<boolean> {
            return AssetApiFp(configuration).cancelAssetUpload(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary checkAssetRejected
         * @param {string} [comment] 
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAssetRejected(comment?: string, assetItem?: AssetItem, options?: any): AxiosPromise<boolean> {
            return AssetApiFp(configuration).checkAssetRejected(comment, assetItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create job for asset
         * @param {string} id 
         * @param {CreateJobObject} [createJobObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJob(id: string, createJobObject?: CreateJobObject, options?: any): AxiosPromise<Asset> {
            return AssetApiFp(configuration).createJob(id, createJobObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteAsset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset(id: string, options?: any): AxiosPromise<void> {
            return AssetApiFp(configuration).deleteAsset(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteSimpleAsset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSimpleAsset(id: string, options?: any): AxiosPromise<void> {
            return AssetApiFp(configuration).deleteSimpleAsset(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary destroyAsset
         * @param {string} id 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyAsset(id: string, sessionId: string, options?: any): AxiosPromise<void> {
            return AssetApiFp(configuration).destroyAsset(id, sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary downloadAssetProject
         * @param {string} id 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAssetProject(id: string, sessionId: string, options?: any): AxiosPromise<void> {
            return AssetApiFp(configuration).downloadAssetProject(id, sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary generateAssetFromJob
         * @param {string} assetItemId 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAssetFromJob(assetItemId: string, assetId: string, options?: any): AxiosPromise<string> {
            return AssetApiFp(configuration).generateAssetFromJob(assetItemId, assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAssetById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetById(id: string, options?: any): AxiosPromise<Asset> {
            return AssetApiFp(configuration).getAssetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAssetItemThumbnailById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetItemThumbnailById(id: string, options?: any): AxiosPromise<any> {
            return AssetApiFp(configuration).getAssetItemThumbnailById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAssetProgress
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetProgress(id: string, options?: any): AxiosPromise<Progress> {
            return AssetApiFp(configuration).getAssetProgress(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAssetThumbnailById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetThumbnailById(id: string, options?: any): AxiosPromise<any> {
            return AssetApiFp(configuration).getAssetThumbnailById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getSimpleAssetById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleAssetById(id: string, options?: any): AxiosPromise<SimpleAsset> {
            return AssetApiFp(configuration).getSimpleAssetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getZipAssetProject
         * @param {string} sessionId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZipAssetProject(sessionId: string, name: string, options?: any): AxiosPromise<any> {
            return AssetApiFp(configuration).getZipAssetProject(sessionId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ingestAsset
         * @param {string} [assetId] 
         * @param {FullAssetItem} [fullAssetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ingestAsset(assetId?: string, fullAssetItem?: FullAssetItem, options?: any): AxiosPromise<Asset> {
            return AssetApiFp(configuration).ingestAsset(assetId, fullAssetItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lockAsset
         * @param {string} id 
         * @param {boolean} [lock] 
         * @param {string} [reason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockAsset(id: string, lock?: boolean, reason?: string, options?: any): AxiosPromise<void> {
            return AssetApiFp(configuration).lockAsset(id, lock, reason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reset all values to the initial value
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetFieldValues(id: string, options?: any): AxiosPromise<Asset> {
            return AssetApiFp(configuration).resetFieldValues(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchAssets
         * @param {SearchAssetsObject} [searchAssetsObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAssets(searchAssetsObject?: SearchAssetsObject, options?: any): AxiosPromise<Array<Asset>> {
            return AssetApiFp(configuration).searchAssets(searchAssetsObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sendSimpleAsset
         * @param {string} [email] 
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSimpleAsset(email?: string, assetItem?: AssetItem, options?: any): AxiosPromise<SimpleAsset> {
            return AssetApiFp(configuration).sendSimpleAsset(email, assetItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateAsset
         * @param {UpdateAssetObject} [updateAssetObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAsset(updateAssetObject?: UpdateAssetObject, options?: any): AxiosPromise<Asset> {
            return AssetApiFp(configuration).updateAsset(updateAssetObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateMasterAssetStatus
         * @param {string} id 
         * @param {AssetItemStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMasterAssetStatus(id: string, status: AssetItemStatus, options?: any): AxiosPromise<Asset> {
            return AssetApiFp(configuration).updateMasterAssetStatus(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateSimpleAsset
         * @param {SimpleAsset} [simpleAsset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSimpleAsset(simpleAsset?: SimpleAsset, options?: any): AxiosPromise<SimpleAsset> {
            return AssetApiFp(configuration).updateSimpleAsset(simpleAsset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary verifyAsset
         * @param {string} sessionId 
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAsset(sessionId: string, assetItem?: AssetItem, options?: any): AxiosPromise<void> {
            return AssetApiFp(configuration).verifyAsset(sessionId, assetItem, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetApi - object-oriented interface
 * @export
 * @class AssetApi
 * @extends {BaseAPI}
 */
export class AssetApi extends BaseAPI {
    /**
     * 
     * @summary addAsset
     * @param {Asset} [asset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public addAsset(asset?: Asset, options?: any) {
        return AssetApiFp(this.configuration).addAsset(asset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary addSimpleAsset
     * @param {SimpleAsset} [simpleAsset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public addSimpleAsset(simpleAsset?: SimpleAsset, options?: any) {
        return AssetApiFp(this.configuration).addSimpleAsset(simpleAsset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary calculateAssetStatus
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public calculateAssetStatus(id: string, options?: any) {
        return AssetApiFp(this.configuration).calculateAssetStatus(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cancelAssetUpload
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public cancelAssetUpload(id: string, options?: any) {
        return AssetApiFp(this.configuration).cancelAssetUpload(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary checkAssetRejected
     * @param {string} [comment] 
     * @param {AssetItem} [assetItem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public checkAssetRejected(comment?: string, assetItem?: AssetItem, options?: any) {
        return AssetApiFp(this.configuration).checkAssetRejected(comment, assetItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create job for asset
     * @param {string} id 
     * @param {CreateJobObject} [createJobObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public createJob(id: string, createJobObject?: CreateJobObject, options?: any) {
        return AssetApiFp(this.configuration).createJob(id, createJobObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteAsset
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public deleteAsset(id: string, options?: any) {
        return AssetApiFp(this.configuration).deleteAsset(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteSimpleAsset
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public deleteSimpleAsset(id: string, options?: any) {
        return AssetApiFp(this.configuration).deleteSimpleAsset(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary destroyAsset
     * @param {string} id 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public destroyAsset(id: string, sessionId: string, options?: any) {
        return AssetApiFp(this.configuration).destroyAsset(id, sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary downloadAssetProject
     * @param {string} id 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public downloadAssetProject(id: string, sessionId: string, options?: any) {
        return AssetApiFp(this.configuration).downloadAssetProject(id, sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary generateAssetFromJob
     * @param {string} assetItemId 
     * @param {string} assetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public generateAssetFromJob(assetItemId: string, assetId: string, options?: any) {
        return AssetApiFp(this.configuration).generateAssetFromJob(assetItemId, assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAssetById
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public getAssetById(id: string, options?: any) {
        return AssetApiFp(this.configuration).getAssetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAssetItemThumbnailById
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public getAssetItemThumbnailById(id: string, options?: any) {
        return AssetApiFp(this.configuration).getAssetItemThumbnailById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAssetProgress
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public getAssetProgress(id: string, options?: any) {
        return AssetApiFp(this.configuration).getAssetProgress(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAssetThumbnailById
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public getAssetThumbnailById(id: string, options?: any) {
        return AssetApiFp(this.configuration).getAssetThumbnailById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getSimpleAssetById
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public getSimpleAssetById(id: string, options?: any) {
        return AssetApiFp(this.configuration).getSimpleAssetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getZipAssetProject
     * @param {string} sessionId 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public getZipAssetProject(sessionId: string, name: string, options?: any) {
        return AssetApiFp(this.configuration).getZipAssetProject(sessionId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ingestAsset
     * @param {string} [assetId] 
     * @param {FullAssetItem} [fullAssetItem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public ingestAsset(assetId?: string, fullAssetItem?: FullAssetItem, options?: any) {
        return AssetApiFp(this.configuration).ingestAsset(assetId, fullAssetItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lockAsset
     * @param {string} id 
     * @param {boolean} [lock] 
     * @param {string} [reason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public lockAsset(id: string, lock?: boolean, reason?: string, options?: any) {
        return AssetApiFp(this.configuration).lockAsset(id, lock, reason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reset all values to the initial value
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public resetFieldValues(id: string, options?: any) {
        return AssetApiFp(this.configuration).resetFieldValues(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchAssets
     * @param {SearchAssetsObject} [searchAssetsObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public searchAssets(searchAssetsObject?: SearchAssetsObject, options?: any) {
        return AssetApiFp(this.configuration).searchAssets(searchAssetsObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sendSimpleAsset
     * @param {string} [email] 
     * @param {AssetItem} [assetItem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public sendSimpleAsset(email?: string, assetItem?: AssetItem, options?: any) {
        return AssetApiFp(this.configuration).sendSimpleAsset(email, assetItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateAsset
     * @param {UpdateAssetObject} [updateAssetObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public updateAsset(updateAssetObject?: UpdateAssetObject, options?: any) {
        return AssetApiFp(this.configuration).updateAsset(updateAssetObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateMasterAssetStatus
     * @param {string} id 
     * @param {AssetItemStatus} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public updateMasterAssetStatus(id: string, status: AssetItemStatus, options?: any) {
        return AssetApiFp(this.configuration).updateMasterAssetStatus(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateSimpleAsset
     * @param {SimpleAsset} [simpleAsset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public updateSimpleAsset(simpleAsset?: SimpleAsset, options?: any) {
        return AssetApiFp(this.configuration).updateSimpleAsset(simpleAsset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary verifyAsset
     * @param {string} sessionId 
     * @param {AssetItem} [assetItem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public verifyAsset(sessionId: string, assetItem?: AssetItem, options?: any) {
        return AssetApiFp(this.configuration).verifyAsset(sessionId, assetItem, options).then((request) => request(this.axios, this.basePath));
    }

}

<template>
  <div style="display:flex; align-items:center">
    <InputText
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      type="text"
      :placeholder="$t('general.search')"
      class="col-dark-blue bor-dark-blue bg-transparent border-radius-4"
      style="width:285px; height:40px; padding:0px 16px 0px 16px;"
      @keyup.enter="blurFunction"
      @blur="blurFunction"
      @click:clear="blurFunction"
    
    /><!-- @keyup.enter="()=>{$emit('update:modelValue', 2)}"
      @blur="()=>{$emit('update:modelValue', 3)}"
      @click:clear="()=>{$emit('update:modelValue', 4)}" -->
    <i class="pi pi-search" style="margin-left:-30px;"/>
  </div>
</template>

<script src="./SearchField.ts"></script>

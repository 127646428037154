import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { SystemApi } from "../../../target/api";

export default defineComponent({
  props: [
    "modelValue",
    "label",
    "options",
    "optionLabel",
    "placeholder",
    "invalid",
    "disabled",
    "showClear",
    "selectedLanguages",
    "filter",
    "includesFlag",
    "dropdownHeight",
    "textOverFlowWidth",
    "includesAutotranslate",
    "selectMulti"

  ],
  components: {},
  setup: function(props, context) {
    let innerModelValue: any = ref<any>();
    let innerOptions: any = ref<any[]>([]);

    let isDropdownOpen = ref<boolean>(false);
    let isModelValueInOptions = ref<boolean>(false);

    let filterText = ref<string>("");

    const systemApi = new SystemApi();
    let autoTranslatableLanguages = ref<Array<String>>();

    let onChangeFilterValue = () => {
      innerOptions.value = props.options;

      innerOptions.value = innerOptions.value.filter((option: any) =>
        (props.optionLabel ? option[props.optionLabel] : option)
          .toLowerCase()
          .includes(filterText.value.toLowerCase())
      );
    };

    let onClickOption = (option: any) => {
      innerModelValue.value = option;
      filterText.value = "";
      innerOptions.value = props.options;
      context.emit("update:modelValue", option);
    };

    let isIncluded = (option: any): boolean => {
      return (
        props.selectedLanguages.filter(
          (language: any) => language.value === option
        ).length >= 1
      );
    };

    let onClickOpenDropdown = (event: any) => {
      if (props.disabled) {
        return;
      }

      if (event.target.nodeName !== "I") {
        nextTick(() => {
          setTimeout(() => {
            isDropdownOpen.value = true;
          }, 75);
        }).then(() => {
          if (props.filter) {
            let filterInputText = document.getElementById("filterInputText");
            if (filterInputText) {
              filterInputText.focus();
            }
          }
        });
      }
    };

    let closeIfOpen = () => {
      if (isDropdownOpen.value = true) {
        isDropdownOpen.value = false;
      }
    }

    let onClickClear = () => {
      isDropdownOpen.value = false;
      innerModelValue.value = undefined;
      filterText.value = "";
      context.emit("update:modelValue", innerModelValue.value);
    };


    watch(props, () => {

      innerModelValue.value = props.modelValue;
      innerOptions.value = props.options;

      let tempValue = innerModelValue.value
        ? props.optionLabel
          ? innerModelValue.value[props.optionLabel]
          : innerModelValue.value
        : "";

      if (props.optionLabel) {
        isModelValueInOptions.value =
          innerOptions.value.filter(
            (options: any) => options[props.optionLabel] === tempValue
          ).length > 0;
      } else {
        isModelValueInOptions.value = innerOptions.value.includes(tempValue);
      }
      if (!props.selectMulti) {
        setTimeout(() => {
          isDropdownOpen.value = false;
        }, 150);
      }
    });

    onMounted(() => {
      if (props.includesAutotranslate) {
        systemApi
          .getSystemPreference("autoTranslatableLanguages")
          .then((response) => {
            autoTranslatableLanguages.value = <Array<String>>(
              response.data.value
            );
            console.log("Autolang:", autoTranslatableLanguages.value);
          });
      }

      if (props.options) {
        innerOptions.value = props.options;
      }
      console.log("prop options", props.options);
    });

    onUnmounted(() => {
    });

    return {
      innerModelValue,
      innerOptions,
      isDropdownOpen,
      isModelValueInOptions,
      filterText,
      autoTranslatableLanguages,
      onChangeFilterValue,
      onClickOption,
      onClickClear,
      onClickOpenDropdown,
      isIncluded,
      closeIfOpen
    };
  },
});

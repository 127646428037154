<template>
  <Transition name="slide-fade">
    <div
      v-if="show"
      class="bg-tt-white list-item-hover"
      style="
        display: flex;
        align-items: center;
        height: 95px;
        width: 97%;
        border-radius: 16px;
        padding: 16px;
        margin-bottom: 10px;
        cursor: pointer;
      "
    >
      <div style="display: flex; justify-content: space-between; width: 100%">
        <div style="display: flex; align-items: center">
          <div>
            <video
              style="
              width: 142px;
              height: 80px;
              border-radius: 14px;
            "
              ref="videoPlayerRef"
              class="video-js"
              :options="videoOptions"
            ></video>
          </div>
          <div style="padding-left: 26px">
            <div style="padding-left: 11px">
              <div
                style="line-height: 20px"
                :class="
                  item.masterAssetItem.needsVerification ? 'col-reject' : ''
                "
              >
                {{item.name}}
              </div>
              <div style="line-height: 20px; font-weight: 300; font-size: 12px">
                {{
                  $t("general.created") +
                  " " +
                  new Date(item.created).toLocaleString(undefined, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit"
                  })
                }}
              </div>
            </div>
            <div style="display: flex; padding-top: 6px">
              <RoundedLabel
                v-if="item.thirdPartyID"
                :text="item.thirdPartyID"
                class="bor-menu-color"
                v-tooltip="$t('general.id')"
                style="margin-right: 10px"
              />
              <RoundedLabel
                v-if="item.groupID"
                @click="
                  $store.commit('ADD_FILTER_BREADCRUMB', {
                    name: item.groupID,
                    type: 'GROUP',
                  });
                  $emitter.emit('search-assets', null);
                "
                :lineThrough="isGroupDeleted"
                :text="item.groupID"
                v-tooltip="$t('general.group')"
                class="bor-menu-color"
                style="margin-right: 10px; cursor: pointer"
              />
              <RoundedLabel
                @click="
                  $store.commit('ADD_FILTER_BREADCRUMB', {
                    name: item.masterAssetItem
                      ? item.masterAssetItem.language
                      : '',
                    type: 'LANGUAGE',
                  });
                  $emitter.emit('search-assets', null);
                "
                v-tooltip="item.masterAssetItem.language"
                :countryFlag="
                  item.masterAssetItem ? item.masterAssetItem.language : ''
                "
                class="bor-menu-color"
                style="margin-right: 10px; cursor: pointer"
              />
              <RoundedLabel
                v-if="hasPermission('assets_view/dropdown/edit')"
                @click="
                  $router.push({
                    name: 'editAsset',
                    query: { id: item.id },
                  })
                "
                leftIcon="pi pi-cog"
                v-tooltip="$t('tooltip.editAsset')"
                class="bor-menu-color"
                style="margin-right: 10px; cursor: pointer"
              />
              <RoundedLabel
                @click="toggleSubscription()"
                :leftIcon="'pi pi-star'+ (isSubscript ? '-fill' : '')"
                v-tooltip="$t('tooltip.subscribe')"
                class="bor-menu-color"
                style="margin-right: 10px; cursor: pointer; color: gold"
              />
              <RoundedLabel
                v-if="hasPermission('assets_view/dropdown/copy_link')"
                @click="copyLink()"
                leftIcon="pi pi-link"
                v-tooltip="$t('tooltip.copyAssetLink')"
                class="bor-menu-color"
                style="margin-right: 10px; cursor: pointer"
              />
              <RoundedLabel
                v-if="hasPermission('assets_view/dropdown/delete')"
                @click="
                  $store.commit('SHOW_CONFIRM_DIALOG', {
                    dialogTitle: 'dialog.areYouSure',
                    dialogContent: { t: 'dialog.deleteAsset', e: [item.name] },
                    confirmCallback: () => {
                      deleteAsset(item);
                      $store.commit('SHOW_SNACKBAR', {
                        snackbarContent: { t: 'snackbar.assetDeleted' },
                        snackbarBtnText: $t('snackbar.undo'),
                        snackbarBtnCallback: () => {
                          undoDeleteAsset();
                        },
                      });
                    },
                  })
                "
                leftIcon="pi pi-trash"
                class="bor-menu-color"
                style="margin-right: 10px; cursor: pointer"
              />
              <RoundedLabel
                v-if="hasPermission('assets_view/dropdown/info')"
                @click="
                  $router.push({
                    name: 'assetInfo',
                    query: { id: item.id },
                  })
                "
                leftIcon="pi pi-info"
                class="bor-menu-color"
                style="cursor: pointer"
              />
            </div>
          </div>
        </div>
        <div style="display: flex; align-items: center; padding-right: 26px">
          <Button
            v-if="!needsVerification(item)"
            @click="onClickJobCount"
            
            class="border-radius-8 col-dark-blue bor-menu-color bg-transparent"
            style="
              height: 42px;
              width: 42px;
              margin-left: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 24px;"
          >
            {{assetItemCount + ""}}
          </Button>
          <div v-if="!needsVerification(item)">
            <Button
              :disabled="item.masterAssetItem.status !== 'CLOSED'"
              @click="
                hasPermission('assets_view/click_items/all')
                  ? $router.push({ name: 'addJob', query: { id: item.id } })
                  : ''
              "
              class="border-radius-8 col-dark-blue bor-menu-color bg-tt-white-60"
              style="height: 42px; width: 42px; margin-left: 10px; display: flex; align-items: center; justify-content: center"
              v-tooltip="$t('tooltip.addJob')"
            >
              <i
                class="pi pi-plus"
                style="font-size: 26px; font-weight: 600"
              ></i>
            </Button>
          </div>
          <div v-else>
            <Button
              @click="
                $router.push({
                  name: 'job',
                  query: { id: item.masterAssetItem.id },
                })
              "
              class="border-radius-8 col-reject bor-reject bg-tt-white-60"
              style="padding: 7px 6px 6px 8px; margin-left: 10px"
              v-tooltip="$t('tooltip.verifyAsset')"
            >
              <i
                class="pi pi-exclamation-circle"
                style="font-size: 26px; font-weight: 600"
              ></i>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script src="./ListAssetItem.ts"></script>

import {
  defineComponent,
  getCurrentInstance,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import {SystemApi, UserApi, UserGroupApi} from "../../../target/api";
import {
  SearchUsersObject,
  UserGroup,
  User,
  UserRole,
} from "../../../target/api/de/moovit/titletoolserver/model";
import {useStore} from "vuex";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {AxiosResponse} from "axios";
import Column from "primevue/column";

export default defineComponent({
  components: { Column, Button, DataTable },
  setup: function () {
    const route = useRoute();
    const store = useStore();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;
    const i18n = useI18n();
    const systemApi: SystemApi = new SystemApi();

    let users = ref([]);
    let approvalUsers = ref([]);
    let groups = ref<UserGroup[]>([]);
    let activeTab = ref(0);
    let sortColumn = ref("");
    let sortAsc = ref(true);
    let searchText = ref("");
    let showAddUserOrGroup = ref(false);
    let showEditUser = ref(false);
    let showEditGroup = ref(false);
    let locked = ref(false);

    let currentUser = ref<User>();
    let currentGroup = ref<UserGroup>();

    let usersRef: any | undefined;
    let addingUsers = ref(false);
    let added0Users = ref(false);
    let usersPage: number = 0;
    let usersPageSize: number = 20;

    let groupsRef: any | undefined;
    let addingGroups = ref(false);
    let added0Groups = ref(false);
    let groupsPage: number = 0;
    let groupsPageSize: number = 20;
    let groupInformation = ref<Map<string, { assets: number; jobs: number }>>();
    let lockedUsers = ref<boolean>(false);
    let restrictedUsers = ref<boolean | undefined>(false);

    let lastDeletedGroup = ref<UserGroup>();
    let lastDeletedUser = ref<User>();

    let userApi: UserApi = new UserApi();
    let userGroupApi: UserGroupApi = new UserGroupApi();

    let approvalUsersDialog = ref<boolean>(false);
    let addUserDialog = ref<boolean>(false);
    let manageUserDialog = ref<boolean>(false);
    let selectedApprovalUser = ref<User>();

    let deleteGroupDialog = ref<boolean>(false);
    let currentDeleteUserGroup = ref<UserGroup>();
    let currentReplaceDeleteUserGroup = ref<string>();

    let isApproveUserAllowed = ref<boolean>(false);

    let selectedColumns = ref<string[]>([]);
    let allColumns = ref<string[]>([
      "general.firstName",
      "general.name",
      "general.role",
      "general.country",
      "general.group",
      "general.email",
    ]);
    let selectedGroupColumns = ref<string[]>([]);
    let allGroupColumns = ref<string[]>([
      "general.name",
      "general.costCenter",
      "general.assetCount",
      "general.jobCount",
    ]);

    let changeSort = (column: string) => {
      if (sortColumn.value == column) {
        if (sortAsc.value) {
          sortAsc.value = false;
        } else {
          sortColumn.value = "";
          sortAsc.value = true;
        }
      } else {
        sortAsc.value = true;
        sortColumn.value = column;
      }

      searchUsers();
      searchGroups();
    };

    let onClickAddBtn = () => {
      showAddUserOrGroup.value = !showAddUserOrGroup.value;
      showEditUser.value = false;
      showEditGroup.value = false;
    };

    let getUsers: any = async () => {
      let searchUsersObject: SearchUsersObject = {};
      searchUsersObject.page = usersPage;
      searchUsersObject.pageSize = usersPageSize;
      searchUsersObject.searchText = searchText.value;

      searchUsersObject.ascSort = sortAsc.value;
      searchUsersObject.sortColumn = sortColumn.value;
      searchUsersObject.locked = lockedUsers.value;

      if (restrictedUsers.value !== undefined) {
        searchUsersObject.restricted = restrictedUsers.value;
      }

      searchUsersObject.groups = [];

      for (let i in store.state.filterBreadcrumbs) {
        let filterBreadcrumb = store.state.filterBreadcrumbs[i];
        if (filterBreadcrumb.type == "GROUP")
          searchUsersObject.groups.push(filterBreadcrumb.name);
        if (filterBreadcrumb.type == "LOCKED") searchUsersObject.locked = true;
        if (filterBreadcrumb.type == "ROLE")
          searchUsersObject.role = [
            UserRole[filterBreadcrumb.name as keyof typeof UserRole],
          ];
        if (filterBreadcrumb.type == "COSTCENTER")
          searchUsersObject.costCenter = filterBreadcrumb.name;
        if (filterBreadcrumb.type == "DEFAULT_LANGUAGE")
          searchUsersObject.defaultLanguage = filterBreadcrumb.name;
      }

      console.debug(searchUsersObject);

      let resp = await userApi.searchUsers(searchUsersObject);

      return resp.data;
    };

    let getGroups: any = () => {
      return userGroupApi.searchUserGroups(
        groupsPage,
        groupsPageSize,
        searchText.value
      );
    };

    let searchUsers = async () => {
      lockedUsers.value = false;
      restrictedUsers.value = undefined;
      users.value = await getUsers();
      console.debug(JSON.parse(JSON.stringify(users.value)));
      if (usersRef) usersRef.scrollTop = 0;
      added0Users.value = false;
    };

    let searchApprovalUsers = async () => {
      lockedUsers.value = true;
      restrictedUsers.value = false;
      approvalUsers.value = await getUsers();
      console.debug(JSON.parse(JSON.stringify(approvalUsers.value)));
      if (usersRef) usersRef.scrollTop = 0;
      added0Users.value = false;
    };

    let onClickApprovalUserRow = (event: any) => {
      if (event.originalEvent.target.nodeName === "DIV") {
        return;
      }
      selectedApprovalUser.value = event.data;
      approvalUsersDialog.value = true;
    };

    let searchGroups = () => {
      groupsPage = 0;
      getGroups().then((result: AxiosResponse<UserGroup[]>) => {
        groups.value = result.data;
        console.debug(JSON.parse(JSON.stringify(groups.value)));
        if (groupsRef) {
          groupsRef.scrollTop = 0;
        }
        added0Groups.value = false;

        groupInformation.value = new Map<
          string,
          { assets: number; jobs: number }
        >();
        for (const group of groups.value) {
          setAssetCount(group.id!);
          setJobCount(group.id!);
        }
      });
    };

    let addUsers = async () => {
      if (addingUsers.value || added0Users.value) return;

      addingUsers.value = true;
      usersPage++;

      let newUsers: any = await getUsers();
      users.value = users.value.concat(newUsers);
      console.debug("ADDED " + newUsers.length + " USERS");

      if (newUsers.length == 0) {
        added0Users.value = true;
      }

      addingUsers.value = false;
    };

    let addGroups = async () => {
      if (addingGroups.value || added0Groups.value) return;

      addingGroups.value = true;
      groupsPage++;

      let newGroups: any = await getGroups();
      groups.value = groups.value.concat(newGroups);
      console.debug("ADDED " + newGroups.length + " GROUPS");

      if (newGroups.length == 0) {
        added0Groups.value = true;
      }

      addingGroups.value = false;
    };

    let openDeleteUserDialog = async (item: UserGroup) => {
      if (item && item.id) {
        userGroupApi.isGroupInUse(item.id).then((result: AxiosResponse<boolean>) => {
          if (result.data) {
            nextTick(() => {
              currentDeleteUserGroup.value = item;
              currentReplaceDeleteUserGroup.value = "";
            }).then(() => {
              deleteGroupDialog.value = true;
            });
          } else {
            deleteGroup(item, undefined);
          }
        });
      }
    };

    let deleteGroup = (
      item: UserGroup,
      replacementGroup: string | undefined
    ) => {
      if (item && item.id) {
        if (deleteGroupDialog.value && !replacementGroup) {
          store.commit("SHOW_SNACKBAR", {
            snackbarType: "error",
            snackbarContent: { t: "snackbar.selectGroup" },
          });
          return;
        }

        deleteGroupDialog.value = false;
        userGroupApi.deleteUserGroup(item.id!, replacementGroup).then(() => {
          lastDeletedGroup.value = item;
          emitter.emit("search-groups");
          emitter.emit("search-users");
          console.debug(
            "deleteGroup {} and replace with {}",
            item,
            replacementGroup
          );

          /* restored group loses all assets/jobs/users
          store.commit('SHOW_SNACKBAR', {
            snackbarContent: {t: 'snackbar.groupDeleted'},
            snackbarBtnText: i18n.t('snackbar.undo'),
            snackbarBtnCallback: () => {
              undoDeleteGroup();
              store.commit('SHOW_SNACKBAR', {
                snackbarText: 'snackbar.groupRestored',
                snackbarContent: {t: 'snackbar.groupRestored'},
                snackbarType: 'info',
                snackbarLife: 3000
              });
            },
          });*/
        });
      }
    };

    let undoDeleteGroup = async () => {
      if (lastDeletedGroup.value) {
        await userGroupApi.restoreUserGroup(lastDeletedGroup.value);
        emitter.emit("search-groups");
        emitter.emit("search-users");
        lastDeletedGroup.value = undefined;
      }
    };

    let deleteApproveUser = (approveUser: User) => {
      userApi.deleteUser(approveUser.id!).then(_ => {
        emitter.emit("search-users");
        console.debug("deleteApproveUser", approveUser);
      });
    }

    let deleteUser = async (item: User) => {
      await userApi.deleteUser(item.id!);
      lastDeletedUser.value = item;
      emitter.emit("search-users");
      console.debug("deleteUser", item);
    };

    let undoDeleteUser = async () => {
      if (lastDeletedUser.value) {
        await userApi.restoreUser(lastDeletedUser.value);
        emitter.emit("search-users");
        lastDeletedUser.value = undefined;
      }
    };

    let checkGroupExistence = (groupId: string) => {
      if (groups.value) {
        return groups.value.filter((group) => group.id === groupId).length > 0;
      }
    };

    let openManageUserDialog = (user: User) => {
      nextTick(() => {
        currentUser.value = user;
      }).then(() => {
        manageUserDialog.value = true;
      });
    };

    let setAssetCount = (groupId: string) => {
      userGroupApi.getUserGroupAssetCount(groupId).then((result) => {
        const assetCount = result.data;
        if (groupInformation.value) {
          let jobCount = groupInformation.value.get(groupId)
            ? groupInformation.value.get(groupId)!.jobs
            : 0;
          groupInformation.value.set(groupId, {
            assets: assetCount,
            jobs: jobCount,
          });
        }
      });
    };

    let setJobCount = async (groupId: string) => {
      userGroupApi.getUserGroupJobCount(groupId).then((result) => {
        const jobCount = result.data;
        if (groupInformation.value) {
          let assetCount = groupInformation.value.get(groupId)
            ? groupInformation.value.get(groupId)!.assets
            : 0;
          groupInformation.value.set(groupId, {
            assets: assetCount,
            jobs: jobCount,
          });
        }
      });
    };

    let handleScrollUsers = (event: any) => {
      let scrollMax = event.target.scrollHeight - event.target.clientHeight;
      let scrollPos = event.target.scrollTop;

      if (scrollMax - scrollPos < 150 && !addingUsers.value) {
        lockedUsers.value = false;
        restrictedUsers.value = undefined;
        addUsers();
      }
    };

    let handleScrollGroups = (event: any) => {
      let scrollMax = event.target.scrollHeight - event.target.clientHeight;
      let scrollPos = event.target.scrollTop;

      if (scrollMax - scrollPos < 150 && !addingUsers.value) {
        addGroups();
      }
    };

    let onBlurSearchField = () => {
      if (activeTab.value === 0) {
        searchUsers();
      } else if (activeTab.value === 1) {
        searchGroups();
      } else if (activeTab.value === 2) {
        searchApprovalUsers();
      }
    };

    let hasPermission = (permString: string) => {
      return JSON.parse(localStorage.loginData).user.permissions.permissions[
        permString
        ];
    };

    watch(activeTab, () => {
      usersPage = 0;
      let tUserManagement = {
        t: "activityBreadcrumbs.userManagement",
        e: undefined,
      };
      let tUsers = {
        t: "activityBreadcrumbs.users",
        e: undefined,
      };
      store.commit("SET_ACTIVITY_BREADCRUMBS", [tUserManagement, tUsers]);
      if (activeTab.value == 1) {
        let tGroups = {
          t: "activityBreadcrumbs.groups",
          e: undefined,
        };
        store.commit("SET_ACTIVITY_BREADCRUMBS", [tUserManagement, tGroups]);
      }
    });

    watch(selectedColumns, () => {
      localStorage.setItem(
        "selectedUserColumns",
        JSON.stringify(selectedColumns.value)
      );
    });

    watch(selectedGroupColumns, () => {
      localStorage.setItem(
        "selectedGroupColumns",
        JSON.stringify(selectedGroupColumns.value)
      );
    });


    onMounted(async () => {
      systemApi.getSystemPreference("allowCreateUser").then((response) => {
        if (response.data) {
          isApproveUserAllowed.value = <boolean>(<unknown>response.data.value);
        }
      });

      if (route.query.page) {
        activeTab.value = Number.parseInt("" + route.query.page) - 1
      }
      if (route.query.userId) {
        userApi.getUserById((<string>route.query.userId)).then(result => {
          if (result.data) {
            selectedApprovalUser.value = result.data;
            approvalUsersDialog.value = true;
          }
        })
      }

      searchUsers();
      searchApprovalUsers();

      try {
        searchGroups();
      } catch (error) {
      }

      if (localStorage.getItem("selectedUserColumns")) {
        selectedColumns.value = JSON.parse(
          localStorage.getItem("selectedUserColumns")!
        );
      } else {
        selectedColumns.value = allColumns.value;
      }

      if (localStorage.getItem("selectedGroupColumns")) {
        selectedGroupColumns.value = JSON.parse(
          localStorage.getItem("selectedGroupColumns")!
        );
      } else {
        selectedGroupColumns.value = allGroupColumns.value;
      }

      let tUserManagement = {
        t: "activityBreadcrumbs.userManagement",
        e: undefined,
      };
      let tUsers = {
        t: "activityBreadcrumbs.users",
        e: undefined,
      };
      store.commit("SET_ACTIVITY_BREADCRUMBS", [tUserManagement, tUsers]);

      emitter.on("search-users", () => {
        searchUsers();
        searchApprovalUsers();
      });
      emitter.on("search-groups", () => {
        searchGroups();
      });
      emitter.on("manageUsers-reload", () => {
        activeTab.value = 0;
        showAddUserOrGroup.value = false;
        showEditUser.value = false;
        showEditGroup.value = false;
      });
    });

    onUnmounted(() => {
      emitter.off("search-users");
      emitter.off("search-groups");
    });

    return {
      users,
      groups,
      sortColumn,
      sortAsc,
      searchText,
      changeSort,
      onClickAddBtn,
      onBlurSearchField,
      hasPermission,
      deleteUser,
      undoDeleteUser,
      deleteGroup,
      undoDeleteGroup,
      deleteApproveUser,
      checkGroupExistence,
      groupInformation,
      activeTab,
      showAddUserOrGroup,
      showEditUser,
      showEditGroup,
      locked,
      addingUsers,
      added0Users,
      addingGroups,
      added0Groups,
      currentUser,
      currentGroup,
      approvalUsers,
      addUserDialog,
      manageUserDialog,
      approvalUsersDialog,
      selectedApprovalUser,
      onClickApprovalUserRow,
      isApproveUserAllowed,
      openManageUserDialog,
      deleteGroupDialog,
      openDeleteUserDialog,
      currentReplaceDeleteUserGroup,
      currentDeleteUserGroup,
      selectedColumns,
      allColumns,
      selectedGroupColumns,
      allGroupColumns,
    };
  },
});

<template>
  <div
    class="border-radius-12 col-dark-blue bg-transparent"
    :class="
      invalid
        ? 'bor-reject'
        : disabled
        ? 'bor-dark-blue-30'
        : 'bor-dark-blue-60'
    "
    @click="onClickOpenDropdown"
    v-click-outside="closeIfOpen"
    style="
			transition: border-radius 0.2s;
			height: 50px;
			overflow: visible;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      display: inline-block;
    "
    :style="
      isDropdownOpen
        ? ' border-bottom-left-radius: 0; border-bottom-right-radius: 0; '
        : '' + !disabled
        ? ' cursor: pointer;'
        : ''
    "
  >
    <div
      v-if="
        isModelValueInOptions ||
          (filter && isDropdownOpen) ||
          filterText.length > 0
      "
      class="col-dark-blue-45"
      style="font-size: 12px; height: 12px; padding: 5px 10px"
    >
      {{ label ? label : placeholder }}
    </div>
    <div
      style="display: flex; padding: 10px; width: 100%; justify-content: space-between"
    >
      <div v-if="(filter && isDropdownOpen) || filterText.length > 0">
        <InputText
          id="filterInputText"
          v-model="filterText"
          style="height: 10px; margin-top: -2px; width: 80%; border-radius: 0; border-bottom: 1px solid var(--dark-blue-30) !important;"
          class="bor-transparent"
          @input="onChangeFilterValue()"
        />
      </div>
      <div v-else>
        <div
          v-if="isModelValueInOptions"
          :class="disabled ? 'col-dark-blue-45' : ''"
          style="display: flex; gap: 15px"
        >
          <Flag v-if="includesFlag" :country-flag="innerModelValue['value']" />
          <div
            :style="
              textOverFlowWidth
                ? 'width: ' + textOverFlowWidth + 'px; '
                : 'width: 220px; '
            "
            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
          >
            {{ optionLabel ? innerModelValue[optionLabel] : innerModelValue }}
          </div>
        </div>
        <div v-else class="col-dark-blue-45" style="margin-top: 5px">
          {{ placeholder }}
        </div>
      </div>

      <i
        v-if="showClear"
        class="pi pi-times"
        style="padding: 5px"
        :style="
          (filter && isDropdownOpen) || innerOptions.includes(innerModelValue)
            ? 'margin-top: -10px'
            : 'margin-top: 2px'
        "
        @click="onClickClear()"
      />
    </div>

    <Transition name="dropdown">
      <div
        v-if="isDropdownOpen"
        style="
		         position: absolute;
		         z-index: 9999;
		         top: 49px;
		         overflow: auto;
		         margin-left: -1px;
		         width: calc(100% + 2px);
		         border-top-left-radius: 0;
		         border-top-right-radius: 0;
		         border-top: none;
				   "
        :style="
          dropdownHeight
            ? ' max-height: ' + dropdownHeight + 'px;'
            : ' max-height: 210px;'
        "
        class="bg-tt-white border-radius-12 bor-dark-blue-60 box-shadow-2"
      >
        <div v-for="option in innerOptions" @click="onClickOption(option)">
          <div
            style="padding: 10px; display: flex; gap: 15px"
            class="hover-highlight-2 bg-tt-white"
            :class="
              selectedLanguages &&
              isIncluded(optionLabel ? option[optionLabel] : option)
                ? 'col-menu-color'
                : ''
            "
          >
            <Flag v-if="includesFlag" :country-flag="option['value']" />
            {{ optionLabel ? option[optionLabel] : option }}
            <div v-if="includesAutotranslate">
              <div
                v-if="
                  autoTranslatableLanguages
                    ? autoTranslatableLanguages.includes(option[optionLabel])
                    : false
                "
                style="position: absolute; right: 5px;"
              >
                <i class="pi pi-comment"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script src="./CustomDropdown.ts"></script>

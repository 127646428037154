// tslint:disable
/**
 * titletool-nextgen-server
 * TitleTool NextGen Server
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: r.hutter@moovit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { CustomerMetadata } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { CustomerMetadataTemplate } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { FontMappings } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { LogoType } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { MailType } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { MediaReplacementTemplate } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { NotificationDto } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { RendernodeInformation } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { RendernodeMessage } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { Replacement } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { SearchFontMappingsObject } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { ServerHealthInfo } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { SystemPreference } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { SystemPreferenceKeyUpdate } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { WorkflowEngineMessageType } from '../../../../de/moovit/titletoolserver/model';
/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addCustomerMetadata
         * @param {CustomerMetadata} [customerMetadata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCustomerMetadata: async (customerMetadata?: CustomerMetadata, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/metadata`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof customerMetadata !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(customerMetadata !== undefined ? customerMetadata : {}) : (customerMetadata || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addCustomerMetadataTemplate
         * @param {CustomerMetadataTemplate} [customerMetadataTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCustomerMetadataTemplate: async (customerMetadataTemplate?: CustomerMetadataTemplate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/metadata/template`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof customerMetadataTemplate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(customerMetadataTemplate !== undefined ? customerMetadataTemplate : {}) : (customerMetadataTemplate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Fontmapping
         * @param {FontMappings} [fontMappings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFontMapping: async (fontMappings?: FontMappings, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/fontMappings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof fontMappings !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fontMappings !== undefined ? fontMappings : {}) : (fontMappings || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addSystemPreference
         * @param {SystemPreference} [systemPreference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSystemPreference: async (systemPreference?: SystemPreference, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/preference`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof systemPreference !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(systemPreference !== undefined ? systemPreference : {}) : (systemPreference || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary checkMediaReplaceableNames
         * @param {string} assetItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkMediaReplaceableNames: async (assetItemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetItemId' is not null or undefined
            if (assetItemId === null || assetItemId === undefined) {
                throw new RequiredError('assetItemId','Required parameter assetItemId was null or undefined when calling checkMediaReplaceableNames.');
            }
            const localVarPath = `/system/checkMediaReplaceableNames`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (assetItemId !== undefined) {
                localVarQueryParameter['assetItemId'] = assetItemId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteCustomerMetadata
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerMetadata: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCustomerMetadata.');
            }
            const localVarPath = `/system/metadata`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteCustomerMetadataTemplate
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerMetadataTemplate: async (id?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/metadata/template`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Fontmapping
         * @param {number} index 
         * @param {string} [group] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFontMappings: async (index: number, group?: string, language?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'index' is not null or undefined
            if (index === null || index === undefined) {
                throw new RequiredError('index','Required parameter index was null or undefined when calling deleteFontMappings.');
            }
            const localVarPath = `/system/fontMappings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (group !== undefined) {
                localVarQueryParameter['group'] = group;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (index !== undefined) {
                localVarQueryParameter['index'] = index;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteSystemPreference
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSystemPreference: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling deleteSystemPreference.');
            }
            const localVarPath = `/system/preference/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary executeCommand
         * @param {string} password 
         * @param {string} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeCommand: async (password: string, command?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'password' is not null or undefined
            if (password === null || password === undefined) {
                throw new RequiredError('password','Required parameter password was null or undefined when calling executeCommand.');
            }
            const localVarPath = `/system/execute/command`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (command !== undefined) {
                localVarQueryParameter['command'] = command;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generateReport
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {boolean} [createAssetsImportedSheet] 
         * @param {boolean} [createJobsCreatedSheet] 
         * @param {boolean} [createJobsCompletedSheet] 
         * @param {boolean} [createActiveUsersSheet] 
         * @param {boolean} [createAllUsersSheet] 
         * @param {boolean} [createJobsToApproveSheet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReport: async (start?: string, end?: string, createAssetsImportedSheet?: boolean, createJobsCreatedSheet?: boolean, createJobsCompletedSheet?: boolean, createActiveUsersSheet?: boolean, createAllUsersSheet?: boolean, createJobsToApproveSheet?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/report`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (createAssetsImportedSheet !== undefined) {
                localVarQueryParameter['createAssetsImportedSheet'] = createAssetsImportedSheet;
            }

            if (createJobsCreatedSheet !== undefined) {
                localVarQueryParameter['createJobsCreatedSheet'] = createJobsCreatedSheet;
            }

            if (createJobsCompletedSheet !== undefined) {
                localVarQueryParameter['createJobsCompletedSheet'] = createJobsCompletedSheet;
            }

            if (createActiveUsersSheet !== undefined) {
                localVarQueryParameter['createActiveUsersSheet'] = createActiveUsersSheet;
            }

            if (createAllUsersSheet !== undefined) {
                localVarQueryParameter['createAllUsersSheet'] = createAllUsersSheet;
            }

            if (createJobsToApproveSheet !== undefined) {
                localVarQueryParameter['createJobsToApproveSheet'] = createJobsToApproveSheet;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllFontMappings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFontMappings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/fontMapping`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllMediaReplacementTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMediaReplacementTemplate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/mediaReplacementTemplate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCountries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/country`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCustomMetadataById
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomMetadataById: async (id?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/metadata`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCustomMetadataByIdList
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomMetadataByIdList: async (ids?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/metadata`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDevObject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevObject: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/dev`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getFaviconLogo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaviconLogo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/logo/FAVICON`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Fontmapping
         * @param {string} [groupId] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFontMapping: async (groupId?: string, language?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/fontMappings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getHealthInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/healthInfo`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getI18nMessages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nMessages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/i18n/messages`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getLanguages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/language`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListSystemPreferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSystemPreferences: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/preferences`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getLogo
         * @param {LogoType} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogo: async (type: LogoType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getLogo.');
            }
            const localVarPath = `/system/logo/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getRendernode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRendernode: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/rendernode`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getRendernodeInformation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRendernodeInformation: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/rendernodeInformation`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getReplaceMediaThumbnail
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReplaceMediaThumbnail: async (path: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling getReplaceMediaThumbnail.');
            }
            const localVarPath = `/system/replaceMedia/thumbnail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getReplacement
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReplacement: async (ids: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling getReplacement.');
            }
            const localVarPath = `/system/mediaReplacement`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSystemPreference
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemPreference: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getSystemPreference.');
            }
            const localVarPath = `/system/preference/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSystemPreferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemPreferences: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/preference`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getVersion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/version`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary isAutoTranslatable
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAutoTranslatable: async (language: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language','Required parameter language was null or undefined when calling isAutoTranslatable.');
            }
            const localVarPath = `/system/language/{language}/autotranslatable`
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary loginTerminal
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginTerminal: async (password: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'password' is not null or undefined
            if (password === null || password === undefined) {
                throw new RequiredError('password','Required parameter password was null or undefined when calling loginTerminal.');
            }
            const localVarPath = `/system/execute/command`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary migrateAll
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateAll: async (srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'srcSystem' is not null or undefined
            if (srcSystem === null || srcSystem === undefined) {
                throw new RequiredError('srcSystem','Required parameter srcSystem was null or undefined when calling migrateAll.');
            }
            // verify required parameter 'srcDb' is not null or undefined
            if (srcDb === null || srcDb === undefined) {
                throw new RequiredError('srcDb','Required parameter srcDb was null or undefined when calling migrateAll.');
            }
            // verify required parameter 'srcUser' is not null or undefined
            if (srcUser === null || srcUser === undefined) {
                throw new RequiredError('srcUser','Required parameter srcUser was null or undefined when calling migrateAll.');
            }
            // verify required parameter 'srcPassword' is not null or undefined
            if (srcPassword === null || srcPassword === undefined) {
                throw new RequiredError('srcPassword','Required parameter srcPassword was null or undefined when calling migrateAll.');
            }
            const localVarPath = `/system/migrate/all`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (srcSystem !== undefined) {
                localVarQueryParameter['srcSystem'] = srcSystem;
            }

            if (srcDb !== undefined) {
                localVarQueryParameter['srcDb'] = srcDb;
            }

            if (srcUser !== undefined) {
                localVarQueryParameter['srcUser'] = srcUser;
            }

            if (srcPassword !== undefined) {
                localVarQueryParameter['srcPassword'] = srcPassword;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary migrateAssetItems
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateAssetItems: async (srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'srcSystem' is not null or undefined
            if (srcSystem === null || srcSystem === undefined) {
                throw new RequiredError('srcSystem','Required parameter srcSystem was null or undefined when calling migrateAssetItems.');
            }
            // verify required parameter 'srcDb' is not null or undefined
            if (srcDb === null || srcDb === undefined) {
                throw new RequiredError('srcDb','Required parameter srcDb was null or undefined when calling migrateAssetItems.');
            }
            // verify required parameter 'srcUser' is not null or undefined
            if (srcUser === null || srcUser === undefined) {
                throw new RequiredError('srcUser','Required parameter srcUser was null or undefined when calling migrateAssetItems.');
            }
            // verify required parameter 'srcPassword' is not null or undefined
            if (srcPassword === null || srcPassword === undefined) {
                throw new RequiredError('srcPassword','Required parameter srcPassword was null or undefined when calling migrateAssetItems.');
            }
            const localVarPath = `/system/migrate/assetItems`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (srcSystem !== undefined) {
                localVarQueryParameter['srcSystem'] = srcSystem;
            }

            if (srcDb !== undefined) {
                localVarQueryParameter['srcDb'] = srcDb;
            }

            if (srcUser !== undefined) {
                localVarQueryParameter['srcUser'] = srcUser;
            }

            if (srcPassword !== undefined) {
                localVarQueryParameter['srcPassword'] = srcPassword;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary migrateAssets
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateAssets: async (srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'srcSystem' is not null or undefined
            if (srcSystem === null || srcSystem === undefined) {
                throw new RequiredError('srcSystem','Required parameter srcSystem was null or undefined when calling migrateAssets.');
            }
            // verify required parameter 'srcDb' is not null or undefined
            if (srcDb === null || srcDb === undefined) {
                throw new RequiredError('srcDb','Required parameter srcDb was null or undefined when calling migrateAssets.');
            }
            // verify required parameter 'srcUser' is not null or undefined
            if (srcUser === null || srcUser === undefined) {
                throw new RequiredError('srcUser','Required parameter srcUser was null or undefined when calling migrateAssets.');
            }
            // verify required parameter 'srcPassword' is not null or undefined
            if (srcPassword === null || srcPassword === undefined) {
                throw new RequiredError('srcPassword','Required parameter srcPassword was null or undefined when calling migrateAssets.');
            }
            const localVarPath = `/system/migrate/assets`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (srcSystem !== undefined) {
                localVarQueryParameter['srcSystem'] = srcSystem;
            }

            if (srcDb !== undefined) {
                localVarQueryParameter['srcDb'] = srcDb;
            }

            if (srcUser !== undefined) {
                localVarQueryParameter['srcUser'] = srcUser;
            }

            if (srcPassword !== undefined) {
                localVarQueryParameter['srcPassword'] = srcPassword;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary migrateFields
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateFields: async (srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'srcSystem' is not null or undefined
            if (srcSystem === null || srcSystem === undefined) {
                throw new RequiredError('srcSystem','Required parameter srcSystem was null or undefined when calling migrateFields.');
            }
            // verify required parameter 'srcDb' is not null or undefined
            if (srcDb === null || srcDb === undefined) {
                throw new RequiredError('srcDb','Required parameter srcDb was null or undefined when calling migrateFields.');
            }
            // verify required parameter 'srcUser' is not null or undefined
            if (srcUser === null || srcUser === undefined) {
                throw new RequiredError('srcUser','Required parameter srcUser was null or undefined when calling migrateFields.');
            }
            // verify required parameter 'srcPassword' is not null or undefined
            if (srcPassword === null || srcPassword === undefined) {
                throw new RequiredError('srcPassword','Required parameter srcPassword was null or undefined when calling migrateFields.');
            }
            const localVarPath = `/system/migrate/fields`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (srcSystem !== undefined) {
                localVarQueryParameter['srcSystem'] = srcSystem;
            }

            if (srcDb !== undefined) {
                localVarQueryParameter['srcDb'] = srcDb;
            }

            if (srcUser !== undefined) {
                localVarQueryParameter['srcUser'] = srcUser;
            }

            if (srcPassword !== undefined) {
                localVarQueryParameter['srcPassword'] = srcPassword;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary migrateUserGroups
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateUserGroups: async (srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'srcSystem' is not null or undefined
            if (srcSystem === null || srcSystem === undefined) {
                throw new RequiredError('srcSystem','Required parameter srcSystem was null or undefined when calling migrateUserGroups.');
            }
            // verify required parameter 'srcDb' is not null or undefined
            if (srcDb === null || srcDb === undefined) {
                throw new RequiredError('srcDb','Required parameter srcDb was null or undefined when calling migrateUserGroups.');
            }
            // verify required parameter 'srcUser' is not null or undefined
            if (srcUser === null || srcUser === undefined) {
                throw new RequiredError('srcUser','Required parameter srcUser was null or undefined when calling migrateUserGroups.');
            }
            // verify required parameter 'srcPassword' is not null or undefined
            if (srcPassword === null || srcPassword === undefined) {
                throw new RequiredError('srcPassword','Required parameter srcPassword was null or undefined when calling migrateUserGroups.');
            }
            const localVarPath = `/system/migrate/userGroups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (srcSystem !== undefined) {
                localVarQueryParameter['srcSystem'] = srcSystem;
            }

            if (srcDb !== undefined) {
                localVarQueryParameter['srcDb'] = srcDb;
            }

            if (srcUser !== undefined) {
                localVarQueryParameter['srcUser'] = srcUser;
            }

            if (srcPassword !== undefined) {
                localVarQueryParameter['srcPassword'] = srcPassword;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary migrateUsers
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateUsers: async (srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'srcSystem' is not null or undefined
            if (srcSystem === null || srcSystem === undefined) {
                throw new RequiredError('srcSystem','Required parameter srcSystem was null or undefined when calling migrateUsers.');
            }
            // verify required parameter 'srcDb' is not null or undefined
            if (srcDb === null || srcDb === undefined) {
                throw new RequiredError('srcDb','Required parameter srcDb was null or undefined when calling migrateUsers.');
            }
            // verify required parameter 'srcUser' is not null or undefined
            if (srcUser === null || srcUser === undefined) {
                throw new RequiredError('srcUser','Required parameter srcUser was null or undefined when calling migrateUsers.');
            }
            // verify required parameter 'srcPassword' is not null or undefined
            if (srcPassword === null || srcPassword === undefined) {
                throw new RequiredError('srcPassword','Required parameter srcPassword was null or undefined when calling migrateUsers.');
            }
            const localVarPath = `/system/migrate/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (srcSystem !== undefined) {
                localVarQueryParameter['srcSystem'] = srcSystem;
            }

            if (srcDb !== undefined) {
                localVarQueryParameter['srcDb'] = srcDb;
            }

            if (srcUser !== undefined) {
                localVarQueryParameter['srcUser'] = srcUser;
            }

            if (srcPassword !== undefined) {
                localVarQueryParameter['srcPassword'] = srcPassword;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary removeMediaReplacementTemplate
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMediaReplacementTemplate: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeMediaReplacementTemplate.');
            }
            const localVarPath = `/system/mediaReplacementTemplate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary removeReplacement
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeReplacement: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeReplacement.');
            }
            const localVarPath = `/system/mediaReplacement`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary rendernodeMessage
         * @param {RendernodeMessage} rendernodeMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rendernodeMessage: async (rendernodeMessage: RendernodeMessage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rendernodeMessage' is not null or undefined
            if (rendernodeMessage === null || rendernodeMessage === undefined) {
                throw new RequiredError('rendernodeMessage','Required parameter rendernodeMessage was null or undefined when calling rendernodeMessage.');
            }
            const localVarPath = `/system/rendernodeMessage`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof rendernodeMessage !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rendernodeMessage !== undefined ? rendernodeMessage : {}) : (rendernodeMessage || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary replaceReplacementFile
         * @param {string} replacementId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceReplacementFile: async (replacementId: string, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'replacementId' is not null or undefined
            if (replacementId === null || replacementId === undefined) {
                throw new RequiredError('replacementId','Required parameter replacementId was null or undefined when calling replaceReplacementFile.');
            }
            const localVarPath = `/system/mediaReplacement`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (replacementId !== undefined) {
                localVarQueryParameter['replacementId'] = replacementId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary replaceTemplateFile
         * @param {string} templateId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceTemplateFile: async (templateId: string, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling replaceTemplateFile.');
            }
            const localVarPath = `/system/mediaReplacementTemplate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (templateId !== undefined) {
                localVarQueryParameter['templateId'] = templateId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveMediaReplacementTemplate
         * @param {MediaReplacementTemplate} [mediaReplacementTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMediaReplacementTemplate: async (mediaReplacementTemplate?: MediaReplacementTemplate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/mediaReplacementTemplate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof mediaReplacementTemplate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(mediaReplacementTemplate !== undefined ? mediaReplacementTemplate : {}) : (mediaReplacementTemplate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveReplacement
         * @param {string} mediaReplacementTemplateId 
         * @param {Replacement} [replacement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReplacement: async (mediaReplacementTemplateId: string, replacement?: Replacement, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mediaReplacementTemplateId' is not null or undefined
            if (mediaReplacementTemplateId === null || mediaReplacementTemplateId === undefined) {
                throw new RequiredError('mediaReplacementTemplateId','Required parameter mediaReplacementTemplateId was null or undefined when calling saveReplacement.');
            }
            const localVarPath = `/system/mediaReplacement`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (mediaReplacementTemplateId !== undefined) {
                localVarQueryParameter['mediaReplacementTemplateId'] = mediaReplacementTemplateId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof replacement !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(replacement !== undefined ? replacement : {}) : (replacement || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchCustomerMetadataTemplate
         * @param {string} [id] 
         * @param {Array<string>} [groupIds] 
         * @param {string} [name] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCustomerMetadataTemplate: async (id?: string, groupIds?: Array<string>, name?: string, type?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/metadata/template`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (groupIds) {
                localVarQueryParameter['groupIds'] = groupIds;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchFontMappings
         * @param {SearchFontMappingsObject} searchFontMappingsObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFontMappings: async (searchFontMappingsObject: SearchFontMappingsObject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchFontMappingsObject' is not null or undefined
            if (searchFontMappingsObject === null || searchFontMappingsObject === undefined) {
                throw new RequiredError('searchFontMappingsObject','Required parameter searchFontMappingsObject was null or undefined when calling searchFontMappings.');
            }
            const localVarPath = `/system/fontMapping`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof searchFontMappingsObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(searchFontMappingsObject !== undefined ? searchFontMappingsObject : {}) : (searchFontMappingsObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sendMail
         * @param {string} [url] 
         * @param {string} [email] 
         * @param {string} [id] 
         * @param {string} [expiration] 
         * @param {MailType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMail: async (url?: string, email?: string, id?: string, expiration?: string, type?: MailType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/mail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (expiration !== undefined) {
                localVarQueryParameter['expiration'] = (expiration as any instanceof Date) ?
                    (expiration as any).toISOString().substr(0,10) :
                    expiration;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sendMessageToWorkflowEngine
         * @param {WorkflowEngineMessageType} type 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessageToWorkflowEngine: async (type: WorkflowEngineMessageType, notificationDto?: NotificationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling sendMessageToWorkflowEngine.');
            }
            const localVarPath = `/system/send/workflowengine/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof notificationDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(notificationDto !== undefined ? notificationDto : {}) : (notificationDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Fontmapping
         * @param {FontMappings} [fontMappings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFontMapping: async (fontMappings?: FontMappings, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/fontMappings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof fontMappings !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fontMappings !== undefined ? fontMappings : {}) : (fontMappings || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateSystemPreference
         * @param {SystemPreference} [systemPreference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystemPreference: async (systemPreference?: SystemPreference, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/preference`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof systemPreference !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(systemPreference !== undefined ? systemPreference : {}) : (systemPreference || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateSystemPreferenceKey
         * @param {string} key 
         * @param {SystemPreferenceKeyUpdate} [systemPreferenceKeyUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystemPreferenceKey: async (key: string, systemPreferenceKeyUpdate?: SystemPreferenceKeyUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling updateSystemPreferenceKey.');
            }
            const localVarPath = `/system/preference/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof systemPreferenceKeyUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(systemPreferenceKeyUpdate !== undefined ? systemPreferenceKeyUpdate : {}) : (systemPreferenceKeyUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadAndSaveMediaReplacementTemplate
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAndSaveMediaReplacementTemplate: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/upload/mediaReplacementTemplate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadAndSaveReplacement
         * @param {string} mediaReplacementTemplateId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAndSaveReplacement: async (mediaReplacementTemplateId: string, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mediaReplacementTemplateId' is not null or undefined
            if (mediaReplacementTemplateId === null || mediaReplacementTemplateId === undefined) {
                throw new RequiredError('mediaReplacementTemplateId','Required parameter mediaReplacementTemplateId was null or undefined when calling uploadAndSaveReplacement.');
            }
            const localVarPath = `/system/upload/template`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (mediaReplacementTemplateId !== undefined) {
                localVarQueryParameter['mediaReplacementTemplateId'] = mediaReplacementTemplateId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadFontMappingsXml
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFontMappingsXml: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/fontMappings/upload/xml`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addCustomerMetadata
         * @param {CustomerMetadata} [customerMetadata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCustomerMetadata(customerMetadata?: CustomerMetadata, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerMetadata>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).addCustomerMetadata(customerMetadata, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary addCustomerMetadataTemplate
         * @param {CustomerMetadataTemplate} [customerMetadataTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCustomerMetadataTemplate(customerMetadataTemplate?: CustomerMetadataTemplate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerMetadataTemplate>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).addCustomerMetadataTemplate(customerMetadataTemplate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add Fontmapping
         * @param {FontMappings} [fontMappings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFontMapping(fontMappings?: FontMappings, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FontMappings>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).addFontMapping(fontMappings, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary addSystemPreference
         * @param {SystemPreference} [systemPreference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSystemPreference(systemPreference?: SystemPreference, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemPreference>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).addSystemPreference(systemPreference, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary checkMediaReplaceableNames
         * @param {string} assetItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkMediaReplaceableNames(assetItemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MediaReplacementTemplate>>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).checkMediaReplaceableNames(assetItemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary deleteCustomerMetadata
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerMetadata(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).deleteCustomerMetadata(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary deleteCustomerMetadataTemplate
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerMetadataTemplate(id?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).deleteCustomerMetadataTemplate(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete Fontmapping
         * @param {number} index 
         * @param {string} [group] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFontMappings(index: number, group?: string, language?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FontMappings>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).deleteFontMappings(index, group, language, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary deleteSystemPreference
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSystemPreference(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).deleteSystemPreference(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary executeCommand
         * @param {string} password 
         * @param {string} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeCommand(password: string, command?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).executeCommand(password, command, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary generateReport
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {boolean} [createAssetsImportedSheet] 
         * @param {boolean} [createJobsCreatedSheet] 
         * @param {boolean} [createJobsCompletedSheet] 
         * @param {boolean} [createActiveUsersSheet] 
         * @param {boolean} [createAllUsersSheet] 
         * @param {boolean} [createJobsToApproveSheet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateReport(start?: string, end?: string, createAssetsImportedSheet?: boolean, createJobsCreatedSheet?: boolean, createJobsCompletedSheet?: boolean, createActiveUsersSheet?: boolean, createAllUsersSheet?: boolean, createJobsToApproveSheet?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).generateReport(start, end, createAssetsImportedSheet, createJobsCreatedSheet, createJobsCompletedSheet, createActiveUsersSheet, createAllUsersSheet, createJobsToApproveSheet, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getAllFontMappings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllFontMappings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FontMappings>>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getAllFontMappings(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getAllMediaReplacementTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMediaReplacementTemplate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MediaReplacementTemplate>>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getAllMediaReplacementTemplate(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getCountries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountries(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getCountries(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getCustomMetadataById
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomMetadataById(id?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerMetadata>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getCustomMetadataById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getCustomMetadataByIdList
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomMetadataByIdList(ids?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerMetadata>>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getCustomMetadataByIdList(ids, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getDevObject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevObject(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getDevObject(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getFaviconLogo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFaviconLogo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getFaviconLogo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Fontmapping
         * @param {string} [groupId] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFontMapping(groupId?: string, language?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FontMappings>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getFontMapping(groupId, language, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getHealthInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealthInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServerHealthInfo>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getHealthInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getI18nMessages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getI18nMessages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getI18nMessages(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getLanguages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLanguages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getLanguages(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getListSystemPreferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListSystemPreferences(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<SystemPreference>; }>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getListSystemPreferences(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getLogo
         * @param {LogoType} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogo(type: LogoType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getLogo(type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getRendernode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRendernode(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getRendernode(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getRendernodeInformation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRendernodeInformation(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RendernodeInformation>>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getRendernodeInformation(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getReplaceMediaThumbnail
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReplaceMediaThumbnail(path: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getReplaceMediaThumbnail(path, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getReplacement
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReplacement(ids: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Replacement>>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getReplacement(ids, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getSystemPreference
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemPreference(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemPreference>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getSystemPreference(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getSystemPreferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemPreferences(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SystemPreference>>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getSystemPreferences(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getVersion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getVersion(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary isAutoTranslatable
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isAutoTranslatable(language: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).isAutoTranslatable(language, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary loginTerminal
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginTerminal(password: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).loginTerminal(password, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary migrateAll
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateAll(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).migrateAll(srcSystem, srcDb, srcUser, srcPassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary migrateAssetItems
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateAssetItems(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).migrateAssetItems(srcSystem, srcDb, srcUser, srcPassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary migrateAssets
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateAssets(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).migrateAssets(srcSystem, srcDb, srcUser, srcPassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary migrateFields
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateFields(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).migrateFields(srcSystem, srcDb, srcUser, srcPassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary migrateUserGroups
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateUserGroups(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).migrateUserGroups(srcSystem, srcDb, srcUser, srcPassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary migrateUsers
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateUsers(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).migrateUsers(srcSystem, srcDb, srcUser, srcPassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary removeMediaReplacementTemplate
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMediaReplacementTemplate(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).removeMediaReplacementTemplate(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary removeReplacement
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeReplacement(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).removeReplacement(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary rendernodeMessage
         * @param {RendernodeMessage} rendernodeMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rendernodeMessage(rendernodeMessage: RendernodeMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).rendernodeMessage(rendernodeMessage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary replaceReplacementFile
         * @param {string} replacementId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceReplacementFile(replacementId: string, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Replacement>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).replaceReplacementFile(replacementId, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary replaceTemplateFile
         * @param {string} templateId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceTemplateFile(templateId: string, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaReplacementTemplate>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).replaceTemplateFile(templateId, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary saveMediaReplacementTemplate
         * @param {MediaReplacementTemplate} [mediaReplacementTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveMediaReplacementTemplate(mediaReplacementTemplate?: MediaReplacementTemplate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaReplacementTemplate>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).saveMediaReplacementTemplate(mediaReplacementTemplate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary saveReplacement
         * @param {string} mediaReplacementTemplateId 
         * @param {Replacement} [replacement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveReplacement(mediaReplacementTemplateId: string, replacement?: Replacement, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Replacement>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).saveReplacement(mediaReplacementTemplateId, replacement, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary searchCustomerMetadataTemplate
         * @param {string} [id] 
         * @param {Array<string>} [groupIds] 
         * @param {string} [name] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCustomerMetadataTemplate(id?: string, groupIds?: Array<string>, name?: string, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerMetadataTemplate>>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).searchCustomerMetadataTemplate(id, groupIds, name, type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary searchFontMappings
         * @param {SearchFontMappingsObject} searchFontMappingsObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFontMappings(searchFontMappingsObject: SearchFontMappingsObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FontMappings>>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).searchFontMappings(searchFontMappingsObject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary sendMail
         * @param {string} [url] 
         * @param {string} [email] 
         * @param {string} [id] 
         * @param {string} [expiration] 
         * @param {MailType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMail(url?: string, email?: string, id?: string, expiration?: string, type?: MailType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).sendMail(url, email, id, expiration, type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary sendMessageToWorkflowEngine
         * @param {WorkflowEngineMessageType} type 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMessageToWorkflowEngine(type: WorkflowEngineMessageType, notificationDto?: NotificationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).sendMessageToWorkflowEngine(type, notificationDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Fontmapping
         * @param {FontMappings} [fontMappings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFontMapping(fontMappings?: FontMappings, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FontMappings>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).updateFontMapping(fontMappings, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateSystemPreference
         * @param {SystemPreference} [systemPreference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSystemPreference(systemPreference?: SystemPreference, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemPreference>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).updateSystemPreference(systemPreference, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateSystemPreferenceKey
         * @param {string} key 
         * @param {SystemPreferenceKeyUpdate} [systemPreferenceKeyUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSystemPreferenceKey(key: string, systemPreferenceKeyUpdate?: SystemPreferenceKeyUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemPreference>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).updateSystemPreferenceKey(key, systemPreferenceKeyUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary uploadAndSaveMediaReplacementTemplate
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAndSaveMediaReplacementTemplate(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaReplacementTemplate>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).uploadAndSaveMediaReplacementTemplate(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary uploadAndSaveReplacement
         * @param {string} mediaReplacementTemplateId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAndSaveReplacement(mediaReplacementTemplateId: string, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Replacement>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).uploadAndSaveReplacement(mediaReplacementTemplateId, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary uploadFontMappingsXml
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFontMappingsXml(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).uploadFontMappingsXml(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary addCustomerMetadata
         * @param {CustomerMetadata} [customerMetadata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCustomerMetadata(customerMetadata?: CustomerMetadata, options?: any): AxiosPromise<CustomerMetadata> {
            return SystemApiFp(configuration).addCustomerMetadata(customerMetadata, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary addCustomerMetadataTemplate
         * @param {CustomerMetadataTemplate} [customerMetadataTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCustomerMetadataTemplate(customerMetadataTemplate?: CustomerMetadataTemplate, options?: any): AxiosPromise<CustomerMetadataTemplate> {
            return SystemApiFp(configuration).addCustomerMetadataTemplate(customerMetadataTemplate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add Fontmapping
         * @param {FontMappings} [fontMappings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFontMapping(fontMappings?: FontMappings, options?: any): AxiosPromise<FontMappings> {
            return SystemApiFp(configuration).addFontMapping(fontMappings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary addSystemPreference
         * @param {SystemPreference} [systemPreference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSystemPreference(systemPreference?: SystemPreference, options?: any): AxiosPromise<SystemPreference> {
            return SystemApiFp(configuration).addSystemPreference(systemPreference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary checkMediaReplaceableNames
         * @param {string} assetItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkMediaReplaceableNames(assetItemId: string, options?: any): AxiosPromise<Array<MediaReplacementTemplate>> {
            return SystemApiFp(configuration).checkMediaReplaceableNames(assetItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteCustomerMetadata
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerMetadata(id: string, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).deleteCustomerMetadata(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteCustomerMetadataTemplate
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerMetadataTemplate(id?: string, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).deleteCustomerMetadataTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Fontmapping
         * @param {number} index 
         * @param {string} [group] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFontMappings(index: number, group?: string, language?: string, options?: any): AxiosPromise<FontMappings> {
            return SystemApiFp(configuration).deleteFontMappings(index, group, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteSystemPreference
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSystemPreference(key: string, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).deleteSystemPreference(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary executeCommand
         * @param {string} password 
         * @param {string} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeCommand(password: string, command?: string, options?: any): AxiosPromise<string> {
            return SystemApiFp(configuration).executeCommand(password, command, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary generateReport
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {boolean} [createAssetsImportedSheet] 
         * @param {boolean} [createJobsCreatedSheet] 
         * @param {boolean} [createJobsCompletedSheet] 
         * @param {boolean} [createActiveUsersSheet] 
         * @param {boolean} [createAllUsersSheet] 
         * @param {boolean} [createJobsToApproveSheet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReport(start?: string, end?: string, createAssetsImportedSheet?: boolean, createJobsCreatedSheet?: boolean, createJobsCompletedSheet?: boolean, createActiveUsersSheet?: boolean, createAllUsersSheet?: boolean, createJobsToApproveSheet?: boolean, options?: any): AxiosPromise<any> {
            return SystemApiFp(configuration).generateReport(start, end, createAssetsImportedSheet, createJobsCreatedSheet, createJobsCompletedSheet, createActiveUsersSheet, createAllUsersSheet, createJobsToApproveSheet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllFontMappings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFontMappings(options?: any): AxiosPromise<Array<FontMappings>> {
            return SystemApiFp(configuration).getAllFontMappings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllMediaReplacementTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMediaReplacementTemplate(options?: any): AxiosPromise<Array<MediaReplacementTemplate>> {
            return SystemApiFp(configuration).getAllMediaReplacementTemplate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getCountries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries(options?: any): AxiosPromise<Array<string>> {
            return SystemApiFp(configuration).getCountries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getCustomMetadataById
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomMetadataById(id?: string, options?: any): AxiosPromise<CustomerMetadata> {
            return SystemApiFp(configuration).getCustomMetadataById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getCustomMetadataByIdList
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomMetadataByIdList(ids?: Array<string>, options?: any): AxiosPromise<Array<CustomerMetadata>> {
            return SystemApiFp(configuration).getCustomMetadataByIdList(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getDevObject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevObject(options?: any): AxiosPromise<object> {
            return SystemApiFp(configuration).getDevObject(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getFaviconLogo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaviconLogo(options?: any): AxiosPromise<any> {
            return SystemApiFp(configuration).getFaviconLogo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Fontmapping
         * @param {string} [groupId] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFontMapping(groupId?: string, language?: string, options?: any): AxiosPromise<FontMappings> {
            return SystemApiFp(configuration).getFontMapping(groupId, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getHealthInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthInfo(options?: any): AxiosPromise<ServerHealthInfo> {
            return SystemApiFp(configuration).getHealthInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getI18nMessages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nMessages(options?: any): AxiosPromise<string> {
            return SystemApiFp(configuration).getI18nMessages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getLanguages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguages(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return SystemApiFp(configuration).getLanguages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListSystemPreferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSystemPreferences(options?: any): AxiosPromise<{ [key: string]: Array<SystemPreference>; }> {
            return SystemApiFp(configuration).getListSystemPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getLogo
         * @param {LogoType} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogo(type: LogoType, options?: any): AxiosPromise<any> {
            return SystemApiFp(configuration).getLogo(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getRendernode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRendernode(options?: any): AxiosPromise<any> {
            return SystemApiFp(configuration).getRendernode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getRendernodeInformation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRendernodeInformation(options?: any): AxiosPromise<Array<RendernodeInformation>> {
            return SystemApiFp(configuration).getRendernodeInformation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getReplaceMediaThumbnail
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReplaceMediaThumbnail(path: string, options?: any): AxiosPromise<any> {
            return SystemApiFp(configuration).getReplaceMediaThumbnail(path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getReplacement
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReplacement(ids: Array<string>, options?: any): AxiosPromise<Array<Replacement>> {
            return SystemApiFp(configuration).getReplacement(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getSystemPreference
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemPreference(key: string, options?: any): AxiosPromise<SystemPreference> {
            return SystemApiFp(configuration).getSystemPreference(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getSystemPreferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemPreferences(options?: any): AxiosPromise<Array<SystemPreference>> {
            return SystemApiFp(configuration).getSystemPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getVersion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(options?: any): AxiosPromise<string> {
            return SystemApiFp(configuration).getVersion(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary isAutoTranslatable
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAutoTranslatable(language: string, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).isAutoTranslatable(language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary loginTerminal
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginTerminal(password: string, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).loginTerminal(password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary migrateAll
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateAll(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).migrateAll(srcSystem, srcDb, srcUser, srcPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary migrateAssetItems
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateAssetItems(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).migrateAssetItems(srcSystem, srcDb, srcUser, srcPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary migrateAssets
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateAssets(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).migrateAssets(srcSystem, srcDb, srcUser, srcPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary migrateFields
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateFields(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).migrateFields(srcSystem, srcDb, srcUser, srcPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary migrateUserGroups
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateUserGroups(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).migrateUserGroups(srcSystem, srcDb, srcUser, srcPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary migrateUsers
         * @param {string} srcSystem 
         * @param {string} srcDb 
         * @param {string} srcUser 
         * @param {string} srcPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateUsers(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).migrateUsers(srcSystem, srcDb, srcUser, srcPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary removeMediaReplacementTemplate
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMediaReplacementTemplate(id: string, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).removeMediaReplacementTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary removeReplacement
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeReplacement(id: string, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).removeReplacement(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary rendernodeMessage
         * @param {RendernodeMessage} rendernodeMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rendernodeMessage(rendernodeMessage: RendernodeMessage, options?: any): AxiosPromise<void> {
            return SystemApiFp(configuration).rendernodeMessage(rendernodeMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary replaceReplacementFile
         * @param {string} replacementId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceReplacementFile(replacementId: string, file?: any, options?: any): AxiosPromise<Replacement> {
            return SystemApiFp(configuration).replaceReplacementFile(replacementId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary replaceTemplateFile
         * @param {string} templateId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceTemplateFile(templateId: string, file?: any, options?: any): AxiosPromise<MediaReplacementTemplate> {
            return SystemApiFp(configuration).replaceTemplateFile(templateId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveMediaReplacementTemplate
         * @param {MediaReplacementTemplate} [mediaReplacementTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMediaReplacementTemplate(mediaReplacementTemplate?: MediaReplacementTemplate, options?: any): AxiosPromise<MediaReplacementTemplate> {
            return SystemApiFp(configuration).saveMediaReplacementTemplate(mediaReplacementTemplate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveReplacement
         * @param {string} mediaReplacementTemplateId 
         * @param {Replacement} [replacement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReplacement(mediaReplacementTemplateId: string, replacement?: Replacement, options?: any): AxiosPromise<Replacement> {
            return SystemApiFp(configuration).saveReplacement(mediaReplacementTemplateId, replacement, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchCustomerMetadataTemplate
         * @param {string} [id] 
         * @param {Array<string>} [groupIds] 
         * @param {string} [name] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCustomerMetadataTemplate(id?: string, groupIds?: Array<string>, name?: string, type?: string, options?: any): AxiosPromise<Array<CustomerMetadataTemplate>> {
            return SystemApiFp(configuration).searchCustomerMetadataTemplate(id, groupIds, name, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchFontMappings
         * @param {SearchFontMappingsObject} searchFontMappingsObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFontMappings(searchFontMappingsObject: SearchFontMappingsObject, options?: any): AxiosPromise<Array<FontMappings>> {
            return SystemApiFp(configuration).searchFontMappings(searchFontMappingsObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sendMail
         * @param {string} [url] 
         * @param {string} [email] 
         * @param {string} [id] 
         * @param {string} [expiration] 
         * @param {MailType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMail(url?: string, email?: string, id?: string, expiration?: string, type?: MailType, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).sendMail(url, email, id, expiration, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sendMessageToWorkflowEngine
         * @param {WorkflowEngineMessageType} type 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessageToWorkflowEngine(type: WorkflowEngineMessageType, notificationDto?: NotificationDto, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).sendMessageToWorkflowEngine(type, notificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Fontmapping
         * @param {FontMappings} [fontMappings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFontMapping(fontMappings?: FontMappings, options?: any): AxiosPromise<FontMappings> {
            return SystemApiFp(configuration).updateFontMapping(fontMappings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateSystemPreference
         * @param {SystemPreference} [systemPreference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystemPreference(systemPreference?: SystemPreference, options?: any): AxiosPromise<SystemPreference> {
            return SystemApiFp(configuration).updateSystemPreference(systemPreference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateSystemPreferenceKey
         * @param {string} key 
         * @param {SystemPreferenceKeyUpdate} [systemPreferenceKeyUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystemPreferenceKey(key: string, systemPreferenceKeyUpdate?: SystemPreferenceKeyUpdate, options?: any): AxiosPromise<SystemPreference> {
            return SystemApiFp(configuration).updateSystemPreferenceKey(key, systemPreferenceKeyUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary uploadAndSaveMediaReplacementTemplate
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAndSaveMediaReplacementTemplate(file?: any, options?: any): AxiosPromise<MediaReplacementTemplate> {
            return SystemApiFp(configuration).uploadAndSaveMediaReplacementTemplate(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary uploadAndSaveReplacement
         * @param {string} mediaReplacementTemplateId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAndSaveReplacement(mediaReplacementTemplateId: string, file?: any, options?: any): AxiosPromise<Replacement> {
            return SystemApiFp(configuration).uploadAndSaveReplacement(mediaReplacementTemplateId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary uploadFontMappingsXml
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFontMappingsXml(file?: any, options?: any): AxiosPromise<boolean> {
            return SystemApiFp(configuration).uploadFontMappingsXml(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 
     * @summary addCustomerMetadata
     * @param {CustomerMetadata} [customerMetadata] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public addCustomerMetadata(customerMetadata?: CustomerMetadata, options?: any) {
        return SystemApiFp(this.configuration).addCustomerMetadata(customerMetadata, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary addCustomerMetadataTemplate
     * @param {CustomerMetadataTemplate} [customerMetadataTemplate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public addCustomerMetadataTemplate(customerMetadataTemplate?: CustomerMetadataTemplate, options?: any) {
        return SystemApiFp(this.configuration).addCustomerMetadataTemplate(customerMetadataTemplate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add Fontmapping
     * @param {FontMappings} [fontMappings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public addFontMapping(fontMappings?: FontMappings, options?: any) {
        return SystemApiFp(this.configuration).addFontMapping(fontMappings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary addSystemPreference
     * @param {SystemPreference} [systemPreference] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public addSystemPreference(systemPreference?: SystemPreference, options?: any) {
        return SystemApiFp(this.configuration).addSystemPreference(systemPreference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary checkMediaReplaceableNames
     * @param {string} assetItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public checkMediaReplaceableNames(assetItemId: string, options?: any) {
        return SystemApiFp(this.configuration).checkMediaReplaceableNames(assetItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteCustomerMetadata
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public deleteCustomerMetadata(id: string, options?: any) {
        return SystemApiFp(this.configuration).deleteCustomerMetadata(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteCustomerMetadataTemplate
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public deleteCustomerMetadataTemplate(id?: string, options?: any) {
        return SystemApiFp(this.configuration).deleteCustomerMetadataTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Fontmapping
     * @param {number} index 
     * @param {string} [group] 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public deleteFontMappings(index: number, group?: string, language?: string, options?: any) {
        return SystemApiFp(this.configuration).deleteFontMappings(index, group, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteSystemPreference
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public deleteSystemPreference(key: string, options?: any) {
        return SystemApiFp(this.configuration).deleteSystemPreference(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary executeCommand
     * @param {string} password 
     * @param {string} [command] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public executeCommand(password: string, command?: string, options?: any) {
        return SystemApiFp(this.configuration).executeCommand(password, command, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary generateReport
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {boolean} [createAssetsImportedSheet] 
     * @param {boolean} [createJobsCreatedSheet] 
     * @param {boolean} [createJobsCompletedSheet] 
     * @param {boolean} [createActiveUsersSheet] 
     * @param {boolean} [createAllUsersSheet] 
     * @param {boolean} [createJobsToApproveSheet] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public generateReport(start?: string, end?: string, createAssetsImportedSheet?: boolean, createJobsCreatedSheet?: boolean, createJobsCompletedSheet?: boolean, createActiveUsersSheet?: boolean, createAllUsersSheet?: boolean, createJobsToApproveSheet?: boolean, options?: any) {
        return SystemApiFp(this.configuration).generateReport(start, end, createAssetsImportedSheet, createJobsCreatedSheet, createJobsCompletedSheet, createActiveUsersSheet, createAllUsersSheet, createJobsToApproveSheet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllFontMappings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getAllFontMappings(options?: any) {
        return SystemApiFp(this.configuration).getAllFontMappings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllMediaReplacementTemplate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getAllMediaReplacementTemplate(options?: any) {
        return SystemApiFp(this.configuration).getAllMediaReplacementTemplate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getCountries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getCountries(options?: any) {
        return SystemApiFp(this.configuration).getCountries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getCustomMetadataById
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getCustomMetadataById(id?: string, options?: any) {
        return SystemApiFp(this.configuration).getCustomMetadataById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getCustomMetadataByIdList
     * @param {Array<string>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getCustomMetadataByIdList(ids?: Array<string>, options?: any) {
        return SystemApiFp(this.configuration).getCustomMetadataByIdList(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getDevObject
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getDevObject(options?: any) {
        return SystemApiFp(this.configuration).getDevObject(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getFaviconLogo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getFaviconLogo(options?: any) {
        return SystemApiFp(this.configuration).getFaviconLogo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Fontmapping
     * @param {string} [groupId] 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getFontMapping(groupId?: string, language?: string, options?: any) {
        return SystemApiFp(this.configuration).getFontMapping(groupId, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getHealthInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getHealthInfo(options?: any) {
        return SystemApiFp(this.configuration).getHealthInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getI18nMessages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getI18nMessages(options?: any) {
        return SystemApiFp(this.configuration).getI18nMessages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getLanguages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getLanguages(options?: any) {
        return SystemApiFp(this.configuration).getLanguages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListSystemPreferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getListSystemPreferences(options?: any) {
        return SystemApiFp(this.configuration).getListSystemPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getLogo
     * @param {LogoType} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getLogo(type: LogoType, options?: any) {
        return SystemApiFp(this.configuration).getLogo(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getRendernode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getRendernode(options?: any) {
        return SystemApiFp(this.configuration).getRendernode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getRendernodeInformation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getRendernodeInformation(options?: any) {
        return SystemApiFp(this.configuration).getRendernodeInformation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getReplaceMediaThumbnail
     * @param {string} path 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getReplaceMediaThumbnail(path: string, options?: any) {
        return SystemApiFp(this.configuration).getReplaceMediaThumbnail(path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getReplacement
     * @param {Array<string>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getReplacement(ids: Array<string>, options?: any) {
        return SystemApiFp(this.configuration).getReplacement(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getSystemPreference
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getSystemPreference(key: string, options?: any) {
        return SystemApiFp(this.configuration).getSystemPreference(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getSystemPreferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getSystemPreferences(options?: any) {
        return SystemApiFp(this.configuration).getSystemPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getVersion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getVersion(options?: any) {
        return SystemApiFp(this.configuration).getVersion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary isAutoTranslatable
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public isAutoTranslatable(language: string, options?: any) {
        return SystemApiFp(this.configuration).isAutoTranslatable(language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary loginTerminal
     * @param {string} password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public loginTerminal(password: string, options?: any) {
        return SystemApiFp(this.configuration).loginTerminal(password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary migrateAll
     * @param {string} srcSystem 
     * @param {string} srcDb 
     * @param {string} srcUser 
     * @param {string} srcPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public migrateAll(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any) {
        return SystemApiFp(this.configuration).migrateAll(srcSystem, srcDb, srcUser, srcPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary migrateAssetItems
     * @param {string} srcSystem 
     * @param {string} srcDb 
     * @param {string} srcUser 
     * @param {string} srcPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public migrateAssetItems(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any) {
        return SystemApiFp(this.configuration).migrateAssetItems(srcSystem, srcDb, srcUser, srcPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary migrateAssets
     * @param {string} srcSystem 
     * @param {string} srcDb 
     * @param {string} srcUser 
     * @param {string} srcPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public migrateAssets(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any) {
        return SystemApiFp(this.configuration).migrateAssets(srcSystem, srcDb, srcUser, srcPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary migrateFields
     * @param {string} srcSystem 
     * @param {string} srcDb 
     * @param {string} srcUser 
     * @param {string} srcPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public migrateFields(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any) {
        return SystemApiFp(this.configuration).migrateFields(srcSystem, srcDb, srcUser, srcPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary migrateUserGroups
     * @param {string} srcSystem 
     * @param {string} srcDb 
     * @param {string} srcUser 
     * @param {string} srcPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public migrateUserGroups(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any) {
        return SystemApiFp(this.configuration).migrateUserGroups(srcSystem, srcDb, srcUser, srcPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary migrateUsers
     * @param {string} srcSystem 
     * @param {string} srcDb 
     * @param {string} srcUser 
     * @param {string} srcPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public migrateUsers(srcSystem: string, srcDb: string, srcUser: string, srcPassword: string, options?: any) {
        return SystemApiFp(this.configuration).migrateUsers(srcSystem, srcDb, srcUser, srcPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary removeMediaReplacementTemplate
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public removeMediaReplacementTemplate(id: string, options?: any) {
        return SystemApiFp(this.configuration).removeMediaReplacementTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary removeReplacement
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public removeReplacement(id: string, options?: any) {
        return SystemApiFp(this.configuration).removeReplacement(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary rendernodeMessage
     * @param {RendernodeMessage} rendernodeMessage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public rendernodeMessage(rendernodeMessage: RendernodeMessage, options?: any) {
        return SystemApiFp(this.configuration).rendernodeMessage(rendernodeMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary replaceReplacementFile
     * @param {string} replacementId 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public replaceReplacementFile(replacementId: string, file?: any, options?: any) {
        return SystemApiFp(this.configuration).replaceReplacementFile(replacementId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary replaceTemplateFile
     * @param {string} templateId 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public replaceTemplateFile(templateId: string, file?: any, options?: any) {
        return SystemApiFp(this.configuration).replaceTemplateFile(templateId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveMediaReplacementTemplate
     * @param {MediaReplacementTemplate} [mediaReplacementTemplate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public saveMediaReplacementTemplate(mediaReplacementTemplate?: MediaReplacementTemplate, options?: any) {
        return SystemApiFp(this.configuration).saveMediaReplacementTemplate(mediaReplacementTemplate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveReplacement
     * @param {string} mediaReplacementTemplateId 
     * @param {Replacement} [replacement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public saveReplacement(mediaReplacementTemplateId: string, replacement?: Replacement, options?: any) {
        return SystemApiFp(this.configuration).saveReplacement(mediaReplacementTemplateId, replacement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchCustomerMetadataTemplate
     * @param {string} [id] 
     * @param {Array<string>} [groupIds] 
     * @param {string} [name] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public searchCustomerMetadataTemplate(id?: string, groupIds?: Array<string>, name?: string, type?: string, options?: any) {
        return SystemApiFp(this.configuration).searchCustomerMetadataTemplate(id, groupIds, name, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchFontMappings
     * @param {SearchFontMappingsObject} searchFontMappingsObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public searchFontMappings(searchFontMappingsObject: SearchFontMappingsObject, options?: any) {
        return SystemApiFp(this.configuration).searchFontMappings(searchFontMappingsObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sendMail
     * @param {string} [url] 
     * @param {string} [email] 
     * @param {string} [id] 
     * @param {string} [expiration] 
     * @param {MailType} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public sendMail(url?: string, email?: string, id?: string, expiration?: string, type?: MailType, options?: any) {
        return SystemApiFp(this.configuration).sendMail(url, email, id, expiration, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sendMessageToWorkflowEngine
     * @param {WorkflowEngineMessageType} type 
     * @param {NotificationDto} [notificationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public sendMessageToWorkflowEngine(type: WorkflowEngineMessageType, notificationDto?: NotificationDto, options?: any) {
        return SystemApiFp(this.configuration).sendMessageToWorkflowEngine(type, notificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Fontmapping
     * @param {FontMappings} [fontMappings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public updateFontMapping(fontMappings?: FontMappings, options?: any) {
        return SystemApiFp(this.configuration).updateFontMapping(fontMappings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateSystemPreference
     * @param {SystemPreference} [systemPreference] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public updateSystemPreference(systemPreference?: SystemPreference, options?: any) {
        return SystemApiFp(this.configuration).updateSystemPreference(systemPreference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateSystemPreferenceKey
     * @param {string} key 
     * @param {SystemPreferenceKeyUpdate} [systemPreferenceKeyUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public updateSystemPreferenceKey(key: string, systemPreferenceKeyUpdate?: SystemPreferenceKeyUpdate, options?: any) {
        return SystemApiFp(this.configuration).updateSystemPreferenceKey(key, systemPreferenceKeyUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary uploadAndSaveMediaReplacementTemplate
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public uploadAndSaveMediaReplacementTemplate(file?: any, options?: any) {
        return SystemApiFp(this.configuration).uploadAndSaveMediaReplacementTemplate(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary uploadAndSaveReplacement
     * @param {string} mediaReplacementTemplateId 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public uploadAndSaveReplacement(mediaReplacementTemplateId: string, file?: any, options?: any) {
        return SystemApiFp(this.configuration).uploadAndSaveReplacement(mediaReplacementTemplateId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary uploadFontMappingsXml
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public uploadFontMappingsXml(file?: any, options?: any) {
        return SystemApiFp(this.configuration).uploadFontMappingsXml(file, options).then((request) => request(this.axios, this.basePath));
    }

}

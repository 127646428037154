import {defineComponent, getCurrentInstance, onMounted, onUnmounted, ref} from 'vue';
import {onBeforeRouteLeave, useRoute, useRouter} from 'vue-router';
import {UserApi} from '../../../target/api';
import {useStore} from 'vuex';
import {InitAppService} from "@/InitAppService";
import {User} from "../../../target/api/de/moovit/titletoolserver/model";

export default defineComponent({
  components: {},
  setup: function () {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let assets = ref([]);
    let currentPage: any = ref(1);
    let currentLanguage = ref(undefined);
    let currentFile = ref(undefined);
    let transitionDir = ref('slide-right');
    let propsValues = ref({});

    let isDataLoaded = ref(false);

    const userApi: UserApi = new UserApi();

    let preventNav = (event: any) => {
      if (!isDataLoaded.value) {
        return;
      }
      event.preventDefault();
      event.returnValue = '';
    };

    let setDataLoaded = (_isDataLoaded: boolean) => {
      isDataLoaded.value = _isDataLoaded;
    };

    onMounted(async () => {
      window.addEventListener('beforeunload', preventNav);
      emitter.on('set-data-loaded', (isDataLoaded: boolean) => {
        setDataLoaded(isDataLoaded);
      });

      let tUploader = {
        t: 'activityBreadcrumbs.uploader',
        e: undefined,
      };
      store.commit('SET_ACTIVITY_BREADCRUMBS', [tUploader]);

      if (!localStorage.getItem("loginData")) {
        let uploader: User = {id: "!Uploader-Test", password: "!)&ßdg)pJjJöQp.ds97,3%/h5qÖasd0)(/&=)"};
        let accessResultResponse = await userApi.loginUser(uploader)
        // let accessResultResponse = await userApi.loginUser("admin", "bitte0479", "")

        localStorage.loginData = JSON.stringify(accessResultResponse.data);
        console.debug("loginData", accessResultResponse.data);

        let result = InitAppService.initData();
        store.commit("SET_COUNTRIES", (await result).countries);
        store.commit("SET_SETTINGS", (await result).settings);
        store.commit("SET_LANGUAGES", (await result).languages);
      }

    });

    onUnmounted(() => {
      window.removeEventListener('beforeunload', preventNav);
      emitter.off('set-data-loaded');
    });

    onBeforeRouteLeave((to, from, next) => {
      if (!isDataLoaded.value) {
        next();
        if (JSON.parse(localStorage.loginData).user.id === "!Uploader-Test") {
          localStorage.removeItem("loginData");
        }
        //assetApi.destroyAsset("id", localStorage.sessionId);
        return;
      }

      store.commit('SHOW_CONFIRM_DIALOG', {
        dialogTitle: 'Do you really wanna leave this page?',
        dialogContentT: "You'll lose all submitted data!",
        btnConfirmText: 'Leave',
        btnRejectText: 'Stay',

        confirmCallback: () => {
          next();
          //assetApi.destroyAsset("id", localStorage.sessionId);
        },
        rejectCallback: () => {
          next(false);
        },
      });
    });

    return {
      assets,
      currentPage,
      currentLanguage,
      currentFile,
      transitionDir,
      propsValues,
    };
  },
});

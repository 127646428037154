import {
  Asset,
  AssetStatus,
  Progress,
  WsTopic,
} from "../../../target/api/de/moovit/titletoolserver/model";
import {AssetItemApi, UserGroupApi, AssetApi} from "../../../target/api";
import main from "../../main";
import {defineComponent, nextTick, onMounted, onUnmounted, ref, watch} from "vue";
import videojs from "video.js";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import Player = videojs.Player;

export default defineComponent({
  components: {},
  props: ["item", "index", "hideProcess", "hideControls"],
  setup: function (props) {
    const assetApi = new AssetApi();
    const assetItemApi = new AssetItemApi();
    const userGroupApi = new UserGroupApi();
    const store = useStore();
    const router = useRouter();

    let show = ref(false);

    let currentItem = ref(undefined);
    let isPlaying = ref(false);
    let itemProgress = ref<Number>(0);

    let assetItemCount = ref<Number>(0);

    let isGroupDeleted = ref<boolean>(false);
    let isSubscript = ref<boolean>(false);
    let isMouseHovered = ref<boolean>(false);

    let player = ref<Player>();
    let videoPlayerRef = ref<any>({});
    let videoPlayerTimecode = ref<number>();
    let isMuted = ref<boolean>(true);
    let videoOptions = ref<any>();

    let isDialogPlaying = ref(false);
    let isDialogMuted = ref<boolean>(false);

    let dialogPlayer = ref<Player>();
    let dialogVideoPlayerRef = ref<any>({});
    let dialogVideoPlayerTimecode = ref<number>();
    let dialogIsMuted = ref<boolean>(true);
    let dialogVideoOptions = ref<any>();

    let intervalId = ref<number>(0);


    let showFullVideoDialog = ref<boolean>(false);

    let hideDropdown = (index: any) => {
      let element: any = document.getElementById("dropdown-" + index);
      if (element) {
        element.style.display = "none";
      }
    };

    let deleteAsset = async (item: Asset) => {
      if (item && item.id) {
        localStorage.savedAsset = JSON.stringify(item);
        await assetApi.deleteAsset(item.id);
        main.config.globalProperties.$emitter.emit("search-assets", null);
      }
    };

    let undoDeleteAsset = async () => {
      let restoredAssetItem: Asset = JSON.parse(localStorage.savedAsset);
      restoredAssetItem.assetStatus = AssetStatus.NEW;

      await assetApi.addAsset(restoredAssetItem);
      main.config.globalProperties.$emitter.emit("search-assets", null);
      store.commit("HIDE_SNACKBAR");
    };

    let showDropdown = (index: any) => {
      let clickedElement: any = document.getElementById("dropdown-" + index);

      if (clickedElement.style.display == "block") {
        clickedElement.style.display = "none";
      } else {
        let elements = document.getElementsByClassName("dropdown");
        for (let i = 0; i < elements.length; i++) {
          let element = elements[i] as HTMLElement;
          element.style.display = "none";
        }

        clickedElement.style.display = "block";
      }
    };

    let copyLink = () => {
      const link = window.location.origin + "/#/assets?id=" + props.item.id;

      copyToClipboard(link).then(() => {
        store.commit("SHOW_SNACKBAR", {
          snackbarContent: { t: "snackbar.linkCopied" },
        });
        hideDropdown(props.index);
      });
    };

    let copyToClipboard = (textToCopy: string) => {
      if (navigator.clipboard && window.isSecureContext) {
        return navigator.clipboard.writeText(textToCopy);
      } else {
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        textArea.style.position = "absolute";
        textArea.style.opacity = "0";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          document.execCommand("copy");
          textArea.remove();
        });
      }
    };

    let getThumbUrl = (asset: Asset, unique: boolean): string | undefined => {
      if (asset && asset.masterAssetItem) {
        return `${process.env.VUE_APP_TT_ENDPOINT}/asset/item/${
          asset.masterAssetItem.id
        }/thumbnail?access_token=${
          JSON.parse(localStorage.loginData).access_token
        }${unique ? "&ts=" + asset.masterAssetItem.lastModified : ""}`;
      } else {
        return undefined;
      }
    };

    let getVideoURL = (asset: Asset, lowRes: boolean, unique: boolean) => {
      if (asset && asset.masterAssetItem) {
        return (
          process.env.VUE_APP_TT_ENDPOINT +
          "/videostream?" + (lowRes ? "site=assets&" : "") + "access_token=" +
          JSON.parse(localStorage.loginData).access_token +
          "&id=" +
          asset.masterAssetItem.id +
          (unique ? "&ts=" + asset.masterAssetItem.lastModified : "")
        );
      } else {
        return null;
      }
    };

    let isIngest = (asset: Asset) => {
      return asset.assetType == "INGEST";
    };

    let needsVerification = (asset: Asset) => {
      return asset.masterAssetItem && asset.masterAssetItem.needsVerification;
    };

    let getDropDownHeight = () => {
      let height = 0;

      if (
        hasPermission("assets_view/dropdown/edit") ||
        hasPermission("assets_view/dropdown/copy_link") ||
        hasPermission("assets_view/dropdown/delete") ||
        hasPermission("assets_view/dropdown/info")
      ) {
        height = height + 56;
      }

      return height;
    };

    let hasPermission = (permString: string) => {
      return JSON.parse(localStorage.loginData).user.permissions.permissions[permString];
    };

    let loadVideo = (unique: boolean, updatedAsset: Asset) => {
      if (player.value) {
        player.value.src({
          src:
            "" + getVideoURL(updatedAsset ? updatedAsset : props.item, true, unique),
          type: "video/mp4",
        });
        player.value.load();
      }
    };

    let getItemLength = async () => {
      if (props.item == null || props.item.masterAssetItem == null) {
        return 0;
      }
      let result = (
        await assetItemApi.getAssetItemCount(props.item.masterAssetItem.id)
      ).data;
      if (result) {
        assetItemCount.value = result == 0 ? result : result - 1;
      }
    };

    let getLanguageFlag = (name: string): string => {
      if (!name) {
        return "";
      }
      let language = store.state.languages.filter(
        (language: { name: string; value: string }) => language.name === name
      );
      if (language.length > 0) {
        return language[0].value;
      } else {
        return "";
      }
    };

    let _checkGroupExistence = async () => {
      if (props.item && props.item.groupID) {
        let result = (await userGroupApi.getUserGroupById(props.item.groupID))
          .data;
        isGroupDeleted.value = result == "";
      }
    };

    let onClickJobCount = () => {
      router.push({ path: "jobs", query: { assetId: props.item.id } });
    };

    let onClickVideo = (event: any) => {
      console.log("Video Clicked!", event.target.id);
      if (event.target.id === "videoContainer") {
        showFullVideoDialog.value = true;
      }
    }

    let toggleSubscription = () => {
      isSubscript.value = !isSubscript.value;

      let userId = JSON.parse(localStorage.loginData).user.id;

      let asset: Asset = {};

      asset.id = props.item.id;

      if (props.item.subscribers) {
        asset.subscribers = props.item.subscribers;
      }

      if (!asset.subscribers) {
        asset.subscribers = [];
      }

      if (isSubscript.value) {
        if (!asset.subscribers.includes(userId)) {
          asset.subscribers.push(userId);
        }
      } else {
        if (asset.subscribers.includes(userId)) {
          asset.subscribers = asset.subscribers.filter(
            (obj: any) => obj !== userId
          );
        }
      }

      assetApi.updateAsset({ asset: asset, updateJobs: false });
    };

    let _checkSubscription = () => {
      if (props.item.subscribers) {
        isSubscript.value = props.item.subscribers.includes(
          JSON.parse(localStorage.loginData).user.id
        );
      }
    };

    let editAssetFields = () => {

      router.push({
        name: 'job',
        query: { id: props.item.masterAssetItem.id, editFields: "true" },
      })

      hideDropdown(props.index);
    }

    let downloadProject = () => {
      store.commit("SHOW_SNACKBAR", {
        snackbarType: "info",
        snackbarLife: 9999999,
        snackbarContent: { t: "snackbar.downloadInitialized" },
      });

      assetApi.downloadAssetProject(props.item.id, localStorage.sessionId);

      hideDropdown(props.index);
    };

    let generateSubtitlesFromVOA = () => {
      store.commit("SHOW_SNACKBAR", {
        snackbarType: "info",
        snackbarContent: { t: "snackbar.generateVOAInitialized" },
      });

      assetItemApi.generateSubtitlesFromVOA(props.item.id).then((result) => {
        if (result.data !== undefined) {
          console.log("Has generating voa2sub Succeed -> ", result.data);
        }
      });

      hideDropdown(props.index);
    };

    let isUserAdmin = () => {
      return JSON.parse(localStorage.loginData).user.role === "ADMIN";
    };

    let downloadZip = (url: string) => {
      console.log("zip download url ->", url);

      let downloadElement: any = document.createElement("a");
      downloadElement.href = url;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);

      store.commit("SHOW_SNACKBAR", {
        snackbarContent: { t: "snackbar.downloadSuccessful" },
      });
    };

    let startVideo = () => {
      player.value!.play();
      isMouseHovered.value = true;
    };

    let pauseVideo = () => {
      player.value!.pause();
      isMouseHovered.value = false;
    };

    let onVideoUpdate = () => {
      videoPlayerTimecode.value =
        (videoPlayerRef.value.currentTime / videoPlayerRef.value.duration) *
        100;
    };

    let onClickVolume = () => {
      isMuted.value = !isMuted.value;
      videoPlayerRef.value.volume = isMuted.value ? 0 : 1;
    };

    watch(showFullVideoDialog, () => {
      if (showFullVideoDialog.value) {
        setTimeout(() => {
          dialogPlayer.value = videojs(dialogVideoPlayerRef.value, dialogVideoOptions.value);

          if (dialogPlayer.value) {
            dialogPlayer.value.src({
              src:
                "" + getVideoURL(props.item, false, true),
              type: "video/mp4",
            });
            dialogPlayer.value.load();

            dialogPlayer.value!.off("play");
            dialogPlayer.value!.on("play", () => {
              isDialogPlaying.value = true;
            });
            dialogPlayer.value!.off("pause");
            dialogPlayer.value!.on("pause", () => {
              isDialogPlaying.value = false;
            });

            dialogPlayer.value.play();
          }
        }, 100);
      }
    });

    onMounted(() => {
      setTimeout(() => {
        nextTick(() => {
          show.value = true;
        }).then(() => {
          videoOptions.value = {
            preload: "metadata",
            poster: getThumbUrl(props.item, true),
            errorDisplay: false,
            controls: false,
            loop: true,
            bigPlayButton: false,
          };

          dialogVideoOptions.value = {
            preload: "metadata",
            poster: getThumbUrl(props.item, true),
            errorDisplay: false,
            controls: false,
            loop: true,
            bigPlayButton: false,
          };

          player.value = videojs(videoPlayerRef.value, videoOptions.value);

          videoPlayerRef.value.volume = 0;

          dialogVideoPlayerRef.value = {};
          dialogVideoPlayerRef.value.volume = 0;

          player.value!.on("play", () => {
            isPlaying.value = true;
          });

          player.value!.on("pause", () => {
            isPlaying.value = false;
          });

          loadVideo(false, props.item);
        });

        itemProgress.value =
          props.item.masterAssetItem &&
          props.item.masterAssetItem &&
          props.item.masterAssetItem.progress
            ? props.item.masterAssetItem.progress
            : 100;

        if (itemProgress.value >= 100) {
          getItemLength();
        }

        intervalId.value = window.setInterval(() => {
          if (
            videoPlayerRef.value &&
            player &&
            player.value &&
            !player.value.paused()
          ) {
            videoPlayerTimecode.value =
              (videoPlayerRef.value.currentTime /
                videoPlayerRef.value.duration) *
              100;
          }
          if (
            dialogVideoPlayerRef.value &&
            dialogPlayer &&
            dialogPlayer.value &&
            !dialogPlayer.value.paused()
          ) {
            dialogVideoPlayerTimecode.value =
              (dialogVideoPlayerRef.value.currentTime /
                dialogVideoPlayerRef.value.duration) *
              100;
          }
        }, 40);

        main.config.globalProperties.$emitter.on(
          "onChangeVideoSliderPosition" + props.item.id,
          (position: number) => {
            videoPlayerRef.value.currentTime =
              (position / 100) * videoPlayerRef.value.duration;


            if (dialogPlayer.value) {
              dialogVideoPlayerRef.value.currentTime = (position / 100) * dialogVideoPlayerRef.value.duration;
            }
          }
        );

        main.config.globalProperties.$emitter.on(
          "loadVideo" + props.item.id,
          (updatedAsset: Asset) => {
            loadVideo(true, updatedAsset);
          }
        );

        main.config.globalProperties.$emitter.on(
          "ws" + WsTopic.ASSETITEMPROGRESS,
          (progress: Progress) => {
            if (progress.percent && progress.assetId === props.item.id) {
              itemProgress.value = progress.percent;
              if (progress.status === "COMPLETE") {
                main.config.globalProperties.$emitter.emit(
                  "update-asset",
                  progress.assetId,
                  loadVideo
                );
              }
            }
          }
        );

        main.config.globalProperties.$emitter.on(
          "ws" + WsTopic.ZIPCREATED,
          (assetId: string) => {
            if (assetId == props.item.id) {
              store.commit("HIDE_SNACKBAR");

              const url =
                process.env.VUE_APP_TT_ENDPOINT +
                "/asset/item/zipped/project/" +
                localStorage.sessionId +
                "/" +
                props.item.name +
                "-" +
                props.item.id +
                "?access_token=" +
                JSON.parse(localStorage.loginData).access_token;
              downloadZip(url);
            }
          }
        );

        _checkGroupExistence();
        _checkSubscription();
      }, 50 * (props.index <= 20 ? props.index : 0));
    });

    onUnmounted(() => {
      main.config.globalProperties.$emitter.off("loadVideo" + props.item.id);
      main.config.globalProperties.$emitter.off(
        "ws" + WsTopic.ASSETITEMPROGRESS
      );
      main.config.globalProperties.$emitter.off("ws" + WsTopic.ZIPCREATED);
      main.config.globalProperties.$emitter.off(
        "onChangeVideoSliderPosition" + props.item.id
      );

      window.clearInterval(intervalId.value);
    });

    return {
      show,
      copyLink,
      showDropdown,
      deleteAsset,
      undoDeleteAsset,
      isPlaying,
      itemProgress,
      currentItem,
      isGroupDeleted,
      assetItemCount,
      hideDropdown,
      player,
      videoPlayerRef,
      videoPlayerTimecode,

      isDialogPlaying,
      isDialogMuted,
      dialogPlayer,
      dialogVideoPlayerRef,
      dialogVideoPlayerTimecode,
      dialogIsMuted,
      dialogVideoOptions,

      onVideoUpdate,
      isIngest,
      videoOptions,
      hasPermission,
      getDropDownHeight,
      getItemLength,
      onClickJobCount,
      needsVerification,
      getLanguageFlag,
      toggleSubscription,
      generateSubtitlesFromVOA,
      editAssetFields,
      downloadProject,
      isUserAdmin,
      onClickVolume,
      onClickVideo,
      startVideo,
      pauseVideo,
      isSubscript,
      isMouseHovered,
      isMuted,
      showFullVideoDialog
    };
  },
});

<template>
  <transition name="fade">
    <div class="modal-overlay" v-if="dialog || dialogIsOpenOnStart"
         @click="dialog = false; dialogIsOpenOnStart = false"></div>
  </transition>
  
  
  <div>
    <transition name="pop">
      <div v-if="dialog || dialogIsOpenOnStart" class="modal"
           style="position: absolute; overflow: hidden; z-index: 1005; opacity:1; width:45%; height:70%;">
        
        <div
          style="display: flex; width:100%; height:100%; flex-direction:column; justify-content:flex-start; align-items:center;">
          
          <div class="col-dark-blue"
               style="height:100px; display:flex; justify-content:center; align-items:center; font-size:24px; font-weight:700;">
            {{language}}, {{group}}
          </div>
          
          <div style="display:grid; gap: 15px; overflow:auto; height: 100%; width: 100%;"
               @change="didChanges = true">
            <CustomInputField v-model="fontMappingRef.oldFullName" :modelValue="fontMappingRef.oldFullName"
                              label="Old Fullname" type="string"/>
            <CustomInputField v-model="fontMappingRef.newFullName" :modelValue="fontMappingRef.newFullName"
                              label="New Fullname" type="string"/>
            <CustomInputField v-model="fontMappingRef.oldFont" :modelValue="fontMappingRef.oldFont" label="Old Font"
                              type="string"/>
            <CustomInputField v-model="fontMappingRef.newFont" :modelValue="fontMappingRef.newFont" label="New Font"
                              type="string"/>
            <CustomInputField v-model="fontMappingRef.oldStyle" :modelValue="fontMappingRef.oldStyle" label="Old Style"
                              type="string"/>
            <CustomInputField v-model="fontMappingRef.newStyle" :modelValue="fontMappingRef.newStyle" label="New Style"
                              type="string"/>
            <CustomInputField v-model="fontMappingRef.fontFactor" :modelValue="fontMappingRef.fontFactor"
                              label="Font Factor"
                              type="number"/>
            <CustomInputField v-model="fontMappingRef.resetTracking" :modelValue="fontMappingRef.resetTracking"
                              label="Reset Tracking" type="boolean"/>
            <CustomInputField v-model="fontMappingRef.resetLineSpacing" :modelValue="fontMappingRef.resetLineSpacing"
                              label="Reset Line Spacing" type="boolean"/>
            <CustomInputField v-model="fontMappingRef.allCaps" :modelValue="fontMappingRef.allCaps" label="All Caps"
                              type="boolean"/>
            <CustomInputField v-model="fontMappingRef.exceptions" :modelValue="fontMappingRef.exceptions"
                              label="Exceptions"
                              type="array"/>
          </div>
          
          
          <div
            style="width: 90%; height: 100px; display:flex; align-items:center; justify-content:space-between; overflow: hidden">
            <Button @click="toggleDialog(false);"
                    label="Cancel"
                    class="border-radius-8 col-dark-blue bor-transparent bg-tt-white"
                    style="min-width:120px; height:40px; padding:7px 6px 6px 8px; margin-left: 50px"
            />
            
            <Button
              @click="toggleDialog(true)"
              label="Save"
              class="border-radius-8 col-white bor-transparent bg-dark-blue"
              style="min-width:120px; height:40px; padding:7px 6px 6px 8px; margin-right: 50px"
            />
          </div>
        
        </div>
      </div>
    </transition>
    <div
      @click="onClick($event)"
      class="list-item-hover"
      style="
         display: flex;
         width: 98%;
         margin: 5px 5px 16px 5px;
         border: 1px solid #bbbbbb;
         border-radius: 16px;
         cursor: pointer;">
      
      <div class="bg-tt-white bor-white"
           style=" width: 2%; display: flex; align-items: center; height: 80px; border-radius: 16px 0px 0px 16px; border-right-width: 0px;"></div>
      
      <div
        class="bg-tt-white bor-white"
        style="width: 18%; display: flex; align-items: center; cursor: pointer"
      >
        <div style="width: 100%">
          <RoundedLabel
            v-if="language"
            @click="$store.commit('ADD_FILTER_BREADCRUMB', {
                 name: language,
                 type: 'LANGUAGE',
               });
               $emitter.emit('search-font-mapping', null);"
            :text="language"
            style="cursor:pointer; width:145px; height:40px; margin-right:24px; float: left; text-overflow: ellipsis;"
            class="border-radius-24 bor-dark-blue-30"
          />
        </div>
      </div>
      
      <div class="bg-tt-white bor-white "
           style="width: 22%; display: flex; align-items: center; cursor: pointer">
        <div style="width: 100%">
          <RoundedLabel
            v-if="group"
            @click="$store.commit('ADD_FILTER_BREADCRUMB', {
                 name: group,
                 type: 'GROUP',
               });
               $emitter.emit('search-font-mapping', null);"
            :text="group"
            style="cursor:pointer; width:195px; height:40px; margin-right:24px; float: left; text-overflow: ellipsis;"
            class="border-radius-24 bor-dark-blue-30"
          />
        </div>
      </div>
      
      <div class="bg-tt-white bor-white"
           style="width: 20%; display: flex; align-items: center; cursor: pointer">
        <div style="width: 100%">
          <div
            style="
               line-height: 20px;
               max-width: 95%;
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
               cursor: pointer;
             "
          >
            {{mapping.oldFullName}}
          </div>
        </div>
      </div>
      
      <div class="bg-tt-white bor-white"
           style="width: 20%; display: flex; align-items: center; cursor: pointer">
        <div style="width: 100%">
          <div
            style="
               line-height: 20px;
               max-width: 95%;
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
               cursor: pointer;
             "
          >
            {{mapping.newFullName}}
          </div>
        </div>
      </div>
      
      <div class="bg-tt-white bor-white"
           style="width: 15%; display: flex; align-items: center; cursor: pointer">
        
        <div
          style="
            line-height: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          "
        >
          <RoundedLabel
            leftIcon="pi pi-trash"
            @click="deleteFontMapping()"
            class="bor-menu-color"
            style="margin: 15px; display: flex; justify-content: center; align-items: center; cursor: pointer"
          />
        </div>
      </div>
    </div>
  
  </div>

</template>

<script src="./ListFontMappingItem.ts"></script>
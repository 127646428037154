<template>
  <div style="display: flex; align-items: center">
    <div style="margin-right: 10px; padding-top: 1px; color: var(--dark-blue); opacity: 0.5">
      {{leftLabel}}
    </div>
    <InputSwitch v-model="innerModelValue" @click="$emit('update:modelValue', !modelValue)"
                 v-tooltip="tooltip ? $t(tooltip) : ''" :disabled="disabled"/>
    <div style="margin-left: 10px; padding-top: 1px; color: var(--dark-blue); opacity: 0.5">
      {{rightLabel}}
    </div>
  </div>
</template>

<script src="./Switch.ts"></script>

<template>
  <div style="padding-left:24px;">
    <div style="padding:40px 24px 0 0;">
      <TabView v-model:activeIndex="activeTab">
        <TabPanel :header="$t('general.permissions')">
          <div
            class="bg-tt-white admin-control-page overflow-y"
          >
            <AdminControlViewPermissions/>
          </div>
        </TabPanel>
        
        <TabPanel :header="$t('general.settings')">
          <div
            class="bg-tt-white admin-control-page overflow-y"
          >
            <AdminControlViewSettings/>
          </div>
        </TabPanel>
        <TabPanel :header="$t('general.reports')">
          <div
            class="bg-tt-white admin-control-page"
          >
            <AdminControlViewReports/>
          </div>
        </TabPanel>
        <TabPanel :header="$t('general.replaceMedia')">
          <div
            class="bg-tt-white admin-control-page"
          >
            <AdminControlViewReplaceMedia/>
          </div>
        </TabPanel>
        <TabPanel :header="$t('general.fontMappings')">
          <div
            class="bg-tt-white admin-control-page"
          >
            <AdminControlViewFontMapping/>
          </div>
        </TabPanel>
        
        <!--
        <TabPanel header="Page 4">
          <div
            class="bg-tt-white admin-control-page"
          >
            <h1>4</h1>
          </div>
        </TabPanel>
        -->
      </TabView>
    </div>
  </div>
</template>

<style>
.admin-control-page {
  padding: 30px 30px;
  border-radius: 0 16px 16px 16px;
  height: calc(92vh - 150px);
}

.overflow-y {
  overflow-y: auto;
}

</style>

<script src="./AdminControlView.ts"></script>

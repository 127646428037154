import {defineComponent, getCurrentInstance, onMounted} from 'vue';
import {useRouter} from 'vue-router';

export default defineComponent({
  components: {},
  props: ['items'],
  setup: function () {
    const router = useRouter();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let onClick = (t: any) => {
      if (t == 'activityBreadcrumbs.jobs') router.push('/jobs');
      if (t == 'activityBreadcrumbs.jobApprovals') router.push('/jobApproval');
      if (t == 'activityBreadcrumbs.assets') router.push('/assets');
      if (t == 'activityBreadcrumbs.userManagement') {

        router.push('/manageUsers');
        emitter.emit('manageUsers-reload');
      }
      if (t == 'activityBreadcrumbs.adminControl') router.push('/admin');
    };

    onMounted(() => {
    });

    return {onClick};
  },
});

// tslint:disable
/**
 * titletool-nextgen-server
 * TitleTool NextGen Server
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: r.hutter@moovit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkflowEngineMessageType {
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    FILEUPLOADED = 'FILE_UPLOADED',
    ASSETIMPORTED = 'ASSET_IMPORTED',
    ASSETDELETED = 'ASSET_DELETED',
    ASSETANALYZED = 'ASSET_ANALYZED',
    ASSETCREATED = 'ASSET_CREATED',
    JOBCREATED = 'JOB_CREATED',
    JOBDELETED = 'JOB_DELETED',
    JOBSUBMITTED = 'JOB_SUBMITTED',
    JOBSUBMITTEDFORAPPROVAL = 'JOB_SUBMITTED_FOR_APPROVAL',
    JOBREJECTED = 'JOB_REJECTED',
    JOBCHANGED = 'JOB_CHANGED',
    APPROVEDJOBCHANGED = 'APPROVED_JOB_CHANGED',
    UPLOADRENDERCOMPLETE = 'UPLOAD_RENDER_COMPLETE',
    SAVEPERMISSIONS = 'SAVE_PERMISSIONS',
    ADDUPDATESYSTEMPREFERENCES = 'ADD_UPDATE_SYSTEM_PREFERENCES',
    ADDUSERGROUP = 'ADD_USERGROUP',
    DELETEUSERGROUP = 'DELETE_USERGROUP',
    ADDUSER = 'ADD_USER',
    VERIFYUSEREMAIL = 'VERIFY_USER_EMAIL',
    USEREMAILVERIFIED = 'USER_EMAIL_VERIFIED',
    DELETEUSER = 'DELETE_USER',
    GENERATEXLS = 'GENERATE_XLS',
    IMPORTXLS = 'IMPORT_XLS',
    RENDERERERROR = 'RENDERER_ERROR',
    CUSTOM = 'CUSTOM'
}




import {UserGroupApi} from '../../../target/api';
import {defineComponent, getCurrentInstance, onMounted, ref, watch} from 'vue';
import {useStore} from 'vuex';

export default defineComponent({
  props: {
    'modelValue': String,
    'addAllGroup': Boolean,
    'isValid': Boolean,
    'placeholder': String,
    'showClear': Boolean,
    'dropdownHeight': Number,
    'fSelected': Function
  },
  components: {},
  setup: function (props, context) {
    const store = useStore();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let userGroupApi = new UserGroupApi();

    let groups: any = ref([]);

    let allGroups: any = [];
    let innerModelValue: any = ref(undefined);

    watch(props, () => {
      innerModelValue.value = props.modelValue;
    });

    watch(innerModelValue, () => {
      context.emit('update:modelValue', innerModelValue.value);
      if (props.fSelected) {
        props.fSelected();
      }
    });

    onMounted(async () => {
      let responseGroups = (await userGroupApi.searchUserGroups()).data;

      let userGroups: any = [];

      if (props.addAllGroup) {
        userGroups.push('All (*)');
      }

      for (let i in responseGroups) {
        userGroups.push(responseGroups[i].name);
      }

      groups.value = userGroups;
      allGroups.value = userGroups;
    });

    return {
      innerModelValue,
      groups,
    };
  },
});

<template>
  <div style="width: 100%; height: 20px; margin-bottom: 5px; display: flex">
    <div style="width: 220px"/>
    <div style="width: 70%; display: flex; justify-content: space-around">
      <div
        v-for="role in userRoles"
        class="splitter header"
        style="display: flex; width: 100%; justify-content: center"
      >
        {{$t("permissions." + role.toLowerCase())}}
      </div>
    </div>
  </div>
  <div v-for="category in permissionsPreview">
    <div v-for="values in category">
      <div class="settings-header-line">
        <div class="settings-header-text">
          {{$t('' + getHeader(category, values))}}
        </div>
      </div>
      
      <div v-for="value in values" style="display: flex; flex-direction: row">
        <div
          style="
            width: 220px;
            display: flex;
            justify-content: right;
            align-items: center;
          "
        >
          {{value.description.displayName}}
          <div v-tooltip="value.description.tooltip">
            <i
              class="pi pi-info-circle col-dark-blue"
              style="font-size: 12px; padding: 0 10px 10px 2px; cursor: pointer"
            ></i>
          </div>
        </div>
        <div style="width: 70%; display: flex; justify-content: space-around">
          <div
            v-for="(item, index) in value.values"
            class="splitter"
            style="
              display: flex;
              justify-content: center;
              margin-top: 2px;
              margin-bottom: 2px;
              width: 100%;
            "
          >
            {{addUserRole(index)}}
            <Switch
              :modelValue="item"
              :disabled="
                value.description.id === 'admin_control/visibility' &&
                index === 'ADMIN'
              "
              @click="
                value.description.id === 'admin_control/visibility' &&
                index === 'ADMIN'
                  ? ''
                  : commitPermission(value.description.id, index, item)
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.header {
  border-color: #0d1d4f !important;
}

.splitter {
  border-right: #e1e1e1 1px solid;
}

.splitter:last-child {
  border-right: none;
}
</style>

<script src="./AdminControlViewPermissions.ts"></script>

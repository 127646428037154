<template>
  <div
    class="border-radius-12 col-dark-blue bor-dark-blue-45 bg-transparent"
    style="display: flex; flex-direction: column; justify-content: space-between; position: relative; padding: 0 5px 0 3px"
  >
    <div
      v-if="innerModelValue"
      class="col-menu-color"
      style="font-size: 12px; position: absolute; left: 10px; top: 1px"
    >
      {{label}}
    </div>
    <InputText
      v-model="innerModelValue"
      :type="type"
      :autocomplete="autocomplete"
      :id="customId ? customId : ''"
      :disabled="disabled"
      :placeholder="modelValue ? modelValue : label"
      style="width: 100%; height: 100%; border: 0; background-color: transparent"
      :style="innerModelValue ? ' padding-top: 22px;' : ''"
      @change="$emit('update:modelValue', innerModelValue)"
      @input="$emit('update:modelValue', innerModelValue)"
    />
  </div>
</template>

<script src="./CustomInputText.ts"></script>

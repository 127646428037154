<template>
  <div style="padding-left: 24px">
    <div style="display: flex; justify-content: space-between">
      <div style="display: flex; align-items: center">
        <div
          class="bor-dark-blue border-radius-24"
          style="
            height: 40px;
            display: flex;
            align-items: center;
            margin-right: 12px;
            padding: 0px 12px 0px 12px;
          "
        >
          <i class="pi pi-sort"></i>
          
          <div
            style="
              white-space: nowrap;
              margin-left: 12px;
              font-size: 14px;
              font-weight: 400;
            "
          >
            {{$t("general.sortBy")}} :
          </div>
          <Dropdown
            v-model="sortColumn"
            @change="$emitter.emit('search-assets', null)"
            :options="[
              { value: 'NAME', name: $t('general.name') },
              { value: 'ID', name: $t('general.id') },
              { value: 'CREATED', name: $t('general.created') },
              { value: 'LANGUAGE', name: $t('general.language') },
              { value: 'GROUP', name: $t('general.group') },
              { value: 'EXPIRATION', name: $t('general.expiration') },
            ]"
            optionLabel="name"
            optionValue="value"
            style="font-size: 14px; margin: 0px 8px 0px 4px;"
          />
          <i
            @click="
              sortAsc = !sortAsc;
              $emitter.emit('search-assets', null);
            "
            v-if="sortAsc"
            class="pi pi-sort-amount-up-alt"
            style="cursor: pointer"
          ></i>
          <i
            @click="
              sortAsc = !sortAsc;
              $emitter.emit('search-assets', null);
            "
            v-if="!sortAsc"
            class="pi pi-sort-amount-down"
            style="cursor: pointer"
          ></i>
        </div>
        <RoundedLabel
          @click="
            $store.commit('SHOW_ADD_FILTER_DIALOG', {
              hideStatus: true,
              hideTranslator: true,
              hideLocked: true,
              hideCostCenter: true,
              hideRoleDropdown: true,
              hideDefaultLanguage: true,
            })
          "
          leftIcon="pi pi-filter"
          :text="$t('general.addFilter')"
          size="l"
          class="bor-dark-blue"
          style="
            cursor: pointer;
            height: 40px;
            margin-right: 24px;
          "
        />
        <FilterBreadcrumb
          :items="$store.state.filterBreadcrumbs"
          maxWidth="calc(100vw - 980px)"
        />
      </div>
      <div style="display: flex">
        <ChangeViewSwitch v-model="isListView" style="margin-right: 24px"/>
        <SearchField
          v-model="searchText"
          style="padding-right: 38px"
          :blurFunction="onBlurSearchField"
        />
      </div>
    </div>
    
    <div style="padding-top: 24px">
      <div
        :class="!isListView ? 'dyn-asset-item' : ''"
        style="
          height: calc(100vh - 180px);
          width: 100%;
          overflow: hidden;
          overflow-y: scroll;
        "
        id="assetsRef"
      >
        <div v-if="isListView">
          <ListAssetItem
            v-for="(item, index) in assets"
            :key="index"
            :item="item"
            :index="index"
          />
          <div
            v-if="addingAssets"
            style="
              float: left;
              width: 100%;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <ProgressSpinner/>
          </div>
          <div
            v-if="added0Assets"
            style="
              float: left;
              width: 100%;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            {{$t("general.noMoreAssets")}}
          </div>
        </div>
        
        <LargeAssetItem
          v-if="!isListView"
          class="dyn-asset-item-item"
          v-for="(item, index) in assets"
          :key="index"
          :item="item"
          :index="index"
        />
        
        <div v-if="!isListView">
          <ScrollTop target="parent"/>
          <div
            v-if="addingAssets"
            style="
              float: left;
              width: 390px;
              height: 270px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <ProgressSpinner/>
          </div>
          <div
            v-if="added0Assets"
            style="
              float: left;
              width: 100%;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            {{$t("general.noMoreAssets")}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./AssetsView.ts"></script>

// tslint:disable
/**
 * titletool-nextgen-server
 * TitleTool NextGen Server
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: r.hutter@moovit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { AccessRequestResult } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { Permissions } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { PermissionsPreview } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { RegisterUserObject } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { SearchUsersObject } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { TokenObject } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { User } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { UserNotification } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { UserRole } from '../../../../de/moovit/titletoolserver/model';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addNotification
         * @param {UserNotification} [userNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNotification: async (userNotification?: UserNotification, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/notification`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userNotification !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userNotification !== undefined ? userNotification : {}) : (userNotification || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addToken
         * @param {TokenObject} [tokenObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToken: async (tokenObject?: TokenObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/token`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof tokenObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(tokenObject !== undefined ? tokenObject : {}) : (tokenObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addUser
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser: async (user?: User, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof user !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(user !== undefined ? user : {}) : (user || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteUser
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUser.');
            }
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDefaultUrl
         * @param {UserRole} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultUrl: async (role: UserRole, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'role' is not null or undefined
            if (role === null || role === undefined) {
                throw new RequiredError('role','Required parameter role was null or undefined when calling getDefaultUrl.');
            }
            const localVarPath = `/user/defaultUrl/{role}`
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getNotifications
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getNotifications.');
            }
            const localVarPath = `/user/notification`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPermissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/permissions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPermissionsByRole
         * @param {UserRole} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionsByRole: async (role: UserRole, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'role' is not null or undefined
            if (role === null || role === undefined) {
                throw new RequiredError('role','Required parameter role was null or undefined when calling getPermissionsByRole.');
            }
            const localVarPath = `/user/permission/{role}`
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPermissionsPreview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionsPreview: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/permissions/preview`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getUserById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserById.');
            }
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary loginUser
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser: async (user?: User, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof user !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(user !== undefined ? user : {}) : (user || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary logoutUser
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUser: async (user?: User, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/logout`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof user !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(user !== undefined ? user : {}) : (user || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary removeNotification
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeNotification: async (notificationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            if (notificationId === null || notificationId === undefined) {
                throw new RequiredError('notificationId','Required parameter notificationId was null or undefined when calling removeNotification.');
            }
            const localVarPath = `/user/notification`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (notificationId !== undefined) {
                localVarQueryParameter['notificationId'] = notificationId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary restoreUser
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreUser: async (user?: User, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/restore`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof user !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(user !== undefined ? user : {}) : (user || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchUsers
         * @param {SearchUsersObject} [searchUsersObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers: async (searchUsersObject?: SearchUsersObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof searchUsersObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(searchUsersObject !== undefined ? searchUsersObject : {}) : (searchUsersObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sendEmailVerification
         * @param {RegisterUserObject} [registerUserObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailVerification: async (registerUserObject?: RegisterUserObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/email/verify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof registerUserObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(registerUserObject !== undefined ? registerUserObject : {}) : (registerUserObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateNotification
         * @param {UserNotification} [userNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotification: async (userNotification?: UserNotification, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/notification`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userNotification !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userNotification !== undefined ? userNotification : {}) : (userNotification || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updatePermissions
         * @param {Permissions} [permissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePermissions: async (permissions?: Permissions, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/permissions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof permissions !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(permissions !== undefined ? permissions : {}) : (permissions || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updatePermissionsPreview
         * @param {string} id 
         * @param {UserRole} role 
         * @param {boolean} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePermissionsPreview: async (id: string, role: UserRole, value: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePermissionsPreview.');
            }
            // verify required parameter 'role' is not null or undefined
            if (role === null || role === undefined) {
                throw new RequiredError('role','Required parameter role was null or undefined when calling updatePermissionsPreview.');
            }
            // verify required parameter 'value' is not null or undefined
            if (value === null || value === undefined) {
                throw new RequiredError('value','Required parameter value was null or undefined when calling updatePermissionsPreview.');
            }
            const localVarPath = `/user/permissions/preview`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateUser
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (user?: User, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof user !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(user !== undefined ? user : {}) : (user || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary validateEmail
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEmail: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling validateEmail.');
            }
            const localVarPath = `/user/email/validate/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary validateToken
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateToken: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling validateToken.');
            }
            const localVarPath = `/user/token/validate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addNotification
         * @param {UserNotification} [userNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNotification(userNotification?: UserNotification, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNotification>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).addNotification(userNotification, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary addToken
         * @param {TokenObject} [tokenObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addToken(tokenObject?: TokenObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).addToken(tokenObject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary addUser
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUser(user?: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).addUser(user, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary deleteUser
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).deleteUser(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getDefaultUrl
         * @param {UserRole} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultUrl(role: UserRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getDefaultUrl(role, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getNotifications
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserNotification>>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getNotifications(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getPermissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Permissions; }>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getPermissions(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getPermissionsByRole
         * @param {UserRole} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissionsByRole(role: UserRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Permissions>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getPermissionsByRole(role, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getPermissionsPreview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissionsPreview(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionsPreview>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getPermissionsPreview(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getUserById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getUserById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary loginUser
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUser(user?: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessRequestResult>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).loginUser(user, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary logoutUser
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutUser(user?: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).logoutUser(user, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary removeNotification
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeNotification(notificationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).removeNotification(notificationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary restoreUser
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreUser(user?: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).restoreUser(user, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary searchUsers
         * @param {SearchUsersObject} [searchUsersObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUsers(searchUsersObject?: SearchUsersObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).searchUsers(searchUsersObject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary sendEmailVerification
         * @param {RegisterUserObject} [registerUserObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailVerification(registerUserObject?: RegisterUserObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).sendEmailVerification(registerUserObject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateNotification
         * @param {UserNotification} [userNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotification(userNotification?: UserNotification, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNotification>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).updateNotification(userNotification, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updatePermissions
         * @param {Permissions} [permissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePermissions(permissions?: Permissions, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).updatePermissions(permissions, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updatePermissionsPreview
         * @param {string} id 
         * @param {UserRole} role 
         * @param {boolean} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePermissionsPreview(id: string, role: UserRole, value: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionsPreview>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).updatePermissionsPreview(id, role, value, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateUser
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(user?: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).updateUser(user, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary validateEmail
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateEmail(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).validateEmail(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary validateToken
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateToken(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessRequestResult>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).validateToken(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary addNotification
         * @param {UserNotification} [userNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNotification(userNotification?: UserNotification, options?: any): AxiosPromise<UserNotification> {
            return UserApiFp(configuration).addNotification(userNotification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary addToken
         * @param {TokenObject} [tokenObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToken(tokenObject?: TokenObject, options?: any): AxiosPromise<boolean> {
            return UserApiFp(configuration).addToken(tokenObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary addUser
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(user?: User, options?: any): AxiosPromise<User> {
            return UserApiFp(configuration).addUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteUser
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getDefaultUrl
         * @param {UserRole} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultUrl(role: UserRole, options?: any): AxiosPromise<string> {
            return UserApiFp(configuration).getDefaultUrl(role, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getNotifications
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(userId: string, options?: any): AxiosPromise<Array<UserNotification>> {
            return UserApiFp(configuration).getNotifications(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getPermissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissions(options?: any): AxiosPromise<{ [key: string]: Permissions; }> {
            return UserApiFp(configuration).getPermissions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getPermissionsByRole
         * @param {UserRole} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionsByRole(role: UserRole, options?: any): AxiosPromise<Permissions> {
            return UserApiFp(configuration).getPermissionsByRole(role, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getPermissionsPreview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionsPreview(options?: any): AxiosPromise<PermissionsPreview> {
            return UserApiFp(configuration).getPermissionsPreview(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getUserById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id: string, options?: any): AxiosPromise<User> {
            return UserApiFp(configuration).getUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary loginUser
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser(user?: User, options?: any): AxiosPromise<AccessRequestResult> {
            return UserApiFp(configuration).loginUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary logoutUser
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUser(user?: User, options?: any): AxiosPromise<User> {
            return UserApiFp(configuration).logoutUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary removeNotification
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeNotification(notificationId: string, options?: any): AxiosPromise<boolean> {
            return UserApiFp(configuration).removeNotification(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary restoreUser
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreUser(user?: User, options?: any): AxiosPromise<User> {
            return UserApiFp(configuration).restoreUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchUsers
         * @param {SearchUsersObject} [searchUsersObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers(searchUsersObject?: SearchUsersObject, options?: any): AxiosPromise<Array<User>> {
            return UserApiFp(configuration).searchUsers(searchUsersObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sendEmailVerification
         * @param {RegisterUserObject} [registerUserObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailVerification(registerUserObject?: RegisterUserObject, options?: any): AxiosPromise<User> {
            return UserApiFp(configuration).sendEmailVerification(registerUserObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateNotification
         * @param {UserNotification} [userNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotification(userNotification?: UserNotification, options?: any): AxiosPromise<UserNotification> {
            return UserApiFp(configuration).updateNotification(userNotification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updatePermissions
         * @param {Permissions} [permissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePermissions(permissions?: Permissions, options?: any): AxiosPromise<boolean> {
            return UserApiFp(configuration).updatePermissions(permissions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updatePermissionsPreview
         * @param {string} id 
         * @param {UserRole} role 
         * @param {boolean} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePermissionsPreview(id: string, role: UserRole, value: boolean, options?: any): AxiosPromise<PermissionsPreview> {
            return UserApiFp(configuration).updatePermissionsPreview(id, role, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateUser
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(user?: User, options?: any): AxiosPromise<User> {
            return UserApiFp(configuration).updateUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary validateEmail
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEmail(token: string, options?: any): AxiosPromise<User> {
            return UserApiFp(configuration).validateEmail(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary validateToken
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateToken(token: string, options?: any): AxiosPromise<AccessRequestResult> {
            return UserApiFp(configuration).validateToken(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary addNotification
     * @param {UserNotification} [userNotification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addNotification(userNotification?: UserNotification, options?: any) {
        return UserApiFp(this.configuration).addNotification(userNotification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary addToken
     * @param {TokenObject} [tokenObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addToken(tokenObject?: TokenObject, options?: any) {
        return UserApiFp(this.configuration).addToken(tokenObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary addUser
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addUser(user?: User, options?: any) {
        return UserApiFp(this.configuration).addUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteUser
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(id: string, options?: any) {
        return UserApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getDefaultUrl
     * @param {UserRole} role 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getDefaultUrl(role: UserRole, options?: any) {
        return UserApiFp(this.configuration).getDefaultUrl(role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getNotifications
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getNotifications(userId: string, options?: any) {
        return UserApiFp(this.configuration).getNotifications(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getPermissions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getPermissions(options?: any) {
        return UserApiFp(this.configuration).getPermissions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getPermissionsByRole
     * @param {UserRole} role 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getPermissionsByRole(role: UserRole, options?: any) {
        return UserApiFp(this.configuration).getPermissionsByRole(role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getPermissionsPreview
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getPermissionsPreview(options?: any) {
        return UserApiFp(this.configuration).getPermissionsPreview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getUserById
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserById(id: string, options?: any) {
        return UserApiFp(this.configuration).getUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary loginUser
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public loginUser(user?: User, options?: any) {
        return UserApiFp(this.configuration).loginUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary logoutUser
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public logoutUser(user?: User, options?: any) {
        return UserApiFp(this.configuration).logoutUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary removeNotification
     * @param {string} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public removeNotification(notificationId: string, options?: any) {
        return UserApiFp(this.configuration).removeNotification(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary restoreUser
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public restoreUser(user?: User, options?: any) {
        return UserApiFp(this.configuration).restoreUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchUsers
     * @param {SearchUsersObject} [searchUsersObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public searchUsers(searchUsersObject?: SearchUsersObject, options?: any) {
        return UserApiFp(this.configuration).searchUsers(searchUsersObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sendEmailVerification
     * @param {RegisterUserObject} [registerUserObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sendEmailVerification(registerUserObject?: RegisterUserObject, options?: any) {
        return UserApiFp(this.configuration).sendEmailVerification(registerUserObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateNotification
     * @param {UserNotification} [userNotification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateNotification(userNotification?: UserNotification, options?: any) {
        return UserApiFp(this.configuration).updateNotification(userNotification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updatePermissions
     * @param {Permissions} [permissions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updatePermissions(permissions?: Permissions, options?: any) {
        return UserApiFp(this.configuration).updatePermissions(permissions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updatePermissionsPreview
     * @param {string} id 
     * @param {UserRole} role 
     * @param {boolean} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updatePermissionsPreview(id: string, role: UserRole, value: boolean, options?: any) {
        return UserApiFp(this.configuration).updatePermissionsPreview(id, role, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateUser
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(user?: User, options?: any) {
        return UserApiFp(this.configuration).updateUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary validateEmail
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public validateEmail(token: string, options?: any) {
        return UserApiFp(this.configuration).validateEmail(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary validateToken
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public validateToken(token: string, options?: any) {
        return UserApiFp(this.configuration).validateToken(token, options).then((request) => request(this.axios, this.basePath));
    }

}

// tslint:disable
/**
 * titletool-nextgen-server
 * TitleTool NextGen Server
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: r.hutter@moovit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { AssetItem } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { BasicAssetItem } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { Field } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { FullAssetItem } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { MediaFile } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { PreviewFrame } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { RenderAssetItemObject } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { ReportAssetItem } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { SearchAssetItemsObject } from '../../../../de/moovit/titletoolserver/model';
// @ts-ignore
import { VoiceOverVoice } from '../../../../de/moovit/titletoolserver/model';
/**
 * AssetItemApi - axios parameter creator
 * @export
 */
export const AssetItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addAssetItem
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAssetItem: async (assetItem?: AssetItem, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/item`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof assetItem !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(assetItem !== undefined ? assetItem : {}) : (assetItem || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createPreviewFrame
         * @param {PreviewFrame} [previewFrame] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreviewFrame: async (previewFrame?: PreviewFrame, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/item/previewFrame`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof previewFrame !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(previewFrame !== undefined ? previewFrame : {}) : (previewFrame || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAssetItem
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssetItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAssetItem.');
            }
            const localVarPath = `/asset/item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generateSRT
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSRT: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling generateSRT.');
            }
            const localVarPath = `/asset/item/{id}/srt`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generateSRT
         * @param {Array<Field>} field 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSrtFromFields: async (field: Array<Field>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling generateSrtFromFields.');
            }
            const localVarPath = `/asset/item/srt`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof field !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(field !== undefined ? field : {}) : (field || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generateSubtitles
         * @param {string} assetItemId Asset Item Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSubtitles: async (assetItemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetItemId' is not null or undefined
            if (assetItemId === null || assetItemId === undefined) {
                throw new RequiredError('assetItemId','Required parameter assetItemId was null or undefined when calling generateSubtitles.');
            }
            const localVarPath = `/asset/item/generate/subtitles`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (assetItemId !== undefined) {
                localVarQueryParameter['assetItemId'] = assetItemId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generateSubtitlesFromVOA
         * @param {string} assetItemId Asset Item Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSubtitlesFromVOA: async (assetItemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetItemId' is not null or undefined
            if (assetItemId === null || assetItemId === undefined) {
                throw new RequiredError('assetItemId','Required parameter assetItemId was null or undefined when calling generateSubtitlesFromVOA.');
            }
            const localVarPath = `/asset/item/generate/subtitles`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (assetItemId !== undefined) {
                localVarQueryParameter['assetItemId'] = assetItemId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generateTextFromSpeech
         * @param {string} assetItemId Asset Item Id
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTextFromSpeech: async (assetItemId: string, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetItemId' is not null or undefined
            if (assetItemId === null || assetItemId === undefined) {
                throw new RequiredError('assetItemId','Required parameter assetItemId was null or undefined when calling generateTextFromSpeech.');
            }
            const localVarPath = `/asset/item/generate/texts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (assetItemId !== undefined) {
                localVarQueryParameter['assetItemId'] = assetItemId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generateVoiceOver
         * @param {string} assetItemId Asset Item Id
         * @param {string} voiceId Voice Id
         * @param {string} name Speaker Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateVoiceOver: async (assetItemId: string, voiceId: string, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetItemId' is not null or undefined
            if (assetItemId === null || assetItemId === undefined) {
                throw new RequiredError('assetItemId','Required parameter assetItemId was null or undefined when calling generateVoiceOver.');
            }
            // verify required parameter 'voiceId' is not null or undefined
            if (voiceId === null || voiceId === undefined) {
                throw new RequiredError('voiceId','Required parameter voiceId was null or undefined when calling generateVoiceOver.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling generateVoiceOver.');
            }
            const localVarPath = `/asset/item/generate/voiceover`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (assetItemId !== undefined) {
                localVarQueryParameter['assetItemId'] = assetItemId;
            }

            if (voiceId !== undefined) {
                localVarQueryParameter['voiceId'] = voiceId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generateXLS
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateXLS: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling generateXLS.');
            }
            const localVarPath = `/asset/item/{id}/xls`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAssetItemById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetItemById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAssetItemById.');
            }
            const localVarPath = `/asset/item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAssetItemCount
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetItemCount: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAssetItemCount.');
            }
            const localVarPath = `/asset/item/count/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getBestTranslatorForJob
         * @param {Array<string>} languages Selected Languages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBestTranslatorForJob: async (languages: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'languages' is not null or undefined
            if (languages === null || languages === undefined) {
                throw new RequiredError('languages','Required parameter languages was null or undefined when calling getBestTranslatorForJob.');
            }
            const localVarPath = `/asset/item/best/job/translator`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (languages) {
                localVarQueryParameter['languages'] = languages;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPreviewFrameById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewFrameById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPreviewFrameById.');
            }
            const localVarPath = `/asset/item/previewFrame/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPreviewFramesByAssetItemId
         * @param {string} assetItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewFramesByAssetItemId: async (assetItemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetItemId' is not null or undefined
            if (assetItemId === null || assetItemId === undefined) {
                throw new RequiredError('assetItemId','Required parameter assetItemId was null or undefined when calling getPreviewFramesByAssetItemId.');
            }
            const localVarPath = `/asset/item/{assetItemId}/previewFrames`
                .replace(`{${"assetItemId"}}`, encodeURIComponent(String(assetItemId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getReportAssetItems
         * @param {SearchAssetItemsObject} [searchAssetItemsObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportAssetItems: async (searchAssetItemsObject?: SearchAssetItemsObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/item/report`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof searchAssetItemsObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(searchAssetItemsObject !== undefined ? searchAssetItemsObject : {}) : (searchAssetItemsObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSpeechToTextStatus
         * @param {string} id Speech2Text Job Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpeechToTextStatus: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSpeechToTextStatus.');
            }
            const localVarPath = `/asset/item/generate/texts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getVoiceOverSpeakerWithId
         * @param {string} id AssetItem Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceOverSpeakerWithId: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getVoiceOverSpeakerWithId.');
            }
            const localVarPath = `/asset/item/{id}/voiceover/voices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ingestAssetItemVariant
         * @param {FullAssetItem} [fullAssetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ingestAssetItemVariant: async (fullAssetItem?: FullAssetItem, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/item/ingestAssetItemVariant`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof fullAssetItem !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fullAssetItem !== undefined ? fullAssetItem : {}) : (fullAssetItem || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary prerenderAssetItem
         * @param {RenderAssetItemObject} [renderAssetItemObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prerenderAssetItem: async (renderAssetItemObject?: RenderAssetItemObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/item/prerender`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof renderAssetItemObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(renderAssetItemObject !== undefined ? renderAssetItemObject : {}) : (renderAssetItemObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary rejectMasterFieldEdited
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectMasterFieldEdited: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling rejectMasterFieldEdited.');
            }
            const localVarPath = `/asset/item/field/edit/master/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary renderAssetItem
         * @param {boolean} [renderVariants] 
         * @param {RenderAssetItemObject} [renderAssetItemObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renderAssetItem: async (renderVariants?: boolean, renderAssetItemObject?: RenderAssetItemObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/item/render`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (renderVariants !== undefined) {
                localVarQueryParameter['renderVariants'] = renderVariants;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof renderAssetItemObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(renderAssetItemObject !== undefined ? renderAssetItemObject : {}) : (renderAssetItemObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resetAssetItemById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAssetItemById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling resetAssetItemById.');
            }
            const localVarPath = `/asset/item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchAssetItems
         * @param {SearchAssetItemsObject} [searchAssetItemsObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAssetItems: async (searchAssetItemsObject?: SearchAssetItemsObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/item/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof searchAssetItemsObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(searchAssetItemsObject !== undefined ? searchAssetItemsObject : {}) : (searchAssetItemsObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateAssetItem
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssetItem: async (assetItem?: AssetItem, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/item`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof assetItem !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(assetItem !== undefined ? assetItem : {}) : (assetItem || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateAssetItemValues
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssetItemValues: async (assetItem?: AssetItem, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/item/values`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof assetItem !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(assetItem !== undefined ? assetItem : {}) : (assetItem || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateMediaFile
         * @param {MediaFile} [mediaFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMediaFile: async (mediaFile?: MediaFile, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/item/mediafile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof mediaFile !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(mediaFile !== undefined ? mediaFile : {}) : (mediaFile || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updatePreviewFrame
         * @param {PreviewFrame} [previewFrame] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreviewFrame: async (previewFrame?: PreviewFrame, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/item/previewFrame`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof previewFrame !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(previewFrame !== undefined ? previewFrame : {}) : (previewFrame || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetItemApi - functional programming interface
 * @export
 */
export const AssetItemApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addAssetItem
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAssetItem(assetItem?: AssetItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetItem>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).addAssetItem(assetItem, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary createPreviewFrame
         * @param {PreviewFrame} [previewFrame] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPreviewFrame(previewFrame?: PreviewFrame, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewFrame>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).createPreviewFrame(previewFrame, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary deleteAssetItem
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAssetItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).deleteAssetItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary generateSRT
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateSRT(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).generateSRT(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary generateSRT
         * @param {Array<Field>} field 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateSrtFromFields(field: Array<Field>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).generateSrtFromFields(field, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary generateSubtitles
         * @param {string} assetItemId Asset Item Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateSubtitles(assetItemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).generateSubtitles(assetItemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary generateSubtitlesFromVOA
         * @param {string} assetItemId Asset Item Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateSubtitlesFromVOA(assetItemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).generateSubtitlesFromVOA(assetItemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary generateTextFromSpeech
         * @param {string} assetItemId Asset Item Id
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTextFromSpeech(assetItemId: string, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).generateTextFromSpeech(assetItemId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary generateVoiceOver
         * @param {string} assetItemId Asset Item Id
         * @param {string} voiceId Voice Id
         * @param {string} name Speaker Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateVoiceOver(assetItemId: string, voiceId: string, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).generateVoiceOver(assetItemId, voiceId, name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary generateXLS
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateXLS(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).generateXLS(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getAssetItemById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetItemById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetItem>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).getAssetItemById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getAssetItemCount
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetItemCount(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).getAssetItemCount(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getBestTranslatorForJob
         * @param {Array<string>} languages Selected Languages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBestTranslatorForJob(languages: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).getBestTranslatorForJob(languages, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getPreviewFrameById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreviewFrameById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).getPreviewFrameById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getPreviewFramesByAssetItemId
         * @param {string} assetItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreviewFramesByAssetItemId(assetItemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PreviewFrame>>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).getPreviewFramesByAssetItemId(assetItemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getReportAssetItems
         * @param {SearchAssetItemsObject} [searchAssetItemsObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportAssetItems(searchAssetItemsObject?: SearchAssetItemsObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportAssetItem>>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).getReportAssetItems(searchAssetItemsObject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getSpeechToTextStatus
         * @param {string} id Speech2Text Job Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpeechToTextStatus(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).getSpeechToTextStatus(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getVoiceOverSpeakerWithId
         * @param {string} id AssetItem Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVoiceOverSpeakerWithId(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VoiceOverVoice>>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).getVoiceOverSpeakerWithId(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ingestAssetItemVariant
         * @param {FullAssetItem} [fullAssetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ingestAssetItemVariant(fullAssetItem?: FullAssetItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicAssetItem>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).ingestAssetItemVariant(fullAssetItem, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary prerenderAssetItem
         * @param {RenderAssetItemObject} [renderAssetItemObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prerenderAssetItem(renderAssetItemObject?: RenderAssetItemObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetItem>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).prerenderAssetItem(renderAssetItemObject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary rejectMasterFieldEdited
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectMasterFieldEdited(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).rejectMasterFieldEdited(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary renderAssetItem
         * @param {boolean} [renderVariants] 
         * @param {RenderAssetItemObject} [renderAssetItemObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renderAssetItem(renderVariants?: boolean, renderAssetItemObject?: RenderAssetItemObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetItem>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).renderAssetItem(renderVariants, renderAssetItemObject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary resetAssetItemById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetAssetItemById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).resetAssetItemById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary searchAssetItems
         * @param {SearchAssetItemsObject} [searchAssetItemsObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAssetItems(searchAssetItemsObject?: SearchAssetItemsObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetItem>>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).searchAssetItems(searchAssetItemsObject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateAssetItem
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssetItem(assetItem?: AssetItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetItem>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).updateAssetItem(assetItem, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateAssetItemValues
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssetItemValues(assetItem?: AssetItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetItem>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).updateAssetItemValues(assetItem, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateMediaFile
         * @param {MediaFile} [mediaFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMediaFile(mediaFile?: MediaFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).updateMediaFile(mediaFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updatePreviewFrame
         * @param {PreviewFrame} [previewFrame] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePreviewFrame(previewFrame?: PreviewFrame, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewFrame>> {
            const localVarAxiosArgs = await AssetItemApiAxiosParamCreator(configuration).updatePreviewFrame(previewFrame, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AssetItemApi - factory interface
 * @export
 */
export const AssetItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary addAssetItem
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAssetItem(assetItem?: AssetItem, options?: any): AxiosPromise<AssetItem> {
            return AssetItemApiFp(configuration).addAssetItem(assetItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createPreviewFrame
         * @param {PreviewFrame} [previewFrame] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreviewFrame(previewFrame?: PreviewFrame, options?: any): AxiosPromise<PreviewFrame> {
            return AssetItemApiFp(configuration).createPreviewFrame(previewFrame, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteAssetItem
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssetItem(id: string, options?: any): AxiosPromise<void> {
            return AssetItemApiFp(configuration).deleteAssetItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary generateSRT
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSRT(id: string, options?: any): AxiosPromise<any> {
            return AssetItemApiFp(configuration).generateSRT(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary generateSRT
         * @param {Array<Field>} field 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSrtFromFields(field: Array<Field>, options?: any): AxiosPromise<any> {
            return AssetItemApiFp(configuration).generateSrtFromFields(field, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary generateSubtitles
         * @param {string} assetItemId Asset Item Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSubtitles(assetItemId: string, options?: any): AxiosPromise<boolean> {
            return AssetItemApiFp(configuration).generateSubtitles(assetItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary generateSubtitlesFromVOA
         * @param {string} assetItemId Asset Item Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSubtitlesFromVOA(assetItemId: string, options?: any): AxiosPromise<boolean> {
            return AssetItemApiFp(configuration).generateSubtitlesFromVOA(assetItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary generateTextFromSpeech
         * @param {string} assetItemId Asset Item Id
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTextFromSpeech(assetItemId: string, body?: string, options?: any): AxiosPromise<string> {
            return AssetItemApiFp(configuration).generateTextFromSpeech(assetItemId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary generateVoiceOver
         * @param {string} assetItemId Asset Item Id
         * @param {string} voiceId Voice Id
         * @param {string} name Speaker Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateVoiceOver(assetItemId: string, voiceId: string, name: string, options?: any): AxiosPromise<boolean> {
            return AssetItemApiFp(configuration).generateVoiceOver(assetItemId, voiceId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary generateXLS
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateXLS(id: string, options?: any): AxiosPromise<any> {
            return AssetItemApiFp(configuration).generateXLS(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAssetItemById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetItemById(id: string, options?: any): AxiosPromise<AssetItem> {
            return AssetItemApiFp(configuration).getAssetItemById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAssetItemCount
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetItemCount(id: string, options?: any): AxiosPromise<number> {
            return AssetItemApiFp(configuration).getAssetItemCount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getBestTranslatorForJob
         * @param {Array<string>} languages Selected Languages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBestTranslatorForJob(languages: Array<string>, options?: any): AxiosPromise<string> {
            return AssetItemApiFp(configuration).getBestTranslatorForJob(languages, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getPreviewFrameById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewFrameById(id: string, options?: any): AxiosPromise<any> {
            return AssetItemApiFp(configuration).getPreviewFrameById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getPreviewFramesByAssetItemId
         * @param {string} assetItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewFramesByAssetItemId(assetItemId: string, options?: any): AxiosPromise<Array<PreviewFrame>> {
            return AssetItemApiFp(configuration).getPreviewFramesByAssetItemId(assetItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getReportAssetItems
         * @param {SearchAssetItemsObject} [searchAssetItemsObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportAssetItems(searchAssetItemsObject?: SearchAssetItemsObject, options?: any): AxiosPromise<Array<ReportAssetItem>> {
            return AssetItemApiFp(configuration).getReportAssetItems(searchAssetItemsObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getSpeechToTextStatus
         * @param {string} id Speech2Text Job Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpeechToTextStatus(id: string, options?: any): AxiosPromise<string> {
            return AssetItemApiFp(configuration).getSpeechToTextStatus(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getVoiceOverSpeakerWithId
         * @param {string} id AssetItem Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceOverSpeakerWithId(id: string, options?: any): AxiosPromise<Array<VoiceOverVoice>> {
            return AssetItemApiFp(configuration).getVoiceOverSpeakerWithId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ingestAssetItemVariant
         * @param {FullAssetItem} [fullAssetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ingestAssetItemVariant(fullAssetItem?: FullAssetItem, options?: any): AxiosPromise<BasicAssetItem> {
            return AssetItemApiFp(configuration).ingestAssetItemVariant(fullAssetItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary prerenderAssetItem
         * @param {RenderAssetItemObject} [renderAssetItemObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prerenderAssetItem(renderAssetItemObject?: RenderAssetItemObject, options?: any): AxiosPromise<AssetItem> {
            return AssetItemApiFp(configuration).prerenderAssetItem(renderAssetItemObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary rejectMasterFieldEdited
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectMasterFieldEdited(id: string, options?: any): AxiosPromise<boolean> {
            return AssetItemApiFp(configuration).rejectMasterFieldEdited(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary renderAssetItem
         * @param {boolean} [renderVariants] 
         * @param {RenderAssetItemObject} [renderAssetItemObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renderAssetItem(renderVariants?: boolean, renderAssetItemObject?: RenderAssetItemObject, options?: any): AxiosPromise<AssetItem> {
            return AssetItemApiFp(configuration).renderAssetItem(renderVariants, renderAssetItemObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary resetAssetItemById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAssetItemById(id: string, options?: any): AxiosPromise<boolean> {
            return AssetItemApiFp(configuration).resetAssetItemById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchAssetItems
         * @param {SearchAssetItemsObject} [searchAssetItemsObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAssetItems(searchAssetItemsObject?: SearchAssetItemsObject, options?: any): AxiosPromise<Array<AssetItem>> {
            return AssetItemApiFp(configuration).searchAssetItems(searchAssetItemsObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateAssetItem
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssetItem(assetItem?: AssetItem, options?: any): AxiosPromise<AssetItem> {
            return AssetItemApiFp(configuration).updateAssetItem(assetItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateAssetItemValues
         * @param {AssetItem} [assetItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssetItemValues(assetItem?: AssetItem, options?: any): AxiosPromise<AssetItem> {
            return AssetItemApiFp(configuration).updateAssetItemValues(assetItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateMediaFile
         * @param {MediaFile} [mediaFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMediaFile(mediaFile?: MediaFile, options?: any): AxiosPromise<boolean> {
            return AssetItemApiFp(configuration).updateMediaFile(mediaFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updatePreviewFrame
         * @param {PreviewFrame} [previewFrame] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreviewFrame(previewFrame?: PreviewFrame, options?: any): AxiosPromise<PreviewFrame> {
            return AssetItemApiFp(configuration).updatePreviewFrame(previewFrame, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetItemApi - object-oriented interface
 * @export
 * @class AssetItemApi
 * @extends {BaseAPI}
 */
export class AssetItemApi extends BaseAPI {
    /**
     * 
     * @summary addAssetItem
     * @param {AssetItem} [assetItem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public addAssetItem(assetItem?: AssetItem, options?: any) {
        return AssetItemApiFp(this.configuration).addAssetItem(assetItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createPreviewFrame
     * @param {PreviewFrame} [previewFrame] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public createPreviewFrame(previewFrame?: PreviewFrame, options?: any) {
        return AssetItemApiFp(this.configuration).createPreviewFrame(previewFrame, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteAssetItem
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public deleteAssetItem(id: string, options?: any) {
        return AssetItemApiFp(this.configuration).deleteAssetItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary generateSRT
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public generateSRT(id: string, options?: any) {
        return AssetItemApiFp(this.configuration).generateSRT(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary generateSRT
     * @param {Array<Field>} field 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public generateSrtFromFields(field: Array<Field>, options?: any) {
        return AssetItemApiFp(this.configuration).generateSrtFromFields(field, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary generateSubtitles
     * @param {string} assetItemId Asset Item Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public generateSubtitles(assetItemId: string, options?: any) {
        return AssetItemApiFp(this.configuration).generateSubtitles(assetItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary generateSubtitlesFromVOA
     * @param {string} assetItemId Asset Item Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public generateSubtitlesFromVOA(assetItemId: string, options?: any) {
        return AssetItemApiFp(this.configuration).generateSubtitlesFromVOA(assetItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary generateTextFromSpeech
     * @param {string} assetItemId Asset Item Id
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public generateTextFromSpeech(assetItemId: string, body?: string, options?: any) {
        return AssetItemApiFp(this.configuration).generateTextFromSpeech(assetItemId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary generateVoiceOver
     * @param {string} assetItemId Asset Item Id
     * @param {string} voiceId Voice Id
     * @param {string} name Speaker Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public generateVoiceOver(assetItemId: string, voiceId: string, name: string, options?: any) {
        return AssetItemApiFp(this.configuration).generateVoiceOver(assetItemId, voiceId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary generateXLS
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public generateXLS(id: string, options?: any) {
        return AssetItemApiFp(this.configuration).generateXLS(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAssetItemById
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public getAssetItemById(id: string, options?: any) {
        return AssetItemApiFp(this.configuration).getAssetItemById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAssetItemCount
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public getAssetItemCount(id: string, options?: any) {
        return AssetItemApiFp(this.configuration).getAssetItemCount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getBestTranslatorForJob
     * @param {Array<string>} languages Selected Languages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public getBestTranslatorForJob(languages: Array<string>, options?: any) {
        return AssetItemApiFp(this.configuration).getBestTranslatorForJob(languages, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getPreviewFrameById
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public getPreviewFrameById(id: string, options?: any) {
        return AssetItemApiFp(this.configuration).getPreviewFrameById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getPreviewFramesByAssetItemId
     * @param {string} assetItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public getPreviewFramesByAssetItemId(assetItemId: string, options?: any) {
        return AssetItemApiFp(this.configuration).getPreviewFramesByAssetItemId(assetItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getReportAssetItems
     * @param {SearchAssetItemsObject} [searchAssetItemsObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public getReportAssetItems(searchAssetItemsObject?: SearchAssetItemsObject, options?: any) {
        return AssetItemApiFp(this.configuration).getReportAssetItems(searchAssetItemsObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getSpeechToTextStatus
     * @param {string} id Speech2Text Job Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public getSpeechToTextStatus(id: string, options?: any) {
        return AssetItemApiFp(this.configuration).getSpeechToTextStatus(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getVoiceOverSpeakerWithId
     * @param {string} id AssetItem Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public getVoiceOverSpeakerWithId(id: string, options?: any) {
        return AssetItemApiFp(this.configuration).getVoiceOverSpeakerWithId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ingestAssetItemVariant
     * @param {FullAssetItem} [fullAssetItem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public ingestAssetItemVariant(fullAssetItem?: FullAssetItem, options?: any) {
        return AssetItemApiFp(this.configuration).ingestAssetItemVariant(fullAssetItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary prerenderAssetItem
     * @param {RenderAssetItemObject} [renderAssetItemObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public prerenderAssetItem(renderAssetItemObject?: RenderAssetItemObject, options?: any) {
        return AssetItemApiFp(this.configuration).prerenderAssetItem(renderAssetItemObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary rejectMasterFieldEdited
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public rejectMasterFieldEdited(id: string, options?: any) {
        return AssetItemApiFp(this.configuration).rejectMasterFieldEdited(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary renderAssetItem
     * @param {boolean} [renderVariants] 
     * @param {RenderAssetItemObject} [renderAssetItemObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public renderAssetItem(renderVariants?: boolean, renderAssetItemObject?: RenderAssetItemObject, options?: any) {
        return AssetItemApiFp(this.configuration).renderAssetItem(renderVariants, renderAssetItemObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary resetAssetItemById
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public resetAssetItemById(id: string, options?: any) {
        return AssetItemApiFp(this.configuration).resetAssetItemById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchAssetItems
     * @param {SearchAssetItemsObject} [searchAssetItemsObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public searchAssetItems(searchAssetItemsObject?: SearchAssetItemsObject, options?: any) {
        return AssetItemApiFp(this.configuration).searchAssetItems(searchAssetItemsObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateAssetItem
     * @param {AssetItem} [assetItem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public updateAssetItem(assetItem?: AssetItem, options?: any) {
        return AssetItemApiFp(this.configuration).updateAssetItem(assetItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateAssetItemValues
     * @param {AssetItem} [assetItem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public updateAssetItemValues(assetItem?: AssetItem, options?: any) {
        return AssetItemApiFp(this.configuration).updateAssetItemValues(assetItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateMediaFile
     * @param {MediaFile} [mediaFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public updateMediaFile(mediaFile?: MediaFile, options?: any) {
        return AssetItemApiFp(this.configuration).updateMediaFile(mediaFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updatePreviewFrame
     * @param {PreviewFrame} [previewFrame] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetItemApi
     */
    public updatePreviewFrame(previewFrame?: PreviewFrame, options?: any) {
        return AssetItemApiFp(this.configuration).updatePreviewFrame(previewFrame, options).then((request) => request(this.axios, this.basePath));
    }

}

<template>
  <CustomDropdown v-model:modelValue="innerModelValue"
                  :options="users"
                  option-label="id"
                  :show-clear="true"
                  :invalid="invalid"
                  :dropdown-height="dropdownHeight"
                  :filter="true"
                  :placeholder="label"
  />
</template>

<script src="./AutoCompleteUser.ts"></script>

<template>
  <div
    id="roundedLabel"
    :class="
      (!noBorder ? ' border-radius-24' : '') +
        (!noHover ? ' rounded-label-hover' : '') +
        (!noHover ? (!hoverBorder ? ' rounded-label-hover-border-1' : ' rounded-label-hover-border-2') : '') +
        (disabled ? ' p-disabled' : '')
    "
    style="
      position: relative;
      overflow: hidden;
      display: flex;
      height: 32px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    "
    :style="(disabled ? ';pointer-events:none !important;' : '')"
  >
    <div
      style="
        display: flex;
        align-items: center;
        pointer-events: none;
        height: 100%;
        vertical-align: middle;
      "
    >
      <div v-if="!countryFlag || leftText" style="width: 12px"></div>
      <div v-if="leftText" style="font-size: 14px; font-weight: 400; white-space: nowrap">
        {{leftText}}
      </div>
      <div v-if="!countryFlag && leftText" style="width: 12px"></div>
      <div v-if="countryFlag" style="padding: 0 7px 0 7px; height: 23px">
        <Flag v-if="countryFlag" :countryFlag="countryFlag" :countryFlagSize="countryFlagSize"/>
      </div>
      
      <i
        v-if="leftIcon"
        :class="leftIcon"
        style="font-size: 16px"
      />
      <div v-if="leftIcon && text" style="width: 10px"></div>
      <div v-if="lineThrough">
        <del>
          <div v-if="text" style="font-size: 14px; font-weight: 400; white-space: nowrap">
            {{"" + getText(text)}}
          </div>
        </del>
      </div>
      <div v-else>
        <div v-if="text" style="font-size: 14px; font-weight: 400; white-space: nowrap">
          {{"" + getText(text)}}
        </div>
      </div>
      
      <div v-if="rightIcon && text" style="width: 10px"></div>
      <i v-if="rightIcon" :class="rightIcon" style="font-size: 16px"/>
      <div v-if="countryFlag && text" style="width: 10px"></div>
      <div v-if="!countryFlag" style="width: 12px"></div>
    </div>
  </div>
</template>

<script src="./RoundedLabel.ts"></script>

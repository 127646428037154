<template>
  <div style="
         position: relative;
         width: 100%;
         height: calc(100vh - 252px);
         overflow: auto;
         background-color: var(--white);">
    
    <div>
      <RoundedLabel :text="$t('replaceMedia.uploadReplaceMedia')"
                    style="width: 250px"
                    class="bg-dark-blue col-white"
                    @click="fileInput.click()"/>
      
      <input
        type="file"
        style="display: none"
        ref="fileInput"
        @change="uploadReplaceMedia"
      />
    </div>
    
    <ReplaceMediaDialog v-for="template in replaceMediaTemplates" :template="template"/>
  </div>
</template>


<script src="./AdminControlViewReplaceMedia.ts"></script>

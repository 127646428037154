<template>
  <div
    style="padding-right:24px; display:flex; align-items:center; flex-direction:column;"
  >
    <ListJobItem :item="headerItem" style="width: 100%; margin-left: 24px;" />

    <div
      class="col-menu-color bg-tt-white border-radius-16"
      style="width:60%; min-width:650px; height: calc(100vh - 241px); position:relative; box-shadow:20px 20px 50px; padding:16px; display:flex; flex-direction:column; align-items:center; justify-content:space-between"
    >
      <!-- HEADLINE -->

      <div
        class="col-dark-blue"
        style="height:100px; display:flex; justify-content:center; align-items:center; font-size:24px; font-weight:600;"
      >
        {{ $t("editJob.editJobDetails") }}
      </div>

      <!-- CONTENT -->

      <div style="display:block; width:548px;">
        <CustomInputText
          v-model="currentJobName"
          :label="$t('general.jobName')"
          class="border-radius-8 col-dark-blue bor-menu-color bg-transparent"
          style="width:228px; height:50px; float:left; padding:0px 16px 0px 16px;"
        />
        <AutoCompleteUser
          v-model="currentApprover"
          :label="$t('general.approver')"
          :userRoles="
            () => {
              return [UserRole.ADMIN, UserRole.MANAGER];
            }
          "
          class="border-radius-8 col-dark-blue bor-menu-color bg-transparent"
          style="width:228px; height:50px; float:left; padding:0 16px 0 16px; font-size:16px; margin-left:24px;"
        />
        <AutoCompleteUser
          v-model="currentTranslator"
          :label="$t('general.translator')"
          :userRoles="
            () => {
              return [UserRole.TRANSLATOR];
            }
          "
          class="border-radius-8 col-dark-blue bor-menu-color bg-transparent"
          style="width: 228px; height: 50px; float: left; padding: 0 16px 0 16px; font-size: 16px; margin-top: 24px"
        />
        <CustomDatePicker
          v-model="currentExpirationDate"
          :label="$t('general.expirationDate')"
          style="width: 228px; height: 50px; margin-top: 24px; margin-left: 24px; float: left;"
        />
      </div>

      <!-- FOOTER -->

      <div
        style="width: 90%; height: 80px; display:flex; align-items:center; justify-content:space-between;"
      >
        <Button
          @click="$router.push('/jobs')"
          :label="$t('general.cancel')"
          class="border-radius-8 col-dark-blue bor-transparent bg-tt-white"
          style="min-width:120px; height:40px; padding:7px 6px 6px 8px;"
        />
        <Button
          @click="
            updateJob();
            $router.push('/jobs');
            $emitter.emit('search-jobs');
          "
          :label="$t('general.updateJob')"
          class="border-radius-8 col-white bor-transparent bg-dark-blue"
          style="min-width:120px; height:40px; padding:7px 6px 6px 8px;"
        />
      </div>
    </div>
  </div>
</template>

<script src="./EditJobView.ts"></script>

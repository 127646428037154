import {defineComponent, onMounted, ref, watch} from 'vue';

export default defineComponent({
  props: ['modelValue', 'label'],
  components: {},
  setup: function (props) {
    let innerModelValue: any = ref(undefined);

    watch(props, () => {
      innerModelValue.value = props.modelValue;
    });

    onMounted(() => {
    });

    return {innerModelValue};
  },
});

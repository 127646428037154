import {defineComponent, onMounted} from "vue";
import {useStore} from "vuex";

export default defineComponent({
  components: {},
  props: ["countryFlag", "countryFlagSize"],
  setup: function (props) {
    onMounted(() => {
    });
    const store = useStore();

    let getCountryFlagUrl = (countryFlag: string) => {
      if (!countryFlag) {
        return "";
      }
      let languages = store.state.languages;
      for (let index = 0; index < languages.length; index++) {
        const language = languages[index];
        if (language.name === countryFlag) {
          countryFlag = language.value;
          break;
        }
      }
      if (countryFlag.startsWith("http")) {
        return countryFlag;
      }
      return "/flags/" + countryFlag + ".svg";
    };

    return {getCountryFlagUrl};
  },
});

import {SystemPreference} from 'target/api/de/moovit/titletoolserver/model';
import {SystemApi, UserApi} from '../target/api';
import LogRocket from "logrocket";

let initialized = false;

export class InitAppService {

  public static isInitialized() {
    return initialized;
  }

  public static async initData() {
    console.debug("Initialize default data...")
    initialized = true;
    const systemApi = new SystemApi()
    const userApi = new UserApi();

    let settings: SystemPreference[] = (await systemApi.getSystemPreferences()).data

    let responseLanguages = (await systemApi.getLanguages()).data
    let languages = []

    for (let lang in responseLanguages) {
      let langObj = {name: lang, value: responseLanguages[lang]}
      languages.push(langObj)
    }

    let responseCountries = (await systemApi.getCountries()).data
    let countries = []

    for (let i in responseCountries) {
      let langObj = {
        name: responseCountries[i],
        value: responseCountries[i]
      }
      countries.push(langObj)
    }

    let darkmode = localStorage.getItem("darkmodeVisible");
    let element = document.getElementById('app');

    if (darkmode === "active") {
      element!.classList.add("darkMode")
    } else {
      element!.classList.remove("darkMode")
    }

    let notifications;

    let userLoginData = JSON.parse(localStorage.loginData);

    if (userLoginData.user && userLoginData.user.permissions && userLoginData.user.permissions.id) {
      let permissions = (await userApi.getPermissions()).data;
      let userRole = userLoginData.user.permissions.id;
      userLoginData.user.permissions = permissions[userRole];

      localStorage.loginData = JSON.stringify(userLoginData);

      notifications = (await userApi.getNotifications(userLoginData.user.id)).data;
    }


    let loginData = JSON.parse(localStorage.loginData);
    if (loginData != null && loginData.user) {
      console.log(loginData.user)
      InitAppService.identifyLogRocketUser(loginData.user.id, loginData.user.email);
    } else {
      console.debug("LogRocket not initialized because user is not set.")
    }

    return {countries, languages, settings, notifications}
  }


  public static identifyLogRocketUser = (
    userName: string | undefined,
    email: string | undefined
  ) => {
    console.debug()
    console.debug("username:", userName);
    console.debug("email:", email)
    if (userName && email) {
      LogRocket.identify(userName, {
        name: userName + " - " + window.origin,
        email: email,
        host: window.location.origin
      });
      console.debug("LogRocket initialized.");
    } else {
      console.debug("LogRocket not initialized because username or email is undefined.");
    }
  };
}

<template>
  <div
    :id="'field-visualizer-container' + assetId"
    class="container">
    <div v-for="row in rows"
         style="display: flex; position: relative; height: 20px; width: 100%; overflow: hidden">
      <div v-for="(field, index) in row">
        <div class="border-radius-8 item"
             :class="getFieldColor(field)"
             style="display: none;"
             :style="innerVideoLength && maxClientWidth && (((maxClientWidth / innerVideoLength) * field.outPoint)) > originMaxClientWidth ? '' : 'display: block;' +
              'margin-left: ' + ((maxClientWidth / innerVideoLength) * field.inPoint) + 'px; ' +
              'padding-right: ' + (((maxClientWidth / innerVideoLength) * field.outPoint) -
              ((maxClientWidth / innerVideoLength) * field.inPoint)) + 'px;'"
             v-tooltip="field.value"
             @click="onClickField(fieldIds.indexOf(field.id))">
        </div>
      </div>
      
      <div v-if="showTimeline"
           :style="'margin-left: ' + ((maxClientWidth / innerVideoLength) * innerVideoPosition) + 'px'"
           style="position: absolute; width: 2px; height: 90%; z-index: 5"
           class="bg-dark-blue"/>
    </div>
  </div>
</template>

<style>
.item {
  position: absolute;
  cursor: pointer;
  color: black;
  overflow: visible;
  height: 18px;
}

.item:not(:hover) {
  transition: all 200ms !important;
}

.item:hover {
  transition: all 200ms !important;
  z-index: 2;
  box-shadow: inset 1rem 1rem 1rem 1rem rgba(0, 0, 0, 0.1);
}

.container {
  height: 80px;
  width: 100%;
  float: left;
  overflow: hidden;
}
</style>

<script src="./FieldVisualizer.ts"></script>

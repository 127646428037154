import {defineComponent, getCurrentInstance, onMounted, ref} from 'vue';
import {useStore} from "vuex";
import {
  CustomerMetadataTemplate,
  CustomerMetadataTemplateTypeEnum,
  VoiceOverVoice
} from "../../../target/api/de/moovit/titletoolserver/model";
import {SystemApi} from "../../../target/api";
import {uuid} from "vue-uuid";

export default defineComponent({
  props: ["value"],
  setup: function (props) {

    const store = useStore();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    const systemApi: SystemApi = new SystemApi();

    let isActive = ref<boolean>(false);
    let isTemplateActive = ref<boolean>(false);

    let innerVoices = ref<Array<VoiceOverVoice>>([]);


    let currentName = ref<string>("");
    let currentId = ref<string>("");
    let currentModelId = ref<string>("");
    let currentConsolidate = ref<boolean>(false);
    let currentLocale = ref<string>("");
    let currentGender = ref<string>("");
    let currentStability = ref<number>(0.0);
    let currentSimilarityBoost = ref<number>(1.0);
    let currentStyle = ref<number>(0.0);
    let currentUseSpeakerBoost = ref<boolean>(true);

    let toggleDialog = () => {
      isActive.value = !isActive.value;
    }

    let toggleTemplateDialog = () => {
      currentName.value = "";
      currentId.value = "";
      currentModelId.value = "";
      currentLocale.value = "";
      currentGender.value = "";
      currentStability.value = 0.0;
      currentSimilarityBoost.value = 1.0;
      currentStyle.value = 0.0;
      currentUseSpeakerBoost.value = true;

      isTemplateActive.value = !isTemplateActive.value;
    }

    let removeVoice = (template: any) => {
      innerVoices.value.splice(innerVoices.value.indexOf(template), 1);

      let value = props.value;
      value.value = innerVoices.value;
      systemApi.updateSystemPreference(value);
    }

    let editVoice = (template: any) => {
      currentName.value = template.name;
      currentId.value = template.uniqueId;
      currentModelId.value = template.modelId;
      currentConsolidate.value = template.consolidate;
      currentLocale.value = template.locale;
      currentGender.value = template.gender;
      currentStability.value = template.stability;
      currentSimilarityBoost.value = template.similarity_boost;
      currentStyle.value = template.style;
      currentUseSpeakerBoost.value = template.use_speaker_boost;

      isTemplateActive.value = !isTemplateActive.value;
    }

    let submitNewVoice = () => {
      if (currentName.value.length === 0 || currentId.value.length === 0 || currentModelId.value.length === 0) {
        store.commit('SHOW_SNACKBAR', {
          snackbarType: "error",
          snackbarContent: { t: 'snackbar.valuesNotSet' },
        });
        return;
      }

      let newVoice: VoiceOverVoice = {
        name: currentName.value,
        uniqueId: currentId.value,
        modelId: currentModelId.value,
        consolidate: currentConsolidate.value,
        gender: currentGender.value,
        use_speaker_boost: currentUseSpeakerBoost.value,
        similarity_boost: currentSimilarityBoost.value,
        locale: currentLocale.value,
        stability: currentStability.value,
        style: currentStyle.value,
      };


      for (let valueElement of innerVoices.value) {
        if (valueElement.name === currentName.value && valueElement.uniqueId === currentId.value) {
          innerVoices.value = innerVoices.value.filter(v => v !== valueElement);
          break;
        }
      }

      innerVoices.value.push(newVoice);

      let value = props.value;
      value.value = innerVoices.value;
      systemApi.updateSystemPreference(value);

      toggleTemplateDialog();
    }

    onMounted(() => {
      console.log(props.value);

      innerVoices.value = props.value.value;
    });

    return {
      toggleDialog,
      toggleTemplateDialog,
      removeVoice,
      editVoice,
      submitNewVoice,
      isActive,
      isTemplateActive,

      currentName,
      currentId,
      currentModelId,
      currentConsolidate,
      currentLocale,
      currentGender,
      currentStability,
      currentSimilarityBoost,
      currentStyle,
      currentUseSpeakerBoost,
    };
  },
});

<template>
  <Transition name="slide-fade">
    <div v-if="show" style="width: 392px; height: 300px; float: left; margin-left: 6px"
         v-tooltip="itemProgress !== 100 ? $t('tooltip.videoIsRendering') : ''">
      <div style="position: relative">
        <div id="videoContainer" style="
            background-color: var(--background);
            width: 100%;
            height: 220px;
            border-radius: 14px;
            margin-top: 10px;
            margin-bottom: 10px;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            box-shadow: 0 12px 10px -6px rgba(0, 0, 0, 0.3);
          " :style="itemProgress !== 100 || needsVerification(item)
            ? 'filter:blur(3px)'
            : 'filter:blur(0px)'
            "
             
             @click="onClickVideo"
             @mouseenter="startVideo()"
             @mouseleave="pauseVideo()">
          <video style="position: absolute; height: 100%; pointer-events: none" ref="videoPlayerRef"
                 @update="onVideoUpdate()"
                 class="video-js vjs-16-9"></video>
          <Transition name="fade">
            <div v-if="isMouseHovered" class="bg-menu-color-transparent-50" style="
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                width: 100%;
                height: 15%;
                bottom: 0;
                position: absolute;
                z-index: 1;
              ">
              <i class="bg-dark-blue-70 border-radius-16 col-white simple-hover"
                 :class="isPlaying ? 'pi pi-pause ' : 'pi pi-play '" style="padding: 6px; cursor: pointer"
                 @click="isPlaying ? player.pause() : player.play()"/>
              <Timeline :videoLength="videoPlayerRef.duration" :timecode="videoPlayerTimecode" :assetItemId="item.id"
                        :hideFrames="true" style="width: 70%"/>
              <div v-if="isMuted" @click="onClickVolume()">
                <i class="pi pi-volume-off col-white bg-dark-blue-70 border-radius-16 simple-hover" style="
                    padding: 6px 11px 7px 2px;
                    font-size: 15px;
                    cursor: pointer;
                  "/>
                <i class="pi pi-times col-white" style="
                    position: absolute;
                    cursor: pointer;
                    margin-left: -12px;
                    margin-top: 1px;
                    top: 10px;
                    font-size: 10px;
                  "/>
              </div>
              <div v-else>
                <i class="pi pi-volume-up col-white bg-dark-blue-70 border-radius-16 simple-hover"
                   style="padding: 6px; cursor: pointer" @click="onClickVolume()"/>
              </div>
            </div>
          </Transition>
        </div>
        
        <p-dialog v-model:visible="showFullVideoDialog" :header="item.name"
                  style="width: 80%; height: calc(100vh - 150px)">
          <div
            class="video-container"
            style="
                  align-items: center;
                  width: 100%;
                  height: calc(100vh - 370px);
                "
          >
            <video
              class="video-js bg-black"
              ref="dialogVideoPlayerRef"
              @timeupdate="onVideoUpdate()"
              style="
                    width: 100%;
                    height: inherit;
                    pointer-events: none;
                  "
            />
          </div>
          
          <div>
            <div class="bg-menu-color-2" style="
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                width: 100%;
                height: 42px;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
              ">
              
              <i class="bg-dark-blue border-radius-16 col-white simple-hover"
                 :class="isDialogPlaying ? 'pi pi-pause ' : 'pi pi-play '" style="padding: 6px; cursor: pointer"
                 @click="isDialogPlaying ? dialogPlayer.pause() : dialogPlayer.play()"/>
              <Timeline :videoLength="dialogVideoPlayerRef ? dialogVideoPlayerRef.duration: 0"
                        :timecode="dialogVideoPlayerTimecode"
                        :assetItemId="item.id"
                        :hideFrames="true" style="width: 70%"/>
              <div v-if="isDialogMuted" @click="dialogVideoPlayerRef.volume = 1; isDialogMuted = false">
                <i class="pi pi-volume-off col-white bg-dark-blue border-radius-16 simple-hover" style="
                    padding: 6px 11px 7px 2px;
                    font-size: 15px;
                    cursor: pointer;
                  "/>
                <i class="pi pi-times col-white" style="
                    position: absolute;
                    cursor: pointer;
                    margin-left: -12px;
                    margin-top: 1px;
                    top: 10px;
                    font-size: 10px;
                  "/>
              </div>
              <div v-else>
                <i class="pi pi-volume-up col-white bg-dark-blue border-radius-16 simple-hover"
                   style="padding: 6px; cursor: pointer"
                   @click="dialogVideoPlayerRef.volume = 0; isDialogMuted = true"/>
              </div>
            </div>
          </div>
          
          <div class="flex-center" style="justify-content: space-between; margin-top: 18px">
            <Button
              @click="showFullVideoDialog = false"
              :label="$t('general.close')"
              class="border-radius-8 col-white bor-transparent bg-dark-blue"
              style="
                  height: 40px;
                  padding: 6px 15px 5px 16px;
                  margin-left: 24px;
                "
            />
            
            <Button
              :disabled="item.masterAssetItem.status !== 'CLOSED'"
              @click="hasPermission('assets_view/click_items/all')
                ? $router.push({ name: 'addJob', query: { id: item.id } })
                : ''"
              :label="$t('general.createJob')"
              class="border-radius-8 col-white bor-transparent bg-dark-blue"
              style="
                  height: 40px;
                  padding: 6px 15px 5px 16px;
                  margin-left: 24px;
                "
            />
          </div>
        </p-dialog>
        
        <div style="width: 100%; position: absolute; bottom: 0; left: 0px">
          <ProgressBar v-if="itemProgress ? itemProgress !== 100 : false" :value="itemProgress ? itemProgress : 0"
                       :mode="!itemProgress || itemProgress === 0
            ? 'indeterminate'
            : 'determinate'
            " style="
              width: 100%;
              height: 12px;
              color: red;
              border-radius: 0 0 10px 10px;
            " :showValue="false"/>
        </div>
        <div style="position: absolute; top: 8px; left: 16px">
          <RoundedLabel @click="
            $store.commit('ADD_FILTER_BREADCRUMB', {
              name: (item && item.masterAssetItem) ? item.masterAssetItem.language : '',
              type: 'LANGUAGE',
            });
          $emitter.emit('search-assets', null);
          " :countryFlag="item.masterAssetItem
  ? getLanguageFlag((item && item.masterAssetItem) ? item.masterAssetItem.language : null)
  : ''
  " v-tooltip="(item && item.masterAssetItem) ? item.masterAssetItem.language : ''" class="bg-tt-white-60"
                        style="cursor: pointer; border: 1px solid transparent"/>
        </div>
        <div style="
            position: absolute;
            top: 8px;
            right: 8px;
            display: flex;
            opacity: 0.6;
          ">
          <RoundedLabel @click="toggleSubscription()" :leftIcon="'pi pi-star' + (isSubscript ? '-fill' : '')"
                        v-tooltip="$t('tooltip.subscribe')" class="bg-tt-white"
                        style="margin-right: 10px; cursor: pointer; color: gold"/>
          <RoundedLabel v-if="!needsVerification(item)" :text="assetItemCount + ''" class="bg-tt-white"
                        style="margin-right: 7px" v-tooltip="$t('tooltip.jobCount')" @click="onClickJobCount"/>
          
          <div>
            <RoundedLabel v-if="item.groupIds"
                          :text="item.groupIds.join(', ')"
                          v-tooltip="$t('general.groups')"
                          class="bg-tt-white handle-font-overflow"
                          style="
                            cursor: pointer;
                            border: 1px solid transparent;
                            max-width: 180px;
                            display: inline-block;
                            padding: 0 5px 0 5px;
                          "
            />
            <RoundedLabel v-else
                          @click="
                            $store.commit('ADD_FILTER_BREADCRUMB', {
                              name: item.groupID,
                              type: 'GROUP',
                            });
                            $emitter.emit('search-assets', null);
                          "
                          :text="item.groupID"
                          v-tooltip="$t('general.group')"
                          :lineThrough="isGroupDeleted"
                          class="bg-tt-white handle-font-overflow"
                          style="
                            cursor: pointer;
                            border: 1px solid transparent;
                            max-width: 180px;
                            display: inline-block;
                            padding: 0 5px 0 5px;
                          "
            />
          
          </div>
        </div>
        <div style="position: absolute; left: 16px; opacity: 0.6">
          <RoundedLabel v-if="item.thirdPartyID" @mouseenter="startVideo()" @mouseleave="pauseVideo()" @click="
            $store.commit('ADD_FILTER_BREADCRUMB', {
              name: item.thirdPartyID,
              type: 'ID',
            });
          $emitter.emit('search-assets', null);
          " :text="item.thirdPartyID" v-tooltip="$t('general.id')" class="bg-tt-white" style="
              cursor: pointer;
              margin-right: 7px;
              height: 30px;
              border: 1px solid transparent;
              transition: bottom 50ms;
            " :style="'bottom:' + (isMouseHovered ? 75 : 44) + 'px;'"/>
        </div>
        <div v-if="!hideControls" @mouseenter="startVideo()" @mouseleave="pauseVideo()"
             style="position: absolute; right: 8px; transition: bottom 50ms"
             :style="'bottom:' + (isMouseHovered ? 35 : 5) + 'px;'">
          <div v-if="item.masterAssetItem && needsVerification(item)">
            <Button @click="
              itemProgress === 100
                ? $router.push({
                  name: 'job',
                  query: { id: item.masterAssetItem.id },
                })
                : ''
              " :disabled="item.masterAssetItem.status !== 'CLOSED'"
                    class="border-radius-8 col-reject bor-reject bg-tt-white-60" style="padding: 10px"
                    v-tooltip="itemProgress === 100 ? $t('tooltip.verifyAsset') : ''">
              <i class="pi pi-exclamation-circle" style="font-size: 26px; font-weight: 600"></i>
            </Button>
          </div>
          <div v-else-if="item && item.masterAssetItem">
            <Button :disabled="item.masterAssetItem.status !== 'CLOSED'" @click="
              hasPermission('assets_view/click_items/all')
                ? $router.push({ name: 'addJob', query: { id: item.id } })
                : ''
              " class="border-radius-8 col-dark-blue bor-menu-color bg-tt-white-60" style="padding: 7px 6px 4px 8px"
                    v-tooltip="$t('tooltip.addJob')">
              <i class="pi pi-plus col-dark-blue" style="font-size: 26px; font-weight: 600; opacity: 0.6"></i>
            </Button>
          </div>
        </div>
      </div>
      <div style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        ">
        <div>
          <div id="largeAssetItemName" style="
              line-height: 20px;
              max-width: 20rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            ">
            {{item.name}}
          </div>
          <div style="line-height: 20px; font-weight: 300; font-size: 12px">
            {{
              $t("general.created") +
              " " +
              new Date(item.created).toLocaleString('en-GB', {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })
            }}
          </div>
        </div>
        <div v-if="!hideControls" style="position: relative">
          <RoundedLabel @click="showDropdown(index)" rightIcon="pi pi-bars"
                        style="border: 1px solid transparent; font-size: 24px !important"/>
          <div class="bg-tt-white border-radius-8 dropdown" style="
              display: none;
              position: absolute;
              left: 0;
              width: 220px;
              z-index: 3;
              box-shadow: 0 12px 10px -6px rgba(0, 0, 0, 0.3);
            " :style="'bottom: -' + getDropDownHeight + 'px;'" :id="'dropdown-' + index">
            <div v-if="hasPermission('assets_view/dropdown/edit')" @click="
              $router.push({
                name: 'editAsset',
                query: { id: item.id },
              })
              " v-tooltip="$t('tooltip.editAsset')" class="hover-highlight" style="
                height: 56px;
                padding-left: 16px;
                display: flex;
                align-items: center;
                border-radius: 8px 8px 8px 8px;
                cursor: pointer;
              ">
              <i class="pi pi-cog" style="margin-right: 14px"></i>{{$t("general.edit")}}
            </div>
            
            <div v-if="hasPermission('assets_view/dropdown/edit_fields')" @click="editAssetFields()"
                 class="hover-highlight"
                 v-tooltip="$t('tooltip.editAssetFields')" style="
                height: 56px;
                padding-left: 16px;
                display: flex;
                align-items: center;
                border-radius: 8px 8px 8px 8px;
                cursor: pointer;
              ">
              <i class="pi pi-file-edit" style="margin-right: 14px"></i>{{$t("general.editAssetFields")}}
            </div>
            
            <div v-if="hasPermission('assets_view/dropdown/copy_link')" @click="copyLink()" class="hover-highlight"
                 v-tooltip="$t('tooltip.copyAssetLink')" style="
                height: 56px;
                padding-left: 16px;
                display: flex;
                align-items: center;
                border-radius: 8px 8px 8px 8px;
                cursor: pointer;
              ">
              <i class="pi pi-link" style="margin-right: 14px"></i>{{$t("general.copyAssetLink")}}
            </div>
            
            <div v-if="hasPermission('assets_view/dropdown/delete')" @click="
              hideDropdown(index);
            $store.commit('SHOW_CONFIRM_DIALOG', {
              dialogTitle: 'dialog.areYouSure',
              dialogContent: { t: 'dialog.deleteAsset', e: [item.name] },
              confirmCallback: () => {
                deleteAsset(item);
                $store.commit('SHOW_SNACKBAR', {
                  snackbarContent: { t: 'snackbar.assetDeleted' },
                  snackbarBtnText: $t('snackbar.undo'),
                  snackbarBtnCallback: () => {
                    undoDeleteAsset();
                  },
                });
              },
            });
            " class="hover-highlight" style="
                height: 56px;
                padding-left: 16px;
                display: flex;
                align-items: center;
                border-radius: 8px 8px 8px 8px;
                cursor: pointer;
              ">
              <i class="pi pi-trash" style="margin-right: 14px"></i>{{$t("general.delete")}}
            </div>
            
            <div v-if="(item && item.masterAssetItem)  && hasPermission(
              'assets_view/dropdown/generateSubtitlesFromVOA'
            ) && item.masterAssetItem.canGenerateSubtitlesFromVoice
              " @click="generateSubtitlesFromVOA()" class="hover-highlight" style="
                height: 56px;
                padding-left: 16px;
                display: flex;
                align-items: center;
                border-radius: 8px 8px 8px 8px;
                cursor: pointer;
              ">
              <i class="pi pi-align-left" style="margin-right: 14px"></i> {{$t("general.generateSubtitlesFromVOA")}}
            </div>
            
            <div v-if="isUserAdmin()" @click="downloadProject()" class="hover-highlight" style="
                height: 56px;
                padding-left: 16px;
                display: flex;
                align-items: center;
                border-radius: 8px 8px 8px 8px;
                cursor: pointer;
              ">
              <i class="pi pi-download" style="margin-right: 14px"></i>{{$t("general.downloadProject")}}
            </div>
            <div v-if="hasPermission('assets_view/dropdown/info')" @click="
              $router.push({
                name: 'assetInfo',
                query: { id: item.id },
              })
              " class="hover-highlight" style="
                height: 56px;
                padding-left: 16px;
                display: flex;
                align-items: center;
                border-radius: 8px 8px 8px 8px;
                cursor: pointer;
              ">
              <i class="pi pi-info-circle" style="margin-right: 14px"></i>{{$t("general.assetInformation")}}
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.multi-video-container {
  position: relative;
  width: initial;
  height: initial;
  
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-container {
  z-index: 1;
  width: 100%;
  height: 220px;
  background: rgba(10, 10, 10, 0.3);
  backdrop-filter: blur(5px);
}

#background-video {
  width: inherit;
  height: inherit;
  transform: scale(0.7);
  object-fit: cover;
  position: absolute;
}
</style>

<script src="./LargeAssetItem.ts"></script>

<template>
  <div style="display: flex">
    <div
      v-for="(item, index) in items"
      :key="item"
      style="font-size: 18px; display: flex; align-items: center"
      class="col-dark-blue"
    >
      <i
        v-if="index != 0"
        class="pi pi-chevron-right col-dark-blue-60"
        style="margin: 0px 24px 0px 24px; font-size:14px"
      ></i>
      <div v-if="item.t"
           @click="onClick(item.t)"
           :style="index == items.length - 1 ? ';font-size:18px;' : ';font-size:14px; cursor:pointer;'"
           :class="
          index == items.length - 1 ? 'col-dark-blue' : 'col-dark-blue-60 underline-on-hover '
        "
      >
        {{$t(item.t, item.e)}}
      </div>
    </div>
  </div>
</template>

<script src="./ActivityBreadcrumb.ts"></script>

<template>
  <div style="display: flex; align-items: center">
    <div style="width: 55px; margin-right: 12px">{{$t('general.changeView')}}</div>
    
    <Button
      @click="$emit('update:modelValue', false)"
      :class="
        'border-radius-8 ' +
          (!modelValue
            ? 'col-white bor-white bg-dark-blue'
            : 'col-dark-blue bor-dark-blue bg-transparent')
      "
      style="padding: 10px 10px 0 10px; width: 45px"
    >
      <div>
        <i class="pi pi-th-large" style="font-size: 16px; margin-bottom: 10px"/>
      </div>
    </Button>
    <Button
      @click="$emit('update:modelValue', true)"
      :class="
        'border-radius-8 ' +
          (modelValue
            ? 'col-white bor-white bg-dark-blue'
            : 'col-dark-blue bor-dark-blue bg-transparent')
      "
      style="padding: 10px 10px 0 10px; width: 45px; margin-left: 10px"
    >
      
      <div>
        <i class="pi pi-list" style="font-size: 16px; margin-bottom: 10px"/>
      </div>
    </Button>
  </div>
</template>

<script src="./ChangeViewSwitch.ts"></script>

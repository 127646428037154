<html xmlns:o="urn:schemas-microsoft-com:office:office"
      xmlns:w="urn:schemas-microsoft-com:office:word"><head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="ProgId" content="Word.Document">
    <meta name="Generator" content="Microsoft Word 15">
    <meta name="Originator" content="Microsoft Word 15">
    <link rel="File-List" href="https://bsh.moovit24.de/DATENSCHUTZ.fld/filelist.xml">

    <link rel="themeData" href="https://bsh.moovit24.de/DATENSCHUTZ.fld/themedata.thmx">
    <link rel="colorSchemeMapping" href="https://bsh.moovit24.de/DATENSCHUTZ.fld/colorschememapping.xml">

<w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="false"
                DefSemiHidden="false" DefQFormat="false" DefPriority="99"
                LatentStyleCount="376">
    <w:LsdException Locked="false" Priority="0" QFormat="true" Name="Normal"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 1"/>
    <w:LsdException Locked="false" Priority="9" SemiHidden="true"
                    UnhideWhenUsed="true" QFormat="true" Name="heading 2"/>
    <w:LsdException Locked="false" Priority="9" SemiHidden="true"
                    UnhideWhenUsed="true" QFormat="true" Name="heading 3"/>
    <w:LsdException Locked="false" Priority="9" SemiHidden="true"
                    UnhideWhenUsed="true" QFormat="true" Name="heading 4"/>
    <w:LsdException Locked="false" Priority="9" SemiHidden="true"
                    UnhideWhenUsed="true" QFormat="true" Name="heading 5"/>
    <w:LsdException Locked="false" Priority="9" SemiHidden="true"
                    UnhideWhenUsed="true" QFormat="true" Name="heading 6"/>
    <w:LsdException Locked="false" Priority="9" SemiHidden="true"
                    UnhideWhenUsed="true" QFormat="true" Name="heading 7"/>
    <w:LsdException Locked="false" Priority="9" SemiHidden="true"
                    UnhideWhenUsed="true" QFormat="true" Name="heading 8"/>
    <w:LsdException Locked="false" Priority="9" SemiHidden="true"
                    UnhideWhenUsed="true" QFormat="true" Name="heading 9"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="index 1"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="index 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="index 3"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="index 4"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="index 5"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="index 6"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="index 7"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="index 8"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="index 9"/>
    <w:LsdException Locked="false" Priority="39" SemiHidden="true"
                    UnhideWhenUsed="true" Name="toc 1"/>
    <w:LsdException Locked="false" Priority="39" SemiHidden="true"
                    UnhideWhenUsed="true" Name="toc 2"/>
    <w:LsdException Locked="false" Priority="39" SemiHidden="true"
                    UnhideWhenUsed="true" Name="toc 3"/>
    <w:LsdException Locked="false" Priority="39" SemiHidden="true"
                    UnhideWhenUsed="true" Name="toc 4"/>
    <w:LsdException Locked="false" Priority="39" SemiHidden="true"
                    UnhideWhenUsed="true" Name="toc 5"/>
    <w:LsdException Locked="false" Priority="39" SemiHidden="true"
                    UnhideWhenUsed="true" Name="toc 6"/>
    <w:LsdException Locked="false" Priority="39" SemiHidden="true"
                    UnhideWhenUsed="true" Name="toc 7"/>
    <w:LsdException Locked="false" Priority="39" SemiHidden="true"
                    UnhideWhenUsed="true" Name="toc 8"/>
    <w:LsdException Locked="false" Priority="39" SemiHidden="true"
                    UnhideWhenUsed="true" Name="toc 9"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Normal Indent"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="footnote text"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="annotation text"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="header"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="footer"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="index heading"/>
    <w:LsdException Locked="false" Priority="35" SemiHidden="true"
                    UnhideWhenUsed="true" QFormat="true" Name="caption"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="table of figures"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="envelope address"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="envelope return"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="footnote reference"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="annotation reference"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="line number"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="page number"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="endnote reference"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="endnote text"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="table of authorities"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="macro"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="toa heading"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List Bullet"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List Number"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List 3"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List 4"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List 5"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List Bullet 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List Bullet 3"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List Bullet 4"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List Bullet 5"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List Number 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List Number 3"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List Number 4"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List Number 5"/>
    <w:LsdException Locked="false" Priority="10" QFormat="true" Name="Title"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Closing"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Signature"/>
    <w:LsdException Locked="false" Priority="1" SemiHidden="true"
                    UnhideWhenUsed="true" Name="Default Paragraph Font"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Body Text"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Body Text Indent"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List Continue"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List Continue 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List Continue 3"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List Continue 4"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="List Continue 5"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Message Header"/>
    <w:LsdException Locked="false" Priority="11" QFormat="true" Name="Subtitle"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Salutation"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Date"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Body Text First Indent"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Body Text First Indent 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Note Heading"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Body Text 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Body Text 3"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Body Text Indent 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Body Text Indent 3"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Block Text"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Hyperlink"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="FollowedHyperlink"/>
    <w:LsdException Locked="false" Priority="22" QFormat="true" Name="Strong"/>
    <w:LsdException Locked="false" Priority="20" QFormat="true" Name="Emphasis"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Document Map"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Plain Text"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="E-mail Signature"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="HTML Top of Form"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="HTML Bottom of Form"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Normal (Web)"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="HTML Acronym"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="HTML Address"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="HTML Cite"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="HTML Code"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="HTML Definition"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="HTML Keyboard"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="HTML Preformatted"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="HTML Sample"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="HTML Typewriter"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="HTML Variable"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="annotation subject"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="No List"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Outline List 1"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Outline List 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Outline List 3"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Simple 1"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Simple 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Simple 3"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Classic 1"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Classic 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Classic 3"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Classic 4"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Colorful 1"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Colorful 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Colorful 3"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Columns 1"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Columns 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Columns 3"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Columns 4"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Columns 5"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Grid 1"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Grid 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Grid 3"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Grid 4"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Grid 5"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Grid 6"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Grid 7"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Grid 8"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table List 1"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table List 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table List 3"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table List 4"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table List 5"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table List 6"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table List 7"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table List 8"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table 3D effects 1"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table 3D effects 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table 3D effects 3"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Contemporary"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Elegant"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Professional"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Subtle 1"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Subtle 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Web 1"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Table Web 2"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Balloon Text"/>
    <w:LsdException Locked="false" Priority="39" Name="Table Grid"/>
    <w:LsdException Locked="false" SemiHidden="true" Name="Placeholder Text"/>
    <w:LsdException Locked="false" Priority="1" QFormat="true" Name="No Spacing"/>
    <w:LsdException Locked="false" Priority="60" Name="Light Shading"/>
    <w:LsdException Locked="false" Priority="61" Name="Light List"/>
    <w:LsdException Locked="false" Priority="62" Name="Light Grid"/>
    <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1"/>
    <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2"/>
    <w:LsdException Locked="false" Priority="65" Name="Medium List 1"/>
    <w:LsdException Locked="false" Priority="66" Name="Medium List 2"/>
    <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1"/>
    <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2"/>
    <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3"/>
    <w:LsdException Locked="false" Priority="70" Name="Dark List"/>
    <w:LsdException Locked="false" Priority="71" Name="Colorful Shading"/>
    <w:LsdException Locked="false" Priority="72" Name="Colorful List"/>
    <w:LsdException Locked="false" Priority="73" Name="Colorful Grid"/>
    <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 1"/>
    <w:LsdException Locked="false" Priority="61" Name="Light List Accent 1"/>
    <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 1"/>
    <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 1"/>
    <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 1"/>
    <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 1"/>
    <w:LsdException Locked="false" SemiHidden="true" Name="Revision"/>
    <w:LsdException Locked="false" Priority="34" QFormat="true"
                    Name="List Paragraph"/>
    <w:LsdException Locked="false" Priority="29" QFormat="true" Name="Quote"/>
    <w:LsdException Locked="false" Priority="30" QFormat="true"
                    Name="Intense Quote"/>
    <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 1"/>
    <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 1"/>
    <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 1"/>
    <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 1"/>
    <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 1"/>
    <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 1"/>
    <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 1"/>
    <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 1"/>
    <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 2"/>
    <w:LsdException Locked="false" Priority="61" Name="Light List Accent 2"/>
    <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 2"/>
    <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 2"/>
    <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 2"/>
    <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 2"/>
    <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 2"/>
    <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 2"/>
    <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 2"/>
    <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 2"/>
    <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 2"/>
    <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 2"/>
    <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 2"/>
    <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 2"/>
    <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 3"/>
    <w:LsdException Locked="false" Priority="61" Name="Light List Accent 3"/>
    <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 3"/>
    <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 3"/>
    <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 3"/>
    <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 3"/>
    <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 3"/>
    <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 3"/>
    <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 3"/>
    <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 3"/>
    <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 3"/>
    <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 3"/>
    <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 3"/>
    <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 3"/>
    <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 4"/>
    <w:LsdException Locked="false" Priority="61" Name="Light List Accent 4"/>
    <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 4"/>
    <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 4"/>
    <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 4"/>
    <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 4"/>
    <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 4"/>
    <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 4"/>
    <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 4"/>
    <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 4"/>
    <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 4"/>
    <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 4"/>
    <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 4"/>
    <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 4"/>
    <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 5"/>
    <w:LsdException Locked="false" Priority="61" Name="Light List Accent 5"/>
    <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 5"/>
    <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 5"/>
    <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 5"/>
    <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 5"/>
    <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 5"/>
    <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 5"/>
    <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 5"/>
    <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 5"/>
    <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 5"/>
    <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 5"/>
    <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 5"/>
    <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 5"/>
    <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 6"/>
    <w:LsdException Locked="false" Priority="61" Name="Light List Accent 6"/>
    <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 6"/>
    <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 6"/>
    <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 6"/>
    <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 6"/>
    <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 6"/>
    <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 6"/>
    <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 6"/>
    <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 6"/>
    <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 6"/>
    <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 6"/>
    <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 6"/>
    <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 6"/>
    <w:LsdException Locked="false" Priority="19" QFormat="true"
                    Name="Subtle Emphasis"/>
    <w:LsdException Locked="false" Priority="21" QFormat="true"
                    Name="Intense Emphasis"/>
    <w:LsdException Locked="false" Priority="31" QFormat="true"
                    Name="Subtle Reference"/>
    <w:LsdException Locked="false" Priority="32" QFormat="true"
                    Name="Intense Reference"/>
    <w:LsdException Locked="false" Priority="33" QFormat="true" Name="Book Title"/>
    <w:LsdException Locked="false" Priority="37" SemiHidden="true"
                    UnhideWhenUsed="true" Name="Bibliography"/>
    <w:LsdException Locked="false" Priority="39" SemiHidden="true"
                    UnhideWhenUsed="true" QFormat="true" Name="TOC Heading"/>
    <w:LsdException Locked="false" Priority="41" Name="Plain Table 1"/>
    <w:LsdException Locked="false" Priority="42" Name="Plain Table 2"/>
    <w:LsdException Locked="false" Priority="43" Name="Plain Table 3"/>
    <w:LsdException Locked="false" Priority="44" Name="Plain Table 4"/>
    <w:LsdException Locked="false" Priority="45" Name="Plain Table 5"/>
    <w:LsdException Locked="false" Priority="40" Name="Grid Table Light"/>
    <w:LsdException Locked="false" Priority="46" Name="Grid Table 1 Light"/>
    <w:LsdException Locked="false" Priority="47" Name="Grid Table 2"/>
    <w:LsdException Locked="false" Priority="48" Name="Grid Table 3"/>
    <w:LsdException Locked="false" Priority="49" Name="Grid Table 4"/>
    <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark"/>
    <w:LsdException Locked="false" Priority="51" Name="Grid Table 6 Colorful"/>
    <w:LsdException Locked="false" Priority="52" Name="Grid Table 7 Colorful"/>
    <w:LsdException Locked="false" Priority="46"
                    Name="Grid Table 1 Light Accent 1"/>
    <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 1"/>
    <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 1"/>
    <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 1"/>
    <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 1"/>
    <w:LsdException Locked="false" Priority="51"
                    Name="Grid Table 6 Colorful Accent 1"/>
    <w:LsdException Locked="false" Priority="52"
                    Name="Grid Table 7 Colorful Accent 1"/>
    <w:LsdException Locked="false" Priority="46"
                    Name="Grid Table 1 Light Accent 2"/>
    <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 2"/>
    <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 2"/>
    <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 2"/>
    <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 2"/>
    <w:LsdException Locked="false" Priority="51"
                    Name="Grid Table 6 Colorful Accent 2"/>
    <w:LsdException Locked="false" Priority="52"
                    Name="Grid Table 7 Colorful Accent 2"/>
    <w:LsdException Locked="false" Priority="46"
                    Name="Grid Table 1 Light Accent 3"/>
    <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 3"/>
    <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 3"/>
    <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 3"/>
    <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 3"/>
    <w:LsdException Locked="false" Priority="51"
                    Name="Grid Table 6 Colorful Accent 3"/>
    <w:LsdException Locked="false" Priority="52"
                    Name="Grid Table 7 Colorful Accent 3"/>
    <w:LsdException Locked="false" Priority="46"
                    Name="Grid Table 1 Light Accent 4"/>
    <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 4"/>
    <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 4"/>
    <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 4"/>
    <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 4"/>
    <w:LsdException Locked="false" Priority="51"
                    Name="Grid Table 6 Colorful Accent 4"/>
    <w:LsdException Locked="false" Priority="52"
                    Name="Grid Table 7 Colorful Accent 4"/>
    <w:LsdException Locked="false" Priority="46"
                    Name="Grid Table 1 Light Accent 5"/>
    <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 5"/>
    <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 5"/>
    <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 5"/>
    <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 5"/>
    <w:LsdException Locked="false" Priority="51"
                    Name="Grid Table 6 Colorful Accent 5"/>
    <w:LsdException Locked="false" Priority="52"
                    Name="Grid Table 7 Colorful Accent 5"/>
    <w:LsdException Locked="false" Priority="46"
                    Name="Grid Table 1 Light Accent 6"/>
    <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 6"/>
    <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 6"/>
    <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 6"/>
    <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 6"/>
    <w:LsdException Locked="false" Priority="51"
                    Name="Grid Table 6 Colorful Accent 6"/>
    <w:LsdException Locked="false" Priority="52"
                    Name="Grid Table 7 Colorful Accent 6"/>
    <w:LsdException Locked="false" Priority="46" Name="List Table 1 Light"/>
    <w:LsdException Locked="false" Priority="47" Name="List Table 2"/>
    <w:LsdException Locked="false" Priority="48" Name="List Table 3"/>
    <w:LsdException Locked="false" Priority="49" Name="List Table 4"/>
    <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark"/>
    <w:LsdException Locked="false" Priority="51" Name="List Table 6 Colorful"/>
    <w:LsdException Locked="false" Priority="52" Name="List Table 7 Colorful"/>
    <w:LsdException Locked="false" Priority="46"
                    Name="List Table 1 Light Accent 1"/>
    <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 1"/>
    <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 1"/>
    <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 1"/>
    <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 1"/>
    <w:LsdException Locked="false" Priority="51"
                    Name="List Table 6 Colorful Accent 1"/>
    <w:LsdException Locked="false" Priority="52"
                    Name="List Table 7 Colorful Accent 1"/>
    <w:LsdException Locked="false" Priority="46"
                    Name="List Table 1 Light Accent 2"/>
    <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 2"/>
    <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 2"/>
    <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 2"/>
    <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 2"/>
    <w:LsdException Locked="false" Priority="51"
                    Name="List Table 6 Colorful Accent 2"/>
    <w:LsdException Locked="false" Priority="52"
                    Name="List Table 7 Colorful Accent 2"/>
    <w:LsdException Locked="false" Priority="46"
                    Name="List Table 1 Light Accent 3"/>
    <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 3"/>
    <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 3"/>
    <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 3"/>
    <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 3"/>
    <w:LsdException Locked="false" Priority="51"
                    Name="List Table 6 Colorful Accent 3"/>
    <w:LsdException Locked="false" Priority="52"
                    Name="List Table 7 Colorful Accent 3"/>
    <w:LsdException Locked="false" Priority="46"
                    Name="List Table 1 Light Accent 4"/>
    <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 4"/>
    <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 4"/>
    <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 4"/>
    <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 4"/>
    <w:LsdException Locked="false" Priority="51"
                    Name="List Table 6 Colorful Accent 4"/>
    <w:LsdException Locked="false" Priority="52"
                    Name="List Table 7 Colorful Accent 4"/>
    <w:LsdException Locked="false" Priority="46"
                    Name="List Table 1 Light Accent 5"/>
    <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 5"/>
    <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 5"/>
    <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 5"/>
    <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 5"/>
    <w:LsdException Locked="false" Priority="51"
                    Name="List Table 6 Colorful Accent 5"/>
    <w:LsdException Locked="false" Priority="52"
                    Name="List Table 7 Colorful Accent 5"/>
    <w:LsdException Locked="false" Priority="46"
                    Name="List Table 1 Light Accent 6"/>
    <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 6"/>
    <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 6"/>
    <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 6"/>
    <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 6"/>
    <w:LsdException Locked="false" Priority="51"
                    Name="List Table 6 Colorful Accent 6"/>
    <w:LsdException Locked="false" Priority="52"
                    Name="List Table 7 Colorful Accent 6"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Mention"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Smart Hyperlink"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Hashtag"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Unresolved Mention"/>
    <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
                    Name="Smart Link"/>
</w:LatentStyles>

</head>

<body style="tab-interval:35.4pt" cz-shortcut-listen="true" lang="DE">

<div class="WordSection1">

    <p class="MsoNormal" style="margin-bottom:15.0pt;line-height:31.5pt;mso-outline-level:
1;background:#F8F8F6"><span style="font-size:27.0pt;font-family: Ubuntu ,sans-serif;
mso-fareast-font-family: Times New Roman ;mso-bidi-font-family: Times New Roman ;
color:#00A5DF;text-transform:uppercase;letter-spacing:.75pt;mso-font-kerning:
18.0pt;mso-fareast-language:DE">DATENSCHUTZ<o:p></o:p></span></p>

    <p class="MsoNormal" style="margin-top:18.75pt;margin-right:0cm;margin-bottom:
15.0pt;margin-left:0cm;line-height:16.5pt;mso-outline-level:4;background:#F8F8F6"><span style="font-size:13.5pt;font-family: Ubuntu ,sans-serif;mso-fareast-font-family:
 Times New Roman ;mso-bidi-font-family: Times New Roman ;color:#3C3C3B;
text-transform:uppercase;letter-spacing:.75pt;mso-fareast-language:DE">DATENSCHUTZERKLÄRUNG<o:p></o:p></span></p>

    <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0cm;margin-bottom:
15.0pt;margin-left:0cm;background:#F8F8F6"><span style="font-size:11.5pt;
font-family: Ubuntu ,sans-serif;mso-fareast-font-family: Times New Roman ;
mso-bidi-font-family: Times New Roman ;color:#6F6D6B;mso-fareast-language:DE">§
1 Information über die Erhebung personenbezogener Daten und
Anbieterkennzeichnung<br>
1.1 Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei
Nutzung dieser Website. Personenbezogene Daten sind alle Daten, die auf Sie
persönlich beziehbar sind, also z.</span><span style="font-size:11.5pt;
font-family: Arial ,sans-serif;mso-fareast-font-family: Times New Roman ;
color:#6F6D6B;mso-fareast-language:DE"> </span><span style="font-size:11.5pt;
font-family: Ubuntu ,sans-serif;mso-fareast-font-family: Times New Roman ;
mso-bidi-font-family: Times New Roman ;color:#6F6D6B;mso-fareast-language:DE">B.
Name, Adresse, E-Mail-Adressen, Nutzerverhalten.<br>
1.2 Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DS-GVO)
ist die MoovIT GmbH Schanzenstr. 29, 51063 Köln (siehe unser Impressum). Unser
Datenschutzbeauftragter ist Herr Andreas Pinheiro LL.M.,
a.pinheiro@ap-datenschutz.de.<br>
1.3 Falls wir für einzelne Funktionen unseres Angebots auf beauftragte
Dienstleister zurückgreifen oder Ihre Daten für werbliche Zwecke nutzen
möchten, werden wir Sie untenstehend im Detail über die jeweiligen Vorgänge
informieren. Dabei nennen wir auch die festgelegten Kriterien der
Speicherdauer.<o:p></o:p></span></p>

    <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0cm;margin-bottom:
15.0pt;margin-left:0cm;background:#F8F8F6"><span style="font-size:11.5pt;
font-family: Ubuntu ,sans-serif;mso-fareast-font-family: Times New Roman ;
mso-bidi-font-family: Times New Roman ;color:#6F6D6B;mso-fareast-language:DE">§
2 Rechte, insbesondere auf Auskunft und Widerruf<br>
2.1 Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden
personenbezogenen Daten:<br>
• Recht auf Auskunft,<br>
• Recht auf Berichtigung oder Löschung,<br>
• Recht auf Einschränkung der Verarbeitung,<br>
• Recht auf Widerspruch gegen die Verarbeitung,<br>
• Recht auf Datenübertragbarkeit.<br>
2.2 Wenn Sie eine Einwilligung zur Nutzung von Daten erteilt haben, können Sie
diese jederzeit widerrufen. Sollte die Rechtmäßigkeit der Verarbeitung auf
Grundlage einer Einwilligung bestehen, bleibt diese bis zur Ausübung des
Widerrufs gültig.<br>
2.3 Alle Informationswünsche, Auskunftsanfragen oder Widersprüche zur
Datenverarbeitung richten Sie bitte per E-Mail an info@moovit.de oder an die
unter § 1 Abs. 2 genannte Adresse.<br>
2.4 Sie können von uns jederzeit verlangen ihre Daten zu löschen. Hierbei
können gesetzliche Aufbewahrungsfristen bestehen, die es uns erlauben ihre
Daten bis zum Ablauf der Frist aufzubewahren.<br>
2.5 Wenn ihre Daten unrichtig sein sollten, haben sie das Recht uns zu einer
Berichtigung aufzufordern. Dieser Aufforderung kommen wird unverzüglich nach.<br>
2.6 Sie haben das Recht ihre uns zur Verfügung gestellten personenbezogenen
Daten, von uns in einem lesbaren Format zu erhalten, soweit technisch möglich,
um Sie einem anderen Unternehmen zur Verfügung zu stellen (Recht auf
Datenübertragbarkeit).<br>
2.7 Sie haben das Recht sich bei der für Sie zuständigen Aufsichtsbehörde zu
beschweren.<o:p></o:p></span></p>

    <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0cm;margin-bottom:
15.0pt;margin-left:0cm;background:#F8F8F6"><span style="font-size:11.5pt;
font-family: Ubuntu ,sans-serif;mso-fareast-font-family: Times New Roman ;
mso-bidi-font-family: Times New Roman ;color:#6F6D6B;mso-fareast-language:DE">§
3 Datensicherheit<br>
3.1 Wir unterhalten aktuelle technische Maßnahmen zur Gewährleistung der
Datensicherheit, insbesondere zum Schutz Ihrer personenbezogenen Daten vor
Gefahren bei Datenübertragungen sowie vor Kenntniserlangung durch Dritte. Diese
werden dem aktuellen Stand der Technik entsprechend jeweils angepasst.<o:p></o:p></span></p>

    <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0cm;margin-bottom:
15.0pt;margin-left:0cm;background:#F8F8F6"><span style="font-size:11.5pt;
font-family: Ubuntu ,sans-serif;mso-fareast-font-family: Times New Roman ;
mso-bidi-font-family: Times New Roman ;color:#6F6D6B;mso-fareast-language:DE">§
4 Erhebung personenbezogener Daten bei informatorischer Nutzung und
Kontaktaufnahme<br>
4.1 Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht
registrieren oder uns anderweitig Informationen übermitteln, erheben wir nur
die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt.
Wenn Sie unsere Website betrachten möchten, erheben wir die folgenden Daten,
die für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und
die Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs.
1 S. 1 lit. f DS-GVO):<br>
• Zeitzonendifferenz zur Greenwich Mean Time (GMT)<br>
• Inhalt der Anforderung (konkrete Seite)<br>
• Zugriffsstatus/HTTP-Statuscode<br>
• Website, von der die Anforderung kommt<br>
• Browser<br>
• Betriebssystem und dessen Oberfläche<br>
• Sprache und Version der Browsersoftware<br>
4.2 Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über das Kontaktformular
wird Ihre E-Mail-Adresse, ihr Name und, falls Sie dies angeben, ihre Adresse
undTelefonnummer von uns gespeichert. Der Zweck dieser Speicherung ist
lediglich die Kontaktaufnahme, um Ihre Fragen zu beantworten.<br>
4.3 Rechtsgrundlage der angegebenen Erhebung ist die Einwilligung, die sie
durch Besuch unserer Webseite und Bestätigen des Cookie-Banners kundgetan haben
(Art. 6 Abs. 1 lit. a DSGVO).<br>
4.4 Wir werden ihre Daten nur gesetzlich im erlaubten Umfang zum Zwecke der
Werbung nutzen. Insbesondere nutzen wir ihre E-Mail-Adresse lediglich zur
Direktwerbung für eigene ähnliche Waren oder Dienstleistungen. Sie können der
Verwendung ihrer Daten zu Werbezwecken jederzeit schriftlich oder in Textform
(E-Mail an info@moovit.de ) widersprechen. Hierbei berufen wir uns auf unser
berechtigtes Interesse an der Bewerbung unserer Produkte gegenüber unseren
Kunden gem. Art. 6 Abs. 1 lit. f DSGVO.<br>
4.5 Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer
Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich um
kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser
zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt
(hier durch uns), bestimmte Informationen zufließen. Cookies können keine
Programme ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu,
das Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen.<o:p></o:p></span></p>

    <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0cm;margin-bottom:
15.0pt;margin-left:0cm;background:#F8F8F6"><span style="font-size:11.5pt;
font-family: Ubuntu ,sans-serif;mso-fareast-font-family: Times New Roman ;
mso-bidi-font-family: Times New Roman ;color:#6F6D6B;mso-fareast-language:DE">§
5 Cookies<br>
5.1 Wir verwenden auf unserer Website Cookies. Solche Cookies sind notwendig,
damit Sie sich auf der Website frei bewegen und deren Features nutzen können;
hierzu gehört u. a. auch der Zugriff auf gesicherte Bereiche der Website. Durch
Cookies können wir nachvollziehen, wer die Seite(n) besucht hat, und hieraus
ableiten, wie häufig bestimmte Seiten besucht werden, welche Teile der Site
sich besonderer Beliebtheit erfreuen. Session-Cookies speichern Informationen
über Ihre Aktivitäten auf unserer Website.<br>
5.2 Diese Website nutzt folgende Arten von Cookies, deren Umfang und
Funktionsweise im Folgenden erläutert werden:<br>
• Transiente Cookies (temporärer Einsatz)<br>
• Persistente Cookies (zeitlich beschränkter Einsatz)<br>
5.3 Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser
schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine
sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers
der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt
werden, wenn Sie auf die Website zurückkehren. Die Session-Cookies werden
gelöscht, wenn Sie sich ausloggen oder Sie den Browser schließen.<br>
5.4 Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer
gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in
den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.<br>
5.5 Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen
konfigurieren und z. B. die Annahme von Third-Party-Cookies oder allen Cookies
ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht alle Funktionen
dieser Website nutzen können.<br>
5.6 Wir setzen Cookies ein, um Sie für Folgebesuche identifizieren zu können,
falls Sie über einen Account bei uns verfügen. Andernfalls müssten Sie sich für
jeden Besuch erneut einloggen.<o:p></o:p></span></p>

    <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0cm;margin-bottom:
15.0pt;margin-left:0cm;background:#F8F8F6"><span style="font-size:11.5pt;
font-family: Ubuntu ,sans-serif;mso-fareast-font-family: Times New Roman ;
mso-bidi-font-family: Times New Roman ;color:#6F6D6B;mso-fareast-language:DE">§
6 Newsletter<br>
6.1 Mit Ihrer Einwilligung können Sie unseren Newsletter abonnieren, mit dem
wir Sie über unsere aktuellen interessanten Angebote informieren. Die
beworbenen Waren und Dienstleistungen sind in der Einwilligungserklärung
benannt.<br>
6.2 Für die Anmeldung zu unserem Newsletter verwenden wir das sog.
Double-opt-in-Verfahren. Das heißt, dass wir Ihnen nach Ihrer Anmeldung eine
E-Mail an die angegebene E-Mail-Adresse senden, in welcher wir Sie um
Bestätigung bitten, dass Sie den Versand des Newsletters wünschen. Wenn Sie
Ihre Anmeldung nicht innerhalb von 24 Stunden bestätigen, werden Ihre
Informationen gesperrt und nach einem Monat automatisch gelöscht. Darüber
hinaus speichern wir jeweils Ihre eingesetzten IP-Adressen und Zeitpunkte der
Anmeldung und Bestätigung. Zweck des Verfahrens ist, Ihre Anmeldung nachweisen
und ggf. einen möglichen Missbrauch Ihrer persönlichen Daten aufklären zu
können.<br>
6.3 Pflichtangabe für die Übersendung des Newsletters ist allein Ihre
E-Mail-Adresse. Die Angabe weiterer, gesondert markierter Daten ist freiwillig
und wird verwendet, um Sie persönlich ansprechen zu können. Nach Ihrer
Bestätigung speichern wir Ihre E-Mail-Adresse zum Zweck der Zusendung des
Newsletters. Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. a DS-GVO.<br>
6.4 Ihre Einwilligung in die Übersendung des Newsletters können Sie jederzeit
widerrufen und den Newsletter abbestellen. Den Widerruf können Sie durch Klick
auf den in jeder Newsletter-E-Mail bereitgestellten Link, per E-Mail an
info@moovit.de oder durch eine Nachricht an die im Impressum angegebenen
Kontaktdaten erklären.<br>
6.5 Wir weisen Sie darauf hin, dass wir bei Versand des Newsletters Ihr
Nutzerverhalten auswerten. Für diese Auswertung beinhalten die versendeten
E-Mails sogenannte Web-Beacons, auch Tracking-Pixel genannt. Dies sind
Ein-Pixel-Bilddateien, die auf unsere Website verlinken und uns so ermöglichen,
Ihr Nutzerverhalten auszuwerten. Dies erfolgt durch Erhebung der in § 4
genannten Daten sowie Web-Beacons, die Ihrer E-Mail-Adresse zugeordnet und mit
einer eigenen ID verknüpft werden. Auch im Newsletter erhaltene Links enthalten
diese ID. Die Daten werden ausschließlich pseudonymisiert erhoben, die IDs
werden also nicht mit Ihren weiteren persönlichen Daten verknüpft, eine direkte
Personenbeziehbarkeit wird so ausgeschlossen. Die so erhobenen Informationen
speichert der Newsletter-Anbieter auf seinem Server in Deutschland.<br>
6.6 Sie können diesem Tracking jederzeit widersprechen, indem Sie den
gesonderten Link, der in jeder E-Mail bereitgestellt wird, anklicken oder uns
an info@moovit.de informieren<o:p></o:p></span></p>

    <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0cm;margin-bottom:
15.0pt;margin-left:0cm;background:#F8F8F6"><span style="font-size:11.5pt;
font-family: Ubuntu ,sans-serif;mso-fareast-font-family: Times New Roman ;
mso-bidi-font-family: Times New Roman ;color:#6F6D6B;mso-fareast-language:DE">§
7 Webtracking – Google Analytics<br>
7.1 Diese Website benutzt Google Analytics, einen Webanalysedienst der Google
Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die
auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der
Website durch Sie ermöglichen (s. § 6). Die durch den Cookie erzeugten
Informationen über Ihre Benutzung dieser Website werden in der Regel an einen
Server von Google in den USA übertragen und dort gespeichert. Im Falle der
Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von
Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in
anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server
von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers
dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der
Website auszuwerten, um Reports über die Website-Aktivitäten zusammenzustellen
und um weitere mit der Website-Nutzung und der Internetnutzung verbundene
Dienstleistungen gegenüber dem Website-Betreiber zu erbringen.<br>
7.2 Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.<br>
7.3 Sie können die Speicherung der Cookies durch eine entsprechende Einstellung
Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie
in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung
der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten
(inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
Google verhindern, indem sie das unter dem folgenden Link verfügbare
Browser-Plug-in herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de.<br>
7.4 Diese Website verwendet Google Analytics mit der Erweiterung
„_anonymizeIp()“. Dadurch werden IP-Adressen gekürzt weiterverarbeitet, eine
Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den über Sie
erhobenen Daten ein Personenbezug zukommt, wird dieser also sofort
ausgeschlossen und die personenbezogenen Daten damit umgehend gelöscht.<br>
7.5 Wir nutzen Google Analytics, um die Nutzung unserer Website analysieren und
regelmäßig verbessern zu können. Über die gewonnenen Statistiken können wir
unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten. Für
die Ausnahmefälle, in denen personenbezogene Daten in die USA übertragen
werden, hat sich Google dem EU-US Privacy Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework.
Rechtsgrundlage für die Nutzung von Google Analytics ist Art. 6 Abs. 1 S. 1
lit. f DS-GVO.<br>
7.6 Informationen des Drittanbieters: Google Dublin, Google Ireland Ltd.,
Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001.
Nutzerbedingungen: http://www.google.com/analytics/terms/de.html, Übersicht zum
Datenschutz: http://www.google.com/intl/de/analytics/learn/privacy.html, sowie
die Datenschutzerklärung: http://www.google.de/intl/de/policies/privacy.<br>
7.7 Diese Website verwendet Google Analytics zudem für eine geräteübergreifende
Analyse von Besucherströmen, die über eine User-ID durchgeführt wird. Sie
können in Ihrem Kundenkonto unter „Meine Daten“, „persönliche Daten“ die
geräteübergreifende Analyse Ihrer Nutzung deaktivieren.<o:p></o:p></span></p>

    <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0cm;margin-bottom:
15.0pt;margin-left:0cm;background:#F8F8F6"><span style="font-size:11.5pt;
font-family: Ubuntu ,sans-serif;mso-fareast-font-family: Times New Roman ;
mso-bidi-font-family: Times New Roman ;color:#6F6D6B;mso-fareast-language:DE">§
8 Einsatz von Google AdSense<br>
8.1 Diese Website verwendet den Online-Werbedienst Google AdSense, durch den
Ihnen auf Ihre Interessen ausgelegte Werbung präsentiert werden kann. Wir
verfolgen damit das Interesse, Ihnen Werbung anzuzeigen, die für Sie von
Interesse sein könnte, um unsere Website für Sie interessanter zu gestalten.
Hierzu werden statistische Informationen über Sie erfasst, die durch unsere
Werbepartner verarbeitet werden. Diese Werbeanzeigen sind über den Hinweis
„Google-Anzeigen“ in der jeweiligen Anzeige erkennbar.<br>
8.2 Durch den Besuch unserer Website erhält Google die Information, dass Sie
unsere Website aufgerufen haben. Dazu nutzt Google einen Web Beacon, um einen
Cookie auf Ihrem Rechner zu setzen. Es werden die unter § 3 dieser Erklärung
genannten Daten übermittelt. Wir haben weder Einfluss auf die erhobenen Daten,
noch ist uns der volle Umfang der Datenerhebung und die Speicherdauer bekannt.
Ihre Daten werden in die USA übertragen und dort ausgewertet. Wenn Sie mit
Ihrem Google-Account eingeloggt sind, können Ihre Daten diesem direkt
zugeordnet werden. Wenn Sie die Zuordnung mit Ihrem Google-Profil nicht
wünschen, müssen Sie sich ausloggen. Es ist möglich, dass diese Daten an
Vertragspartner von Google an Dritte und Behörden weitergegeben werden.
Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 S. 1 lit. f
DS-GVO. Diese Website schaltet über Google AdSense keine Anzeigen von
Drittanbietern.<br>
8.3 Sie können die Installation der Cookies von Google AdSense auf verschiedene
Weise verhindern: a) durch eine entsprechende Einstellung Ihrer
Browser-Software, insbesondere führt die Unterdrückung von Drittcookies dazu,
dass Sie keine Anzeigen von Drittanbietern erhalten; b) durch Deaktivierung der
interessenbezogenen Anzeigen bei Google über den Link
http://www.google.de/ads/preferences, wobei diese Einstellung gelöscht wird,
wenn Sie Ihre Cookies löschen; c) durch Deaktivierung der interessenbezogenen
Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne „About Ads“
sind, über den Link http://www.aboutads.info/choices, wobei diese Einstellung
gelöscht wird, wenn Sie Ihre Cookies löschen; d) durch dauerhafte Deaktivierung
in Ihren Browsern Firefox, Internetexplorer oder Google Chrome unter dem Link
http://www.google.com/settings/ads/plugin. Wir weisen Sie darauf hin, dass Sie
in diesem Fall gegebenenfalls nicht alle Funktionen dieses Angebots
vollumfänglich nutzen können.<br>
8.4 Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung
sowie weitere Informationen zu Ihren diesbezüglichen Rechten und
Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre erhalten Sie bei:
Google Inc., 1600 Amphitheater Parkway, Mountainview, California 94043, USA;
Datenschutzbedingungen für Werbung:
http://www.google.de/intl/de/policies/technologies/ads. Google hat sich dem
EU-US Privacy Shield unterworfen,
https://www.privacyshield.gov/EU-US-Framework.<o:p></o:p></span></p>

    <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0cm;margin-bottom:
15.0pt;margin-left:0cm;background:#F8F8F6"><span style="font-size:11.5pt;
font-family: Ubuntu ,sans-serif;mso-fareast-font-family: Times New Roman ;
mso-bidi-font-family: Times New Roman ;color:#6F6D6B;mso-fareast-language:DE">§
9 Youtube<br>
9.1 Wir haben YouTube-Videos in unser Online-Angebot eingebunden, die auf http://www.YouTube.com
gespeichert sind und von unserer Website aus direkt abspielbar sind. Diese sind
alle im „erweiterten Datenschutz-Modus“ eingebunden, d.</span><span style="font-size:11.5pt;font-family: Arial ,sans-serif;mso-fareast-font-family:
 Times New Roman ;color:#6F6D6B;mso-fareast-language:DE"> </span><span style="font-size:11.5pt;font-family: Ubuntu ,sans-serif;mso-fareast-font-family:
 Times New Roman ;mso-bidi-font-family: Times New Roman ;color:#6F6D6B;
mso-fareast-language:DE">h. dass keine Daten über Sie als Nutzer an YouTube
übertragen werden, wenn Sie die Videos nicht abspielen.7 Erst wenn Sie die
Videos abspielen, werden die in Abs. 2 genannten Daten übertragen. Auf diese
Datenübertragung haben wir keinen Einfluss.<o:p></o:p></span></p>

    <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0cm;margin-bottom:
15.0pt;margin-left:0cm;background:#F8F8F6"><span style="font-size:11.5pt;
font-family: Ubuntu ,sans-serif;mso-fareast-font-family: Times New Roman ;
mso-bidi-font-family: Times New Roman ;color:#6F6D6B;mso-fareast-language:DE">9.2
Durch den Besuch auf der Website erhält YouTube die Information, dass Sie die
entsprechende Unterseite unserer Website aufgerufen haben. Zudem werden die
unter § 3 dieser Erklärung genannten Daten übermittelt. Dies erfolgt unabhängig
davon, ob YouTube ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind,
oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden
Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem
Profil bei YouTube nicht wünschen, müssen Sie sich vor Aktivierung des Buttons
ausloggen. YouTube speichert Ihre Daten als Nutzungsprofile und nutzt sie für
Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner
Website. Eine solche Auswertung erfolgt insbesondere (selbst für nicht
eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere
Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu
informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser
Nutzerprofile, wobei Sie sich zur Ausübung dessen an YouTube richten müssen.<br>
9.3 Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer
Verarbeitung durch YouTube erhalten Sie in der Datenschutzerklärung. Dort
erhalten Sie auch weitere Informationen zu Ihren Rechten und
Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre:
https://www.google.de/intl/de/policies/privacy. Google verarbeitet Ihre
personenbezogenen Daten auch in den USA und hat sich dem EU-US-Privacy-Shield
unterworfen, https://www.privacyshield.gov/EU-US-Framework.<o:p></o:p></span></p>

    <p class="MsoNormal" style="margin-top:15.0pt;background:#F8F8F6"><span style="font-size:11.5pt;font-family: Ubuntu ,sans-serif;mso-fareast-font-family:
 Times New Roman ;mso-bidi-font-family: Times New Roman ;color:#6F6D6B;
mso-fareast-language:DE">§ 10 Einbindung von Google Maps<br>
10.1 Auf dieser Webseite nutzen wir das Angebot von Google Maps. Dadurch können
wir Ihnen interaktive Karten direkt in der Website anzeigen und ermöglichen
Ihnen die komfortable Nutzung der Karten-Funktion.<br>
10.2 Durch den Besuch auf der Website erhält Google die Information, dass Sie
die entsprechende Unterseite unserer Website aufgerufen haben. Zudem werden die
unter § 3 dieser Erklärung genannten Daten übermittelt. Dies erfolgt unabhängig
davon, ob Google ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind,
oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden
Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem
Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung des Buttons
ausloggen. Google speichert Ihre Daten als Nutzungsprofile und nutzt sie für
Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner
Website. Eine solche Auswertung erfolgt insbesondere (selbst für nicht
eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere
Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu
informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser
Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google richten müssen.<br>
10.3 Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer
Verarbeitung durch den Plug-in-Anbieter erhalten Sie in den
Datenschutzerklärungen des Anbieters. Dort erhalten Sie auch weitere
Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten
zum Schutze Ihrer Privatsphäre: http://www.google.de/intl/de/policies/privacy.
Google verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich
dem EU-US Privacy Shield unterworfen,
https://www.privacyshield.gov/EU-US-Framework.<o:p></o:p></span></p>

    <p class="MsoNormal"><o:p>&nbsp;</o:p></p>

</div>




</body></html>
import {defineComponent, onMounted, ref} from 'vue'
import {useStore} from "vuex";

import {FontMapping} from '../../../target/api/de/moovit/titletoolserver/model';
import main from "../../main";

export default defineComponent({
  components: {},
  props: ["language", "group", "mapping", "index", "dialogIsOpenOnStart"],
  setup: function (props) {

    const store = useStore();

    let fontMappingRef = ref<FontMapping>({});
    let fontMappingBackup = ref<FontMapping>({});

    let hover = ref(false);
    let dialog = ref(false);

    let didChanges = ref(false);

    let toggleDialog = (valid: boolean) => {
      if (dialog.value) {
        dialog.value = false;

        if (!valid) {
          fontMappingRef.value = Object.assign({}, fontMappingBackup.value);
          return;
        }
        fontMappingBackup.value = Object.assign({}, fontMappingRef.value);
        updateMapping();

      } else {
        fontMappingRef.value = Object.assign({}, props.mapping);
        if (!fontMappingRef.value.exceptions) {
          fontMappingRef.value.exceptions = [];
        }

        didChanges.value = false;
        dialog.value = true;
      }
    }

    let updateMapping = () => {
      main.config.globalProperties.$emitter.emit('update-mapping', {
        language: props.language,
        group: props.group,
        index: props.index,
        mapping: fontMappingRef.value
      });
    }

    let deleteFontMapping = () => {
      main.config.globalProperties.$emitter.emit('delete-mapping', {
        language: props.language,
        group: props.group,
        index: props.index
      });
    }

    let onClick = (e: any) => {
      if (e.target.id == 'roundedLabel') return;

      toggleDialog(true);
    };

    onMounted(async () => {
      fontMappingBackup.value = Object.assign({}, props.mapping);
    })

    return {
      hover,
      dialog,
      didChanges,
      fontMappingRef,
      toggleDialog,
      onClick,
      deleteFontMapping
    }
  }
})

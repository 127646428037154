import {
  defineComponent,
  onMounted,
  ref,
  getCurrentInstance
} from 'vue';
import {SystemApi, UserGroupApi} from '../../../target/api';
import {useStore} from "vuex";
import {
  CustomerMetadataTemplate,
  CustomerMetadataTemplateTypeEnum
} from "../../../target/api/de/moovit/titletoolserver/model";
import {uuid} from "vue-uuid";

export default defineComponent({
  props: [],
  components: {},
  setup: function (props) {
    const store = useStore();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    const systemApi: SystemApi = new SystemApi();
    const userGroupApi: UserGroupApi = new UserGroupApi();

    let isActive = ref<boolean>(false);
    let isTemplateActive = ref<boolean>(false);

    let customerMetadataTemplates = ref<Array<CustomerMetadataTemplate>>([]);

    let currentName = ref<string>();
    let currentGroups = ref<Array<string>>();
    let currentTypeEnum = ref<string>();
    let currentRequired = ref<boolean>(false);
    let currentNeedTranslation = ref<boolean>(false);

    let typeEnumValues = ref<string[]>([]);

    let allGroups = ref<Array<string>>([]);

    let toggleDialog = () => {
      isActive.value = !isActive.value;
    }

    let toggleTemplateDialog = () => {
      currentName.value = "";
      currentGroups.value = [];
      currentTypeEnum.value = "";
      currentRequired.value = false;
      currentNeedTranslation.value = false;

      isTemplateActive.value = !isTemplateActive.value;
    }

    let submitNewTemplate = () => {
      if (!currentName.value || !currentTypeEnum.value) {
        return;
      }

      let isNameTaken = customerMetadataTemplates.value.filter(value => {
        return value.name === currentName.value;
      });

      if (isNameTaken.length !== 0) {
        currentName.value = "";
        return;
      }

      let customerMetadataTemplate: CustomerMetadataTemplate = {
        id: uuid.v1(),
        name: currentName.value ? currentName.value : undefined,
        groupIds: currentGroups.value ? currentGroups.value : undefined,
        required: currentRequired.value,
        needTranslation: currentNeedTranslation.value,
        type: currentTypeEnum.value ? CustomerMetadataTemplateTypeEnum[currentTypeEnum.value as keyof typeof CustomerMetadataTemplateTypeEnum] : undefined,
      };

      systemApi.addCustomerMetadataTemplate(customerMetadataTemplate).then(result => {
        customerMetadataTemplates.value.push(result.data);

        currentName.value = "";
        currentGroups.value = [];
        currentRequired.value = false;
        currentNeedTranslation.value = false;
        currentTypeEnum.value = "";
        isTemplateActive.value = false;
      });
    }

    let removeTemplate = (template: CustomerMetadataTemplate) => {
      if (!template.id) {
        return
      }

      systemApi.deleteCustomerMetadataTemplate(template.id).then(result => {
        customerMetadataTemplates.value.splice(customerMetadataTemplates.value.indexOf(template), 1);
      });
    }

    let init = async () => {
      customerMetadataTemplates.value = (await systemApi.searchCustomerMetadataTemplate()).data;

      typeEnumValues.value = Object.keys(CustomerMetadataTemplateTypeEnum);

      let responseGroups = (await userGroupApi.searchUserGroups()).data;

      for (let i in responseGroups) {
        allGroups.value.push(responseGroups[i].name!);
      }
    }

    onMounted(() => {
      init();
    });

    return {
      toggleDialog,
      toggleTemplateDialog,
      submitNewTemplate,
      removeTemplate,
      customerMetadataTemplates,
      currentName,
      currentGroups,
      currentRequired,
      currentNeedTranslation,
      currentTypeEnum,
      isActive,
      isTemplateActive,
      typeEnumValues,
      allGroups
    };
  },
});

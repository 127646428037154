import {defineComponent, getCurrentInstance, onMounted, onUnmounted, ref} from 'vue';
import {onBeforeRouteLeave, useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';

export default defineComponent({
  components: {},
  setup: function () {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let assets = ref([]);
    let currentPage: any = ref(1);
    let currentLanguage = ref(undefined);
    let currentFile = ref(undefined);
    let transitionDir = ref('slide-right');
    let propsValues = ref({});

    let isDataLoaded = ref(false);

    let getCircleClass = (page: number, currentPage: number) => {
      if (page < currentPage) return ' pi-check-circle';
      if (page > currentPage) return ' pi-circle-on col-menu-color';
      if (page == currentPage) return ' pi-circle-on ';
    };

    let preventNav = (event: any) => {
      if (!isDataLoaded.value) {
        return;
      }
      event.preventDefault();
      event.returnValue = '';
    };

    let setDataLoaded = (_isDataLoaded: boolean) => {
      isDataLoaded.value = _isDataLoaded;
    };

    onMounted(() => {
      window.addEventListener('beforeunload', preventNav);
      emitter.on('set-data-loaded', (isDataLoaded: boolean) => {
        setDataLoaded(isDataLoaded);
      });

      if (route.query.page && parseInt(route.query.page + '') >= 3) {
        currentPage.value = route.query.page;
        router.push({
          query: {
            id: route.query.id,
            page: route.query.page,
          },
        });
      }

      let tAddNewAsset = {
        t: 'activityBreadcrumbs.addNewAsset',
        e: undefined,
      };
      store.commit('SET_ACTIVITY_BREADCRUMBS', [tAddNewAsset]);
    });

    onUnmounted(() => {
      window.removeEventListener('beforeunload', preventNav);
      emitter.off('set-data-loaded');
    });

    onBeforeRouteLeave((to, from, next) => {
      if (!isDataLoaded.value) {
        next();
        return;
      }

      store.commit('SHOW_CONFIRM_DIALOG', {
        dialogTitle: 'addAsset.leavePageWarningHeader',
        dialogContentT: 'addAsset.leavePageWarningDetails',
        btnConfirmText: 'general.leave',
        btnRejectText: 'general.stay',

        confirmCallback: () => {
          next();
        },
        rejectCallback: () => {
          next(false);
        },
      });
    });

    return {
      assets,
      currentPage,
      getCircleClass,
      currentLanguage,
      currentFile,
      transitionDir,
      propsValues,
    };
  },
});

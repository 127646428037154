<template>
  <div
    class="border-radius-12 col-dark-blue bor-dark-blue-60 bg-transparent"
    style="display: flex; flex-direction: column; justify-content: space-between; position: relative; padding: 0 0 0 16px"
  >
    <div
      v-if="innerModelValue"
      class="col-menu-color"
      style="font-size: 12px; position: absolute; left: 4px; top: 2px"
    >
      {{label}}
    </div>
    
    <div
      style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: space-between"
    >
      <div :class="innerModelValue ? 'col-dark-blue' : 'col-menu-color'">
        {{innerModelValue ? innerModelValue : label}}
      </div>
      <Calendar v-model="date" dateFormat="dd.mm.yy"
                style="opacity: 0; left: 0; top: 1px; cursor: pointer; position: absolute; height: 100%; width: 100%; background: red"
                @date-select="onDateSelected(); $emit('update:modelValue', innerModelValue)"
                :style="innerModelValue ? 'color: white' : 'color: black'"/>
    </div>
  </div>
</template>

<script src="./CustomDatePicker.ts"></script>

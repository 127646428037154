import {defineComponent, nextTick, onMounted, onUnmounted, ref, watch} from "vue";
import {useStore} from "vuex";

import {
  AssetItemStatus,
  AssetType,
  SearchAssetItemsObject,
  SearchAssetsObject,
  SearchUsersObject
} from '../../../target/api/de/moovit/titletoolserver/model';

import {SystemApi, AssetApi, AssetItemApi, UserApi} from '../../../target/api';
import {useI18n} from "vue-i18n";

export default defineComponent({
  components: {},
  setup: function () {
    const store = useStore();
    const i18n = useI18n();

    const systemApi: SystemApi = new SystemApi();
    const assetApi: AssetApi = new AssetApi();
    const assetItemApi: AssetItemApi = new AssetItemApi();

    let activeSheetTab = ref<number>(0);
    let windowWidth = ref<number>(0);

    const dateOptions: any = { year: 'numeric', month: 'numeric', day: 'numeric' };

    let startDate = ref<Date>();
    let endDate = ref<Date>();

    let selectedPreset = ref<string>("");
    let presets = ref(
      [i18n.t('general.day'), i18n.t('general.month'), i18n.t('general.year')]
    );

    let assetsImported = ref<boolean>(true);
    let jobsCreated = ref<boolean>(true);
    let jobsCompleted = ref<boolean>(true);
    let activeUsers = ref<boolean>(true);
    let allUsers = ref<boolean>(true);

    //Assets Imported
    let assets = ref<{
      name: string,
      id: string,
      created: string,
      jobCount: string,
      language: string,
      groups: string[]
    }[]>([]);

    let groups = ref<Map<string, number>>(new Map());

    let assetsPerWeek = ref(0);
    let assetsPerMonth = ref(0);

    //Jobs Created
    let jobs = ref<{
      id: string,
      name: string,
      created: string,
      masterAsset: string,
      masterLanguage: string,
      translator: string,
      email: string,
      costCenter: string,
      mamId: string,
      languages: string,
      status: string
    }[]>([]);

    let jobsPerWeek = ref(0);
    let jobsPerMonth = ref(0);

    //Jobs Completed
    let jobsComplete = ref<{
      id: string,
      name: string,
      status: string,
      user: string,
      groups: string[],
      costCenter: string,
      language: string,
      email: string,
      date: string,
    }[]>([]);

    let jobsCompletePerWeek = ref(0);
    let jobsCompletePerMonth = ref(0);

    //Active Users
    let totalLogins = ref(0);
    let loginPerWeek = ref(0);
    let loginPerMonth = ref(0);

    //All Users
    let users = ref<{
      id: string,
      firstName: string,
      name: string,
      email: string,
      country: string,
      costCenter: string,
      role: string,
      locked: string,
      groups: string
    }[]>([]);

    let selectedColumnsAssetsImported = ref<string[]>([]);
    let allColumnsAssetsImported = ref<string[]>([
      "general.id",
      "general.name",
      "general.created",
      "general.jobCount",
      "general.language",
      "general.groups",
    ]);

    let selectedColumnsJobsCreated = ref<string[]>([]);
    let allColumnsJobsCreated = ref<string[]>([
      "general.id",
      "general.name",
      "general.created",
      "general.masterLanguage",
      "general.translator",
      "general.mamId",
      "general.costCenter",
      "general.languages",
      "general.status",
    ]);

    let selectedColumnsJobsCompleted = ref<string[]>([]);
    let allColumnsJobsCompleted = ref<string[]>([
      "general.id",
      "general.name",
      "general.translator",
      "general.groups",
      "general.costCenter",
      "general.language",
      "general.completed",
    ]);

    let selectedColumnsUsers = ref<string[]>([]);
    let allColumnsUsers = ref<string[]>([
      "general.id",
      "general.firstName",
      "general.name",
      "general.eMail",
      "general.country",
      "general.costCenter",
      "general.role",
      "general.groups",
    ]);

    let _initAssetsImported = async () => {
      let searchAssetsObject: SearchAssetsObject = {};
      searchAssetsObject.assetType = AssetType.MASTER;
      searchAssetsObject.ascSort = false;
      searchAssetsObject.includeDeleted = true;

      searchAssetsObject.groups = [];
      searchAssetsObject.languages = [];

      if (startDate.value) {
        searchAssetsObject.startDate = getFormattedDateString(startDate.value);
      }

      if (endDate.value) {
        searchAssetsObject.endDate = getFormattedDateString(endDate.value);
      }

      let searchAssets = (await assetApi.searchAssets(searchAssetsObject)).data;

      assets.value = [];
      for (const searchAsset of searchAssets) {
        if (searchAsset) {
          assets.value.push({
            name: searchAsset.name!,
            id: searchAsset.id!,
            created: "" + searchAsset.created,
            jobCount: "" + (searchAsset.assetItems ? searchAsset.assetItems.length : "0"),
            language: searchAsset.masterAssetItem!.language!,
            groups: searchAsset.groupIds ? searchAsset.groupIds : [searchAsset.groupID!]
          });
        }
      }

      groups.value = new Map<string, number>();
      for (const asset of assets.value) {
        for (let group of asset.groups) {
          if (groups.value.has(group)) {
            let curr = groups.value.get(group)!;
            groups.value.set(group, curr + 1);
          } else {
            groups.value.set(group, 1);
          }
        }
      }
    }

    let _initJobsCreated = async () => {
      let searchAssetItemsObject: SearchAssetItemsObject = {};

      searchAssetItemsObject.groups = [];
      searchAssetItemsObject.languages = [];
      searchAssetItemsObject.translators = [];

      if (startDate.value) {
        searchAssetItemsObject.startDate = getFormattedDateString(startDate.value);
      }

      if (endDate.value) {
        searchAssetItemsObject.endDate = getFormattedDateString(endDate.value);
      }

      let searchReportAssetItems = (await assetItemApi.getReportAssetItems(searchAssetItemsObject)).data;

      jobs.value = [];
      for (const searchReportAssetItem of searchReportAssetItems) {
        if (searchReportAssetItem) {
          jobs.value.push({
            id: searchReportAssetItem.id!,
            name: searchReportAssetItem.assetName!,
            created: searchReportAssetItem.created!,
            masterAsset: searchReportAssetItem.assetID!,
            masterLanguage: searchReportAssetItem.masterLanguage!,
            translator: searchReportAssetItem.translatorID!,
            email: searchReportAssetItem.email!,
            costCenter: searchReportAssetItem.costCenter!,
            mamId: searchReportAssetItem.mamId!,
            languages: searchReportAssetItem.language!,
            status: searchReportAssetItem.status!
          });
        }
      }
    }

    let _initJobsCompleted = async () => {
      let searchAssetItemsObject: SearchAssetItemsObject = {};

      searchAssetItemsObject.groups = [];
      searchAssetItemsObject.languages = [];
      searchAssetItemsObject.translators = [];

      if (startDate.value) {
        searchAssetItemsObject.completedStart = getFormattedDateString(startDate.value);
      }

      if (endDate.value) {
        searchAssetItemsObject.completedEnd = getFormattedDateString(endDate.value);
      }

      searchAssetItemsObject.isCompletedSearch = true;

      let searchReportAssetItems = (await assetItemApi.getReportAssetItems(searchAssetItemsObject)).data;

      jobsComplete.value = [];
      for (const job of searchReportAssetItems) {
        jobsComplete.value.push({
          id: job.id!,
          name: job.assetName!,
          status: job.status!,
          user: job.translatorID!,
          groups: job.groupIds ? job.groupIds : [job.groupID!],
          costCenter: job.costCenter!,
          language: job.language!,
          email: job.email!,
          date: job.completed!,
        });
      }
    }

    let _initAllUsers = async () => {
      let userApi = new UserApi();

      let searchUsersObject: SearchUsersObject = {};
      searchUsersObject.searchText = "";

      let searchUsers = (await userApi.searchUsers(searchUsersObject)).data;

      users.value = [];
      for (const searchUser of searchUsers) {
        if (searchUser) {
          let groups = "";
          if (searchUser.groups) {
            for (const group of searchUser.groups!) {
              groups = groups + group.name + " ";
            }
          }

          users.value.push({
            id: searchUser.id!,
            firstName: searchUser.firstname!,
            name: searchUser.name!,
            email: searchUser.email!,
            country: searchUser.country!,
            costCenter: searchUser.costCenter!,
            role: searchUser.role!,
            locked: (searchUser.locked !== undefined ? searchUser.locked.toString() : ''),
            groups: groups
          });
        }
      }
    }

    let loadData = async () => {
      _initAssetsImported();
      _initJobsCreated();
      _initJobsCompleted();
      _initAllUsers();
    }

    let getDateString = (date: string): string => {
      if (date) {
        if (!isNaN(Number(date))) {
          return new Date(+date).toDateString();
        } else {
          return date;
        }
      } else {
        return "";
      }
    }

    let downloadExcel = () => {
      let ifNoStart: Date = new Date();
      ifNoStart.setFullYear(2000);

      let downloadUrl = process.env.VUE_APP_TT_ENDPOINT + "/system/report?access_token=" + JSON.parse(localStorage.loginData).access_token +
        "&start=" + (startDate.value ? getFormattedDateString(startDate.value) : "") +
        "&end=" + (endDate.value ? getFormattedDateString(endDate.value) : "") +
        "&createAssetsImportedSheet=" + true +
        "&createJobsCreatedSheet=" + true +
        "&createJobsCompletedSheet=" + true +
        "&createActiveUsersSheet=" + false +
        "&createAllUsersSheet=" + true +
        "&createJobsToApproveSheet=" + true;

      let downloadElement = document.createElement("a");
      downloadElement.href = downloadUrl + "&dl=true";
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
    }

    let getFormattedDateString = (date: string | Date) => {
      return new Date(date).toLocaleString("de-DE");
    }

    let _onResize = () => {
      windowWidth.value = window.innerWidth;
    }

    watch(selectedPreset, () => {
      let date = new Date();
      endDate.value = date;
      switch (selectedPreset.value) {
        case i18n.t('general.day'):
          let oneDayAgo = new Date(date)
          oneDayAgo.setDate(oneDayAgo.getDate() - 1);
          startDate.value = oneDayAgo;
          loadData();
          return;
        case i18n.t('general.month'):
          let oneMonthAgo = new Date(date)
          oneMonthAgo.setMonth(date.getMonth() - 1);
          startDate.value = oneMonthAgo;
          loadData();
          return;
        case i18n.t('general.year'):
          let oneYearAgo = new Date(date)
          oneYearAgo.setFullYear(date.getFullYear() - 1);
          startDate.value = oneYearAgo;
          loadData();
          return;
      }
    });

    watch(selectedColumnsAssetsImported, () => {
      localStorage.setItem(
        "selectedColumnsAssetsImported",
        JSON.stringify(selectedColumnsAssetsImported.value)
      );
    });

    watch(selectedColumnsJobsCompleted, () => {
      localStorage.setItem(
        "selectedColumnsJobsCompleted",
        JSON.stringify(selectedColumnsJobsCompleted.value)
      );
    });

    watch(selectedColumnsJobsCreated, () => {
      localStorage.setItem(
        "selectedColumnsJobsCreated",
        JSON.stringify(selectedColumnsJobsCreated.value)
      );
    });

    watch(selectedColumnsUsers, () => {
      localStorage.setItem(
        "selectedColumnsUsers",
        JSON.stringify(selectedColumnsUsers.value)
      );
    });

    onMounted(async () => {
      if (localStorage.getItem("selectedColumnsAssetsImported")) {
        selectedColumnsAssetsImported.value = JSON.parse(
          localStorage.getItem("selectedColumnsAssetsImported")!
        );
      } else {
        selectedColumnsAssetsImported.value = allColumnsAssetsImported.value;
      }

      if (localStorage.getItem("selectedColumnsJobsCompleted")) {
        selectedColumnsJobsCompleted.value = JSON.parse(
          localStorage.getItem("selectedColumnsJobsCompleted")!
        );
      } else {
        selectedColumnsJobsCompleted.value = allColumnsJobsCompleted.value;
      }

      if (localStorage.getItem("selectedColumnsJobsCreated")) {
        selectedColumnsJobsCreated.value = JSON.parse(
          localStorage.getItem("selectedColumnsJobsCreated")!
        );
      } else {
        selectedColumnsJobsCreated.value = allColumnsJobsCreated.value;
      }

      if (localStorage.getItem("selectedColumnsUsers")) {
        selectedColumnsUsers.value = JSON.parse(
          localStorage.getItem("selectedColumnsUsers")!
        );
      } else {
        selectedColumnsUsers.value = allColumnsUsers.value;
      }

      loadData();
      _onResize();
      window.addEventListener("resize", () => _onResize());
    })

    onUnmounted(() => {
      window.removeEventListener("resize", () => _onResize());
    })

    return {
      activeSheetTab,
      windowWidth,

      getDateString,
      downloadExcel,
      loadData,

      startDate,
      endDate,

      selectedPreset,
      presets,

      assetsImported,
      jobsCreated,
      jobsCompleted,
      activeUsers,
      allUsers,

      assets,
      groups,
      assetsPerWeek,
      assetsPerMonth,

      jobs,
      jobsPerWeek,
      jobsPerMonth,

      jobsComplete,
      jobsCompletePerWeek,
      jobsCompletePerMonth,

      totalLogins,
      loginPerWeek,
      loginPerMonth,

      users,

      selectedColumnsAssetsImported,
      allColumnsAssetsImported,
      selectedColumnsJobsCreated,
      allColumnsJobsCreated,
      selectedColumnsJobsCompleted,
      allColumnsJobsCompleted,
      selectedColumnsUsers,
      allColumnsUsers
    }
  }
});
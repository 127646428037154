import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import {useStore} from "vuex";
import {
  PreviewFrame,
  Progress,
  WsTopic,
} from "../../../target/api/de/moovit/titletoolserver/model";
import {AssetItemApi} from "../../../target/api";

export default defineComponent({
  components: {},
  props: ["timecode", "assetItemId", "videoLength", "hideFrames"],
  setup: function (props) {
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    const store = useStore();
    const assetItemApi = new AssetItemApi();

    let maxClientWidth = ref<number>(0);
    let innerAssetItemId = ref<string>("");
    let innerVideoLength = ref<number>(0);
    let innerTimecode = ref<number>(0);

    //Render Frame
    let previewFrames = ref<Array<PreviewFrame>>([]);

    let getPreviewFrame = (previewFrameId: string) => {
      return (
        process.env.VUE_APP_TT_ENDPOINT +
        "/asset/item/previewFrame/" +
        previewFrameId +
        "?access_token=" +
        JSON.parse(localStorage.loginData).access_token +
        "&timecode=" +
        Date.now()
      );
    };

    let _getAllPreviewFrames = async () => {
      previewFrames.value = [];
      previewFrames.value = (
        await assetItemApi.getPreviewFramesByAssetItemId(
          innerAssetItemId.value ? innerAssetItemId.value : props.assetItemId
        )
      ).data;
    };

    let _emitPreviewFrameUpdated = async (progress: Progress) => {
      if (progress.status == "COMPLETE") {
        store.commit("SHOW_SNACKBAR", {
          snackbarContent: { t: "snackbar.previewFrameCreated" },
        });
        await _getAllPreviewFrames();
      } else if (progress.status == "ERROR") {
        store.commit("SHOW_SNACKBAR", {
          snackbarType: 'error',
          snackbarContent: { t: "snackbar.previewFrameFailed" },
        });
        await _getAllPreviewFrames();
      }
    };

    let _emitAddPreviewFrame = (previewFrame: PreviewFrame) => {
      previewFrames.value.push(previewFrame);
    };

    let onChangeVideoSliderPosition = (position: number) => {
      emitter.emit(
        "onChangeVideoSliderPosition" + innerAssetItemId.value,
        position
      );
    };

    let openImage = (previewFrameId: string, time: number) => {
      emitter.emit(
        "setCurrentPreviewFrameLink",
        getPreviewFrame(previewFrameId)
      );

      let newTimeCodePosition = (time / props.videoLength) * 100;

      onChangeVideoSliderPosition(newTimeCodePosition);
      innerTimecode.value = newTimeCodePosition;
    };

    let _handleResize = () => {
      let element = document.getElementById("image-visualizer-container");

      if (element != null) {
        maxClientWidth.value = element.clientWidth - 2;
      }
    };

    watch(props, async () => {
      innerVideoLength.value = props.videoLength;
      innerTimecode.value = props.timecode;
      if (props.assetItemId !== innerAssetItemId.value) {
        await _loadEmitter();
        innerAssetItemId.value = props.assetItemId;
        await _getAllPreviewFrames();
        _handleResize();
      }
    });

    let _loadEmitter = () => {
      if (
        emitter.all &&
        emitter.all.get("wsPREVIEW_FRAME_CREATED") &&
        emitter.all.get("ws" + WsTopic.PREVIEWFRAMEUPDATED).length == 0
      ) {
        emitter.on("ws" + WsTopic.PREVIEWFRAMEUPDATED, (progress: Progress) => {
          _emitPreviewFrameUpdated(progress);
        });
      }
    };

    onMounted(() => {
      innerAssetItemId.value = props.assetItemId;

      window.addEventListener("resize", function () {
        _handleResize();
      });

      emitter.on("ws" + WsTopic.PREVIEWFRAMEUPDATED, (progress: Progress) => {
        _emitPreviewFrameUpdated(progress);
      });

      emitter.on("addPreviewFrame", (previewFrame: PreviewFrame) => {
        _emitAddPreviewFrame(previewFrame);
      });

      _getAllPreviewFrames();

      _handleResize();
    });

    onUnmounted(() => {
      emitter.off("ws" + WsTopic.PREVIEWFRAMEUPDATED);
      emitter.off("addPreviewFrame");

      window.removeEventListener("resize", function () {
        _handleResize();
      });
    });

    return {
      previewFrames,
      innerTimecode,
      maxClientWidth,
      innerVideoLength,

      getPreviewFrame,
      onChangeVideoSliderPosition,
      openImage,
    };
  },
});

import {computed, defineComponent, getCurrentInstance, onMounted, onUnmounted, ref, watch} from 'vue';
import {FieldApi} from '../../../target/api';
import {Field, FieldType} from '../../../target/api/de/moovit/titletoolserver/model';

export default defineComponent({
  components: {},
  props: ['assetId', 'videoLength', 'videoPosition', 'fieldIds', 'showTimeline', 'returnField'],
  setup: function (props) {

    const fieldApi: FieldApi = new FieldApi();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let rows = ref<Array<Array<Field>>>();

    let originMaxClientWidth = ref<number>(0);
    let maxClientWidth = ref<number>(0);
    let zoomFactor = ref<number>(0); //TODO: implement, should scroll automatically with current timecode in the middle

    let multiplicator = ref<number>(0);

    let innerVideoLength = ref<number>(0);
    let innerVideoPosition = ref<number>(0);

    let handleResize = () => {
      let element = document.getElementById(("field-visualizer-container" + props.assetId));

      if (element != null) {
        originMaxClientWidth.value = element.clientWidth - 2;
        maxClientWidth.value = element.clientWidth - 2 + zoomFactor.value;
      }

      multiplicator.value = (maxClientWidth.value / innerVideoLength.value);
    }

    let onClickField = (index: number) => {
      if (props.returnField) {
        emitter.emit('returnField', index);
      } else {
        emitter.emit('goToField' + props.assetId, index);
      }
    }

    let _updateFields = async () => {
      let fieldIdList: Array<string> = props.fieldIds;
      if (fieldIdList.length !== 0) {
        fieldApi.getFieldTimeline(fieldIdList).then(response => {
          rows.value = response.data;
        });
      } else {
        rows.value = [[]];
      }
    }

    let getFieldColor = (field: Field): string => {
      if (field.srcValue !== field.value) {
        if (field.autoTranslated) {
          return 'bg-new-light bor-new';
        } else {
          return 'bg-complete-light bor-complete';
        }
      } else {
        if (field.fieldType == FieldType.LETTERING) {
          return 'bg-readytoapprove bor-reject';
        } else {
          return 'bg-menu-color-2 bor-dark-blue-70';
        }
      }
    }

    watch(props, () => {
      innerVideoPosition.value = props.videoPosition;
      innerVideoLength.value = props.videoLength;
    });

    onMounted(() => {
      console.debug("onMounted - FieldVisualizer", props.assetId);

      emitter.on('field-visualizer-update-fields', () => {
        _updateFields()
      });

      setTimeout(() => {
        handleResize();

        innerVideoLength.value = props.videoLength;
        innerVideoPosition.value = props.videoPosition;

        _updateFields();
      }, 350);

      window.addEventListener('resize', function () {
        handleResize();
      });
    });

    onUnmounted(() => {
      emitter.off('field-visualizer-update-fields');

      window.removeEventListener('resize', function () {
        handleResize();
      });
    });

    return {
      rows,
      innerVideoLength,
      innerVideoPosition,
      multiplicator,
      maxClientWidth,
      originMaxClientWidth,
      zoomFactor,
      onClickField,
      getFieldColor
    }
  }
})

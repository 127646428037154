import {defineComponent, onMounted, ref} from "vue";

import {AssetApi, AssetItemApi, SystemApi, UserApi} from '../../../target/api';

import router from "@/router";
import {useI18n} from "vue-i18n";

export default defineComponent({
  components: {},
  setup: function () {

    const i18n = useI18n();
    const systemApi: SystemApi = new SystemApi();
    const assetApi: AssetApi = new AssetApi();
    const assetItemApi: AssetItemApi = new AssetItemApi();
    const userApi: UserApi = new UserApi();

    let test = ref();


    const init = async () => {
      if (process.env.NODE_ENV !== 'development') {
        router.push('/');
      }

      console.debug("Happy debugging!")

      test.value = (await systemApi.getDevObject()).data;
    }

    let click = async () => {
      console.debug("DEV CLICK");

    }


    onMounted(() => {
      init();
    })

    return {
      test,
      click,
      i18n
    };
  },
});

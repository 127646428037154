<template>
  <div class="mainContainer">
    <Transition name="slide-fade">
      <div
        v-if="show"
        class="fullscreenContainer"
        :class="isFullscreen ? 'fullScreen' : ''"
      >
        <div class="masterHolder">
          <div class="masterContainer">
            <RoundedLabel
              :countryFlag="
                    asset.masterAssetItem ? asset.masterAssetItem.language : ''
                  "
              :text="asset.masterAssetItem ? asset.masterAssetItem.language : ''"
              languageCode="true"
              noHover="true"
              class="bg-background"
              style="width: 100px; margin-left: 24px;"
            />
            
            <div
              style="position: relative; height: 60px; width: 100px; border-radius: 12px; overflow: hidden; cursor: pointer"
              class="bg-dark-blue hover-master-video"
              @click="onClickMasterVideo()">
              <div>
                <span
                  style="
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 50px;
                    width: 88px;
                    z-index: 2;
                    opacity: 0;
                  "
                >
                  <i
                    class="pi pi-play col-white"
                    style="transform: scale(1.3); margin: 10px 0 0 10px"
                  ></i>
                </span>
                <video
                  ref="playerVideoControlsHeader"
                  class="video-js"
                  style="position: relative; width: 100px; height: 60px"
                  :options="playerVideoOptionsHeader"
                ></video>
              </div>
              
              <pDialog
                :header="asset.name"
                v-model:visible="isMasterVideoMaximized"
                :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
                :style="{ width: '50vw' }"
                :draggable="false"
                :maximizable="true"
                :modal="true"
              >
                <video
                  ref="playerVideoControlsDialog"
                  class="video-js"
                  :options="playerVideoOptionsDialog"
                  style="
                  position: relative;
                  width: 100%;
                  height: auto;
                  background: transparent;
                "
                ></video>
                
                <template #footer>
                  <RoundedLabel
                    :text="$t('general.close')"
                    class="bg-dark-blue col-white"
                    @click="onClickMasterVideo"
                  />
                </template>
              </pDialog>
            </div>
            
            <div style="width: 40%">
              <div class="handle-font-overflow"
                   style="line-height: 20px"
                   v-tooltip="asset.name">
                {{asset.name}}
              </div>
              <div style="font-size: 12px">
                {{
                  $t("general.created") +
                  " " +
                  new Date(asset.created).toLocaleString('en-GB', {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                }}
              </div>
            </div>
            
            <div
              style="display: flex; align-items: center"
            >
              <i
                class="pi pi-star-fill"
                style="margin-right: 5px; margin-bottom: 5px"
              ></i
              >{{$t("general.master")}}
            </div>
            
            <div>
              <RoundedLabel
                leftIcon="pi pi-download"
                class="bg-background"
                :style="
                  hasPermission('job_view/download_master')
                    ? 'cursor: pointer'
                    : 'color: silver'
                "
                style="margin-left: 24px; margin-right: 24px"
                :disabled="!hasPermission('job_view/download_master')"
                v-tooltip="$t('tooltip.downloadMaster')"
                @click="downloadMaster()"
              />
            </div>
          </div>
        </div>
        
        <div class="jobHolder">
          <div class="jobContainer">
            <div v-for="(assetItem, index) in assetItems">
              <div v-if="index === currentAssetItemIndex">
                <JobItem :asset="asset"
                         :assetItem="assetItem"
                         :assetItemIndex="index"
                         :currentAssetItemIndex="currentAssetItemIndex"
                         :needsVerification="isJobMasterAssetItemVerifyJob"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style>
.mainContainer {
  display: flex;
  height: calc(100vh - 80px);
  width: calc(100vw - 80px);
  padding-left: 10px;
}

.fullscreenContainer {
  display: flex;
  flex-direction: column;
}

.masterHolder {
  display: flex;
  align-items: center;
  background-color: var(--menu-color);
  border-radius: 16px 16px 0 0;
  margin-left: 18px;
  padding: 10px;
  min-width: 700px;
  width: 60vw;
  height: 70px;
}

.masterContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.jobHolder {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0 0 8px 0 var(--shadow);
  padding: 10px;
  width: calc(100vw - 100px);
  height: calc(100vh - 160px);
}
</style>

<script src="./JobPage.ts"></script>

import {defineComponent, nextTick, onMounted, ref} from 'vue';
import {AssetItem} from '../../../target/api/de/moovit/titletoolserver/model';
import {UserGroupApi} from '../../../target/api';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import videojs from "video.js";
import {useI18n} from 'vue-i18n';

export default defineComponent({
  components: {},
  props: ['item', 'index'],
  setup: function (props) {

    const store = useStore();
    const router = useRouter();
    const i18n: any = useI18n();
    const userGroupApi = new UserGroupApi();

    let show = ref(false);

    let isGroupDeleted = ref<boolean>(false);

    let player: any = ref();
    let videoPlayerRef: any = ref(null);

    let getVideoURL = (assetItem: AssetItem) => {
      if (assetItem && assetItem.id) {
        return (
          process.env.VUE_APP_TT_ENDPOINT +
          '/videostream?access_token=' +
          JSON.parse(localStorage.loginData).access_token +
          '&id=' +
          assetItem.id +
          '&tc=' + assetItem.lastModified
        );
      } else {
        return null;
      }
    };

    let videoOptions: any = ref({
      errorDisplay: true,
      sources: [
        {
          src: getVideoURL(props.item),
          type: 'video/mp4',
        },
      ],
    });

    let getBorderColor = (jobStatus: string) => {
      if (!jobStatus) return '';
      if (jobStatus.toUpperCase() == 'APPROVED') return ' bor-complete ';
      if (jobStatus.toUpperCase() == 'REJECTED') return ' bor-reject ';
      return ' bor-transparent ';
    };

    let getOpacity = (jobStatus: string) => {
      if (!jobStatus) return '';
      if (jobStatus.toUpperCase() == 'APPROVED' || jobStatus.toUpperCase() == 'REJECTED')
        return ';opacity:0.3;';
      return ';opacity:1;';
    };

    let hasPermission = (permString: string) => {
      return JSON.parse(localStorage.loginData).user.permissions.permissions[permString];
    };

    let onClick = (e: any) => {
      console.debug('onClick', e.target.id);
      if (e.target.id == 'roundedLabel') return;
      hasPermission('approval_view/click_items/to_be_approved')
        ? router.push({
          name: 'approveJob',
          query: {id: props.item.id},
        })
        : '';
    };

    let getDaysToNow = (date: string) => {
      let oneDay: number = 24 * 60 * 60 * 1000;
      let firstDate: any = new Date(Date.now());
      let secondDate: any = new Date(date);
      let diffDays = Math.floor(Math.abs((firstDate - secondDate) / oneDay));
      return diffDays;
    };

    let getCompletedText = (date: string) => {
      let diffDays = getDaysToNow(date);
      if (diffDays > 30) return new Date(date).toDateString();
      if (diffDays == 1) return i18n.t('general.xDaysAgo', 1);
      if (diffDays == 0) return i18n.t('general.xDaysAgo', 0);
      return i18n.t('general.xDaysAgo', [diffDays], 2);
    };


    let _checkGroupExistence = async () => {
      if (props.item && props.item.groupID) {
        let result = (await userGroupApi.getUserGroupById(props.item.groupID)).data;
        isGroupDeleted.value = result == "";
      }
    }

    onMounted(() => {
      if (props.index <= 20) {
        setTimeout(() => {
          nextTick(() => {
            show.value = true;
          }).then(() => {
            player.value = videojs(videoPlayerRef.value, videoOptions.value);
            _checkGroupExistence();
          });
        }, 40 * props.index);
      } else {
        nextTick(() => {
          show.value = true;
        }).then(() => {
          player.value = videojs(videoPlayerRef.value, videoOptions.value);
          _checkGroupExistence();
        });
      }
    });

    return {
      show,
      player,
      videoPlayerRef,
      videoOptions,
      isGroupDeleted,
      getBorderColor,
      getCompletedText,
      getOpacity,
      getDaysToNow,
      hasPermission,
      onClick
    };
  },
});

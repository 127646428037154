import {defineComponent, onMounted, onUnmounted, ref} from "vue";
import {useStore} from "vuex";

import {SystemApi} from '../../../target/api';
import {SystemPreference} from '../../../target/api/de/moovit/titletoolserver/model';

export default defineComponent({
  components: {},
  setup: function () {
    const store = useStore();

    // let config = new Configuration({
    // 	basePath: process.env.VUE_APP_TT_ENDPOINT,
    // 	accessToken: store.state.access_token,
    // });
    let settingsApi = new SystemApi();

    let settings = ref<any>();

    let activeTabs = ref<string>("");

    let commitSettings = (value: SystemPreference) => {
      console.debug("value about to be saved", value);

      settingsApi.updateSystemPreference(value);

      if (value.id === "experimentalFeatures") {
        store.commit('SET_EXPERIMENTAL_FEATURES', value.value);
      }

      store.commit('SHOW_SNACKBAR', {
        snackbarContent: {t: 'snackbar.settingsSaved'},
      });
      // for (const valueKey in settings.value) {
      // 	if (settings.value[valueKey].id == objectString) {
      // 		if (typeof settings.value[valueKey].value == 'boolean') {
      // 			settings.value[valueKey].value = !settings.value[valueKey].value;
      // 		}
      // 		console.debug(settings.value[valueKey]);
      // 		settingsApi.updateSystemPreference(settings.value[valueKey]);
      // 		return;
      // 	}
      // }
    }

    onMounted(async () => {
      settings.value = (await settingsApi.getListSystemPreferences()).data;

      console.debug("Settings", settings.value);

      let i = 0;
      for (const set in settings.value) {
        activeTabs.value = activeTabs.value + i + ","
        i++;
      }
    })

    onUnmounted(() => {
      store.commit('SET_SETTINGS', settings.value);
    })

    return {
      settings,
      activeTabs,
      commitSettings,
    }
  }
});
<template>
  <div>
    <div
      v-if="isLoading"
      style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center"
    >
      <ProgressSpinner/>
    </div>
    <div v-if="!isLoading">
      <Header v-if="($route.path !== '/' && $route.path !== '/setupUser')"/>
      <div style="display: flex; margin-top: 12px">
        <Sidebar v-if="($route.path !== '/' && $route.path !== '/uploader' && $route.path !== '/setupUser')"/>
        <div style="
                  width: 100%;
                  height: 100%;
                  padding: 10px 0 0 0;">
          <router-view/>
        </div>
      </div>
      <Dialog/>
      <AddFilterDialog v-if="$route.path !== '/'"/>
      <Snackbar/>
      
      <Toast/>
      <Toast position="top-right" group="info"/>
      <Toast position="top-center" group="callback">
        <template #message="slotProps">
          <div style="width: 100%; display: flex; flex-direction: column; align-items: center; padding-left: 25px">
            <i class="pi pi-info-circle" style="font-size: 3rem"></i>
            <h2>{{slotProps.message.summary}}</h2>
            <div>{{slotProps.message.detail}}</div>
            
            <Button style="margin: 20px 0 10px 0; padding: 10px 20px 10px 20px" class="border-radius-8"
                    :class="'p-button-' + $store.state.snackbar.type"
                    :label="$store.state.snackbar.btnText"
                    @click="
                        $store.state.snackbar.btnCallback ? $store.state.snackbar.btnCallback() : '';
                        this.$toast.removeGroup('callback')"
            />
          </div>
        </template>
      </Toast>
    </div>
  </div>
</template>

<style src="./tailwind.css"></style>

<script src="./App.ts"></script>

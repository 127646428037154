import {getCurrentInstance, onMounted, onUnmounted, ref, watch} from 'vue';

import * as dmr from "dom-mediacapture-record";

export function useMicrophone() {
  const app: any = getCurrentInstance();
  const emitter: any = app.appContext.config.globalProperties.$emitter;

  const microphoneStream = ref<MediaStream>();
  const microphone = ref<MediaDeviceInfo>();
  const microphones = ref<MediaDeviceInfo[]>([]);

  let mediaRecorder = ref<MediaRecorder>();
  let audioChunks = ref<Array<any>>([]);
  let audioBlob = ref<Blob>();

  let audiosBlobs = ref<Blob[]>([]);

  async function handler() {
    await navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      microphoneStream.value = stream;
    });
    navigator.mediaDevices.enumerateDevices().then(devices => {
      microphones.value = devices.filter(device => device.kind === 'audioinput');

      if (microphones.value.length > 0) {
        microphone.value = microphones.value[0];
      }
    });
  }

  let stopStream = () => {
    if (microphoneStream.value) {
      microphoneStream.value.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  };

  let startMicrophoneRecording = () => {
    if (microphoneStream.value && microphoneStream.value.active) {
      mediaRecorder.value = new MediaRecorder(microphoneStream.value);

      if (mediaRecorder.value && microphone.value) {
        console.log("New Input device selected and setup:", microphone.value.label, mediaRecorder.value.stream);

        if (mediaRecorder.value) {
          mediaRecorder.value.removeEventListener("dataavailable", (event: any) => _dataAvailableListener(event));
          mediaRecorder.value.removeEventListener("stop", () => _stopListener());
          mediaRecorder.value.addEventListener("dataavailable", (event: any) => _dataAvailableListener(event));
          mediaRecorder.value.addEventListener("stop", () => _stopListener());
        }
      }

      mediaRecorder.value.start();
    } else {
      console.log("Microphone Stream is inactive");
    }
  }

  let stopMicrophoneRecording = () => {
    if (mediaRecorder.value) {
      mediaRecorder.value.stop();
    }
  }

  watch(microphone, async () => {
    if (microphone.value) {
      const config = {
        audio: { deviceId: microphone.value.deviceId ? { exact: microphone.value.deviceId } : undefined },
        video: false,
      };

      await navigator.mediaDevices.getUserMedia(config).then(stream => {
        microphoneStream.value = stream;
      });
    }
  });

  const _dataAvailableListener = (event: any) => {
    audioChunks.value.push(event.data);
  }
  const _stopListener = () => {
    audioBlob.value = new Blob(audioChunks.value);
    const audioUrl = URL.createObjectURL(audioBlob.value);

    const audio = new Audio(audioUrl);

    console.log("audio result", audio);

    audiosBlobs.value.push(audioBlob.value);

    audioChunks.value = [];

    emitter.emit("audio-recorder-finished");
  }

  onMounted(() => {
    if (navigator && navigator.mediaDevices) {
      navigator.mediaDevices.addEventListener('devicechange', handler);
    }
  });

  onUnmounted(() => {
    if (navigator && navigator.mediaDevices) {
      navigator.mediaDevices.removeEventListener('devicechange', handler);
    }

    if (mediaRecorder.value) {
      mediaRecorder.value.removeEventListener("dataavailable", (event: any) => _dataAvailableListener(event));
      mediaRecorder.value.removeEventListener("stop", () => _stopListener());
    }
  });

  return {
    microphoneStream,
    microphone,
    microphones,

    mediaRecorder,
    audiosBlobs,

    handler,

    stopStream,
    startMicrophoneRecording,
    stopMicrophoneRecording
  }
}

// tslint:disable
/**
 * titletool-nextgen-server
 * TitleTool NextGen Server
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: r.hutter@moovit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldType {
    CONTINUOUS = 'CONTINUOUS',
    LOGO = 'LOGO',
    PICTOGRAM = 'PICTOGRAM',
    LETTERING = 'LETTERING',
    VOICEOVER = 'VOICEOVER',
    CLEAN = 'CLEAN',
    NONE = 'NONE'
}




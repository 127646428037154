<template>
  <div @click="toggleDialog()" class="hover-icon transitions" style="cursor: pointer; padding: 5px">
    Click to extend
  </div>
  
  <pDialog v-model:visible="isActive" header="Customer Metadata" style="width: 80%">
    <div style="display: flex; flex-direction: column">
      <div @click="toggleTemplateDialog()"
           class="hover-master-video border-radius-16 transitions bg-dark-blue col-white"
           style="cursor: pointer; margin: 5px; padding: 5px; width: 100px">
        Click to add
      </div>
      
      <div style="margin-top: 15px; padding-top: 15px; border-top: black 1px solid">
        <div style="display: flex; margin-left: 50px; align-items: center; justify-content: space-around">
          <div style="width: 150px">
            Name
          </div>
          <div style="width: 200px">
            Id
          </div>
          <div style="width: 180px">
            ModelId
          </div>
          <div style="width: 150px">
            Consolidate
          </div>
          <div style="width: 150px">
            Locale
          </div>
          <div style="width: 150px">
            Gender
          </div>
          <div style="width: 150px">
            Stability
          </div>
          <div style="width: 150px">
            Similarity Boost
          </div>
          <div style="width: 150px">
            Style
          </div>
          <div style="width: 150px">
            Use Speaker Boost
          </div>
        </div>
        <div v-for="voice in value.value"
             style="height: 55px; margin: 15px; display: flex; align-items: center; justify-content: space-around"
             class="bg-menu-color-2 box-shadow-2 border-radius-24">
          <div style="width: 150px" class="flex-center">
            {{voice.name}}
          </div>
          <div style="width: 200px" class="flex-center">
            {{voice.uniqueId}}
          </div>
          <div style="width: 180px" class="flex-center">
            {{voice.modelId}}
          </div>
          <div style="width: 150px" class="flex-center">
            {{voice.consolidate}}
          </div>
          <div style="width: 150px" class="flex-center">
            {{voice.locale}}
          </div>
          <div style="width: 150px" class="flex-center">
            {{voice.gender}}
          </div>
          <div style="width: 150px" class="flex-center">
            {{voice.stability}}
          </div>
          <div style="width: 150px" class="flex-center">
            {{voice.similarity_boost}}
          </div>
          <div style="width: 150px" class="flex-center">
            {{voice.style}}
          </div>
          <div style="width: 150px" class="flex-center">
            {{voice.use_speaker_boost}}
          </div>
          
          
          <i class="pi pi-cog hover-icon"
             @click="editVoice(voice)"
             style="margin-left: 10px; padding: 5px"></i>
          <i class="pi pi-trash hover-icon"
             @click="removeVoice(voice)"
             style="margin-left: 10px; padding: 5px"></i>
        </div>
      </div>
    
    </div>
  </pDialog>
  
  
  <pDialog v-model:visible="isTemplateActive" header="Add Customer Metadata" style="width: 30%">
    <div class="flex-center" style="flex-direction: column; gap: 15px; margin: 20px 50px 0 50px">
      <div>
        Name*
        <CustomInputText v-model:modelValue="currentName" label="Name*"
                         class="grid-item m-5 bor-dark-blue-60 border-radius-12"></CustomInputText>
      </div>
      <div>
        Id*
        <CustomInputText v-model:modelValue="currentId" label="Id*"
                         class="grid-item m-5 bor-dark-blue-60 border-radius-12"></CustomInputText>
      </div>
      <div>
        Model Id
        <CustomInputText v-model:modelValue="currentModelId" label="Model Id*"
                         class="grid-item m-5 bor-dark-blue-60 border-radius-12"></CustomInputText>
      </div>
      <div>
        Consolidate
        <Switch v-model:modelValue="currentConsolidate" label="Consolidate"
                         class="grid-item m-5 bor-dark-blue-60 border-radius-12"></Switch>
      </div>
      <div>
        Locale
        <CustomInputText v-model:modelValue="currentLocale" label="Locale"
                         class="grid-item m-5 bor-dark-blue-60 border-radius-12"></CustomInputText>
      </div>
      <div>
        Gender
        <CustomInputText v-model:modelValue="currentGender" label="Gender"
                         class="grid-item m-5 bor-dark-blue-60 border-radius-12"></CustomInputText>
      </div>
      <div style="display: flex; flex-direction: column">
        Stability
        <InputNumber v-model="currentStability"
                     showButtons
                     buttonLayout="horizontal"
                     :step="0.1"
                     decrementButtonClass="p-button-danger"
                     incrementButtonClass="p-button-success"
                     incrementButtonIcon="pi pi-plus"
                     decrementButtonIcon="pi pi-minus"
                     :useGrouping="false"
                     mode="decimal"></InputNumber>
      </div>
      <div style="display: flex; flex-direction: column">
        Similarity Boost
        <InputNumber v-model="currentSimilarityBoost"
                     showButtons
                     buttonLayout="horizontal"
                     :step="0.1"
                     decrementButtonClass="p-button-danger"
                     incrementButtonClass="p-button-success"
                     incrementButtonIcon="pi pi-plus"
                     decrementButtonIcon="pi pi-minus"
                     :useGrouping="false"
                     mode="decimal"></InputNumber>
      </div>
      <div style="display: flex; flex-direction: column">
        Style
        <InputNumber v-model="currentStyle"
                     showButtons
                     buttonLayout="horizontal"
                     :step="0.1"
                     decrementButtonClass="p-button-danger"
                     incrementButtonClass="p-button-success"
                     incrementButtonIcon="pi pi-plus"
                     decrementButtonIcon="pi pi-minus"
                     :useGrouping="false"
                     mode="decimal"></InputNumber>
      </div>
      <div>
        Use Speaker Boost
        <Switch v-model:modelValue="currentUseSpeakerBoost"
                class="grid-item m-5 bor-dark-blue-60 border-radius-12"></Switch>
      </div>
      
      <RoundedLabel
        @click="submitNewVoice()"
        :text="$t('general.submit')"
        class="bg-dark-blue col-white bor-transparent"
        style="cursor: pointer; width: 256px; height: 50px; margin-top: 50px; border-radius: 8px"
      />
    
    </div>
  </pDialog>
</template>

<script src="./VoiceOverSettingsDialog.ts"></script>

<template>
  <div style="display: flex; justify-content: center">
    <UploaderSetup
      class="site"
      v-if="currentPage === 1 || currentPage === 2"
      v-model:currentPage="currentPage"
      v-model:currentFile="currentFile"
      v-model:transitionDir="transitionDir"
      v-model:propsValues="propsValues"/>
    
    <UploaderFieldPreview
      class="site"
      v-if="currentPage === 3"
      v-model:currentPage="currentPage"
      v-model:currentFile="currentFile"
      v-model:transitionDir="transitionDir"
      v-model:propsValues="propsValues"/>
    
    <UploaderVideoPreview
      class="site"
      v-if="currentPage === 4"
      v-model:currentPage="currentPage"
      v-model:currentFile="currentFile"
      v-model:transitionDir="transitionDir"
      v-model:propsValues="propsValues"/>
  </div>
</template>

<style>
.site {
  position: absolute;
}
</style>

<script src="./Uploader.ts"></script>
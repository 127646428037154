<template>
  <transition-group name="pop-down">
    <div
      v-if="$store.state.snackbarVisible"
      style="position:fixed; top:20px; left: 30%; width:40%; display:flex; justify-content:center"
      id="snackbar"
    >
      <div
        class="border-radius-8 bor-complete bg-complete-light"
        style="width:100%; display:flex; justify-content:space-between; padding:10px"
      >
        <div></div>
        <div>{{$t($store.state.snackbar.content.t, $store.state.snackbar.content.e)}}</div>
        <div v-if="$store.state.snackbar.btnText"></div>
        <u
          @click="$store.state.snackbar.btnCallback ? $store.state.snackbar.btnCallback() : ''"
          v-if="$store.state.snackbar.btnText"
          style="margin-left:24px; cursor:pointer;"
        >
          {{$t($store.state.snackbar.btnText)}}
        </u>
        <div></div>
      </div>
    </div>
  </transition-group>
</template>

<script src="./Snackbar.ts"></script>

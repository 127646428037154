import {defineComponent, onMounted, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {SystemApi} from '../../../target/api'

export default defineComponent({
  components: {},
  props: undefined,
  setup: function () {

    const systemApi: SystemApi = new SystemApi();
    const i18n: any = useI18n();

    let version = ref<string>("");

    let getJobsText = () => {
      return i18n.t('general.job', 2);
    };

    let getVersion = async () => {
      version.value = (await systemApi.getVersion()).data;
    }

    let hasPermission = (permString: string) => {
      if (localStorage.loginData)
        return JSON.parse(localStorage.loginData).user.permissions.permissions[permString];
    };

    onMounted(() => {
      getVersion();
    });

    return {hasPermission, getJobsText, version};
  },
});

<template>
  <div
    class="m-5 grid-item bor-dark-blue-60 border-radius-12"
    :class="isInvalid ? 'bor-reject' : ''"
    style="display: flex; flex-direction: column; justify-content: space-between; position: relative"
  >
    <div
      v-if="innerMetadata"
      class="col-menu-color"
      style="font-size: 12px; position: absolute; left: 10px; top: 3px"
    >
      {{(innerMetadata.name + (innerMetadata.required ? "*" : ""))}}
    </div>
    
    <div v-if="metadata.type === 'STRING'" class="flex-center">
			<Textarea v-if="bigString"
                v-model:modelValue="metadata.typeValueString"
                :autoResize="false"
                style="width: 100%; border: none"
                :style="' height: ' + (bigStringHeight ? bigStringHeight : '147px')"
                @change="onChangeMetadata(metadata)"/>
      <CustomInputText v-else
                       v-model:modelValue="metadata.typeValueString"
                       style="height: 50px; width: 100%; border: none"
                       @change="onChangeMetadata(metadata)"/>
    </div>
    <div v-else-if="metadata.type === 'BOOLEAN'" class="flex-center" style="height: 50px">
      <Switch v-model="metadata.typeValueBoolean"
              @click="onChangeMetadata(metadata)"/>
    </div>
    <div v-else-if="metadata.type === 'DOUBLE'">
      <InputNumber v-model="metadata.typeValueDouble"
                   showButtons
                   inputStyle="width: 50px; height: 48px; border: none; border-radius: 12px"
                   :step="0.1"
                   incrementButtonClass="inputnumber-up"
                   decrementButtonClass="inputnumber-down"
                   :useGrouping="false"
                   mode="decimal"
                   style="width: 100%"
                   @blur="onChangeMetadata(metadata)"/>
    </div>
    <div v-else-if="metadata.type === 'INTEGER'">
      <InputNumber v-model="metadata.typeValueInteger"
                   showButtons
                   inputStyle="width: 50px; height: 48px; border: none; border-radius: 12px"
                   :step="1"
                   incrementButtonClass="inputnumber-up"
                   decrementButtonClass="inputnumber-down"
                   :useGrouping="false"
                   mode="decimal"
                   style="width: 100%"
                   @blur="onChangeMetadata(metadata)"/>
    </div>
  
  
  </div>
</template>

<style scoped>

</style>

<script src="./CustomerMetadataInput.ts"></script>

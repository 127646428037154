import {defineComponent, onMounted, ref, watch} from 'vue';
import {UserGroupApi} from '../../../target/api';
import {useStore} from "vuex";
import {CustomerMetadata} from "../../../target/api/de/moovit/titletoolserver/model";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
  components: {},
  props: ['items', 'maxWidth'],
  setup: function (props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const userGroupApi = new UserGroupApi()

    let blacklistGroups = ref<string[]>([]);

    let queryItems = ref<Map<string, string>>();

    let allowedFilters = ref<Array<string>>(["TRANSLATOR", "LANGUAGE", "GROUP", "CUSTOM", "STATUS", "COSTCENTER", "METADATA"]);

    watch(props.items, (items) => {
      console.debug("items", items);
      blacklistGroups.value = [];
      queryItems.value = new Map<string, string>();

      for (let key in items) {
        let item = items[key];

        if (item.type == 'GROUP') {
          if (!item.name.includes('Deleted')) {
            isGroupDeleted(key, item);
          }
        }

        putItemToQuery(queryItems, item);
      }

      router.push({
        name: router.currentRoute.value.name!,
        query: Object.fromEntries(queryItems.value.entries())
      });
    });

    let putItemToQuery = (queryItems: any, item: any) => {
      if (queryItems.value.get(item.type)) {
        let strings = queryItems.value.get(item.type)!.split(",");

        if (!strings.includes(item.name)) {
          queryItems.value.set(item.type, queryItems.value.get(item.type) + "," + item.name);
        }
      } else {
        queryItems.value.set(item.type, item.name);
      }
    }

    let isGroupDeleted = async (index: any, item: any) => {
      console.debug(index, item)
      let result = (await userGroupApi.getUserGroupById(item.name)).data;
      if (result == "") {
        blacklistGroups.value.push(item.name);
      }
    }

    let getLanguageFlag = (name: string): string => {
      let language = store.state.languages
        .filter((language: { name: string, value: string }) => language.name === name);
      if (language.length > 0) {
        return language[0].value;
      } else {
        return ""
      }
    }

    let getCustomerMetadataValue = (metadata: CustomerMetadata): string => {
      let result = "";

      switch (metadata.type) {
        case "BOOLEAN":
          result = "" + (metadata.typeValueBoolean ? "true" : "false");
          break;
        case "STRING":
          result = "" + metadata.typeValueString;
          break;
        case "INTEGER":
          result = "" + metadata.typeValueInteger;
          break;
        case "DOUBLE":
          result = "" + metadata.typeValueDouble;
          break;
      }

      console.log("result", result)

      return result !== "undefined" ? result : "No Value";
    }

    onMounted(() => {
      let params = new Map(Object.entries(route.query));

      for (let param of params) {
        console.log("param", param)
        let key = param[0];
        let items = ("" + param[1]).split(",");

        for (let item of items) {
          if (allowedFilters.value.includes(key)) {
            store.commit("ADD_FILTER_BREADCRUMB", { type: key, name: item });
          }
        }
      }

    });

    return {
      blacklistGroups,
      getLanguageFlag,
      getCustomerMetadataValue
    };
  },
});

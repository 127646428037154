import {useStore} from "vuex";
import axios from "axios";
import {uuid} from "vue-uuid";
import main from "../../main";
import {
  Asset,
  AssetItem,
  CustomerMetadata,
  Progress,
  ProgressStatus,
  UpdateAssetObject,
  WsTopic,
} from "../../../target/api/de/moovit/titletoolserver/model";
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import {AssetApi, AssetItemApi, UserGroupApi} from "../../../target/api";
import {useRoute, useRouter} from "vue-router";
import {SystemApi} from "../../../target/api";
import Resumable from "resumablejs";

export default defineComponent({
  components: {},
  props: ["currentPage", "currentFile", "transitionDir", "id", "propsValues"],
  setup: function (props: any, context) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const fileInput: any = ref(null);
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    const assetApi: AssetApi = new AssetApi();
    const assetItemApi: AssetItemApi = new AssetItemApi();
    const systemApi: SystemApi = new SystemApi();
    const userGroupApi: UserGroupApi = new UserGroupApi();

    let currentId = ref<string>("");
    let currentProjectName = ref<string>("");
    let currentLanguage = ref<{ name: string; value: string }>({
      name: "",
      value: "",
    });
    let currentGroups: any = ref<Array<string>>([]);
    let allGroups: any = ref<Array<string>>([]);

    let currentAsset = ref<Asset>({});

    let message = ref<string>("");
    let completedMessage = ref<string>("");
    let allStagesDone = ref<boolean>(false);
    let isMobile = ref<boolean>(false);
    let error = ref<boolean>(false);
    let currentStage = ref<number>(0);
    let currentProgress = ref<number>(0);
    let currentProgress0 = ref<number>(0);
    let currentProgress1 = ref<number>(0);
    let currentProgress2 = ref<number>(0);
    let currentProgress3 = ref<number>(0);
    let currentStatus0 = ref<ProgressStatus>();
    let currentStatus1 = ref<ProgressStatus>();
    let currentStatus2 = ref<ProgressStatus>();
    let currentStatus3 = ref<ProgressStatus>();
    let currentAssetId = ref<string | undefined>(undefined);

    let customerMetadata = ref<Array<CustomerMetadata>>();

    let onFilePicked = (event: any) => {
      console.debug("onFilePicked()", event.target.files[0]);
      context.emit("update:currentFile", event.target.files[0]);
      emitter.emit("set-data-loaded", true);
    };

    let dragOverFunc = (e: any) => {
      e.preventDefault();
    };
    let getId = () => {
      return currentAssetId.value;
    };

    let submit = async () => {
      // let formData: FormData = new FormData();
      let id: string = uuid.v1();
      console.debug("init with id " + id);
      currentAssetId.value = id;
      context.emit("update:currentPage", props.currentPage + 1);

      router.push({ query: { id: id } });

      // formData.append("file", props.currentFile);
      let url =
        `${process.env.VUE_APP_TT_ENDPOINT}/upload?task=assetupload&id=${id}&sessionId=${store.state.sessionId}&access_token=${JSON.parse(localStorage.loginData).access_token}`;
      currentProjectName.value = props.currentFile
        ? props.currentFile.name.replace(/\.[^/.]+$/, "")
        : "";

      main.config.globalProperties.$emitter.on(
        "ws" + WsTopic.INGESTPROGRESS,
        (progress: Progress) => {
          var id = getId();
          if (
            (progress.assetItemId && id === progress.assetItemId) ||
            (progress.assetId && id === progress.assetId)
          ) {
            if (progress.status === ProgressStatus.ERROR) {
              if (!allStagesDone.value) {
                if (progress.message) {
                  message.value = progress.message;
                }
                currentProgress0.value = 100;
                currentProgress1.value = 100;
                currentProgress2.value = 100;
                currentProgress3.value = 100;
                currentStage.value = 4;
                error.value = true;
              }
            } else {
              let percent = progress.percent as number;

              if (percent) {
                if (progress.stage != null) {
                  currentStage.value = progress.stage;
                  switch (progress.stage) {
                    case 0:
                      currentProgress0.value = percent;
                      currentStatus0.value = progress.status;
                      currentProgress1.value = 0;
                      currentProgress2.value = 0;
                      currentProgress3.value = 0;
                      message.value = progress.message ? progress.message : "";
                      break;
                    case 1:
                      currentProgress0.value = 100;
                      currentProgress1.value = percent;
                      currentStatus1.value = progress.status;
                      currentProgress2.value = 0;
                      currentProgress3.value = 0;
                      message.value = progress.message ? progress.message : "";
                      break;
                    case 2:
                      currentProgress0.value = 100;
                      currentProgress1.value = 100;
                      currentProgress2.value = percent;
                      currentStatus2.value = progress.status;
                      currentProgress3.value = 0;
                      message.value = progress.message ? progress.message : "";
                      break;
                    case 3:
                      currentProgress0.value = 100;
                      currentProgress1.value = 100;
                      currentProgress2.value = 100;
                      currentProgress3.value = percent;
                      currentStatus3.value = progress.status;

                      if (progress.status == "COMPLETE") {
                        allStagesDone.value = true;
                        message.value = progress.message
                          ? progress.message
                          : "";
                      } else {
                        message.value = "";
                      }
                      break;
                  }
                }
                currentProgress.value = percent;
              }
              // console.debug('message ' + progress.message)

              message.value = progress.message ? progress.message : "";
            }
          } else {
            console.debug(progress.assetId + " != " + id);
            console.debug(progress);
          }
        }
      );

      // TODO: use generated api instead
      // SOLUTION?:
      // let response = await assetApi.uploadAsset(formData);
      var r = new Resumable({
        target: url,
        testChunks: false,
        method: "octet"
      });
      r.on("fileAdded", (file, event) => {
        console.log("this.files", file);
        console.log("this.event", event);
        r.upload();

        // keep a list of files with some extra data that we can use as props
      });
      r.on("fileError", (file, message) => {
        console.log("this.fileError", message);
      });
      r.on("error", (message, file) => {
        console.log("this.error", message);
      });
      r.on("uploadStart", () => {
        console.log("start.......");
      });
      r.addFile(props.currentFile);
      console.log(r.getSize());
      // axios
      //   .post(url, formData, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   })
      //   .then(function () {
      //     console.debug("SUCCESS!!");
      //   })
      //   .catch(function () {
      //     console.debug("FAILURE!!");
      //   });
    };

    let dropFunc = (e: any) => {
      e.preventDefault();
      let files = e.dataTransfer.files;
      if (files.length > 0) {
        let file = files[0];
        if (file.name.endsWith(".zip") || file.name.endsWith(".mp4")) {
          context.emit("update:currentFile", file);
        } else {
          store.commit("SHOW_INFO_DIALOG", {
            dialogTitle: "general.error",
            dialogContent: { t: "dialog.collectedFileMustBeZip" },
          });
        }
      }
    };

    let getCircleClass = (page: number, currentPage: number) => {
      if (page < currentPage) return " pi-check-circle";
      if (page > currentPage) return " pi-circle-on col-menu-color";
      if (page == currentPage) return " pi-circle-on ";
    };

    let resize = () => {
      // console.debug(window.innerWidth);
      isMobile.value = window.innerWidth < 1100;
    };

    let onClickCancel = async () => {
      if (currentAssetId.value) {
        assetApi
          .cancelAssetUpload(currentAssetId.value)
          .then((response) => {
            resetView();
          })
          .catch((err) => {
            console.debug(err);
          });
      } else {
        resetView();
      }
    };

    let resetView = () => {
      console.debug("RESET VIEW");
      currentAssetId.value = undefined;
      message.value = "";
      allStagesDone.value = false;
      currentProjectName.value = "";
      context.emit("update:currentPage", 1);
      context.emit("update:currentFile", undefined);
      context.emit("update:currentLanguage", undefined);
      context.emit("update:assets", []);
      context.emit("update:propsValues", {});
      currentId.value = "";
      currentProjectName.value = "";
      currentLanguage.value = { name: "", value: "" };
      currentGroups.value = [];
      currentAsset.value = {};
      currentProgress0.value = 0;
      currentProgress1.value = 0;
      currentProgress2.value = 0;
      currentProgress3.value = 0;
      error.value = false;

      emitter.emit("set-data-loaded", false);
      router.push({
        query: {},
      });
    };

    let onClickNext = async () => {
      context.emit("update:transitionDir", "slide-right");

      context.emit("update:propsValues", {
        currentId: currentId.value,
        currentProjectName: currentProjectName.value,
        currentLanguage: currentLanguage.value,
        currentGroups: currentGroups.value,
        customerMetadata: customerMetadata.value,
      });

      currentAsset.value.id = currentAssetId.value;
      currentAsset.value.name = currentProjectName.value;
      currentAsset.value.groupIds = currentGroups.value;

      if (!currentAsset.value.masterAssetItem) {
        currentAsset.value.masterAssetItem = {};
      }
      currentAsset.value.masterAssetItem.id = currentAssetId.value;

      if (
        currentLanguage.value &&
        currentLanguage.value.value &&
        currentLanguage.value.name
      ) {
        currentAsset.value.masterAssetItem.language =
          currentLanguage.value.name;
      }

      if (currentId.value) {
        currentAsset.value.thirdPartyID = currentId.value;
      }

      currentAsset.value.userID = JSON.parse(localStorage.loginData).user.id;

      console.debug("new asset", currentAsset.value);
      let updateAssetObject: UpdateAssetObject = {};
      updateAssetObject.asset = currentAsset.value;

      await assetApi.updateAsset(updateAssetObject);

      let assetItem: AssetItem = (
        await assetItemApi.getAssetItemById(currentAsset.value.id!)
      ).data;

      if (
        currentLanguage.value &&
        currentLanguage.value.value &&
        currentLanguage.value.name
      ) {
        assetItem.language = currentLanguage.value.name;
      }
      assetItem.assetName = currentProjectName.value;

      await assetItemApi.updateAssetItemValues(assetItem);

      setTimeout(async () => {
        if (
          currentProjectName.value &&
          currentLanguage.value &&
          currentGroups.value &&
          currentGroups.value.length > 0 &&
          !checkCustomerMetadataRequired()
        ) {
          let applier = 2;

          if (assetItem.fields!.length === 0) {
            applier = 3;
          }

          await router.push({
            query: {
              id: currentAssetId.value,
              page: props.currentPage + applier,
            },
          });

          context.emit("update:currentPage", props.currentPage + applier);
        } else {
          await router.push({
            query: { id: currentAssetId.value, page: props.currentPage + 1 },
          });

          context.emit("update:currentPage", props.currentPage + 1);
        }
      }, 200);
    };

    let checkCustomerMetadataRequired = () => {
      if (!customerMetadata.value) {
        return true;
      }
      for (const metadata of customerMetadata.value) {
        if (metadata.required) {
          switch (metadata.type) {
            case "STRING":
              if (metadata.typeValueString === undefined) {
                return true;
              }
              break;
            case "INTEGER":
              if (metadata.typeValueInteger === undefined) {
                return true;
              }
              break;
            case "DOUBLE":
              if (metadata.typeValueDouble === undefined) {
                return true;
              }
              break;
            case "BOOLEAN":
              if (metadata.typeValueBoolean === undefined) {
                return true;
              }
              break;
          }
        }
      }
      return false;
    };

    watch(currentGroups, async () => {
      customerMetadata.value = (
        await systemApi.searchCustomerMetadataTemplate(
          "",
          currentGroups.value && currentGroups.value.length > 0 ? currentGroups.value : [_getLocalUserGroup()]
        )
      ).data;

      for (const metadata of customerMetadata.value) {
        metadata.id = uuid.v1();
      }
    });

    let _getLocalUserGroup = (): string => {
      let localUserGroup = "";
      if (
        JSON.parse(localStorage.loginData) &&
        JSON.parse(localStorage.loginData).user &&
        JSON.parse(localStorage.loginData).user.groups &&
        JSON.parse(localStorage.loginData).user.groups[0] &&
        JSON.parse(localStorage.loginData).user.groups[0].id
      ) {
        localUserGroup = JSON.parse(localStorage.loginData).user.groups[0].id;
      }

      return localUserGroup;
    };

    let init = async () => {
      if (props.propsValues && props.propsValues.customerMetadata) {
        customerMetadata.value = props.propsValues.customerMetadata;
      } else {
        customerMetadata.value = (
          await systemApi.searchCustomerMetadataTemplate(
            "",
            currentGroups.value && currentGroups.value.length > 0 ? currentGroups.value : [_getLocalUserGroup()]
          )
        ).data;

        for (const metadata of customerMetadata.value) {
          metadata.id = uuid.v1();
        }
      }

      userGroupApi.searchUserGroups().then(response => {
        if (response.data) {
          allGroups.value = response.data.map(value => {
            return value.id;
          });
        }
      });
    };

    onMounted(() => {
      router.push({ query: { id: undefined } });
      document.addEventListener("dragover", dragOverFunc);
      document.addEventListener("drop", dropFunc);

      window.addEventListener("resize", resize);

      init();
    });

    onUnmounted(() => {
      document.removeEventListener("dragover", dragOverFunc);
      document.removeEventListener("drop", dropFunc);
      window.removeEventListener("resize", resize);
    });

    return {
      getCircleClass,
      fileInput,
      onFilePicked,
      onClickNext,
      onClickCancel,
      currentId,
      currentProjectName,
      currentLanguage,
      currentGroups,
      allGroups,
      submit,
      currentStage,
      currentProgress,
      currentProgress0,
      currentProgress1,
      currentProgress2,
      currentProgress3,
      currentStatus0,
      currentStatus1,
      currentStatus2,
      currentStatus3,
      allStagesDone,
      message,
      error,
      completedMessage,
      currentAssetId,
      isMobile,
      customerMetadata,
    };
  },
});

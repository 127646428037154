import {defineComponent, nextTick, onMounted, ref} from 'vue';
import {Asset, AssetStatus, WsTopic, Progress} from '../../../target/api/de/moovit/titletoolserver/model';
import {UserGroupApi, AssetItemApi, AssetApi} from '../../../target/api';
import main from '../../main';
import {useStore} from 'vuex';
import videojs from 'video.js';
import {useRouter} from 'vue-router';

export default defineComponent({
  components: {},
  props: ['item', 'index'],
  setup: function (props) {
    const store = useStore();
    const assetApi = new AssetApi();
    const assetItemApi = new AssetItemApi();
    const userGroupApi = new UserGroupApi();
    const router = useRouter();

    let isGroupDeleted = ref<boolean>(false);

    let show = ref(false);

    let assetItemCount = ref<Number>(0);
    let itemProgress = ref<Number>(0);

    let isSubscript = ref<boolean>(false);

    let getVideoURL = (asset: Asset, unique: boolean) => {
      if (asset && asset.masterAssetItem) {
        return (
          process.env.VUE_APP_TT_ENDPOINT + "/videostream?site=assets&access_token=" +
          JSON.parse(localStorage.loginData).access_token + "&id=" +
          asset.masterAssetItem.id + (unique ? "&ts=" + asset.masterAssetItem.lastModified : "")
        );
      } else {
        return null;
      }
    };
    let getThumbUrl = (asset: Asset, unique: boolean) => {
      if (asset && asset.masterAssetItem) {
        return (
          `${process.env.VUE_APP_TT_ENDPOINT}/asset/item/${asset.masterAssetItem.id}/thumbnail?access_token=${JSON.parse(localStorage.loginData).access_token}${unique ? "&ts=" + asset.masterAssetItem.lastModified : ""}`
        );
      } else {
        return null;
      }
    };

    let copyLink = () => {
      const link = window.location.origin + '/#/assets?id=' + props.item.id;

      navigator.clipboard.writeText(link).then(() => {
        store.commit('SHOW_SNACKBAR', {
          snackbarContent: {t: 'snackbar.linkCopied'},
        });
      });
    };

    let player: any = ref();
    let videoPlayerRef: any = ref(null);
    let videoOptions: any = ref({
      errorDisplay: false,
      poster: getThumbUrl(props.item, true),
      sources: [
        {
          src: getVideoURL(props.item, true),
          type: 'video/mp4',
        },
      ],
    });


    let deleteAsset = async (item: Asset) => {
      if (item && item.id) {
        localStorage.savedAsset = JSON.stringify(item);
        await assetApi.deleteAsset(item.id);
        main.config.globalProperties.$emitter.emit('search-assets', null);
      }
    };

    let onClickJobCount = () => {
      router.push({path: "jobs", query: {assetId: props.item.id}});
    };

    let undoDeleteAsset = async () => {
      let restoredAssetItem: Asset = JSON.parse(localStorage.savedAsset);
      restoredAssetItem.assetStatus = AssetStatus.NEW;

      await assetApi.addAsset(restoredAssetItem);
      main.config.globalProperties.$emitter.emit('search-assets', null);
      store.commit('HIDE_SNACKBAR');
    };

    let getItemLength = async () => {
      let result = (await assetItemApi.getAssetItemCount(props.item.masterAssetItem.id)).data;
      if (result) {
        assetItemCount.value = result == 0 ? result : result - 1;
      }
    };

    let needsVerification = (asset: Asset) => {
      return !!(asset.masterAssetItem && asset.masterAssetItem.needsVerification);
    };

    let hasPermission = (permString: string) => {
      return JSON.parse(localStorage.loginData).user.permissions.permissions[permString];
    };

    let loadVideo = (unique: boolean, updatedAsset: Asset) => {
      player.value.src({
        type: "video/mp4",
        src: getVideoURL(updatedAsset ? updatedAsset : props.item, unique),
      });
      player.value.load();
    };

    let _checkGroupExistence = async () => {
      let result = (await userGroupApi.getUserGroupById(props.item.groupID)).data;
      isGroupDeleted.value = (result == undefined);
    }

    let toggleSubscription = () => {
      isSubscript.value = !isSubscript.value;

      let userId = JSON.parse(localStorage.loginData).user.id;

      let asset: Asset = {};

      asset.id = props.item.id;

      if (props.item.subscribers) {
        asset.subscribers = props.item.subscribers;
      }

      if (!asset.subscribers) {
        asset.subscribers = [];
      }

      if (isSubscript.value) {
        if (!asset.subscribers.includes(userId)) {
          asset.subscribers.push(userId);
        }
      } else {
        if (asset.subscribers.includes(userId)) {
          asset.subscribers = asset.subscribers.filter((obj: any) => obj !== userId);
        }
      }

      assetApi.updateAsset({asset: asset, updateJobs: false});
    }

    let _checkSubscription = () => {
      if (props.item.subscribers) {
        isSubscript.value = props.item.subscribers.includes(JSON.parse(localStorage.loginData).user.id);
      }
    }

    onMounted(() => {
      getItemLength();
      if (props.index <= 20) {
        setTimeout(() => {
          nextTick(() => {
            show.value = true;
          }).then(() => {
            itemProgress.value = props.item.masterAssetItem &&
            props.item.masterAssetItem &&
            props.item.masterAssetItem.progress ? props.item.masterAssetItem.progress : 100;
            player.value = videojs(videoPlayerRef.value, videoOptions.value);
            _checkGroupExistence();
            _checkSubscription();
          });
        }, 40 * props.index);
      } else {
        nextTick(() => {
          show.value = true;
        }).then(() => {
          player.value = videojs(videoPlayerRef.value, videoOptions.value);
          _checkGroupExistence();
          _checkSubscription();
        });
      }
      main.config.globalProperties.$emitter.on("ws" + WsTopic.ASSETITEMPROGRESS, (progress: Progress) => {
        if (progress.percent && progress.assetId === props.item.id) {
          itemProgress.value = progress.percent;
          if (progress.status === "COMPLETE") {
            main.config.globalProperties.$emitter.emit("update-asset", progress.assetId, loadVideo);
          }
        }
      });
    });

    return {
      show,
      player,
      needsVerification,
      videoPlayerRef,
      videoOptions,
      assetItemCount,
      isGroupDeleted,
      itemProgress,
      isSubscript,
      deleteAsset,
      undoDeleteAsset,
      hasPermission,
      getItemLength,
      copyLink,
      onClickJobCount,
      toggleSubscription,
    };
  },
});

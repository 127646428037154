import {computed, defineComponent, onMounted, ref} from 'vue';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';
import {AssetApi, AssetItemApi, SystemApi} from '../../../../target/api';
import {useI18n} from 'vue-i18n';
import {CustomerMetadata} from "../../../../target/api/de/moovit/titletoolserver/model";

export default defineComponent({
  components: {},
  setup: function (props) {
    const route = useRoute();
    const store = useStore();
    const i18n = useI18n();
    const assetApi = new AssetApi();
    const assetItemApi = new AssetItemApi();
    const systemApi = new SystemApi();

    let item: any = ref({});
    let masterAssetItem: any = ref({});
    let headerItem: any = ref(undefined);
    let updateJobs = ref(false);
    let assetInfos: any = ref(undefined);
    let assetCustomInfos: any = ref<Array<CustomerMetadata>>([]);

    let getSecondsAsTime = (seconds: number) => {
      if (!seconds || seconds <= 0) return '--:--';
      let outSeconds: number = seconds % 60;
      let outMinutes: number = (seconds - outSeconds) / 60;
      return (
        (outMinutes < 10 ? '0' + outMinutes : outMinutes) +
        ':' +
        (outSeconds < 10 ? '0' + outSeconds : outSeconds)
      );
    };

    onMounted(async () => {
      let getAssetByIdResponse: any = await assetApi.getAssetById('' + route.query.id);

      let asset: any = getAssetByIdResponse.data;
      item.value = headerItem.value = asset;
      console.debug('AssetInfo - ITEM', asset);

      let tAssets = {
        t: 'activityBreadcrumbs.assets',
        e: undefined,
      };
      let tAssetInfos = {
        t: 'activityBreadcrumbs.assetInfos',
        e: [item.value.name],
      };
      store.commit('SET_ACTIVITY_BREADCRUMBS', [tAssets, tAssetInfos]);

      let getAssetItemByIdResponse: any = await assetItemApi.getAssetItemById(
        '' + asset.masterAssetItem.id
      );
      const mAssetItem = (masterAssetItem.value = getAssetItemByIdResponse.data);
      console.debug('getAssetItemByIdResponse.data', mAssetItem);

      assetInfos.value = [];
      assetInfos.value.push({key: i18n.t('general.createdBy'), value: asset.userID});
      assetInfos.value.push({key: i18n.t('general.createdAt'), value: new Date(asset.created).toLocaleString()});
      if (mAssetItem && mAssetItem.duration > 0) {
        assetInfos.value.push({
          key: 'duration',
          value: getSecondsAsTime(mAssetItem.duration),
        });
      }
      assetInfos.value.push({key: i18n.t('general.group'), value: asset.groupID});
      assetInfos.value.push({key: i18n.t('general.language'), value: asset.masterAssetItem.language});
      assetInfos.value.push({key: i18n.t('general.masterMamId'), value: asset.thirdPartyID});

      if (mAssetItem.customerMetadata) {
        for (let i of mAssetItem.customerMetadata) {
          systemApi.getCustomMetadataById(i).then(value => {
            if (value.data != null) {
              assetCustomInfos.value.push(value.data);
            }
          })
        }
      }

      console.debug('AssetInfos', JSON.parse(JSON.stringify(assetInfos.value)));
    });

    return {
      item,
      masterAssetItem,
      headerItem,
      assetInfos,
      assetCustomInfos,
      updateJobs,
    };
  },
});

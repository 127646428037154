<template>
  <CustomDropdown
    :label="placeholder ? placeholder : $t('general.role')"
    :placeholder="placeholder ? placeholder : $t('general.role')"
    v-model:modelValue="innerModelValue"
    :show-clear="showClear !== undefined ? showClear : true"
    :disabled="disabled"
    :options="userRoles"
  />
</template>

<script src="./UserRoleDropdown.ts"></script>

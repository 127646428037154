import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
} from "vue";
import {useStore} from "vuex";

export default defineComponent({
  props: {
    modelValue: Object,
    addAllLanguage: Boolean,
    isValid: Boolean,
    placeholder: String,
    dropdownHeight: Number,
    textOverFlowWidth: Number,
    selectedLanguages: Array,
    fSelected: Function,
  },
  components: {},
  setup: function (props, context) {
    const store = useStore();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let allLanguages = ref<{ name: string; value: string }[]>();
    let languages = ref<{ name: string; value: string }[]>();
    let innerModelValue: any = ref(undefined);

    watch(props, () => {
      innerModelValue.value = props.modelValue;
    });

    watch(innerModelValue, () => {
      context.emit("update:modelValue", innerModelValue.value);

      if (props.fSelected) {
        props.fSelected(innerModelValue.value);
      }
    });

    onMounted(() => {
      languages.value = [];

      if (languages.value && props.addAllLanguage) {
        languages.value.push({ name: "All (*)", value: "OW" });
      }

      if (store.state.languages.length !== 0) {
        languages.value!.push(...Object.assign([], store.state.languages));

        allLanguages.value = languages.value;
      } else {
        setTimeout(() => {
          languages.value!.push(...Object.assign([], store.state.languages));

          allLanguages.value = languages.value;
        }, 2000);
      }
    });

    return {
      innerModelValue,
      languages,
    };
  },
});

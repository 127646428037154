import {defineComponent, onMounted} from 'vue';

export default defineComponent({
  components: {},
  setup: function () {
    onMounted(() => {
    });

    return {};
  },
});

import {defineComponent, ref} from 'vue';

export default defineComponent({
  props: ['item'],
  components: {},
  setup: function (props) {

    let getCountryFlags = () => {
      const asset = props.item;
      let flags = [];
      if (asset && asset.assetItems.length) {
        for (let i = 0; i < 5; i++) {
          let assetItem = asset.assetItems[i];
          if (assetItem) {
            if (assetItem.status == 'COMPLETE') {
              flags.push(assetItem.language);
            }
          }
        }
        return flags;
      }
      return [];
    };

    let innerModelValue = ref({});
    let showDownloadIcon = ref(true);

    return {innerModelValue, showDownloadIcon, getCountryFlags};
  },
});

<template>
  <CustomDropdown
    :label="$t('general.status')"
    :placeholder="$t('general.status')"
    v-model:modelValue="innerModelValue"
    :show-clear="true"
    :options="userRoles"
  />
</template>

<script src="./AssetStatusDropdown.ts"></script>

import {defineComponent, getCurrentInstance, nextTick, onMounted, onUnmounted, ref, watch} from "vue";

import videojs, {VideoJsPlayer, VideoJsPlayerOptions} from "video.js";
import {useMicrophone} from "@/components/job/utils/microphone";
import {Field, FieldType} from "../../../target/api/de/moovit/titletoolserver/model";
import {FieldApi} from "../../../target/api";
import {uuid} from "vue-uuid";
import axios from "axios";
import {useStore} from "vuex";

// Idea for Master Wave for Fields
// https://wavesurfer-js.org/plugins/markers.html
// https://codepen.io/Sambego/pen/VmBJJN

export default defineComponent({
  components: {},
  props: ["videoUrl", "visible", "item"],
  setup: function (props, context) {
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    const store = useStore();

    const fieldApi: FieldApi = new FieldApi();

    const {
      stopStream,
      startMicrophoneRecording,
      stopMicrophoneRecording,
      handler,
      audiosBlobs,
      microphone,
      microphones
    } = useMicrophone();

    let videoPlayer = ref<VideoJsPlayer>();
    let playerVideoControls = ref<any>({});
    let playerVideoOptions = ref<VideoJsPlayerOptions>({});
    let playerDuration = ref<number>(0);

    let intervalId = ref<number>();
    let playerSliderPosition = ref<number>(0);

    let availableInputs = ref<Array<{ value: string; text: string }>>([]);
    let currentInputDevice = ref<{ value: string; text: string }>();

    let isActive = ref<boolean>(false);
    let isReady = ref<boolean>(false);
    let isRecording = ref<boolean>(false);

    let startTime = ref<number>(0);
    let endTime = ref<number>(0);
    let audioFields = ref<Array<Field>>([]);

    let currentTime = ref<number>(0.0);
    let isTimelineMove = ref<boolean>(false);

    let playButtonIcon = ref<string>("pi pi-play");

    let toggleDialog = () => {
      console.debug("Dialog toggled");
      if (isActive.value) {
        isActive.value = false;
      } else {
        nextTick(() => {
          isActive.value = true;
        }).then(() => {
          handler();
          _loadVideo();
          audioFields.value = [];

          fieldApi.getAssetItemFieldsByAssetItemId(props.item.id).then(response => {
            nextTick(() => {
              audioFields.value = response.data.filter(value => (value.fieldType === FieldType.VOICEOVER && value.parentName !== "audioRecorderVoiceOver"));
            }).then(() => {
              emitter.emit('field-visualizer-update-fields');
            })
          });

          emitter.on("audio-recorder-finished", () => {
            _audioFileCreated();
          });

          emitter.on("returnField", (index: number) => {
            onClickField(index);
          });

          emitter.on("onChangeVideoSliderPosition" + props.item.id + "000", (value: number) => {
            onChangeTimelinePosition(value);
          });
        });
      }
    };

    let _loadVideo = () => {
      intervalId.value = window.setInterval(() => {
        if (isTimelineMove.value) {
          isTimelineMove.value = false;

          currentTime.value = playerVideoControls.value.currentTime;
        }

        if (playerVideoControls.value && videoPlayer && videoPlayer.value && !videoPlayer.value.paused()) {
          playerSliderPosition.value =
            (playerVideoControls.value.currentTime / playerVideoControls.value.duration) * 100;

          currentTime.value = playerVideoControls.value.currentTime;
        }
        if (!playerDuration.value && playerVideoControls.value) {
          playerDuration.value = playerVideoControls.value.duration;
        }
      }, 40)

      playerVideoOptions.value = {};
      playerVideoOptions.value.preload = "metadata";

      videoPlayer.value = videojs(
        playerVideoControls.value,
        playerVideoOptions.value
      );
      videoPlayer.value.src({
        type: "video/mp4",
        src: props.videoUrl,
      });
      videoPlayer.value.load();
    };

    let startRecording = () => {
      if (!isRecording.value) {
        isRecording.value = true;
        startTime.value = currentTime.value;
        startMicrophoneRecording();
        onClickPlayButton();
      }
    };

    let stopRecording = () => {
      if (videoPlayer.value && !videoPlayer.value.paused()) {
        videoPlayer.value.pause();
      }

      if (isRecording.value) {
        isRecording.value = false;
        endTime.value = currentTime.value;
        stopMicrophoneRecording();
      }
    };

    let onVideoEnded = () => {
      stopRecording();
      playerVideoControls.value.currentTime = 0;
      playerSliderPosition.value = 0;
      playButtonIcon.value = "pi pi-play";
      currentTime.value = 0;
    }

    let onClickFinish = () => {
      toggleDialog();
    };

    let onClickPlayButton = () => {
      if (videoPlayer.value) {
        if (videoPlayer.value.paused()) {
          playButtonIcon.value = "pi pi-pause";
          videoPlayer.value.play();
        } else {
          playButtonIcon.value = "pi pi-play";
          videoPlayer.value.pause();
          stopRecording();
        }
      }
    };

    let getCurrentTimecode = (seconds: number) => {
      if (!seconds) {
        return "00 : 00";
      }
      let minutes = Math.floor(seconds / 60);
      seconds -= minutes * 60;

      let minuteString: string = minutes + "";
      if (minutes < 10) minuteString = "0" + minutes;

      let secondString: string = Math.floor(seconds) + "";
      if (seconds < 10) secondString = "0" + Math.floor(seconds);

      return minuteString + " : " + secondString;
    };

    let onChangeTimelinePosition = (position: number) => {
      playerVideoControls.value.currentTime = (position / 100) * playerVideoControls.value.duration;

      isTimelineMove.value = true;
    }

    let onClickField = (index: number) => {
      console.log("Clicked Field: ", index, audioFields.value[index].id);

      fieldApi.deleteField(audioFields.value[index]!.id!).then(result => {
        emitter.emit('field-visualizer-update-fields');
        audioFields.value.splice(index, 1);
      })
    }

    let _audioFileCreated = () => {
      let field: Field = {};

      field.id = uuid.v4();

      field.inPoint = startTime.value;
      field.outPoint = endTime.value;

      field.value = "Audio " + (audioFields.value.length + 1);
      field.srcValue = "Audio " + (audioFields.value.length + 1);

      field.prtlTrackItemName = "VoiceRecorder";

      field.fieldType = FieldType.VOICEOVER;
      field.assetItemID = props.item.id;

      console.log("audio field", field);

      fieldApi.addField(field).then(() => {
        nextTick(() => {
          audioFields.value.push(field);

          _uploadAudioFile(field,
            new File([audiosBlobs.value.pop()!],
              field.id + ".wav",
              { type: "audio/wav" }
            ));
        }).then(() => {
          emitter.emit('field-visualizer-update-fields');
        });
      });
    };

    let _uploadAudioFile = (field: Field, audioFile: File) => {
      console.debug("JobView - onUploadNewAudio(event)");
      console.debug(audioFile);

      let formData: FormData = new FormData();
      formData.append("file", audioFile);
      let url =
        "upload?task=uploadVoiceOverFile&fieldId=" +
        field.id +
        "&sessionId=" +
        store.state.sessionId;

      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function () {
          console.debug("SUCCESS!!");
          emitter.emit("field-visualizer-update-fields");
        })
        .catch(function () {
          console.debug("FAILURE!!");
        });
    };

    watch(isActive, () => {
      if (!isActive.value) {
        stopStream();

        window.clearInterval(intervalId.value);

        emitter.off("audio-recorder-finished");
        emitter.off("returnField");
        emitter.off("onChangeVideoSliderPosition" + props.item.id + "000");

        console.log("addOrRemoveVoiceOverField", props.item.id, audioFields.value.length != 0);
        fieldApi.addOrRemoveVoiceOverField("" + props.item.id, audioFields.value.length != 0)
          .then(_ => {
            emitter.emit("setVoiceOverTracks" + props.item.id);
          });
      }
    });

    onMounted(() => {
      emitter.on("toggleCustomVoiceOverTrack", () => {
        toggleDialog();
      });
    });

    onUnmounted(() => {
      stopStream();
      emitter.off("toggleCustomVoiceOverTrack");
      emitter.off("audio-recorder-finished");
      emitter.off("onChangeVideoSliderPosition" + props.item.id + "000");
    });

    return {
      playerVideoControls,
      playerSliderPosition,
      playerDuration,

      onClickPlayButton,
      getCurrentTimecode,

      microphone,
      microphones,

      toggleDialog,
      isActive,
      currentTime,

      startRecording,
      stopRecording,
      audioFields,

      onClickFinish,
      onClickField,
      onVideoEnded,

      isReady,
      isRecording,
      availableInputs,
      currentInputDevice,
      playButtonIcon,
    };
  },
});

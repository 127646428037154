<template>
  <div
    class="border-radius-8 col-dark-blue bor-menu-color bg-transparent"
    :class="!isValid ? 'bor-reject' : ''"
    style="display:flex; flex-direction:column; width:400px; height:50px; justify-content:center; position: relative;"
  >
    <div
      style="display:flex; flex-direction:column; justify-content:center; position: relative; padding-left:16px; padding-right:16px;"
    >
      <div
        v-if="innerModelValue"
        class="col-menu-color"
        style="font-size:12px; position:absolute; left:4px; top:-1px;"
      >
        {{$t("general.language")}}
      </div>
      <AutoComplete
        placeholder="Select Language"
        v-model="innerModelValue"
        :suggestions="languages"
        @complete="onComplete"
        @item-select="submitLanguage(innerModelValue); $emit('update:modelValue', innerModelValue)"
        @item-unselect="$emit('update:modelValue', innerModelValue)"
        field="name"
        :delay="300"
        completeOnFocus
        forceSelection
        inputStyle="border:0px; background:transparent; width:100% !important; color: red; height:50px !important; font-size:16px;"
      >
        <template #item="slotProps">
          <div style="margin-right: 20px"
               :style="disabledLanguages.includes(slotProps.item.name) ? 'color: lightgrey' : ''">
            <Flag :countryFlag="slotProps.item.value" style="float: left; margin-right: 20px"/>
            {{slotProps.item.name}}
          </div>
        </template>
      </AutoComplete>
    </div>
  </div>
</template>

<script src="./AutoLanguageDropdown.ts"></script>
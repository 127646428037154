import {defineComponent, getCurrentInstance, onMounted, ref, watch} from 'vue';

export default defineComponent({
  props: ['modelValue', 'label', 'type'],
  components: {},
  setup: function (props, context) {
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let innerModelValue: any = ref();

    let date = ref();

    const options = {year: 'numeric', month: 'numeric', day: 'numeric'};

    let onDateSelected = () => {
      let tempDate = date.value.toLocaleString('de-DE', options).split(".");

      date.value = tempDate[2] +
        "-" + (tempDate[1].length == 1 ? "0" + tempDate[1] : tempDate[1]) +
        "-" + (tempDate[0].length == 1 ? "0" + tempDate[0] : tempDate[0]);

      innerModelValue.value = date.value;
      emitter.emit('update:modelValue', innerModelValue);
    }

    watch(props, () => {
      innerModelValue.value = props.modelValue;
    });

    onMounted(() => {
    });

    return {innerModelValue, date, onDateSelected};
  },
});

// if (localStorage.getItem("darkmodeVisible") === null) {
//   localStorage.setItem("darkmodeVisible", "inactive");
// }
// let viewMode: string;
// if (localStorage.getItem("darkmodeVisible") == "active") {
//   viewMode = "global-dark.scss";
// } else {
//   viewMode = "global.scss";
// }

//import "@/global2.scss";
//import "@/global.scss";
//import "@/global-dark.scss";
//import "@/global-dark2.scss";

// import("@/" + viewMode);
import LogRocket from "logrocket";

import "@/tailwind.css";
import "@/global.scss";
import "@/sort-table.js";
import "primeicons/primeicons.css";
import "@vueform/slider/themes/default.css";

import {createApp, Ref} from "vue";
import App from "./App.vue";

const app = createApp(App);

import {createI18n} from "vue-i18n";

const i18n: any = createI18n({
  locale: "en",
  fallbackLocale: "en",
});
app.use(i18n);

import router from "./router";

app.use(router);

import {store} from "./store";

app.use(store);

import PrimeVue from "primevue/config";

app.use(PrimeVue);

import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";

app.use(FloatingVue);

import ToastService from "primevue/toastservice";

app.use(ToastService);

import {Chart, registerables} from "chart.js";

Chart.register(...registerables);

import ClickOutside from './directives/clickOutside';

app.directive("click-outside", ClickOutside);

import BadgeDirective from "primevue/badgedirective";

app.directive("badge", BadgeDirective);

import Badge from "primevue/badge";

app.component("Badge", Badge);

import Toast from "primevue/toast";

app.component("Toast", Toast);

import Draggable from "vue3-draggable";

app.component("Draggable", Draggable);

import VueCircle from "vue3-circle-progress";

app.component("VueCircle", VueCircle);

import ProgressSpinner from "primevue/progressspinner";

app.component("ProgressSpinner", ProgressSpinner);

import AutoComplete from "primevue/autocomplete";

app.component("AutoComplete", AutoComplete);

import TabView from "primevue/tabview";

app.component("TabView", TabView);

import Textarea from "primevue/textarea";

app.component("Textarea", Textarea);

import TabPanel from "primevue/tabpanel";

app.component("TabPanel", TabPanel);

import Accordion from "primevue/accordion";

app.component("Accordion", Accordion);

import AccordionTab from "primevue/accordiontab";

app.component("AccordionTab", AccordionTab);

import ProgressBar from "primevue/progressbar";

app.component("ProgressBar", ProgressBar);

import Dropdown from "primevue/dropdown";

app.component("Dropdown", Dropdown);

import TieredMenu from "primevue/tieredmenu";

app.component("TieredMenu", TieredMenu);

import Button from "primevue/button";

app.component("Button", Button);

import Tag from "primevue/tag";

app.component("Tag", Tag);

import Chip from "primevue/chip";

app.component("Chip", Chip);

import Chips from "primevue/chips";

app.component("Chips", Chips);

import InputText from "primevue/inputtext";

app.component("InputText", InputText);

import InputNumber from "primevue/inputnumber";

app.component("InputNumber", InputNumber);

import ScrollTop from "primevue/scrolltop";

app.component("ScrollTop", ScrollTop);

import Carousel from "primevue/carousel";

app.component("Carousel", Carousel);

import MultiSelect from "primevue/multiselect";

app.component("MultiSelect", MultiSelect);

import InputSwitch from "primevue/inputswitch";

app.component("InputSwitch", InputSwitch);

import pDialog from "primevue/dialog";

app.component("pDialog", pDialog);

import Avatar from "primevue/avatar";

app.component("Avatar", Avatar);

import SelectButton from "primevue/selectbutton";

app.component("SelectButton", SelectButton);

import DataTable from "primevue/datatable";

app.component("DataTable", DataTable);

import Column from "primevue/column";

app.component("Column", Column);

import Slider from "primevue/slider";

app.component("Slider", Slider);

import Terminal from "primevue/terminal";

app.component("Terminal", Terminal);

import TerminalService from "primevue/terminalservice";

app.component("TerminalService", TerminalService);

import OverlayPanel from "primevue/overlaypanel";

app.component("OverlayPanel", OverlayPanel);

import Calendar from "primevue/calendar";

app.component("Calendar", Calendar);

import AddAssetViewChooseFile from "./views/add_asset/AddAssetViewChooseFile.vue";

app.component("AddAssetViewChooseFile", AddAssetViewChooseFile);

import AdminControlViewSettings from "./views/admin_control/AdminControlViewSettings.vue";

app.component("AdminControlViewSettings", AdminControlViewSettings);

import AdminControlViewFontMapping from "./views/admin_control/AdminControlViewFontMapping.vue";

app.component("AdminControlViewFontMapping", AdminControlViewFontMapping);

import AdminControlViewReplaceMedia from "./views/admin_control/AdminControlViewReplaceMedia.vue";

app.component("AdminControlViewReplaceMedia", AdminControlViewReplaceMedia);

import AdminControlViewReports from "./views/admin_control/AdminControlViewReports.vue";

app.component("AdminControlViewReports", AdminControlViewReports);

import AdminControlViewPermissions from "./views/admin_control/AdminControlViewPermissions.vue";

app.component("AdminControlViewPermissions", AdminControlViewPermissions);

import AddAssetViewDetails from "./views/add_asset/AddAssetViewDetails.vue";

app.component("AddAssetViewDetails", AddAssetViewDetails);

import AddAssetViewCheckmark from "./views/add_asset/AddAssetViewCheckmark.vue";

app.component("AddAssetViewCheckmark", AddAssetViewCheckmark);

import AddAssetViewPreview from "./views/add_asset/AddAssetViewPreview.vue";

app.component("AddAssetViewPreview", AddAssetViewPreview);

import AddJobViewJobDetails from "./views/assets/add_job/AddJobViewJobDetails.vue";

app.component("AddJobViewJobDetails", AddJobViewJobDetails);

import AddJobViewSummary from "./views/assets/add_job/AddJobViewSummary.vue";

app.component("AddJobViewSummary", AddJobViewSummary);

import ActivityBreadcrumb from "./components/breadcrumb/ActivityBreadcrumb.vue";

app.component("ActivityBreadcrumb", ActivityBreadcrumb);

import FilterBreadcrumb from "./components/breadcrumb/FilterBreadcrumb.vue";

app.component("FilterBreadcrumb", FilterBreadcrumb);

import Sidebar from "./components/visual/Sidebar.vue";

app.component("Sidebar", Sidebar);

import Snackbar from "./components/visual/Snackbar.vue";

app.component("Snackbar", Snackbar);

import Dialog from "./components/dialog/Dialog.vue";

app.component("Dialog", Dialog);

import TerminalDialog from "./components/dialog/TerminalDialog.vue";

app.component("TerminalDialog", TerminalDialog);

import ManageUserDialog from "./components/dialog/ManageUserDialog.vue";

app.component("ManageUserDialog", ManageUserDialog);

import VoiceOverSettingsDialog from "./components/dialog/VoiceOverSettingsDialog.vue";

app.component("VoiceOverSettingsDialog", VoiceOverSettingsDialog);

import AddGroupDialog from "./components/dialog/AddGroupDialog.vue";

app.component("AddGroupDialog", AddGroupDialog);

import CustomerMetadataDialog from "./components/dialog/CustomerMetadataDialog.vue";

app.component("CustomerMetadataDialog", CustomerMetadataDialog);

import JobItem from "./components/job/JobItem.vue";

app.component("JobItem", JobItem);

import Timeline from "./components/job/Timeline.vue";

app.component("Timeline", Timeline);

import VoiceOverRecorder from "./components/job/VoiceOverRecorder.vue";

app.component("VoiceOverRecorder", VoiceOverRecorder);

import AddFilterDialog from "./components/dialog/AddFilterDialog.vue";

app.component("AddFilterDialog", AddFilterDialog);

import ReplaceMediaDialog from "./components/dialog/ReplaceMediaDialog.vue";

app.component("ReplaceMediaDialog", ReplaceMediaDialog);

import Header from "./components/visual/Header.vue";

app.component("Header", Header);

import RoundedLabel from "./components/visual/RoundedLabel.vue";

app.component("RoundedLabel", RoundedLabel);

import SearchField from "./components/input/SearchField.vue";

app.component("SearchField", SearchField);

import Switch from "./components/input/Switch.vue";

app.component("Switch", Switch);

import ChangeViewSwitch from "./components/input/ChangeViewSwitch.vue";

app.component("ChangeViewSwitch", ChangeViewSwitch);

import CustomDropdown from "./components/dropdown/CustomDropdown.vue";

app.component("CustomDropdown", CustomDropdown);

import DownloadJobDropdown from "./components/dropdown/DownloadJobDropdown.vue";

app.component("DownloadJobDropdown", DownloadJobDropdown);

import AutoLanguageDropdown from "./components/dropdown/AutoLanguageDropdown.vue";

app.component("AutoLanguageDropdown", AutoLanguageDropdown);

import Flag from "./components/visual/Flag.vue";

app.component("Flag", Flag);

import FieldVisualizer from "./components/visual/FieldVisualizer.vue";

app.component("FieldVisualizer", FieldVisualizer);

import CustomInputText from "./components/input/CustomInputText.vue";

app.component("CustomInputText", CustomInputText);

import CustomInputField from "./components/input/CustomInputField.vue";

app.component("CustomInputField", CustomInputField);

import AdvancedTextArea from "./components/input/AdvancedTextArea.vue";

app.component("AdvancedTextArea", AdvancedTextArea);

import CustomerMetadataInput from "./components/input/CustomerMetadataInput.vue";

app.component("CustomerMetadataInput", CustomerMetadataInput);

import CustomDatePicker from "./components/input/CustomDatePicker.vue";

app.component("CustomDatePicker", CustomDatePicker);

import AutoCompleteUser from "./components/input/AutoCompleteUser.vue";

app.component("AutoCompleteUser", AutoCompleteUser);

import AutoCompleteLanguage from "./components/input/AutoCompleteLanguage.vue";

app.component("AutoCompleteLanguage", AutoCompleteLanguage);

import MultiSelectLanguage from "./components/input/MultiSelectLanguage.vue";

app.component("MultiSelectLanguage", MultiSelectLanguage);

import AutoCompleteGroup from "./components/input/AutoCompleteGroup.vue";

app.component("AutoCompleteGroup", AutoCompleteGroup);

import MultiLanguageDropdown from "./components/dropdown/MultiLanguageDropdown.vue";

app.component("MultiLanguageDropdown", MultiLanguageDropdown);

import CountryDropdown from "./components/dropdown/CountryDropdown.vue";

app.component("CountryDropdown", CountryDropdown);

import UserRoleDropdown from "./components/dropdown/UserRoleDropdown.vue";

app.component("UserRoleDropdown", UserRoleDropdown);

import AssetStatusDropdown from "./components/dropdown/AssetStatusDropdown.vue";

app.component("AssetStatusDropdown", AssetStatusDropdown);

import LargeAssetItem from "./components/list_items/LargeAssetItem.vue";

app.component("LargeAssetItem", LargeAssetItem);

import ListAssetItem from "./components/list_items/ListAssetItem.vue";

app.component("ListAssetItem", ListAssetItem);

import ListFontMappingItem from "./components/list_items/ListFontMappingItem.vue";

app.component("ListFontMappingItem", ListFontMappingItem);

import ListJobApprovalItem from "./components/list_items/ListJobApprovalItem.vue";

app.component("ListJobApprovalItem", ListJobApprovalItem);

import SettingsListItem from "./components/list_items/SettingsListItem.vue";

app.component("SettingsListItem", SettingsListItem);

import UserNotificationItem from "./components/list_items/UserNotificationItem.vue";

app.component("UserNotificationItem", UserNotificationItem);

import UploaderSetup from "./views/uploader/UploaderSetup.vue";

app.component("UploaderSetup", UploaderSetup);

import UploaderFieldPreview from "./views/uploader/UploaderFieldPreview.vue";

app.component("UploaderFieldPreview", UploaderFieldPreview);

import UploaderVideoPreview from "./views/uploader/UploaderVideoPreview.vue";

app.component("UploaderVideoPreview", UploaderVideoPreview);

import PrivacyPolicy from "./components/visual/PrivacyPolicy.vue";

app.component("PrivacyPolicy", PrivacyPolicy);

import Changelog from "./components/visual/Changelog.vue";

app.component("Changelog", Changelog);

import axios, {AxiosError} from "axios";

console.debug("Current environment:", process.env.NODE_ENV);
axios.defaults.baseURL = process.env.VUE_APP_TT_ENDPOINT;
axios.interceptors.request.use(async function (config) {
  if (localStorage.loginData && !config.headers.Authorization) {
    let loginData = JSON.parse(localStorage.loginData);
    config.headers.Authorization = "Bearer " + loginData.access_token;
    config.headers.userid = loginData.user.id;
    await initData();
    initWebsocket();
  }

  return config;
});

let initData = async () => {
  if (!InitAppService.isInitialized()) {
    InitAppService.initData().then(result => {
      store.commit("SET_COUNTRIES", result.countries);
      store.commit("SET_SETTINGS", result.settings);
      store.commit("SET_EXPERIMENTAL_FEATURES", result.settings.filter(value => value.id === "experimentalFeatures"));
      store.commit("SET_LANGUAGES", result.languages);
      store.commit("SET_NOTIFICATIONS", result.notifications);
    });
  }
};
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (axiosError: AxiosError) {
    try {
      if (router && (router.currentRoute.value.fullPath.includes("/uploader") || router.currentRoute.value.fullPath.includes("/?token="))) {
        return;
      }

      LogRocket.captureException(axiosError, {
        tags: {
          subscription: 'TitleTool',
        },
        extra: {
          path: router.currentRoute.value.fullPath,
        },
      });

      console.error("axios.interceptors.response.error", axiosError.response);
      if (axiosError.response) {
        if (axiosError.response.status == 401 || axiosError.response.status == 403) {
          if (router.currentRoute.value.fullPath.length > 1) {
            localStorage.savedURL = router.currentRoute.value.fullPath;
            console.debug("SAVED URL -> " + localStorage.savedURL);
          }
          localStorage.removeItem("loginData");
          location.assign(window.location.origin);
        }
      }
    } catch (error: any) {
      let isDarkmode = localStorage.getItem('darkmodeVisible');
      localStorage.clear();
      if (isDarkmode) {
        localStorage.setItem('darkmodeVisible', isDarkmode);
      }
      console.error("Auth error", error);
      if (axiosError.code != "ERR_NETWORK") {
        location.assign(window.location.origin);
      }
    }
  }
);

import mitt from "mitt";

const emitter = mitt();
app.config.globalProperties.$emitter = emitter;

app.mount("#app");

import VueNativeSock from "vue-native-websocket-vue3";
import {uuid} from "vue-uuid";
import {InitAppService} from "./InitAppService";

let initWebsocket = () => {
  if (!store.state.socket || !store.state.socket.isConnected) {
    if (!localStorage.sessionId) {
      localStorage.sessionId = uuid.v1();
    }
    let wsProtocol = process.env.VUE_APP_TT_WS_PROTOCOL;
    if (window.location.protocol !== "https:" && wsProtocol === "wss://") {
      wsProtocol = "ws://";
    }
    let loginData = JSON.parse(localStorage.loginData);

    let wsUri =
      wsProtocol +
      window.location.hostname +
      process.env.VUE_APP_TT_WS +
      "?sessionId=" +
      localStorage.sessionId +
      "&userId=" +
      encodeURI(loginData.user.id);

    app.use(VueNativeSock, wsUri, {
      format: "json",
      reconnection: true,
      reconnectionDelay: 3000,
      store: store,
    });
  }
};

let setFavicon = () => {
  try {
    const url = process.env.VUE_APP_TT_ENDPOINT + "/system/logo/FAVICON";
    var link = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement;
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      link.type = "image/x-icon";
      document.head.appendChild(link);
    }
    link.href = url;
  } catch (error) {
    console.error(error);
  }
};
LogRocket.init(process.env.VUE_APP_TT_LOGROCKET_KEY, {
  shouldDebugLog: true,
  browser: {
    urlSanitizer: url => {
      let sanitizedUrl = url;

      sanitizedUrl = sanitizedUrl.replace(/access_token=([^&]*)/, 'access_token=**secret**');

      return sanitizedUrl;
    }
  },
  network: {
    requestSanitizer: request => {
      if (request.headers['bearer-token']) {
        request.headers['bearer-token'] = '';
      }

      request.headers['x-secret'] = undefined;

      return request;
    },
  },
});
setFavicon();

export default app;

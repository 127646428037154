import {onMounted, ref} from 'vue';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import {SystemApi} from '../target/api';
import {useToast} from "primevue/usetoast";
import LogRocket from 'logrocket';
import {AxiosResponse} from "axios";
import {SystemPreference} from "../target/api/de/moovit/titletoolserver/model";

export default {
  setup: function () {
    const store = useStore();
    const route = useRoute();
    const i18n = useI18n();
    const toast = useToast();
    const systemApi = new SystemApi();
    let isLoading = ref(true);


    let printoutColors = () => {
      const colors = [
        {name: 'transparent', color: 'transparent'},
        {name: 'dark-blue', color: '#0d1d4f'},
        {name: 'dark-blue-30', color: 'rgba(13, 29, 79, 0.3)'},
        {name: 'dark-blue-60', color: 'rgba(13, 29, 79, 0.6)'},
        {name: 'dark-blue-70', color: 'rgba(13, 29, 79, 0.7)'},
        {name: 'black', color: '#000000'},
        {name: 'white', color: '#ffffff'},
        {name: 'white-60', color: 'rgba(255, 255, 255, 0.6)'},
        {name: 'light-blue', color: '#E2E6F3'},
        {name: 'background', color: '#e2e7ec'},
        {name: 'menu-color', color: '#B7C2D6'},
        {name: 'complete', color: '#47DF84'},
        {name: 'complete-light', color: '#EFFFF5'},
        {name: 'action-green-light', color: 'rgba(0, 232, 51, 0.24)'},
        {name: 'new', color: '#D7DF82'},
        {name: 'new-light', color: '#FDFFE2'},
        {name: 'inprogress-light', color: '#FFE1DA'},
        {name: 'inprogress', color: '#FFBDAE'},
        {name: 'reject', color: '#E31D1C'},
        {name: 'white-blue', color: '#FBFBFF'},
      ];

      let outStr = '';
      colors.forEach((col) => {
        outStr += '$' + col.name + ': ' + col.color + ';';
      });
      colors.forEach((col) => {
        outStr +=
          '.col-' +
          col.name +
          ' { color: $' +
          col.name +
          ';}.bg-' +
          col.name +
          ' { background: $' +
          col.name +
          ';}.bor-' +
          col.name +
          ' { border-width: 1px; border-style: solid; border-color: $' +
          col.name +
          ' !important' +
          ';} ';
      });
      console.debug(outStr);
    };


    onMounted(async () => {
      // printoutColors();
     
      store.commit("SET_TOAST", toast);
      console.debug("ToastService initialized:", store.state.toast);
      store.commit("SET_I18N", i18n);
      console.debug("i18n initialized:", store.state.i18n);

      let i18nMessages: any = (await systemApi.getI18nMessages()).data;

      let toolName = "";
      systemApi.getSystemPreference("toolName").then((response: AxiosResponse<SystemPreference>) => {
        if (response.data) {
          toolName = (<string><unknown>response.data.value);
          document.title = 'Welcome to ' + toolName;
        }
      });
     

      console.debug('i18n - i18nMessages', i18nMessages);

      for (const key in i18nMessages) {
        if (Object.prototype.hasOwnProperty.call(i18nMessages, key)) {
          const element = i18nMessages[key];

          i18n.setLocaleMessage(key, element);
        }
      }

      console.debug('i18n - initialized');

      isLoading.value = false;

      document.title = 'Welcome to ' + toolName;

      setInterval(function () {
        if (i18n
          && store
          && store.state
          && store.state.activityBreadcrumbs
          && store.state.activityBreadcrumbs[0]
          && store.state.activityBreadcrumbs[0].t) {
          document.title = toolName + ' - ' + i18n.t(store.state.activityBreadcrumbs[0].t);
        } else {
          document.title = 'Welcome to ' + toolName;
        }
      }, 3000);
    });

    return {isLoading};
  },
};

<template>
  <transition-group name="simple-slide-left">
    <div
      v-if="$store.state.sidebarVisible"
      style="width: 80px; min-width:80px; display: flex; flex-direction: column; align-items:center; height: 100vh; overflow:hidden; z-index:2;"
      class="bg-menu-color"
    >
      <div style="height:24px;"></div>
      <Button
        v-if="hasPermission('assets_view/visibility')"
        @click="
          $router.push('/assets');
          $emitter.emit('search-assets', null);
          $store.commit('SET_FILTER_BREADCRUMBS', []);
        "
        :class="
          'sidebar-button p-button-text ' +
            ($route.path === '/assets' || $route.path === '/editAsset' || $route.path === '/addJob'
              ? 'bg-dark-blue col-white'
              : 'bg-transparent col-dark-blue')
        "
      >
        <div>
          <i class="pi pi-video" style="font-size: 24px; margin-bottom: 10px"/>
          <div style="font-size:11px">{{$t('general.assets')}}</div>
        </div>
      </Button>
      <Button
        v-if="hasPermission('jobs_view/visibility')"
        @click="
          $router.push('/jobs');
          $store.commit('SET_FILTER_BREADCRUMBS', []);
        "
        :class="
          'sidebar-button p-button-text ' +
            ($route.path === '/jobs' || $route.path === '/job'
              ? 'bg-dark-blue col-white'
              : 'bg-transparent col-dark-blue')
        "
      >
        <div>
          <i class="pi pi-clone" style="font-size: 24px; margin-bottom: 10px"/>
          <div style="font-size:11px">
            {{getJobsText()}}
          </div>
        </div>
      </Button>
      <Button
        v-if="hasPermission('approval_view/visibility')"
        @click="
          $router.push('/jobApproval');
          $store.commit('SET_FILTER_BREADCRUMBS', []);
        "
        :class="
          'sidebar-button p-button-text ' +
            ($route.path === '/jobApproval' || $route.path === '/approveJob'
              ? 'bg-dark-blue col-white'
              : 'bg-transparent col-dark-blue')
        "
      >
        <div>
          <i class="pi pi-check-circle" style="font-size: 24px; margin-bottom: 10px"/>
          <div style="font-size:11px; display:flex; justify-content:center;">{{$t('general.approvals')}}</div>
        </div>
      </Button>
      <Button
        v-if="hasPermission('add_asset_view/visibility')"
        @click="
          $router.push('/addAsset');
        "
        :class="
          'sidebar-button p-button-text ' +
            ($route.path === '/addAsset' ? 'bg-dark-blue col-white' : 'bg-transparent col-dark-blue')
        "
      >
        <div>
          <i class="pi pi-plus-circle" style="font-size: 24px; margin-bottom: 10px"/>
          <div style="font-size: 11px">{{$t('general.addAsset')}}</div>
        </div>
      </Button>
      
      <Button
        v-if="hasPermission('manage_users/visibility')"
        @click="
          $router.push('/manageUsers');
          $emitter.emit('manageUsers-reload');
          $store.commit('SET_FILTER_BREADCRUMBS', []);
        "
        :class="
          'sidebar-button p-button-text ' +
            ($route.path === '/manageUsers'
              ? 'bg-dark-blue col-white'
              : 'bg-transparent col-dark-blue')
        "
      >
        <div>
          <i class="pi pi-users" style="font-size: 24px; margin-bottom: 10px"/>
          <div style=" font-size:11px">{{$t('general.manageUsers')}}
          </div>
        </div>
      </Button>
      <Button
        v-if="hasPermission('admin_control/visibility')"
        @click="
          $router.push('/admin');
        "
        :class="
          'sidebar-button p-button-text ' +
            ($route.path === '/admin'
              ? 'bg-dark-blue col-white'
              : 'bg-transparent col-dark-blue')
        "
      >
        <div>
          <i class="pi pi-cog" style="font-size: 24px; margin-bottom: 10px"/>
          <div style=" font-size:11px">{{$t('general.adminControl')}}
          </div>
        </div>
      </Button>
      
      <div style="position:absolute; bottom: 10px" class="col-dark-blue-30" v-tooltip="version">
        {{version.split('-')[0]}}
      </div>
    </div>
  
  </transition-group>
</template>

<script src="./Sidebar.ts"></script>

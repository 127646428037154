<template>
  <div style="display:flex; position:relative; padding-left:24px; padding-top: 20px">
    <LargeAssetItem style="z-index: 100" class="dyn-container" v-if="headerItem" :item="headerItem" :index="3"
                    :hideProcess="true" :hideControls="true"/>
    
    <transition-group :name="transitionDir">
      <AddJobViewJobDetails
        style="position: absolute; margin-left: 420px; height: calc(100vh - 130px); width: calc(96vw - 500px)"
        v-if="currentPage === 1"
        v-model:currentPage="currentPage"
        v-model:transitionDir="transitionDir"
        v-model:assetName="assetName"
        v-model:approver="approver"
        v-model:isApprover="isApprover"
        v-model:groups="groups"
        v-model:translator="translator"
        v-model:expirationDate="expirationDate"
        v-model:subtitleOption="subtitleOption"
        v-model:languages="languages"
        v-model:selectedAudioTrack="selectedAudioTrack"
      />
      
      <AddJobViewSummary
        style="position: absolute; margin-left: 420px; height: calc(100vh - 130px); width: calc(96vw - 500px)"
        v-if="currentPage === 2"
        v-model:currentPage="currentPage"
        v-model:transitionDir="transitionDir"
        v-model:assetName="assetName"
        v-model:expirationDate="expirationDate"
        v-model:subtitleOption="subtitleOption"
        v-model:translator="translator"
        v-model:approver="approver"
        v-model:groups="groups"
        v-model:languages="languages"
        v-model:selectedAudioTrack="selectedAudioTrack"
      />
    </transition-group>
  </div>
</template>

<script src="./AddJobView.ts"></script>

import {defineComponent, nextTick, onMounted, ref, watch} from 'vue';
import {UserApi} from '../../../target/api';
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";


export default defineComponent({
  components: {},
  props: ['notification', 'mode', 'fClose'],
  setup: function (props) {

    const router = useRouter();
    const store = useStore();
    const userApi: UserApi = new UserApi();
    const i18n = useI18n();

    let visible = ref<boolean>(true);

    let markAsRead = (isRead: boolean = false) => {
      props.notification.read = (isRead ? true : !props.notification.read);
      userApi.updateNotification(props.notification);
    }

    function timeSince(date: any) {
      let seconds = Math.floor((Date.now() - date) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " " + i18n.t("general.years");
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " " + i18n.t("general.months");
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " " + i18n.t("general.days");
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " " + i18n.t("general.hours");
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " " + i18n.t("general.minutes");
      }
      return Math.floor(seconds) + " " + i18n.t("general.seconds");
    }

    let removeNotification = async () => {
      nextTick(async () => {
        await userApi.removeNotification(props.notification.id);
      }).then(async () => {
        store.commit('SET_NOTIFICATIONS',
          (await userApi.getNotifications(JSON.parse(localStorage.loginData).user.id)).data);
      })
    }

    let jumpToSource = () => {
      console.log(props.notification)

      if (props.fClose) {
        props.fClose();
      }
      switch (props.notification.category) {
        case "LOGIN":
          break;
        case "JOB_REJECTED":
          router.push({ path: "/" }).then(_ => {
            router.push({ path: "job", query: { id: props.notification.assetId } });
          });
          break;
        case "JOB_SUBMITTED_FOR_APPROVAL":
          router.push({ path: "/" }).then(_ => {
            router.push({ path: "approveJob", query: { id: props.notification.assetItemId } });
          });
          break;
        case "JOB_SUBMITTED":
          router.push({ path: "/" }).then(_ => {
            router.push({ path: "job", query: { id: props.notification.assetId } });
          });
          break;
        case "JOB_CREATED":
          router.push({ path: "/" }).then(_ => {
            router.push({ path: "job", query: { id: props.notification.assetId } });
          });
          break;
        case "ASSET_IMPORTED":
          router.push({ path: "/" }).then(_ => {
            router.push({ path: "assets", query: { id: props.notification.assetId } });
          });
          break;
        default:
          break;
      }
    }

    let getIcon = (): string => {
      if (props.notification.category === "LOGIN") {
        return "pi pi-sign-in"
      } else if (props.notification.category === "JOB_REJECTED") {
        return "pi pi-minus-circle"
      } else if (props.notification.category === "JOB_SUBMITTED_FOR_APPROVAL") {
        return "pi pi-eye"
      } else if (props.notification.category === "JOB_SUBMITTED") {
        return "pi pi-check"
      } else if (props.notification.category === "JOB_CREATED") {
        return "pi pi-pencil"
      } else if (props.notification.category === "ASSET_IMPORTED") {
        return "pi pi-check"
      }


      return "pi pi-bell";
    }

    let changeMode = (mode: number) => {
      if (mode === 2) {
        visible.value = !props.notification.read;
      } else if (mode === 3) {
        visible.value = props.notification.read;
      } else {
        visible.value = true;
      }
    }

    watch(props, () => {
      changeMode(props.mode);
    });

    onMounted(() => {
      changeMode(props.mode);
    });

    return {
      markAsRead,
      removeNotification,
      timeSince,
      getIcon,
      jumpToSource,
      visible
    };
  },
});

// tslint:disable
/**
 * titletool-nextgen-server
 * TitleTool NextGen Server
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: r.hutter@moovit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum JobTask {
    ZIP = 'ZIP',
    UNZIP = 'UNZIP',
    PRANALYZE = 'PR_ANALYZE',
    VIDEOANALYZE = 'VIDEO_ANALYZE',
    AFXANALYSE = 'AFX_ANALYSE',
    PRRENDER = 'PR_RENDER',
    PRPREVIEWFRAME = 'PR_PREVIEW_FRAME',
    AFXPREVIEWFRAME = 'AFX_PREVIEW_FRAME',
    VIDEORENDER = 'VIDEO_RENDER',
    PRADDSUBTITLES = 'PR_ADD_SUBTITLES',
    AFXRENDER = 'AFX_RENDER',
    IDENTIFY = 'IDENTIFY',
    DELETE = 'DELETE',
    PROGRESS = 'PROGRESS',
    CREATETHUMBNAIL = 'CREATE_THUMBNAIL',
    THUMBNAILCREATED = 'THUMBNAIL_CREATED'
}




import {defineComponent, onMounted, ref} from 'vue';
import {useStore} from 'vuex';

import {SystemApi} from '../../../target/api';
import {SystemPreferenceKeyUpdate} from '../../../target/api/de/moovit/titletoolserver/model';

export default defineComponent({
  components: {},
  props: ['settings', 'isFirstLineEditable', 'isDeletable', 'allowAddNew'],
  setup: function (props) {

    const store = useStore();

    let settingsApi = new SystemApi();

    let dialog = ref(false);

    let addNewValue = ref(false);

    let fieldInputKey = ref();
    let fieldInputValue = ref();

    let settingsKeys = ref<Array<string>>();
    let settingsOldKeys = ref<Array<string>>();

    let settingsValues = ref<Array<string>>();

    let didChanges = ref<boolean>(false);

    let toggleDialog = () => {
      if (dialog.value) {
        dialog.value = false;

        if (didChanges.value) {
          store.commit('SHOW_SNACKBAR', {
            snackbarContent: {t: 'snackbar.settingsSaved'},
          });

          if (props.settings.id == "languages") {
            let languages = []
            for (let lang in props.settings.value) {
              let langObj = {name: lang, value: props.settings.value[lang]}
              languages.push(langObj)
            }
            store.commit('SET_LANGUAGES', languages);
          }
        }
      } else {
        didChanges.value = false;
        dialog.value = true;
      }
    }

    let toggleAddNewValue = () => {
      if (addNewValue.value) {
        if (fieldInputValue.value == null || !fieldInputValue.value) {
          return;
        }

        if (fieldInputKey.value == null || !fieldInputKey.value) {
          fieldInputKey.value = props.settings.value.length;
        }

        if (!fieldInputKey.value) {
          return;
        }

        if (props.isFirstLineEditable) {
          settingsKeys.value!.push(fieldInputKey.value);
          settingsOldKeys.value!.push(fieldInputKey.value);
          settingsValues.value!.push(fieldInputValue.value);
        } else {
          settingsValues.value!.push(fieldInputValue.value);
        }

        saveSettings();

        fieldInputKey.value = "";
        fieldInputValue.value = "";

        addNewValue.value = false;
      } else {
        addNewValue.value = true;
      }
    }

    let keyChange = (oldKey: string, newKey: string, value: string) => {
      let keyUpdate: SystemPreferenceKeyUpdate = {};
      keyUpdate.newKey = newKey;
      keyUpdate.oldKey = oldKey;
      keyUpdate.value = value;

      settingsOldKeys.value = JSON.parse(JSON.stringify(settingsKeys.value));
      settingsApi.updateSystemPreferenceKey(props.settings.id, keyUpdate);
      saveSettings();
    }

    let onClickDelete = (index: number) => {
      if (props.isFirstLineEditable) {
        settingsKeys.value!.splice(index, 1);
        settingsOldKeys.value!.splice(index, 1);
      }
      settingsValues.value!.splice(index, 1);

      saveSettings();
    }

    let saveSettings = () => {
      if (props.isFirstLineEditable) {
        props.settings.value = combineSettings();
        settingsApi.updateSystemPreference(props.settings);
      } else {
        props.settings.value = settingsValues.value;
        settingsApi.updateSystemPreference(props.settings);
      }
      setSettingsKeyRef();
      didChanges.value = true;
    }

    let combineSettings = () => {
      let map: any = {};

      for (let i = 0; i < settingsKeys.value!.length; i++) {
        map[settingsKeys.value![i]] = settingsValues.value![i];
      }

      return map;
    }

    let setSettingsKeyRef = () => {
      if (props.isFirstLineEditable) {
        settingsKeys.value = new Array<string>();
        settingsOldKeys.value = new Array<string>();
        settingsValues.value = new Array<string>();
        for (const key in props.settings.value) {
          settingsKeys.value.push(key);
          settingsOldKeys.value.push(key);
          settingsValues.value.push(props.settings.value[key]);
        }
      } else {
        settingsValues.value = new Array<string>();
        for (const key in props.settings.value) {
          settingsValues.value.push(props.settings.value[key]);
        }
      }
    }

    onMounted(() => {
      setSettingsKeyRef();
    });

    return {
      toggleDialog,
      toggleAddNewValue,
      keyChange,
      onClickDelete,
      saveSettings,

      dialog,
      addNewValue,
      fieldInputKey,
      fieldInputValue,
      settingsKeys,
      settingsOldKeys,
      settingsValues,
    };
  },
});

import {defineComponent, onMounted, watch} from 'vue';

export default defineComponent({
  components: {},
  props: ['modelValue'],
  setup: function (props, context) {

    watch(props, () => {
        localStorage.setItem('isListView', props.modelValue);
      }
    );

    onMounted(() => {
    });

    return {};
  },
});

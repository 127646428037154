<template>
  <div
    style="
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    "
  >
    <div style="display: flex">
      <div v-if="$route.path !== '/uploader'">
        <Button
          v-if="!$store.state.sidebarVisible"
          icon="pi pi-bars"
          class="header-button bg-menu-color"
          style="border-bottom-right-radius: 20px"
          @click="$store.commit('SET_SIDEBAR_VISIBLE', true)"
          v-tooltip="$t('tooltip.openSidebar')"
        />
        <Button
          v-if="$store.state.sidebarVisible"
          icon="pi pi-chevron-left"
          class="header-button bg-menu-color"
          style="transform: scale(1)"
          @click="$store.commit('SET_SIDEBAR_VISIBLE', false)"
          v-tooltip="$t('tooltip.closeSidebar')"
        />
      </div>

      <ActivityBreadcrumb
        :items="$store.state.activityBreadcrumbs"
        style="margin-left: 24px"
      />
    </div>
    <div style="display:flex; align-items:center; gap: 10px">
      <div
        v-if="$route.path !== '/uploader'"
        style="display: flex; align-items: center; justify-content: center; width: 42px; height: 42px"
        class="border-radius-8"
        @click="toggleNotifications"
        v-tooltip="$t('tooltip.notifications')"
      >
        <i
          v-if="isNotificationUnread()"
          class="pi pi-bell"
          style="font-size: 1.2rem"
          v-badge.success="
            $store.state.notifications.filter((notify) => notify.read !== true)
              .length
          "
        ></i>
        <i v-else class="pi pi-bell" style="font-size: 1.2rem"></i>
      </div>

      <OverlayPanel
        ref="notificationPanel"
        style="width: 600px; max-height: 80%; overflow: auto; box-shadow: 0px 0px 5px 5px var(--dark-blue-30);"
      >
        <div
          style="
                align-items: center;
                justify-content: space-between;
                display: flex;"
        >
          <h2>{{ $t("tooltip.notifications") }}</h2>
          <div class="markAllAsRead" @click="markAllAsRead">
            {{$t("notification.markAllAsRead")}}
          </div>
        </div>

        <div class="selection-picker">
          <div
            @click="notificationTab = 1"
            :style="
              notificationTab === 1
                ? 'padding-bottom: 7px; text-decoration: underline; text-underline-offset: 3px; text-decoration-thickness: 2px; text-shadow: 2px 3px 5px var(--menu-color-80);'
                : 'color: var(--multi-token-80);'
            "
          >
            {{$t("notification.all")}}
          </div>
          <div
            @click="notificationTab = 2"
            :style="
              notificationTab === 2
                ? 'padding-bottom: 7px; text-decoration: underline; text-underline-offset: 3px; text-decoration-thickness: 2px; text-shadow: 2px 3px 5px var(--menu-color-80);'
                : 'color: var(--multi-token-80);'
            "
          >
            {{$t("notification.unread")}}
          </div>
          <div
            @click="notificationTab = 3"
            :style="
              notificationTab === 3
                ? 'padding-bottom: 7px; text-decoration: underline; text-underline-offset: 3px; text-decoration-thickness: 2px; text-shadow: 2px 3px 5px var(--menu-color-80);'
                : 'color: var(--multi-token-80);'
            "
          >
            {{$t("notification.read")}}
          </div>
        </div>

        <div
          v-for="notification in $store.state.notifications"
          class="user-notification-list"
        >
          <UserNotificationItem
            :notification="notification"
            :mode="notificationTab"
            :f-close="toggleNotifications"
          />
        </div>
        <div
          style="width: 100%; margin-top: 20px; display: flex; justify-content: center"
        >
          {{$t("notification.noMoreNotification")}}
        </div>
      </OverlayPanel>

      <div
        style="display: flex; align-items: center; justify-content: center; width: 42px; height: 42px; cursor: pointer;"
        class="border-radius-8"
        @click="toggleServerStatus"
        v-tooltip="$t('tooltip.serverStatus')"
      >
        <i class="pi pi-server" style="font-size: 1.2rem"></i>
      </div>

      <OverlayPanel
        ref="serverStatusPanel"
        style="width: 550px; box-shadow: 0px 0px 5px 5px var(--dark-blue-30);"
      >
        <div
          style="
                align-items: center;
                justify-content: space-between;
                display: flex"
        >
          <h2>{{ $t("tooltip.serverStatus") }}</h2>
        </div>

        <div
          v-if="getLatestRendernodeInformation().healthInfo !== undefined"
          style="display: flex; align-items: center; flex-direction: column; max-height: calc(100vh - 200px); overflow: auto;"
        >
          <div
            style="height: 175px; width: 96%; margin: 10px 10px 30px 10px; padding-left: 10px; padding-top: 10px; padding-right: 10px; font-size: 20px; flex-direction: column"
            class="border-radius-16 box-shadow-2 flex-center col-dark-blue transition"
            :class="
              getLatestRendernodeInformation().queueSize >= 8
                ? ' bg-readytoapprove-light bor-readytoapprove '
                : getLatestRendernodeInformation().queueSize >= 3
                ? ' bg-new-light bor-new  '
                : ' bg-complete-light bor-complete '
            "
          >
            <div
              style="width: 30%; display: flex; align-items: center; justify-content: flex-end; font-size: 14px"
            >
              Current Queue: {{ getLatestRendernodeInformation().queueSize }}
            </div>

            <div
              style="height: 75%; width: 100%; margin-bottom: 10px"
              class="bg-menu-color-2 border-radius-8"
            >
              <LineChart
                :chartData="
                  getChartInformation(getLatestRendernodeInformation().address)
                "
                :options="chartOptions"
                style="height: 100%"
              />
            </div>
          </div>

          <div
            style="display: flex; justify-content: center; height: 50px; width: 96%; margin: 10px; padding-left: 10px; padding-right: 10px; font-size: 20px; flex-direction: column"
            class="border-radius-16 box-shadow-2 col-dark-blue transition"
            :class="
              getLatestRendernodeInformation().healthInfo.status ===
              ('ANALYZING' || 'UNZIPPING')
                ? ' bg-new-light bor-new '
                : getLatestRendernodeInformation().healthInfo.status ===
                  'RENDERING'
                ? ' bg-readytoapprove-light bor-readytoapprove '
                : ' bg-complete-light bor-complete '
            "
          >
            <div
              style="display: flex; align-items: center; justify-content: space-between; height: 25%; width: 100%;"
            >
              <div style="width: 80%; display: flex">
                {{ getLatestRendernodeInformation().address.split("|")[0] }}
                <div class="col-dark-blue-30">
                  :{{ getLatestRendernodeInformation().version }}
                </div>
              </div>

              <div
                style="height: 25px; width: 120px"
                class="flex-center border-radius-8 col-dark-blue bg-menu-color-2"
              >
                {{ getLatestRendernodeInformation().healthInfo.status }}
              </div>
            </div>
          </div>
        </div>
      </OverlayPanel>

      <div v-if="isUserAdmin()" v-tooltip="$t('tooltip.terminal')">
        <TerminalDialog />
      </div>

      <MultiLanguageDropdown
        v-model="language"
        :change="onChangeLanguage"
        v-tooltip="$t('tooltip.changeSystemLanguage')"
        style="width: 42px; height: 42px;"
      />
      <div
        v-if="darkmodeActive"
        @click="toggleDarkmode"
        v-tooltip="$t('tooltip.lightmode')"
        class="pi pi-sun bg-transparent bor-transparent col-dark-blue"
        style="
          border-radius: 6px;
          font-size: 18px;
          display: flex;
          height: 42px;
          width: 42px;
          padding-left: 11px;
          cursor: pointer;
          align-items: center;
        "
      />
      <div
        v-else
        @click="toggleDarkmode"
        v-tooltip="$t('tooltip.darkmode')"
        class="pi pi-moon bg-transparent bor-transparent col-dark-blue"
        style="
          border-radius: 6px;
          font-size: 18px;
          display: flex;
          height: 42px;
          width: 42px;
          padding-left: 11px;
          cursor: pointer;
          align-items: center;
        "
      />
      <div
        @click="logout"
        class="pi pi-sign-out bg-transparent bor-transparent col-dark-blue"
        style="
          border-radius: 6px;
          font-size: 18px;
          display: flex;
          height: 42px;
          width: 42px;
          padding-left: 12px;
          padding-top: 2px;
          align-items: center;
          margin-right: 12px;
        "
        v-tooltip="$t('tooltip.logout')"
      />
    </div>
  </div>

  <Transition name="fade">
    <div
      v-if="assetItemRenderProgress && progressAssetItem"
      style="position: absolute; margin-left: 80px; height: 5px; margin-top: -20px; width: calc(100vw - 80px)"
    >
      <div class="flex-center" style="width: 100%; height: 25px; gap: 15px">
        <div style="text-transform: capitalize">
          {{
            assetItemRenderProgress.status.toLowerCase() +
              " '" +
              progressAssetItem.assetName +
              "'"
          }}
        </div>

        <RoundedLabel
          text="Go to Job"
          style="height: 20px"
          class="bor-dark-blue"
          @click="onClickGoToJob()"
        />
      </div>
      <ProgressBar
        v-if="assetItemRenderProgress.status !== 'ERROR'"
        style="height: 5px; width: 100%"
        :value="assetItemRenderProgress.percent"
      />

      <ProgressBar
        v-else
        id="error-bar"
        value="100"
        style="height: 5px; width: 100%"
      />
    </div>
  </Transition>
</template>

<style scoped>
:deep(.p-progressbar-value) {
  background-color: #47df84 !important;
}

#error-bar :deep(.p-progressbar-value) {
  background-color: red !important;
}

.selection-picker {
  display: flex;
  height: 25px;
  width: 100%;
  border-bottom: var(--background) 2px solid;
}

.selection-picker div {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  gap: 2px;
  padding-bottom: 3px;
  height: 100%;
  width: 20%;
}

.markAllAsRead {
  color: var(--dark-blue-60);
  cursor: pointer;
  text-decoration: underline;
}

.selection-picker div:hover {
  transition: all 0.2s;
  padding-bottom: 7px;
}

.user-notification-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.transition {
  transition: background-color 0.2s, border-top-color 0.2s,
    border-bottom-color 0.2s, border-left-color 0.2s, border-right-color 0.2s;
}
</style>

<script src="./Header.ts"></script>

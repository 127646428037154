import {defineComponent, onMounted, ref, onBeforeUnmount} from 'vue';
import {SystemApi} from '../../../target/api';
import TerminalService from "primevue/terminalservice";
import {useStore} from "vuex";

export default defineComponent({
  components: {},
  setup: function () {
    const store = useStore();
    const systemApi = new SystemApi();

    let isActive = ref<boolean>(false);
    let isPasswordActive = ref<boolean>(false);

    let sessionLogin = ref<string | undefined>(undefined);
    let currentPassword = ref<string>("");

    let toolName = ref<string>("");

    let responses = ref<Array<string>>([]);

    let isCommandQueued = ref<boolean>(false);

    let toggleDialog = () => {
      if (sessionLogin.value) {
        isActive.value = !isActive.value;
      } else {
        isPasswordActive.value = !isPasswordActive.value;
      }
    }

    let login = async (password: string) => {
      currentPassword.value = "";
      let result = (await systemApi.loginTerminal(password)).data;

      if (result) {
        sessionLogin.value = password;

        isPasswordActive.value = false;
        isActive.value = true;

        store.commit("SHOW_SNACKBAR", {
          snackbarContent: {t: "general.loginSuccess"},
        });
      } else {
        store.commit("SHOW_SNACKBAR", {
          snackbarType: 'error',
          snackbarContent: {t: "general.loginFailed"},
        });
      }
    }

    let keyPressedPassword = (key: KeyboardEvent, password: string) => {
      if ("Enter" == key.code) {
        login(password);
      }
    };

    const commandHandler = async (input: string) => {
      if (isCommandQueued.value) return;
      if (!sessionLogin.value) return;

      isCommandQueued.value = true;
      respondConsole("> " + (await systemApi.executeCommand(input, sessionLogin.value)).data);
      isCommandQueued.value = false;
    }

    let respondConsole = (response: string) => {
      // @ts-ignore
      TerminalService.emit('response', response);
    }

    onMounted(() => {
      // @ts-ignore
      TerminalService.on('command', commandHandler);

      systemApi.getSystemPreference("toolName").then(responses => {
        if (responses.data) {
          toolName.value = (<string><unknown>responses.data.value);
        }
      })
    })

    onBeforeUnmount(() => {
      // @ts-ignore
      TerminalService.off('command', commandHandler);
      sessionLogin.value = undefined;
    })

    return {
      isActive,
      isPasswordActive,
      isCommandQueued,
      toolName,
      toggleDialog,
      currentPassword,
      keyPressedPassword,
      login
    };
  },
});

import {
  Asset,
  Field,
  FieldType,
  SubtitleOptions, User, UserGroup,
  UserRole
} from "../../../../target/api/de/moovit/titletoolserver/model";
import {defineComponent, getCurrentInstance, nextTick, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {AssetApi, AssetItemApi, FieldApi, SystemApi, UserGroupApi} from "../../../../target/api";
import {useStore} from "vuex";
import {uuid} from "vue-uuid";

export default defineComponent({
  props: [
    "modelValue",
    "currentPage",
    "transitionDir",
    "assetName",
    "selectedAudioTrack",
    "approver",
    "isApprover",
    "groups",
    "translator",
    "translatorEmail",
    "expirationDate",
    "subtitleOption",
    "languages"
  ],
  components: {},

  setup: function (props, context) {
    const assetApi = new AssetApi();
    const systemApi = new SystemApi();
    const assetItemApi = new AssetItemApi();
    const fieldApi = new FieldApi();
    const userGroupApi = new UserGroupApi();

    const route = useRoute();
    const store = useStore();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let valid = true;
    let item = ref<Asset>({});
    let currentAssetName = ref("");
    let currentApprover = ref("");
    let currentGroups = ref<Array<string>>([]);
    let allGroups = ref<Array<string>>([]);
    let currentTranslator = ref<User>();
    let currentExpirationDate: any = ref(null);
    let selectedAudioTrack = ref(null);

    let hasAssetItemSubtitles = ref<boolean>(false);

    let newJobUniqueId = ref<string>();

    let isApprover = ref<boolean>(false);

    let voiceOverTracks = ref<Array<Field>>([]);

    let submitted = ref(false);

    let languages = ref<{
      name: string;
      value: string;
      autoTranslate: boolean;
      autoTranslateAvailable: boolean;
      autoTextToSpeech: boolean
    }[]>([]);

    let selectedLanguages = ref<{
      name: string;
      value: string;
      autoTranslate: boolean;
      autoTranslateAvailable: boolean;
      autoTextToSpeech: boolean
    }[]>([]);

    let selectedLanguage = ref<null | {
      name: string;
      value: string;
      autoTranslate: boolean;
      autoTranslateAvailable: boolean;
      autoTextToSpeech: boolean
    }>();
    let autoTranslatableLanguages = ref<Array<String>>();

    let subtitleOptions = ref<Array<string>>();
    let selectedSubtitleOption = ref<SubtitleOptions>();

    let submit = async () => {
      submitted.value = true;
      if (!!currentAssetName.value
        && currentGroups.value!.length >= 1
        && !!currentTranslator.value
        && !!currentExpirationDate.value
        && selectedLanguages.value!.length >= 1) {
        if (isApprover.value && !currentApprover.value) {
          return;
        }

        context.emit("update:assetName", currentAssetName.value);

        if (currentApprover.value) {
          context.emit("update:approver", currentApprover.value);
          context.emit("update:isApprover", isApprover.value);
        }
        context.emit("update:groups", currentGroups.value);
        context.emit("update:selectedAudioTrack", selectedAudioTrack.value);
        context.emit("update:languages", selectedLanguages.value);
        context.emit("update:translator", currentTranslator.value);
        context.emit("update:expirationDate", currentExpirationDate.value);
        context.emit("update:subtitleOption", selectedSubtitleOption.value);

        context.emit("update:transitionDir", "slide-right");
        context.emit("update:currentPage", props.currentPage + 1);
      }
    };

    let getCircleClass = (page: number, currentPage: number) => {
      if (page < currentPage) return " pi-check-circle col-complete";
      if (page > currentPage) return " pi-circle-on col-menu-color";
      if (page == currentPage) return " pi-circle-on ";
    };

    let getRoles = (rolesString: Array<String>): Array<UserRole> => {
      let roles: Array<UserRole> = [];
      for (const key of rolesString) {
        roles.push(UserRole[key as keyof typeof UserRole]);
      }
      return roles;
    };

    let toggleAutoTranslate = (index: number) => {
      selectedLanguages.value[index].autoTranslate = !selectedLanguages.value[index].autoTranslate;
    }

    let toggleAutoTextToSpeech = (index: number) => {
      selectedLanguages.value[index].autoTextToSpeech = !selectedLanguages.value[index].autoTextToSpeech;
    }

    let checkApprover = () => {
      setTimeout(() => {
        if (!currentApprover.value) {
          isApprover.value = false;
        }
      }, 200);
    }

    let getSelectedLanguages = () => {
      const languages = Object.assign([], selectedLanguages.value);

      if (item.value && item.value.masterAssetItem) {
        languages.push({
          name: "" + item.value.masterAssetItem.language,
          value: "" + item.value.masterAssetItem.language,
          autoTranslate: true,
          autoTranslateAvailable: true,
          autoTextToSpeech: false
        });
      }
      return languages;
    }

    let focusApprover = () => {
      let approverInputElement: any = document.getElementById("approverInput");
      approverInputElement.focus();
    }

    let _calculateBestTranslator = () => {
      assetItemApi.getBestTranslatorForJob(selectedLanguages.value.map(value => {
        return value.name
      })).then(result => {
        if (result.data !== "" && (currentTranslator.value === "" || !currentTranslator.value)) {
          currentTranslator.value = { id: result.data };
          context.emit("update:translator", result.data);
        }
      });
    }


    watch(currentTranslator, (newVal: any, oldVal) => {
      context.emit("update:translator", newVal);

      if (newVal && newVal.defaultLanguage) {
        console.log(languages.value)
        let language = languages.value.find((l) => l.name === newVal.defaultLanguage);

        if (language && !selectedLanguages.value.includes(language)) {
          selectedLanguages.value.push(language!);
        }
      }
    });

    watch(selectedLanguage, (newVal: any) => {
      nextTick(() => {
        if (newVal && newVal.name && selectedLanguages.value.length >= 5) {
          store.commit('SHOW_SNACKBAR', {
            snackbarType: 'error',
            snackbarContent: { t: 'snackbar.languageLimit', e: [5] }
          });
        } else if (newVal && newVal.name && !selectedLanguages.value.includes(newVal) && item.value.masterAssetItem!.language != newVal.name) {
          selectedLanguages.value.push(newVal);

          _calculateBestTranslator();
        }
      }).then(() => {
        selectedLanguage.value = null;
      });
    });

    onMounted(() => {
      console.debug("AddJobViewDetails - onMount");

      assetApi.getAssetById("" + route.query.id).then(response => {
        item.value = response.data;

        fieldApi.getAssetItemAudioTracksByAssetItemId(item.value.masterAssetItem!.id!).then(response => {
          for (const track of response.data) {
            if (track.fieldType == FieldType.VOICEOVER) {
              let item = {
                name: track.srcName,
                id: track.id,
              };

              voiceOverTracks.value.push(item);
            }
          }
        });

        systemApi.getSystemPreference("autoTranslatableLanguages").then(response => {
          autoTranslatableLanguages.value = (<Array<String>>response.data.value);
        })

        fieldApi.hasAssetItemSubtitles(item.value.masterAssetItem!.id!).then(response => {
          hasAssetItemSubtitles.value = response.data;
        })

        if (item.value.groupIds) {
          allGroups.value.push(...item.value.groupIds);
        } else if (item.value.groupID) {
          allGroups.value.push(item.value.groupID)
        }

        currentGroups.value = allGroups.value;

        newJobUniqueId.value = uuid.v1();

        subtitleOptions.value = [];
        for (const subtitleOption in SubtitleOptions) {
          subtitleOptions.value.push(subtitleOption);
        }

        if (props.languages) {
          selectedLanguages.value = props.languages;
        } else {
          selectedLanguages.value = [];
        }

        if (props.assetName) {
          currentAssetName.value = props.assetName;
        } else if (item.value.name) {
          currentAssetName.value = item.value.name;
        }

        if (props.approver) {
          currentApprover.value = props.approver;
        } else if (item.value.approverId) {
          currentApprover.value = item.value.approverId;
        }

        if (props.isApprover) {
          isApprover.value = props.isApprover;
        }

        if (props.selectedAudioTrack) {
          selectedAudioTrack.value = props.selectedAudioTrack;
        }

        if (props.subtitleOption) {
          selectedSubtitleOption.value = props.subtitleOption;
        }

        if (props.translator) {
          currentTranslator.value = props.translator;
        }

        if (props.languages) {
          selectedLanguages.value = props.languages;
        }

        if (props.groups) {
          currentGroups.value = props.groups;
        } else if (item.value.groupIds) {
          currentGroups.value = item.value.groupIds;
        }

        if (props.expirationDate) {
          currentExpirationDate.value = props.expirationDate;
        } else {
          let newExpirationDate = new Date();
          newExpirationDate.setDate(newExpirationDate.getDate() + 14);
          currentExpirationDate.value = newExpirationDate.toISOString().split("T")[0];
        }
      });

      setTimeout(() => {
        languages.value = store.state.languages;
      }, 500);
    });

    return {
      submit,
      submitted,
      currentAssetName,
      currentExpirationDate,
      isApprover,
      currentApprover,
      currentTranslator,
      currentGroups,
      allGroups,
      voiceOverTracks,
      selectedAudioTrack,
      getCircleClass,
      getRoles,
      checkApprover,
      focusApprover,
      toggleAutoTranslate,
      toggleAutoTextToSpeech,
      getSelectedLanguages,
      valid,
      hasAssetItemSubtitles,

      selectedLanguages,
      selectedLanguage,
      autoTranslatableLanguages,

      languages,
      subtitleOptions,
      selectedSubtitleOption,

      newJobUniqueId
    };
  },
});

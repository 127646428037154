import {defineComponent, onMounted, ref, watch} from 'vue';
import {useStore} from 'vuex';

export default defineComponent({
  components: {},
  setup: function () {
    const store = useStore();
    let activeTab = ref(0);

    watch(activeTab, () => {
      let tAdminControl = {
        t: 'activityBreadcrumbs.adminControl',
        e: undefined,
      };
      let tPermissions = {
        t: 'activityBreadcrumbs.permissions',
        e: undefined,
      };

      store.commit('SET_ACTIVITY_BREADCRUMBS', [tAdminControl, tPermissions]);

      if (activeTab.value == 1) {
        let tSettings = {
          t: 'activityBreadcrumbs.settings',
          e: undefined,
        };
        store.commit('SET_ACTIVITY_BREADCRUMBS', [tAdminControl, tSettings]);
      } else if (activeTab.value == 2) {
        let tFontMappings = {
          t: 'activityBreadcrumbs.reports',
          e: undefined,
        };
        store.commit('SET_ACTIVITY_BREADCRUMBS', [tAdminControl, tFontMappings]);
      } else if (activeTab.value == 3) {
        let tReports = {
          t: 'activityBreadcrumbs.replaceMedia',
          e: undefined,
        };
        store.commit('SET_ACTIVITY_BREADCRUMBS', [tAdminControl, tReports]);
      } else if (activeTab.value == 4) {
        let tFontMappings = {
          t: 'activityBreadcrumbs.fontMappings',
          e: undefined,
        };
        store.commit('SET_ACTIVITY_BREADCRUMBS', [tAdminControl, tFontMappings]);
      }
    });

    onMounted(async () => {
      let tAdminControl = {
        t: 'activityBreadcrumbs.adminControl',
        e: undefined,
      };
      let tPermissions = {
        t: 'activityBreadcrumbs.permissions',
        e: undefined,
      };
      store.commit('SET_ACTIVITY_BREADCRUMBS', [tAdminControl, tPermissions]);
    });

    return {
      activeTab,
    };
  },
});

import {defineComponent, getCurrentInstance, onMounted, ref, watch} from 'vue';
import {useStore} from 'vuex';

export default defineComponent({
  props: ['modelValue', 'addAllLanguage', 'isValid', 'disabledLanguages'],
  components: {},
  setup: function (props) {
    const store = useStore();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let allLanguages = ref<{ name: string, value: string }[]>();
    let languages = ref<{ name: string, value: string }[]>();
    let innerModelValue: any = ref(undefined);

    let onComplete = (event: any) => {
      let currentInput = event.query;
      setTimeout(() => {
        let autoCompletePanel: any = document.getElementsByClassName('p-autocomplete-panel');
        if (autoCompletePanel) {
          autoCompletePanel.scrollTop = 0;
        }

        languages.value = filterLanguage(currentInput);

      }, 50);
    }

    let submitLanguage = (input: { name: string, value: string }) => {
      emitter.emit('on-change-language', input);
      if (languages.value) {
        languages.value.splice(languages.value.indexOf(input), 1);
      }
      innerModelValue.value = '';
    }

    let filterLanguage = (input: string) => {
      let origin = allLanguages.value!;
      let array = [];

      for (let val of origin) {
        if ((val.value.toLowerCase()).includes(input.toLowerCase())) {
          array.push(val);
        }
      }

      return array;
    }

    watch(props, () => {
      innerModelValue.value = props.modelValue;
    });

    onMounted(() => {
      languages.value = Object.assign([], store.state.languages);

      if (languages.value && props.addAllLanguage) {
        languages.value.push({name: 'All (*)', value: 'All (*)'});
      }

      allLanguages.value = languages.value;
    });

    return {
      onComplete,
      submitLanguage,
      innerModelValue,
      languages,
    };
  },
});

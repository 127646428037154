import {defineComponent, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {AssetApi, FieldApi} from '../../../target/api';
import {Field} from '../../../target/api/de/moovit/titletoolserver/model';

export default defineComponent({
  props: ["currentPage", "currentFile", "transitionDir", "id", "propsValues"],
  components: {},
  setup: function (props: any, context) {
    const route = useRoute();
    const assetApi = new AssetApi();
    const fieldApi: FieldApi = new FieldApi();
    let selectedLanguage = ref(undefined);

    interface DragItemsList {
      draggable: boolean,
      average: number,
      fields: Array<Field>,
    }

    let draggableLists = ref<Array<DragItemsList>>([]);

    let getCircleClass = (page: number, currentPage: number) => {
      if (page < currentPage) return ' pi-check-circle';
      if (page > currentPage) return ' pi-circle-on col-menu-color';
      if (page == currentPage) return ' pi-circle-on ';
    };

    let items = ref<Array<Field>>([]);

    let duplicateAverage = ref<Array<number>>([]);

    let submitChanges = async () => {
      let newOrder = [];
      for (const item of draggableLists.value) {
        for (const field of item.fields) {
          newOrder.push(field);
        }
      }

      items.value = newOrder;

      for (const field of items.value) {
        if (!field.enabled) {
          fieldApi.deleteField(field.id!);
        } else {
          fieldApi.updateField(field.id!, field);
        }
      }
      console.debug('AddAsset - Checkmark - Submit Changes', JSON.parse(JSON.stringify(items.value)));
    };

    let getAverage = (item: any) => {
      return (item.inPoint + item.outPoint) / 2;
    }

    onMounted(async () => {
      let getAssetResponse: any = await assetApi.getAssetById(
        '' + route.query.id
      );
      console.debug(
        'AddAssetViewCheckmark - ASSET',
        JSON.parse(JSON.stringify(getAssetResponse.data))
      );

      let assetItemFieldsResponse = await fieldApi.getAssetItemFieldsByAssetItemId(
        getAssetResponse.data.masterAssetItem.id
      );

      items.value = assetItemFieldsResponse.data;

      let lastAverage = 0;
      let tempDragItemList: DragItemsList = {draggable: false, fields: [], average: 0};

      items.value.forEach((item: any) => {
        if (item.fieldType == 'CONTINUOUS') item.isChecked = true;

        let itemAverage = (item.inPoint + item.outPoint) / 2;

        if (lastAverage == 0) {
          tempDragItemList = {
            draggable: false,
            average: itemAverage,
            fields: [item]
          }
        } else {
          if (lastAverage === itemAverage) {
            tempDragItemList.fields.push(item);
            tempDragItemList.draggable = true;
          } else {
            draggableLists.value.push(Object.assign({}, tempDragItemList));

            tempDragItemList = {
              draggable: false,
              average: itemAverage,
              fields: [item]
            };
          }
        }

        lastAverage = itemAverage;

        /**
         *
         * [
         *  {
         *    true,
         *    [
         *      {field1},
         *      {field2}
         *    ]
         *  },
         *  {
         *    false,
         *    [
         *      {field3},
         *      {field4}
         *    ]
         *  },
         *  {
         *    true,
         *    [
         *      {field5},
         *      {field6},
         *      {field7}
         *    ]
         *  }
         * ]
         *
         */
      });
      if (tempDragItemList && tempDragItemList.average != 0) {
        draggableLists.value.push(Object.assign({}, tempDragItemList));
      }

      console.debug(draggableLists.value);
      console.debug('AddAssetViewCheckmark - MASTERASSETITEM - FIELDS', assetItemFieldsResponse.data);
    });

    return {
      items,
      getCircleClass,
      selectedLanguage,
      submitChanges,
      duplicateAverage,
      getAverage,
      draggableLists
    };
  },
});

<template>
  <div :class="isFullscreen ? 'fullScreen' : ''">
    <div
      style="
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      "
    >
      <div
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 15px;
        "
      >
        <div
          v-if="!isJobMasterAssetVerify"
          style="display: flex; align-items: center; gap: 10px"
        >
          <div style="display: flex; align-items: center; margin-left: 25px">
            <Flag
              v-for="(assetItem, i) in asset.assetItems"
              :key="i"
              @click="changeCurrentAssetItemIndex(i)"
              :countryFlag="assetItem ? assetItem.language : ''"
              :countryFlagSize="currentAssetItemIndex === i ? 45 : 27"
              :style="
                'cursor: pointer; margin-right: 3px; margin-top: 3px;' +
                  (currentAssetItemIndex === i
                    ? 'opacity: 1.0; width: 47px;'
                    : 'opacity: 0.6')
              "
              class="col-dark-blue bor-transparent"
            />
          </div>
          <div style="display: flex; gap: 5px">
            <Dropdown
              id="voiceOverTracksDropdown"
              v-if="voiceTracks && voiceTracks.length > 0"
              v-model="selectedVoiceOverTrack"
              :placeholder="$t('general.selectVoiceOver')"
              style="
                height: 30px;
                width: 180px;
                padding-left: 10px;
              "
              class="col-dark-blue bor-dark-blue-60 border-radius-24 box-shadow-2"
              :label="$t('general.selectVoiceOver')"
              :emptyMessage="$t('general.noVoiceOverAvailable')"
              :disabled="isAssetItemStatusClosed() && !isJobMasterAssetVerify"
              @change="updateSelectedVoiceOverTrack()"
              optionLabel="srcName"
              :showClear="true"
              :options="getVoiceOverTracks()"
            />
            
            
            <div style="position: relative">
              <TieredMenu ref="voiceOverDropdown" id="overlay_voiceOver" :model="voiceOverDropdownItems" popup/>
              
              <input
                type="file"
                style="display: none"
                ref="audioUploadFileInput"
                accept="audio/*"
                @change="onUploadNewAudio"
              />
              
              <RoundedLabel
                @click="toggleVoiceOverDropdown"
                :leftText="windowWidth < 900 ? '' : $t('general.voiceOver')"
                :disabled="isAssetItemStatusClosed() && !isJobMasterAssetVerify"
                :hoverBorder="true"
                rightIcon="pi pi-bars"
                class="bor-dark-blue-60 col-dark-blue box-shadow-2"
                style="font-size: 24px !important"
              />
            </div>
            
            <div
              v-if="variants.length > 1"
              style="position: relative; display: flex; gap: 15px"
            >
              <RoundedLabel
                @click="showMultiFormatDropdown = !showMultiFormatDropdown"
                v-tooltip="$t('general.multiFormat')"
                :leftText="
                  windowWidth < 900 ? '' : item.assetName.substring(0, 13)
                "
                rightIcon="pi pi-window-maximize"
                :hoverBorder="true"
                class="bor-dark-blue-60 col-dark-blue box-shadow-2"
                style="
                  font-size: 24px !important;
                  min-width: 122px;
                  max-width: 170px;
                "
              />
              <div
                v-if="showMultiFormatDropdown"
                class="bg-tt-white border-radius-8 dropdown"
                style="
                  position: absolute;
                  left: 0;
                  width: 170px;
                  z-index: 3;
                  box-shadow: 0 5px 10px -4px rgba(0, 0, 0, 0.5);
                "
              >
                <div
                  v-for="(variant, index) in variants"
                  @click="onChangeVariant(variant)"
                  class="hover-highlight col-dark-blue dropdown-item"
                >
                  {{variant.assetName.replace("MAIN", "")}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else/>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        
        <div style="display: flex; height: 35px; gap: 1px">
          <div v-if="onlySubtitleOrTextlayer === true">
            <RoundedLabel
              v-if="windowWidth > 980"
              :text="$t('general.subTitle')"
              class="col-dark-blue bor-dark-blue-30 border-both bg-menu-color-2"
              noHover="true"
              style="height: 30px; width: 175px"
            />
          </div>
          
          <div v-if="onlySubtitleOrTextlayer === null" style="display: flex">
            <RoundedLabel
              :text="windowWidth < 850 ? '' : $t('general.textLayer')"
              v-tooltip="$t('tooltip.showTextlayer')"
              class="col-dark-blue bor-dark-blue-30 border-right"
              style="height: 30px; cursor: pointer"
              :left-icon="windowWidth < 850 ? 'pi pi-bars' : ''"
              :style="windowWidth < 850 ? 'width: 40px;' : 'width: 80px;'"
              :class="
                showSubtitleFields === false
                  ? 'bg-menu-color'
                  : 'bg-menu-color-2'
              "
              @click="onClickChangeFieldType(true)"
            />
            <RoundedLabel
              :text="windowWidth < 850 ? '' : $t('tooltip.allFields')"
              v-tooltip="$t('tooltip.showAllFields')"
              class="col-dark-blue bor-dark-blue-30"
              style="
                height: 30px;
                border-radius: 0;
                cursor: pointer;
              "
              :left-icon="windowWidth < 850 ? 'pi pi-align-justify' : ''"
              :style="windowWidth < 850 ? 'width: 40px;' : 'width: 90px;'"
              :class="
                showSubtitleFields === null
                  ? 'bg-menu-color '
                  : 'bg-menu-color-2 '
              "
              @click="onClickChangeFieldType(null)"
            />
            <RoundedLabel
              :text="windowWidth < 850 ? '' : $t('general.subTitle')"
              v-tooltip="$t('tooltip.showSubtitle')"
              class="col-dark-blue bor-dark-blue-30 border-left"
              :left-icon="windowWidth < 850 ? 'pi pi-minus' : ''"
              style="height: 30px; cursor: pointer"
              :style="windowWidth < 850 ? 'width: 40px' : 'width: 90px'"
              :class="
                showSubtitleFields === true
                  ? 'bg-menu-color'
                  : 'bg-menu-color-2'
              "
              @click="onClickChangeFieldType(false)"
            />
          </div>
          
          <div v-if="onlySubtitleOrTextlayer === false">
            <RoundedLabel
              v-if="windowWidth > 1050"
              :text="$t('general.textLayer')"
              class="col-dark-blue bor-dark-blue-30 border-both bg-menu-color-2"
              noHover="true"
              style="height: 30px; width: 175px"
            />
          </div>
        </div>
        
        <div style="position: relative">
          <RoundedLabel
            @click="showReplaceMediaDropdown = !showReplaceMediaDropdown"
            :disabled="isAssetItemStatusClosed() && !isJobMasterAssetVerify"
            rightIcon="pi pi-bars"
            :left-text="windowWidth < 1050 ? '' : $t('general.dataMenu')"
            class="bor-dark-blue-60 col-dark-blue box-shadow-2"
            :hoverBorder="true"
            v-tooltip="$t('general.replaceMediaAdvanced')"
            style="font-size: 24px !important"
          />
          <div
            v-if="showReplaceMediaDropdown"
            class="bg-tt-white border-radius-8 dropdown"
            style="
              position: absolute;
              left: 0;
              width: 160px;
              margin-left: -40px;
              z-index: 3;
              box-shadow: 0 5px 10px -4px rgba(0, 0, 0, 0.5);
            "
            :style="
              windowWidth < 1050
                ? 'margin-left: -120px;'
                : 'margin-left: -40px;'
            "
          >
            <div
              v-if="hasPermission('job_view/generate_xls')"
              @click="onClickGenerateXLS()"
              v-bind:class="{
                'p-disabled': isItemRendering() || isItemClosed(),
              }"
              class="hover-highlight col-dark-blue dropdown-item"
            >
              <i class="pi pi-cloud-download" style="margin-right: 14px"></i
              >{{$t("general.generateXls")}}
            </div>
            
            <div
              v-if="hasPermission('job_view/import_xls')"
              @click="
                fileInput.click();
                showReplaceMediaDropdown = false;
              "
              v-bind:class="{
                'p-disabled': isItemRendering() || isItemClosed(),
              }"
              class="hover-highlight col-dark-blue dropdown-item"
            >
              <i class="pi pi-cloud-upload" style="margin-right: 14px"></i
              >{{$t("general.importXls")}}
            </div>
            
            <input
              type="file"
              style="display: none"
              ref="fileInput"
              accept="application/vnd.ms-excel"
              @change="onClickImportXLS"
            />
          </div>
        </div>
      </div>
      
      <VoiceOverRecorder :visible="false" :videoUrl="getVideoURL(item)" :item="item"/>
      
      <div
        style="
          display: flex;
          justify-content: center;
          width: calc(100vw - 120px);
          height: calc(100vh - 225px);
          margin-top: 5px;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 51%;
          "
        >
          <!-- Video & TextArea -->
          <div
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 20px;
            "
          >
            <div
              :class="
                currentPreviewFrameLink !== ''
                  ? 'bor-complete'
                  : 'bor-dark-blue'
              "
              class="border-radius-16"
              style="
                background-color: black;
                border-width: 2px;
                z-index: 2;
                overflow: hidden;
              "
            >
              <div
                class="video-container"
                :style="
                  currentPreviewFrameLink !== ''
                    ? 'display: none; '
                    : 'display: flex; '
                "
                style="
                  align-items: center;
                  width: 100%;
                  height: calc(70vh - 160px);
                "
              >
                <video
                  id="jobVideo"
                  class="video-js"
                  ref="playerVideoControls"
                  @play="onVideoPlay()"
                  @pause="onVideoPause()"
                  @timeupdate="onVideoUpdate()"
                  @ended="onVideoEnded()"
                  style="
                    width: 100%;
                    height: inherit;
                    background: transparent;
                    pointer-events: none;
                  "
                />
                
                <div
                  v-if="isRendering || renderError"
                  class="flex-center bg-transparent col-white"
                  style="position: absolute; height: inherit; width: inherit"
                  :style="
                    isRendering
                      ? ' backdrop-filter: blur(4px);'
                      : ' backdrop-filter: none;'
                  "
                >
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 220px;
                      width: 220px;
                      border-radius: 140px;
                      background-color: rgba(0, 0, 0, 0.5);
                      box-shadow: 0 0 15px black;
                    "
                  >
                    <div>
                      <div style="position: absolute; color: white">
                        <div
                          v-if="!renderError"
                          style="margin-top: -8px; margin-left: -16px"
                        >
                          {{renderProgress}}%
                        </div>
                        <div
                          v-else
                          style="
                            width: 100px;
                            margin-top: -16px;
                            margin-left: -50px;
                          "
                        >
                          {{$t("snackbar.renderingFailed")}}
                        </div>
                      </div>
                      <VueCircle
                        style="
                          position: absolute;
                          width: 220px;
                          height: 220px;
                          margin-top: -110px;
                          margin-left: -110px;
                        "
                        v-model:percent="renderProgressCircle"
                        :show-percent="false"
                        empty-color="transparent"
                        :fill-color="renderError ? 'red' : '#47DF84'"
                        :border-width="8"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="video-container bor-complete"
                :style="
                  currentPreviewFrameLink === ''
                    ? 'display: none'
                    : 'display: flex'
                "
                style="
                  align-items: center;
                  width: 100%;
                  height: calc(70vh - 160px);
                  border-width: 2px;
                "
              >
                <img
                  :src="currentPreviewFrameLink"
                  class="max-size-image"
                  style="
                    width: 100%;
                    height: 100%;
                    background-color: var(--white);
                  "
                />
                <label
                  style="
                    position: absolute;
                    gap: 5px;
                    color: var(--complete);
                    top: 5px;
                    left: 7px;
                  "
                >
                  <i class="pi pi-info-circle" style="font-size: 1rem"></i>
                  {{$t("job.previewFrameDisclaimer")}}
                </label>
              </div>
              <div
                :class="
                  currentPreviewFrameLink !== ''
                    ? 'bg-complete-light'
                    : 'bg-menu-color-2'
                "
                style="
                  height: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 5px;
                  z-index: 1;
                  border-bottom-right-radius: 13px;
                  border-bottom-left-radius: 13px;
                "
              >
                <div style="display: flex; align-items: center; gap: 20px">
                  <RoundedLabel
                    id="job-video-play-button"
                    :leftIcon="playButtonIcon"
                    :disabled="
                      isAssetItemStatusRendering() ||
                        isAssetItemStatusScheduled() ||
                        isAssetItemStatusFailed()
                    "
                    v-tooltip="$t(playButtonText)"
                    :class="
                      currentPreviewFrameLink !== ''
                        ? 'bg-complete-light'
                        : 'bg-menu-color-2'
                    "
                    class="border-radius-8 col-dark-blue"
                    :noHover="true"
                    style="
                      width: 25px;
                      padding-left: 8px;
                      height: 25px;
                      border: none;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                    @click="onClickPlayButton()"
                  />
                  <div>
                    <RoundedLabel
                      :leftIcon="
                        'pi pi-' +
                          (pauseOnNextField
                            ? 'chevron-circle-right'
                            : 'angle-double-right')
                      "
                      :class="
                        currentPreviewFrameLink !== ''
                          ? 'bg-complete-light'
                          : 'bg-menu-color-2'
                      "
                      class="col-dark-blue"
                      :noHover="true"
                      v-tooltip="$t('tooltip.jobAutoPlay')"
                      style="width: 30px; height: 25px; font-size: 20px"
                      @click="onClickPauseOnNextFieldButton()"
                    />
                  </div>
                  
                  <div>
                    <span
                      v-if="showVolumeSlider"
                      class="bg-tt-white border-radius-16 box-shadow-2"
                      style="
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 130px;
                        width: 30px;
                        margin-top: -130px;
                        margin-left: -7px;
                      "
                    >
                      <Slider
                        v-model="playerVolume"
                        orientation="vertical"
                        :tooltips="false"
                        :step="10"
                        style="width: 4px; height: 100px"
                        @change="onChangeVideoSliderVolume"
                      />
                    </span>
                    <i
                      class="col-dark-blue"
                      :class="
                        playerVolume === 0
                          ? 'pi pi-volume-off'
                          : playerVolume <= 50
                          ? 'pi pi-volume-down'
                          : 'pi pi-volume-up'
                      "
                      style="font-size: 18px; margin-top: 2px; cursor: pointer"
                      v-tooltip="$t('tooltip.volume')"
                      @click="showVolumeSlider = !showVolumeSlider"
                    >
                    </i>
                  </div>
                  
                  <div
                    v-tooltip="$t('job.changePlayerRate')"
                    @click="onChangeVideoSpeed()"
                  >
                    <div style="display: flex; align-items: center">
                      <div
                        class="col-dark-blue"
                        style="
                          width: 25px;
                          margin-right: 3px;
                          display: flex;
                          align-items: center;
                          justify-content: flex-end;
                        "
                      >
                        {{playerSpeed}}x
                      </div>
                      <i
                        class="col-dark-blue"
                        :class="
                          playerSpeed === 1
                            ? 'pi pi-angle-right'
                            : 'pi pi-angle-double-right'
                        "
                        :style="
                          playerSpeed === 1
                            ? 'margin-left: -4px; margin-right: 4px'
                            : playerSpeed === 1.5
                            ? ''
                            : ''
                        "
                        style="font-size: 18px; cursor: pointer"
                      />
                      <i
                        v-if="playerSpeed === 2"
                        class="pi pi-angle-right col-dark-blue"
                        style="
                          font-size: 18px;
                          margin-left: -7px;
                          cursor: pointer;
                        "
                      />
                      <div v-else style="width: 11px"></div>
                    </div>
                  </div>
                  <div>
                    {{
                      isJobLoaded
                        ? getCurrentTimecode(playerVideoControls.currentTime)
                        : "00 : 00"
                    }}
                    /
                    {{
                      isJobLoaded
                        ? getCurrentTimecode(playerVideoControls.duration)
                        : "00 : 00"
                    }}
                  </div>
                </div>
                <div>
                  <!--                TODO: Timeline Zoom -->
                </div>
                <div>
                  <RoundedLabel
                    @click="isFullscreen = !isFullscreen"
                    noHover="true"
                    :rightIcon="
                      'pi ' +
                        (isFullscreen
                          ? 'pi-window-minimize'
                          : 'pi-window-maximize')
                    "
                    class="col-dark-blue"
                    size="l"
                    style="margin-right: 10px; cursor: pointer"
                  />
                </div>
              </div>
            </div>
            
            <div
              style="
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-left: 10px;
                margin-right: 10px;
              "
            >
              <div>
                <Timeline
                  :timecode="playerSliderPosition"
                  :assetItemId="item.id ? item.id : assetItem.id"
                  :videoLength="playerVideoControls.duration"
                />
              </div>
              <div>
                <FieldVisualizer
                  v-if="isJobLoaded"
                  :assetId="item.id"
                  :videoLength="playerVideoControls.duration"
                  :videoPosition="playerVideoControls.currentTime"
                  :fieldIds="item.fields"
                />
              </div>
            </div>
          </div>
          <div
            style="width: 100%; display: flex"
            v-if="!isJobMasterAssetVerify"
          >
            <RoundedLabel
              v-if="hasPermission('job_view/advanced_settings') && !(isAssetItemStatusRendering() ||
                  isAssetItemStatusScheduled() ||
                  isAssetItemStatusClosed() ||
                  isAssetItemStatusFailed() ||
                  currentField.fieldType !== 'CONTINUOUS')"
              :text="$t('general.advancedSettings')"
              class="col-dark-blue"
              rightIcon="pi pi-angle-down"
              style="height: 20px"
              :noHover="true"
              @click="onClickAdvancedSettings"
            />
            
            <OverlayPanel
              ref="advancedSettingsOverlayPanel"
              @mouseleave="saveCurrentFieldValues"
              :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
              style="overflow-x: auto; overflow-y: hidden; box-shadow: 0px 0px 5px 5px var(--dark-blue-30);"
            >
              <div
                style="
                  width: 800px;
                  height: 200px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                :style="'background-color: var(--white);'"
              >
                <div style="width: 50%; height: 100%">
                  <div
                    style="
                      width: 100%;
                      height: 33%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      gap: 5px;
                    "
                  >
                    <div class="col-dark-blue">
                      {{$t("general.offset")}} X (px):
                    </div>
                    <Slider
                      v-model="advancedSettingsOffsetX"
                      :tooltips="false"
                      style="width: 200px; height: 4px"
                      :min="-400"
                      :max="400"
                    />
                    <div class="col-dark-blue" style="display: flex; gap: 5px">
                      <InputText
                        v-model="advancedSettingsOffsetX"
                        class="border-radius-4 col-dark-blue bor-menu-color bg-transparent"
                        style="width: 65px; height: 18px"
                      />
                      <div>px</div>
                    </div>
                  </div>
                  
                  <div
                    style="
                      width: 100%;
                      height: 33%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      gap: 5px;
                    "
                  >
                    <div class="col-dark-blue">
                      {{$t("general.offset")}} Y (px):
                    </div>
                    <Slider
                      v-model="advancedSettingsOffsetY"
                      :tooltips="false"
                      style="width: 200px; height: 4px"
                      :min="-400"
                      :max="400"
                    />
                    <div class="col-dark-blue" style="display: flex; gap: 5px">
                      <InputText
                        v-model="advancedSettingsOffsetY"
                        class="border-radius-4 col-dark-blue bor-menu-color bg-transparent"
                        style="width: 65px; height: 18px"
                      />
                      <div>px</div>
                    </div>
                  </div>
                  <div
                    style="
                      width: 100%;
                      height: 33%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      gap: 5px;
                    "
                  >
                    <RoundedLabel
                      :text="$t('general.close')"
                      leftIcon="pi pi-times"
                      v-tooltip="$t('general.close')"
                      class="col-dark-blue bor-light-blue"
                      style="
                    height: 40px;
                    width: 120px;
                    cursor: pointer;
                  "
                      :style="
                        windowWidth < 1400 ? 'width: 60px' : 'width: 160px'
                      "
                      @click="onClickAdvancedSettings"
                    />
                  </div>
                </div>
                
                <div style="width: 50%; height: 100%">
                  <div
                    style="
                      width: 100%;
                      height: 33%;
                      display: flex;
                      align-items: center;
                      justify-content: start;
                      padding-left: 35px;
                      gap: 5px;
                    "
                  >
                    <div class="col-dark-blue">
                      {{$t("general.fontAdjust")}}:
                    </div>
                    <Dropdown
                      v-model="advancedSettingsFontAdjust"
                      class="border-radius-4 col-dark-blue bor-menu-color bg-transparent"
                      style="
                        width: 120px;
                        height: 20px;
                        float: left;
                        display: flex;
                        align-items: center;
                        padding-left: 10px;
                        font-size: 14px;
                      "
                      :options="[
                        { name: 'Default', value: 'DEFAULT' },
                        { name: 'Automatic', value: 'AUTOMATIC' },
                        { name: 'Manual', value: 'MANUAL' },
                      ]"
                      optionLabel="name"
                      optionValue="value"
                    />
                  </div>
                  
                  <div
                    style="
                      width: 100%;
                      height: 33%;
                      display: flex;
                      align-items: center;
                      justify-content: start;
                      padding-left: 35px;
                      gap: 5px;
                    "
                  >
                    <div class="col-dark-blue">
                      {{$t("general.fontSize")}} (%):
                    </div>
                    <InputText
                      v-if="advancedSettingsFontAdjust !== 'MANUAL'"
                      v-model="advancedSettingsFontSizePercent"
                      class="border-radius-4 col-dark-blue bor-menu-color bg-transparent"
                      style="width: 102px; height: 18px; padding-left: 16px"
                    />
                    <Slider
                      v-if="advancedSettingsFontAdjust === 'MANUAL'"
                      v-model="advancedSettingsFontSizePercent"
                      :tooltips="false"
                      style="width: 10rem; height: 4px"
                      :min="1"
                      :max="200"
                    />
                    <div
                      v-if="advancedSettingsFontAdjust === 'MANUAL'"
                      class="col-dark-blue"
                      style="width: 30px; padding-left: 10px"
                    >
                      {{advancedSettingsFontSizePercent}}%
                    </div>
                  </div>
                  
                  <div
                    style="width: 100%;
                      height: 33%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      padding-left: 35px;"
                  >
                    <RoundedLabel
                      :text="windowWidth < 1400 ? '' : $t(renderButtonText)"
                      :disabled="
                        ((isAssetItemStatusClosed() ||
                          isAssetItemStatusScheduled()) &&
                          !isJobMasterAssetVerify &&
                          renderProgress !== 0 &&
                          renderProgress !== 100) ||
                          (isAssetItemStatusClosed() && !isAssetItemStatusReadyToApprove())
                      "
                      leftIcon="pi pi-refresh"
                      v-tooltip="
                        windowWidth < 1400
                          ? $t('general.updateAndWatch')
                          : $t('job.renderAdvanced')
                      "
                      class="col-dark-blue bor-complete bg-complete-light"
                      style="
                    height: 40px;
                    width: 120px;
                    cursor: pointer;
                  "
                      :style="
                        windowWidth < 1400 ? 'width: 60px' : 'width: 160px'
                      "
                      @click="
                        onClickUpdateAndWatchButton(false);
                        $forceUpdate();
                      "
                    />
                  </div>
                </div>
              </div>
            </OverlayPanel>
          </div>
        </div>
        
        <!--      Text Fields-->
        <div style="width: 49%; padding-left: 10px">
          <div
            style="
              display: flex;
              flex-direction: column;
              height: calc(70vh - 130px);
            "
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div style="display: flex; width: 100%">
                <i
                  class="pi pi-star col-dark-blue"
                  style="margin-right: 10px"
                ></i>
                <div class="col-dark-blue">
                  {{
                    isJobMasterAssetVerify
                      ? $t("general.autoTranslated")
                      : $t("general.master")
                  }}
                </div>
              </div>
              <RoundedLabel
                v-if="!isJobMasterAssetVerify"
                :countryFlag="
                  asset.masterAssetItem ? asset.masterAssetItem.language : ''
                "
                noHover="true"
                noBorder="true"
                languageCode="true"
                :text="
                  asset.masterAssetItem ? asset.masterAssetItem.language : ''
                "
                style="width: 120px; margin-left: 48px"
                class="col-dark-blue"
              />
            </div>
            <Textarea
              v-if="!isAdvancedField"
              v-model="currentMasterValue"
              class="border-radius-8 bg-light-blue bor-transparent col-dark-blue"
              disabled
              :value="currentMasterValue"
              style="
                width: 100%;
                flex-grow: 1;
                margin-bottom: 24px;
                padding: 12px 0 0 12px;
                font-size: calc(8px + 6 * ((80vw - 390px) / 680));
              "
              :style="isTextRTL ? 'direction: rtl' : ''"
            />
            
            <AdvancedTextArea
              v-if="isAdvancedField"
              :assetItemId="assetItem.id"
              :firstText="firstMainText"
              :mainText="currentMasterValue"
              :lastText="lastMainText"
              :rtl-text="isTextRTL"
              :disableSwitches="true"
              :disabled="true"
            />
            
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 5px;
              "
            >
              <div style="display: flex">
                <i
                  class="pi pi-pencil col-dark-blue"
                  style="margin-right: 10px"
                ></i>
                <div class="col-dark-blue">
                  {{
                    isJobMasterAssetVerify
                      ? $t("general.newMasterValue")
                      : $t("general.translation")
                  }}
                </div>
              </div>
              <RoundedLabel
                v-if="!isJobMasterAssetVerify"
                :countryFlag="item ? item.language : ''"
                noHover="true"
                noBorder="true"
                languageCode="true"
                :text="item ? item.language : ''"
                style="width: 120px; margin-left: 48px"
                class="col-dark-blue"
              />
            </div>
            <Textarea
              v-if="!isAdvancedField"
              id="currentTranslation"
              v-model="currentTranslationValue"
              :value="currentTranslationValue"
              class="border-radius-8 bg-tt-white bor-dark-blue col-dark-blue"
              :disabled="
                (isAssetItemStatusRendering() ||
                  isAssetItemStatusClosed() ||
                  !currentField.enabled ||
                  isAssetItemStatusScheduled() ||
                  isAssetItemStatusFailed()) &&
                  !isJobMasterAssetVerify
              "
              @blur="saveCurrentFieldValues()"
              style="
                width: 100%;
                flex-grow: 1;
                padding: 12px 0 0 12px;
                font-size: calc(8px + 6 * ((80vw - 390px) / 680));
              "
              :style="isTextRTL ? 'direction: rtl' : ''"
            />
            
            <AdvancedTextArea
              v-else
              :assetItemId="assetItem.id"
              :firstText="firstTranslationText"
              :mainText="currentTranslationValue"
              :lastText="lastTranslationText"
              :rtl-text="isTextRTL"
              :disabled="
                (isAssetItemStatusRendering() ||
                  isAssetItemStatusClosed() ||
                  isAssetItemStatusScheduled() ||
                  isAssetItemStatusFailed()) &&
                  !isJobMasterAssetVerify
              "
              :disableSwitches="true"
            />
          </div>
          
          <div
            style="
              display: flex;
              flex-direction: column;
              height: calc(30vh - 100px);
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                width: 100%;
                margin-top: 12px;
              "
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <RoundedLabel
                  :text="$t('general.view')"
                  :rightIcon="'pi pi-' + (isAdvancedField ? 'minus' : 'bars')"
                  class="col-dark-blue bg-menu-color-2 bor-dark-blue"
                  v-tooltip="$t('tooltip.jobChangeInputField')"
                  style="
                    width: 80px;
                    height: 22px;
                    font-size: 20px;
                    border-radius: 12px 12px 0 0;
                    border-width: 1px 1px 0 1px;
                  "
                  @click="onClickChangeInputField()"
                />
                
                <div
                  style="display: flex"
                  class="box-shadow-2 border-radius-24"
                >
                  <RoundedLabel
                    :text="windowWidth < 1234 ? '' : $t('general.prevShort')"
                    leftIcon="pi pi-angle-left"
                    v-tooltip="$t('tooltip.jobPrevButton')"
                    class="col-dark-blue bor-dark-blue bg-menu-color-2"
                    style="
                      height: 40px;
                      cursor: pointer;
                      border-top-right-radius: 0;
                      border-bottom-right-radius: 0;
                    "
                    :style="windowWidth < 1234 ? 'width: 30px' : 'width: 60px'"
                    @click="onClickPreviousFieldButton()"
                  />
                  <RoundedLabel
                    :text="
                      (currentFieldCounter >= 0
                        ? currentFieldCounter + 1
                        : '0') +
                        ' / ' +
                        fields.length
                    "
                    :noHover="true"
                    class="col-dark-blue bor-dark-blue"
                    style="
                      height: 40px;
                      width: 55px;
                      cursor: pointer;
                      border-radius: 0;
                      border-width: 1px 0 1px 0;
                    "
                  />
                  <RoundedLabel
                    :text="windowWidth < 1234 ? '' : $t('general.nextShort')"
                    rightIcon="pi pi-angle-right"
                    v-tooltip="$t('tooltip.jobNextButton')"
                    class="col-dark-blue bor-dark-blue bg-menu-color-2"
                    style="
                      height: 40px;
                      cursor: pointer;
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                    "
                    :style="windowWidth < 1234 ? 'width: 30px' : 'width: 60px'"
                    @click="onClickNextFieldButton()"
                  />
                </div>
                
                <div
                  id="savedElement"
                  style="position: absolute; margin-top: 70px"
                  class="col-complete saved-element"
                >
                  {{$t("job.fieldSaved")}}
                </div>
              </div>
              
              <div
                style="display: flex; margin-top: 25px"
                class="box-shadow-2 border-radius-24"
              >
                <RoundedLabel
                  :text="windowWidth < 1400 ? '' : $t('general.framePreview')"
                  :disabled="
                    (isAssetItemStatusClosed() ||
                      isAssetItemStatusScheduled()) &&
                      !isJobMasterAssetVerify
                  "
                  leftIcon="pi pi-image"
                  v-tooltip="
                    windowWidth < 1400
                      ? $t('job.renderFrame')
                      : $t('job.renderFrameAdvanced')
                  "
                  class="col-dark-blue bor-complete bg-complete-light"
                  style="
                    height: 40px;
                    cursor: pointer;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                  "
                  :style="windowWidth < 1400 ? 'width: 60px' : 'width: 160px'"
                  @click="onClickRenderCurrentFrame()"
                />
                <RoundedLabel
                  :text="windowWidth < 1400 ? '' : $t(renderButtonText)"
                  :disabled="
                    ((isAssetItemStatusClosed() ||
                      isAssetItemStatusScheduled()) &&
                      !isJobMasterAssetVerify &&
                      renderProgress !== 0 &&
                      renderProgress !== 100) ||
                      (isAssetItemStatusClosed() && !isAssetItemStatusReadyToApprove())
                  "
                  leftIcon="pi pi-refresh"
                  v-tooltip="
                        windowWidth < 1400
                          ? $t('general.updateAndWatch')
                          : $t('job.renderAdvanced')
                      "
                  class="col-dark-blue bor-complete bg-complete-light"
                  style="
                    height: 40px;
                    width: 120px;
                    cursor: pointer;
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                  "
                  :style="
                        windowWidth < 1400 ? 'width: 60px' : 'width: 160px'
                      "
                  @click="
                    onClickUpdateAndWatchButton(false);
                    $forceUpdate();
                  "
                />
              </div>
            </div>
            <div style="display: none; margin-top: 12px">
              <Button
                :icon="
                  'pi pi-' +
                    (pauseOnNextField
                      ? 'chevron-circle-right'
                      : 'angle-double-right')
                "
                class="border-radius-8 col-dark-blue bor-dark-blue bg-light-blue"
                v-tooltip="$t('tooltip.jobAutoPlay')"
                style="
                  padding-left: 10px;
                  width: 50px;
                  height: 50px;
                  font-size: 20px;
                  margin-left: 12px;
                "
                @click="onClickPauseOnNextFieldButton()"
              />
              <Button
                :icon="'pi pi-' + (isAdvancedField ? 'minus' : 'bars')"
                class="border-radius-8 col-dark-blue bor-dark-blue bg-light-blue"
                v-tooltip="$t('tooltip.jobChangeInputField')"
                style="
                  padding-left: 10px;
                  width: 50px;
                  height: 50px;
                  font-size: 20px;
                  margin-left: 12px;
                "
                @click="onClickChangeInputField()"
              />
            </div>
            
            <div style="display: flex; justify-content: end; margin-top: 5px">
              <div v-if="!isJobMasterAssetVerify">
                <div v-if="isJobLoaded">
                  <Button
                    v-if="isAssetItemStatusClosed() && !isAssetItemStatusReadyToApprove()"
                    @click="onClickGenerateAssetFromJob()"
                    :label="$t('general.generateAssetFromJob')"
                    class="border-radius-8 col-white bor-transparent bg-dark-blue"
                    :disabled="isButtonClicked"
                    style="
                      height: 40px;
                      margin-right: 10px;
                      padding: 6px 15px 5px 16px;
                    "
                  />
                  
                  <Button
                    @click="onClickDownloadOrComplete"
                    :label="
                      isAssetItemStatusClosed()
                        ? isAssetItemStatusReadyToApprove()
                          ? $t('general.verify')
                          : $t('general.download')
                        : item.approverID
                        ? $t('general.submitJob')
                        : $t('general.completeJob')
                    "
                    :disabled="
                      isAssetItemStatusRendering() ||
                        isAssetItemStatusScheduled() ||
                        isAssetItemStatusFailed()
                    "
                    class="border-radius-8 col-white bor-transparent bg-dark-blue"
                    style="height: 40px; padding: 6px 15px 5px 16px"
                  />
                  
                  <Button
                    v-if="
                      isAssetItemStatusClosed() &&
                        !isAssetItemStatusReadyToApprove() &&
                        onlySubtitleOrTextlayer !== false
                    "
                    @click="onClickDownloadSrt()"
                    :label="$t('general.downloadSrt')"
                    class="border-radius-8 col-white bor-transparent bg-dark-blue"
                    style="
                      height: 40px;
                      margin-left: 10px;
                      padding: 6px 15px 5px 16px;
                    "
                  />
                  
                  <Button
                    v-if="
                      isAssetItemStatusClosed() &&
                        !isAssetItemStatusReadyToApprove() &&
                        onlySubtitleOrTextlayer !== false &&
                        isUserAdmin()
                    "
                    @click="onClickResetJob()"
                    :label="$t('general.resetJob')"
                    class="border-radius-8 col-white bor-transparent bg-dark-blue"
                    style="
                      height: 40px;
                      margin-left: 10px;
                      padding: 6px 15px 5px 16px;
                    "
                  />
                  
                  
                  <!-- todo: implement in seperat feature ticket
                  
                  //only show button if job is completed (and masterFieldsEdited === true
                  
                  <Button
                    v-if="item.masterFieldsEdited && isAssetItemStatusClosed()"
                    @click="approveMasterFieldsDialog = true"
                    :label="$t('general.approveMasterFields')"
                    :disabled="!hasPermission('job_view/approveMasterFieldChange')"
                    class="border-radius-8 col-white bor-transparent bg-dark-blue"
                    style="height: 40px; margin-left: 10px; padding: 6px 15px 5px 16px"
                  />
                  -->
                  
                  <pDialog
                    v-model:visible="approveMasterFieldsDialog"
                    style="width: 40%"
                    :header="$t('dialog.approveMasterFields')"
                  >
                    <div
                      style="
                        display: flex;
                        width: 100%;
                        padding: 10px;
                        justify-content: space-between;
                      "
                    >
                      <Button
                        :label="$t('general.reject')"
                        @click="onClickApproveMasterFields(false)"
                        class="border-radius-8 bor-dark-blue bg-tt-white"
                        style="
                          height: 40px;
                          margin-left: 10px;
                          padding: 6px 15px 5px 16px;
                        "
                      />
                      <Button
                        :label="$t('general.approve')"
                        @click="onClickApproveMasterFields(true)"
                        class="border-radius-8 col-white bor-transparent bg-dark-blue"
                        style="
                          height: 40px;
                          margin-left: 10px;
                          padding: 6px 15px 5px 16px;
                        "
                      />
                    </div>
                  </pDialog>
                  
                  <pDialog
                    v-model:visible="completeDialogVisible"
                    style="width: 40%"
                    :header="
                      customerMetadata.length <= 0
                        ? $t(
                            item.approverID
                              ? 'dialog.submitJob'
                              : 'dialog.completeJob',
                            [asset.name]
                          )
                        : $t('dialog.areYouSure')
                    "
                  >
                    <div style="margin-bottom: 66px">
                      <div v-for="metadata in customerMetadata">
                        {{metadata.name}}
                        <CustomerMetadataInput
                          :metadata="metadata"
                          :autoSave="true"
                          :bigString="true"
                          bigStringHeight="147px"
                          style="width: 100%; height: 150px"
                        />
                      </div>
                    </div>
                    
                    <div
                      style="
                        display: flex;
                        width: 100%;
                        padding: 10px;
                        justify-content: space-between;
                      "
                    >
                      <Button
                        :label="$t('general.cancel')"
                        @click="completeDialogVisible = false"
                        class="border-radius-8 bor-dark-blue bg-tt-white"
                        style="
                          height: 40px;
                          margin-left: 10px;
                          padding: 6px 15px 5px 16px;
                        "
                      />
                      
                      <Button
                        :label="
                          $t(
                            item.approverID
                              ? 'general.submitJob'
                              : 'general.completeJob'
                          )
                        "
                        @click="onClickFinalCompleteJob()"
                        class="border-radius-8 col-white bor-transparent bg-dark-blue"
                        style="
                          height: 40px;
                          margin-left: 10px;
                          padding: 6px 15px 5px 16px;
                        "
                      />
                    </div>
                  </pDialog>
                </div>
              </div>
              
              <Button
                v-else
                @click="onClickVerifyMasterAsset()"
                :label="$t('general.verify')"
                class="border-radius-8 col-white bor-transparent bg-dark-blue"
                style="
                  height: 40px;
                  padding: 6px 15px 5px 16px;
                  margin-left: 24px;
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.dropdown {
  transition: all 0.2s !important;
}

.video-container {
  position: relative;
  width: 100%;
  height: 60vh;
  
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.max-size-image {
  background-color: black !important;
  height: 100%;
  width: 100%;
  border: none;
  object-fit: contain;
}

.border-left {
  border-radius: 0 10px 10px 0 !important;
  border-left: none !important;
}

.border-right {
  border-radius: 10px 0 0 10px !important;
  border-right: none !important;
}

.border-both {
  border-radius: 10px 10px 10px 10px !important;
}

.saved-element {
  opacity: 0;
}

.saved-element-animation {
  animation: saved-animation 3s ease-out 0s 1 normal forwards;
}

@keyframes saved-animation {
  0% {
    opacity: 1;
  }
  
  40% {
    opacity: 1;
  }
  
  100% {
    opacity: 0;
  }
}
</style>

<script src="./JobItem.ts"></script>

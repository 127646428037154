<template>
  <div @click="toggleDialog()" class="hover-icon transitions" style="cursor: pointer; padding: 5px">
    Click to extend
  </div>
  
  <pDialog v-model:visible="isActive" header="Customer Metadata" style="width: 70%">
    <div style="display: flex; flex-direction: column">
      <div @click="toggleTemplateDialog()"
           class="hover-master-video border-radius-16 transitions bg-dark-blue col-white"
           style="cursor: pointer; margin: 5px; padding: 5px; width: 100px">
        Click to add
      </div>
      
      <div style="margin-top: 15px; padding-top: 15px; border-top: black 1px solid">
        <div style="display: flex; margin-left: 50px; align-items: center; justify-content: space-around">
          <div style="width: 100px">
            Name
          </div>
          <div style="width: 100px">
            Group ID
          </div>
          <div style="width: 70px">
            Required
          </div>
          <div style="width: 130px">
            Need Translation
          </div>
          <div style="width: 90px">
            Type
          </div>
        </div>
        <div v-for="template in customerMetadataTemplates"
             style="height: 55px; margin: 15px; display: flex; align-items: center; justify-content: space-around"
             class="bg-menu-color-2 box-shadow-2 border-radius-24">
          <div style="width: 150px" class="flex-center">
            {{template.name}}
          </div>
          <div style="width: 150px" class="flex-center">
            <div v-for="(group, index) in template.groupIds">
              
              <Tag v-if="index <= 3"
                   :value="index <= 2 ? group : ('...' + template.groupIds.length)"
                   style="margin: 5px"></Tag>
            </div>
          </div>
          
          <div style="width: 150px" class="flex-center">
            {{template.required !== undefined ? template.required ? 'Yes' : 'No' : 'No'}}
          </div>
          
          <div style="width: 150px" class="flex-center">
            <div v-if="template.type === 'STRING'">
              {{template.needTranslation !== undefined ? template.needTranslation ? 'Yes' : 'No' : 'No'}}
            </div>
          </div>
          
          <div style="width: 120px; justify-content: space-between" class="flex-center">
            <div></div>
            <Tag :value="template.type"
                 rounded
                 :severity="
								template.type === 'STRING' ? 'success' :
								template.type === 'BOOLEAN' ? 'warning' : '' "
            />
            
            <i class="pi pi-trash hover-icon"
               @click="removeTemplate(template)"
               style="margin-left: 10px; padding: 5px"></i>
          </div>
        </div>
      </div>
    </div>
  </pDialog>
  
  
  <pDialog v-model:visible="isTemplateActive" header="Add Customer Metadata" style="width: 30%">
    <div class="flex-center" style="flex-direction: column; gap: 15px; margin: 20px 50px 0 50px">
      <div>
        Name
        <CustomInputText v-model:modelValue="currentName" label="Name*"
                         class="grid-item m-5 bor-dark-blue-60 border-radius-12"></CustomInputText>
      </div>
      <div style="display: flex; flex-direction: column">
        <div>
          Groups
        </div>
        <div class="grid-item m-5 bor-dark-blue-60 border-radius-12">
          <MultiSelect id="metadata-multiselect" v-model="currentGroups" :options="allGroups" placeholder="Groups"/>
        </div>
      </div>
      <div>
        Required
        <Switch v-model:modelValue="currentRequired" class="grid-item m-5 bor-dark-blue-60 border-radius-12"/>
      </div>
      <div>
        Need Translation
        <Switch v-model:modelValue="currentNeedTranslation" :disabled="currentTypeEnum !== 'STRING'"
                :rightLabel="currentTypeEnum !== 'STRING' ? 'Type must be STRING' : ''"
                class="grid-item m-5 bor-dark-blue-60 border-radius-12"/>
      </div>
      <div style="display: flex; flex-direction: column">
        Type
        <CustomDropdown v-model:modelValue="currentTypeEnum"
                        :placeholder="'Type*'"
                        :options="typeEnumValues"
                        class="grid-item m-5"
                        style="margin-bottom: 20px"/>
      </div>
      
      <RoundedLabel
        @click="submitNewTemplate()"
        :text="$t('general.submit')"
        class="bg-dark-blue col-white bor-transparent"
        style="cursor: pointer; width: 256px; height: 50px; margin-top: 50px; border-radius: 8px"
      />
    
    </div>
  </pDialog>
</template>

<style>

#metadata-multiselect {
  height: 48px;
  width: 100%;
}


</style>

<script src="./CustomerMetadataDialog.ts"></script>
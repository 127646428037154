import {defineComponent} from 'vue'

export default defineComponent({
  components: {},
  props: [
    'text',
    'leftText',
    'leftIcon',
    'noBorder',
    'noHover',
    'hoverBorder',
    'rightIcon',
    'countryFlag',
    'languageCode',
    'countryFlagSize',
    'disabled',
    'lineThrough'
  ],
  setup: function (props) {


    let getText = (text: string) => {
      if (!props.languageCode) {
        return text
      } else {
        let splits = text.split(' ')
        return splits[0] + ' ' + splits[1]
      }
    }
    let getCountryFlagUrl = (countryFlag: string) => {
      return '/flags/' + countryFlag + '.svg'
    }

    return {getCountryFlagUrl, getText}
  }
})

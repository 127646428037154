<template>
  <div style="
         position: relative;
         width: 100%;
         height: 100%;
         background-color: var(--white);
         border: var(--black) dashed 1px">
		<!--   Start testing your components here -->
		{{ test }}
		
		<div style="display: flex; flex-direction: column; width: 100%; height: 100%; padding: 5px; margin: 5px; gap: 15px">
			
			<CustomDropdown :placeholder="'Placeholder1'"
			                :filter="true"
			                show-clear="true"
			                :model-value="test"
			                option-label="name"
			                :options="[{name: 'test1', id: 1}, {name: 'test2', id: 2}, {name: 'test3', id: 3}, {name: 'test4', id: 4}, ]"
			                label="Label1"
			/>
			
			<CustomDropdown :placeholder="'Placeholder2'"
			                :show-clear="true"
			                :model-value="test"
			                :options="[i18n.t('general.day'), i18n.t('general.month'), i18n.t('general.year')]"
			                label="Label2"
			/>
			
			
			<CustomDropdown :placeholder="'Placeholder3'"
			                :model-value="test"
			                option-label="name"
			                :options="[{name: 'test1', id: 1}, {name: 'test2', id: 2}, {name: 'test3', id: 3}, {name: 'test4', id: 4}, ]"
			                label="Label3"
			/>
			<CustomDropdown :placeholder="'Placeholder4'"
			                :model-value="test"
			                option-label="name"
			                :options="[{name: 'test1', id: 1}, {name: 'test2', id: 2}, {name: 'test3', id: 3}, {name: 'test4', id: 4}, ]"
			                label="Label4"
			/>
		
		
		</div>
	</div>
</template>

<style scoped>
body {
  height: 100%
}
</style>

<script src="./DeveloperView.ts"></script>
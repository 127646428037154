import {defineComponent, onMounted, ref, watch} from 'vue';
import {AssetStatus} from '../../../target/api/de/moovit/titletoolserver/model';

export default defineComponent({
  props: ['modelValue'],
  components: {},
  setup: function (props, context) {
    let innerModelValue: any = ref(undefined);
    let userRoles = ref([]);

    watch(props, () => {
      innerModelValue.value = props.modelValue;
    });

    watch(innerModelValue, () => {
      context.emit('update:modelValue', innerModelValue.value);
    });

    onMounted(async () => {
      for (let userRole in AssetStatus) {
        let userRolesList: any = userRoles.value;
        if (userRolesList) userRolesList.push(userRole);
      }
    });

    return {innerModelValue, userRoles};
  },
});

import {useStore} from "vuex";
import axios, {AxiosResponse} from "axios";
import {uuid} from "vue-uuid";
import main from "../../main";
import {
  Asset,
  AssetItem,
  Progress,
  ProgressStatus,
  UpdateAssetObject,
  WsTopic
} from "../../../target/api/de/moovit/titletoolserver/model";
import {defineComponent, getCurrentInstance, onMounted, onUnmounted, ref} from "vue";
import {AssetItemApi, AssetApi} from "../../../target/api";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
  props: ["currentPage", "currentFile", "transitionDir", "id", "propsValues"],
  components: {},
  setup: function (props: any, context) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const fileInput: any = ref(null);
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    const assetApi: AssetApi = new AssetApi();
    const assetItemApi: AssetItemApi = new AssetItemApi();

    let currentId = ref<string>("TitleToolUploaderTest");
    let currentProjectName = ref<string>("");
    let currentLanguage = ref<{ name: string; value: string }>({name: "", value: ""});
    let currentGroup: any = ref<string>("UploaderTestGroup");
    let currentAsset = ref<Asset>({});

    let message = ref<string>("");
    let completedMessage = ref<string>("");
    let allStagesDone = ref<boolean>(false);
    let isMobile = ref<boolean>(false);
    let error = ref<boolean>(false);
    let currentStage = ref<number>(0);
    let currentProgress = ref<number>(0);
    let currentProgress0 = ref<number>(0);
    let currentProgress1 = ref<number>(0);
    let currentProgress2 = ref<number>(0);
    let currentProgress3 = ref<number>(0);
    let currentStatus0 = ref<ProgressStatus>();
    let currentStatus1 = ref<ProgressStatus>();
    let currentStatus2 = ref<ProgressStatus>();
    let currentStatus3 = ref<ProgressStatus>();
    let currentAssetId = ref<string | undefined>(undefined);

    let isVideoFile = ref<boolean>(false);

    let onFilePicked = (event: any) => {
      console.debug("onFilePicked()", event.target.files[0]);
      context.emit("update:currentFile", event.target.files[0]);
      emitter.emit("set-data-loaded", true);
    };

    let dragOverFunc = (e: any) => {
      e.preventDefault();
    };

    let submit = async () => {
      let formData: FormData = new FormData();
      let id: string = uuid.v1();
      console.debug("init with id " + id);
      currentAssetId.value = id;
      context.emit("update:currentPage", props.currentPage + 1);

      router.push({query: {id: id}});

      formData.append("file", props.currentFile);
      let url = "upload?task=assetupload&id=" + id + "&sessionId=" + store.state.sessionId
        + "&access_token=" + JSON.parse(localStorage.loginData).access_token;
      console.debug(url);
      currentProjectName.value = props.currentFile ? props.currentFile.name.replace(/\.[^/.]+$/, "") : "";

      main.config.globalProperties.$emitter.on("ws" + WsTopic.INGESTPROGRESS, (progress: Progress) => {
        let id = currentAssetId.value;
        if ((progress.assetItemId && id === progress.assetItemId) || (progress.assetId && id === progress.assetId)) {
          if (progress.status === ProgressStatus.ERROR) {
            if (progress.message) {
              message.value = progress.message;
            }
            currentProgress0.value = 100;
            currentProgress1.value = 100;
            currentProgress2.value = 100;
            currentProgress3.value = 100;
            error.value = true;
          } else {
            let percent = progress.percent as number;

            if (percent) {
              if (progress.stage != null) {
                currentStage.value = progress.stage;
                switch (progress.stage) {
                  case 0:
                    currentProgress0.value = percent;
                    currentStatus0.value = progress.status;
                    currentProgress1.value = 0;
                    currentProgress2.value = 0;
                    currentProgress3.value = 0;
                    message.value = progress.message ? progress.message : "";
                    // allStagesDone.value = true; // TOTO: remove, used for debuggin
                    break;
                  case 1:
                    currentProgress0.value = 100;
                    currentProgress1.value = percent;
                    currentStatus1.value = progress.status;
                    currentProgress2.value = 0;
                    currentProgress3.value = 0;
                    message.value = progress.message ? progress.message : "";
                    break;
                  case 2:
                    currentProgress0.value = 100;
                    currentProgress1.value = 100;
                    currentProgress2.value = percent;
                    currentStatus2.value = progress.status;
                    currentProgress3.value = 0;
                    message.value = progress.message ? progress.message : "";
                    break;
                  case 3:
                    currentProgress0.value = 100;
                    currentProgress1.value = 100;
                    currentProgress2.value = 100;
                    currentProgress3.value = percent;
                    currentStatus3.value = progress.status;

                    if (percent == 100) {
                      allStagesDone.value = true;
                      message.value = progress.message ? progress.message : "";
                    } else {
                      message.value = "";
                    }
                    break;
                }
              }
              currentProgress.value = percent;
            }
            // console.debug('message ' + progress.message)

            message.value = progress.message ? progress.message : "";
          }
        } else {
          console.debug(progress.assetId + " != " + id);
          console.debug(progress);
        }
      });

      // TODO: use generated api instead
      // SOLUTION?:
      // let response = await assetApi.uploadAsset(formData);

      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function () {
          console.debug("SUCCESS!!");
        })
        .catch(function () {
          console.debug("FAILURE!!");
        });
    };

    let dropFunc = (e: any) => {
      e.preventDefault();
      let files = e.dataTransfer.files;
      if (files.length > 0) {
        let file = files[0];
        if (file.name.endsWith(".zip")) {
          context.emit("update:currentFile", file);
        } else {
          store.commit("SHOW_INFO_DIALOG", {
            dialogTitle: "general.error",
            dialogContent: {t: "dialog.collectedFileMustBeZip"},
          });
        }
      }
    };

    let getCircleClass = (page: number, currentPage: number) => {
      if (page < currentPage) return " pi-check-circle";
      if (page > currentPage) return " pi-circle-on col-menu-color";
      if (page == currentPage) return " pi-circle-on ";
    };

    let resize = () => {
      // console.debug(window.innerWidth);
      isMobile.value = window.innerWidth < 1100;
    };

    let onClickCancel = async () => {
      if (currentAssetId.value) {
        assetApi
          .cancelAssetUpload(currentAssetId.value)
          .then((response: AxiosResponse<boolean>) => {
            resetView();
          })
          .catch((err: any) => {
            console.debug(err);
          });
      } else {
        resetView();
      }
    };
    let resetView = () => {
      console.debug("RESET VIEW")
      context.emit("update:currentFile", null);
      currentAssetId.value = undefined;
      message.value = "";
      allStagesDone.value = false;
      currentProjectName.value = "";
      context.emit("update:currentPage", 1);
      currentProgress0.value = 0;
      currentProgress1.value = 0;
      currentProgress2.value = 0;
      currentProgress3.value = 0;
      error.value = false;
    };
    let onClickNext = async () => {
      context.emit("update:transitionDir", "slide-right");

      currentAsset.value.id = currentAssetId.value;
      currentAsset.value.name = currentProjectName.value;
      currentAsset.value.groupID = currentGroup.value;

      if (!currentAsset.value.masterAssetItem) {
        currentAsset.value.masterAssetItem = {};
      }
      currentAsset.value.masterAssetItem.id = currentAssetId.value;
      currentAsset.value.masterAssetItem.language = currentLanguage.value.name;

      if (currentId.value) {
        currentAsset.value.thirdPartyID = currentId.value;
      }

      currentAsset.value.userID = JSON.parse(localStorage.loginData).user.id;

      console.debug("new asset", currentAsset.value);
      let updateAssetObject: UpdateAssetObject = {};
      updateAssetObject.asset = currentAsset.value;

      await assetApi.updateAsset(updateAssetObject);

      let assetItem: AssetItem = (await assetItemApi.getAssetItemById(currentAsset.value.id!)).data;
      assetItem.language = currentLanguage.value.name;
      assetItem.assetName = currentProjectName.value;

      await assetItemApi.updateAssetItemValues(assetItem);

      context.emit("update:propsValues", {
        currentId: currentId.value,
        currentProjectName: currentProjectName.value,
        currentLanguage: currentLanguage.value,
        currentGroup: currentGroup.value,
      });

      setTimeout(async () => {
        if (currentProjectName.value && currentLanguage.value && currentGroup.value) {
          console.debug("ALL PROPS VALUES SET");

          await router.push({
            query: {id: currentAssetId.value, page: props.currentPage + 1},
          });

          context.emit("update:currentPage", props.currentPage + 1);
        }
      }, 200);
    };

    onMounted(() => {
      router.push({query: {id: undefined}});
      document.addEventListener("dragover", dragOverFunc);
      document.addEventListener("drop", dropFunc);

      window.addEventListener("resize", resize);
    });

    onUnmounted(() => {
      document.removeEventListener("dragover", dragOverFunc);
      document.addEventListener("drop", dropFunc);
    });

    return {
      getCircleClass,
      fileInput,
      onFilePicked,
      onClickNext,
      onClickCancel,
      currentId,
      currentProjectName,
      currentLanguage,
      currentGroup,
      submit,
      currentStage,
      currentProgress,
      currentProgress0,
      currentProgress1,
      currentProgress2,
      currentProgress3,
      currentStatus0,
      currentStatus1,
      currentStatus2,
      currentStatus3,
      allStagesDone,
      message,
      error,
      completedMessage,
      currentAssetId,
      isMobile,
    };
  },
});

import {defineComponent, onMounted, ref} from "vue";
import * as md from 'raw-loader!../../../public/changelog.md';
import VueMarkdown from 'vue-markdown-render'

export default defineComponent({
  components: {
    VueMarkdown
  },
  setup: function () {

    let markdownText = ref<string>('');

    onMounted(() => {
      markdownText.value = md.default;
    })

    return {
      markdownText
    };
  },
});

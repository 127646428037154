// tslint:disable
/**
 * titletool-nextgen-server
 * TitleTool NextGen Server
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: r.hutter@moovit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MediaReplacement
 */
export interface MediaReplacement {
    /**
     * 
     * @type {string}
     * @memberof MediaReplacement
     */
    logoType?: MediaReplacementLogoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MediaReplacement
     */
    pattern?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MediaReplacementLogoTypeEnum {
    BOSCH4C = 'BOSCH4C',
    NEFFCHARTS = 'NEFFCHARTS',
    NEFFRGB64MM = 'NEFF_RGB_64MM',
    BOSCHANIMATED = 'BOSCH_ANIMATED',
    BSHDisclaimer = 'BSHDisclaimer',
    BOSCH16x9 = 'BOSCH_16x9',
    BOSCHSL = 'BOSCHSL',
    BOSCHLIFECLIP169 = 'BOSCH_LIFE_CLIP_169',
    GENERIC = 'GENERIC',
    BOSCHRGB = 'BOSCHRGB',
    SIEMENS2018 = 'SIEMENS2018'
}




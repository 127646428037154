// tslint:disable
/**
 * titletool-nextgen-server
 * TitleTool NextGen Server
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: r.hutter@moovit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { UserGroup } from '../../../../de/moovit/titletoolserver/model';
/**
 * UserGroupApi - axios parameter creator
 * @export
 */
export const UserGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addUserGroup
         * @param {UserGroup} [userGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserGroup: async (userGroup?: UserGroup, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/group`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userGroup !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userGroup !== undefined ? userGroup : {}) : (userGroup || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteUserGroup
         * @param {string} id 
         * @param {string} [replaceUserGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserGroup: async (id: string, replaceUserGroupId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserGroup.');
            }
            const localVarPath = `/user/group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (replaceUserGroupId !== undefined) {
                localVarQueryParameter['replaceUserGroupId'] = replaceUserGroupId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getUserGroupAssetCount
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupAssetCount: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserGroupAssetCount.');
            }
            const localVarPath = `/user/group/assetCount/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getUserGroupById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserGroupById.');
            }
            const localVarPath = `/user/group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getUserGroupJobCount
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupJobCount: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserGroupJobCount.');
            }
            const localVarPath = `/user/group/jobCount/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary isGroupInUse
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isGroupInUse: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling isGroupInUse.');
            }
            const localVarPath = `/user/group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary restoreUserGroup
         * @param {UserGroup} [userGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreUserGroup: async (userGroup?: UserGroup, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/group/restore`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userGroup !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userGroup !== undefined ? userGroup : {}) : (userGroup || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchUserGroups
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUserGroups: async (page?: number, pageSize?: number, searchText?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/group/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateUserGroup
         * @param {UserGroup} [userGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserGroup: async (userGroup?: UserGroup, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/group`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userGroup !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userGroup !== undefined ? userGroup : {}) : (userGroup || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserGroupApi - functional programming interface
 * @export
 */
export const UserGroupApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addUserGroup
         * @param {UserGroup} [userGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserGroup(userGroup?: UserGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroup>> {
            const localVarAxiosArgs = await UserGroupApiAxiosParamCreator(configuration).addUserGroup(userGroup, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary deleteUserGroup
         * @param {string} id 
         * @param {string} [replaceUserGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserGroup(id: string, replaceUserGroupId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserGroupApiAxiosParamCreator(configuration).deleteUserGroup(id, replaceUserGroupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getUserGroupAssetCount
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGroupAssetCount(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await UserGroupApiAxiosParamCreator(configuration).getUserGroupAssetCount(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getUserGroupById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGroupById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroup>> {
            const localVarAxiosArgs = await UserGroupApiAxiosParamCreator(configuration).getUserGroupById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getUserGroupJobCount
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGroupJobCount(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await UserGroupApiAxiosParamCreator(configuration).getUserGroupJobCount(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary isGroupInUse
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isGroupInUse(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await UserGroupApiAxiosParamCreator(configuration).isGroupInUse(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary restoreUserGroup
         * @param {UserGroup} [userGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreUserGroup(userGroup?: UserGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroup>> {
            const localVarAxiosArgs = await UserGroupApiAxiosParamCreator(configuration).restoreUserGroup(userGroup, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary searchUserGroups
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUserGroups(page?: number, pageSize?: number, searchText?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserGroup>>> {
            const localVarAxiosArgs = await UserGroupApiAxiosParamCreator(configuration).searchUserGroups(page, pageSize, searchText, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateUserGroup
         * @param {UserGroup} [userGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserGroup(userGroup?: UserGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroup>> {
            const localVarAxiosArgs = await UserGroupApiAxiosParamCreator(configuration).updateUserGroup(userGroup, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserGroupApi - factory interface
 * @export
 */
export const UserGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary addUserGroup
         * @param {UserGroup} [userGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserGroup(userGroup?: UserGroup, options?: any): AxiosPromise<UserGroup> {
            return UserGroupApiFp(configuration).addUserGroup(userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteUserGroup
         * @param {string} id 
         * @param {string} [replaceUserGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserGroup(id: string, replaceUserGroupId?: string, options?: any): AxiosPromise<void> {
            return UserGroupApiFp(configuration).deleteUserGroup(id, replaceUserGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getUserGroupAssetCount
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupAssetCount(id: string, options?: any): AxiosPromise<number> {
            return UserGroupApiFp(configuration).getUserGroupAssetCount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getUserGroupById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupById(id: string, options?: any): AxiosPromise<UserGroup> {
            return UserGroupApiFp(configuration).getUserGroupById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getUserGroupJobCount
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupJobCount(id: string, options?: any): AxiosPromise<number> {
            return UserGroupApiFp(configuration).getUserGroupJobCount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary isGroupInUse
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isGroupInUse(id: string, options?: any): AxiosPromise<boolean> {
            return UserGroupApiFp(configuration).isGroupInUse(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary restoreUserGroup
         * @param {UserGroup} [userGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreUserGroup(userGroup?: UserGroup, options?: any): AxiosPromise<UserGroup> {
            return UserGroupApiFp(configuration).restoreUserGroup(userGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchUserGroups
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUserGroups(page?: number, pageSize?: number, searchText?: string, options?: any): AxiosPromise<Array<UserGroup>> {
            return UserGroupApiFp(configuration).searchUserGroups(page, pageSize, searchText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateUserGroup
         * @param {UserGroup} [userGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserGroup(userGroup?: UserGroup, options?: any): AxiosPromise<UserGroup> {
            return UserGroupApiFp(configuration).updateUserGroup(userGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserGroupApi - object-oriented interface
 * @export
 * @class UserGroupApi
 * @extends {BaseAPI}
 */
export class UserGroupApi extends BaseAPI {
    /**
     * 
     * @summary addUserGroup
     * @param {UserGroup} [userGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public addUserGroup(userGroup?: UserGroup, options?: any) {
        return UserGroupApiFp(this.configuration).addUserGroup(userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteUserGroup
     * @param {string} id 
     * @param {string} [replaceUserGroupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public deleteUserGroup(id: string, replaceUserGroupId?: string, options?: any) {
        return UserGroupApiFp(this.configuration).deleteUserGroup(id, replaceUserGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getUserGroupAssetCount
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public getUserGroupAssetCount(id: string, options?: any) {
        return UserGroupApiFp(this.configuration).getUserGroupAssetCount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getUserGroupById
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public getUserGroupById(id: string, options?: any) {
        return UserGroupApiFp(this.configuration).getUserGroupById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getUserGroupJobCount
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public getUserGroupJobCount(id: string, options?: any) {
        return UserGroupApiFp(this.configuration).getUserGroupJobCount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary isGroupInUse
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public isGroupInUse(id: string, options?: any) {
        return UserGroupApiFp(this.configuration).isGroupInUse(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary restoreUserGroup
     * @param {UserGroup} [userGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public restoreUserGroup(userGroup?: UserGroup, options?: any) {
        return UserGroupApiFp(this.configuration).restoreUserGroup(userGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchUserGroups
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [searchText] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public searchUserGroups(page?: number, pageSize?: number, searchText?: string, options?: any) {
        return UserGroupApiFp(this.configuration).searchUserGroups(page, pageSize, searchText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateUserGroup
     * @param {UserGroup} [userGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public updateUserGroup(userGroup?: UserGroup, options?: any) {
        return UserGroupApiFp(this.configuration).updateUserGroup(userGroup, options).then((request) => request(this.axios, this.basePath));
    }

}

// tslint:disable
/**
 * titletool-nextgen-server
 * TitleTool NextGen Server
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: r.hutter@moovit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CustomerMetadataTemplate
 */
export interface CustomerMetadataTemplate {
    /**
     * 
     * @type {string}
     * @memberof CustomerMetadataTemplate
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMetadataTemplate
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerMetadataTemplate
     */
    groupIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerMetadataTemplate
     */
    required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerMetadataTemplate
     */
    needTranslation?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerMetadataTemplate
     */
    type?: CustomerMetadataTemplateTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CustomerMetadataTemplateTypeEnum {
    STRING = 'STRING',
    INTEGER = 'INTEGER',
    BOOLEAN = 'BOOLEAN',
    DOUBLE = 'DOUBLE'
}




<template>
  <div
    class="border-radius-8 col-dark-blue bor-menu-color bg-transparent p-dropdown-trigger-col"
    style="display:flex; flex-direction:column; justify-content:space-between; align-items:space-between; position: relative; padding-left:16px;"
  >
    <div
      v-if="innerModelValue"
      class="col-menu-color"
      style="font-size:12px; position:absolute; left:4px; top:-1px;"
    >
      {{$t('general.country')}}
    </div>
    <Dropdown
      showClear
      :placeholder="$t('general.country')"
      v-model="innerModelValue"
      style="display:flex; align-items:center;"
      :options="$store.state.countries"
      @change="$emit('update:modelValue', innerModelValue)"
    >
      <template #value="slotProps">
        <div
          v-if="slotProps.value && slotProps.value.value"
          style="display:flex; align-items:center; position: relative;"
        >
          <Flag :countryFlag="slotProps.value.value" style="margin-right:12px;"/>
          <div class="col-dark-blue">{{slotProps.value.value}}</div>
        </div>
        <span v-else>
          {{slotProps.placeholder}}
        </span>
      </template>
      <template #option="slotProps">
        <div style="display:flex; align-items:center;">
          <Flag :countryFlag="slotProps.option.value" style="margin-right:12px;"/>
          <div>
            {{slotProps.option.value}}
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script src="./CountryDropdown.ts"></script>

import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { SystemApi, UserApi } from "../../../target/api";
import {
  AccessRequestResult,
  RegisterUserObject,
  SystemPreference,
  User,
  UserNotification,
} from "../../../target/api/de/moovit/titletoolserver/model";
import { InitAppService } from "@/InitAppService";
import ProgressSpinner from "primevue/progressspinner";
import InputText from "primevue/inputtext";
import { AxiosResponse } from "axios";

export default defineComponent({
  components: { InputText, ProgressSpinner },
  setup: function() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const userApi: UserApi = new UserApi();
    const systemApi: SystemApi = new SystemApi();

    let username = ref<string | undefined>(); // admin
    let password = ref<string | undefined>(); // bitte0479

    let submitted = ref(false);
    let valid = ref(true);

    let showPrivacy = ref<boolean>(false);
    let showChangelog = ref<boolean>(false);

    let isRegister = ref<boolean>(false);

    let firstName = ref<string>("");
    let lastName = ref<string>("");
    let email = ref<string>("");
    let confirmPassword = ref("");

    let registrationValid = ref<boolean>(false);

    let registrationCompleteDialog = ref<boolean>(false);

    let allowCreateAccount = ref<boolean>(false);

    let brandText = ref<string>("");

    let keyPressedPassword = (key: KeyboardEvent) => {
      if ("Enter" == key.code) {
        onClickLoginOrRegister();
      }
    };

    let keyPressedUsername = (key: KeyboardEvent) => {
      if ("Enter" == key.code) {
        let passwordField: any = document.getElementById("passwordField");
        passwordField.focus();
      }
    };

    let getLogoUrl = () => {
      return process.env.VUE_APP_TT_ENDPOINT + "/system/logo/LOGIN";
    };

   
    let getHeaderUrl = () => {
      return process.env.VUE_APP_TT_ENDPOINT + "/system/logo/HEADER";
    };

    let login = () => {
      submitted.value = true;
      localStorage.loginData = "";

      if (!username.value || !password.value) {
        return;
      }

      let user: User = { id: username.value, password: password.value };

      userApi
        .loginUser(user)
        .then((response: AxiosResponse<AccessRequestResult>) => {
          console.log("response", response);
          if (response && response.data && response.data.success) {
            valid.value = true;

            localStorage.loginData = JSON.stringify(response.data);

            let user = response.data.user;

            if (user && user.role) {
              userApi
                .getPermissionsByRole(user.role)
                .then((permission: any) => {
                  if (user && user.id) {
                    user.permissions = permission.data;

                    if (!user.permissions) {
                      user.permissions = {};
                    }

                    response.data.user = user;

                    localStorage.loginData = JSON.stringify(response.data);
                    console.debug("loginData", response.data);

                    userApi
                      .getNotifications(user.id)
                      .then(
                        (response: AxiosResponse<Array<UserNotification>>) => {
                          store.state.notifications = response.data;
                        }
                      );
                  }

                  if (
                    localStorage.savedURL &&
                    localStorage.savedURL.length > 1
                  ) {
                    router.push(localStorage.savedURL);
                    console.debug("restored URL -> " + localStorage.savedURL);
                    localStorage.removeItem("savedURL");
                  } else {
                    userApi
                      .getDefaultUrl(user!.role!)
                      .then((response: AxiosResponse<string>) => {
                        router.push(response.data).then((response) => {
                          store.commit("SHOW_SNACKBAR", {
                            snackbarContent: {
                              t: "snackbar.welcomeToTitletool",
                              e: [user!.firstname],
                            },
                            snackbarType: "info",
                            snackbarLife: 5000,
                          });
                        });
                      });
                  }
                });
            } else {
              console.log(user);
              store.commit("SHOW_SNACKBAR", {
                snackbarContent: {
                  t: "snackbar.userNotUnlocked",
                  e: [user!.id],
                },
                snackbarType: "error",
                snackbarLife: 5000,
              });

              resetData();
              return;
            }

            if (!InitAppService.isInitialized()) {
              InitAppService.initData().then((response) => {
                store.commit("SET_COUNTRIES", response.countries);
                store.commit("SET_SETTINGS", response.settings);
                store.commit("SET_LANGUAGES", response.languages);
              });
            }
          } else {
            console.log("bad password, valid false");
            valid.value = false;
          }
        });
    };

    let register = () => {
      submitted.value = true;
      if (!firstName.value || !lastName.value || !email.value) {
        valid.value = false;
        return;
      }

      if (password.value != confirmPassword.value) {
        confirmPassword.value = "";
        return;
      }

      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(email.value!)) {
        email.value = "";
        return;
      }

      registrationValid.value = true;

      let registerUserObject: RegisterUserObject = {
        firstName: firstName.value,
        lastName: lastName.value,
        password: password.value,
        email: email.value,
      };

      userApi
        .sendEmailVerification(registerUserObject)
        .then((response: AxiosResponse<User>) => {
          console.debug("Email sent to user: ", response);
          if (response.data && response.data.id) {
            username.value = response.data.id;
          }
        });
    };

    let onClickLoginOrRegister = () => {
      if (!isRegister.value) {
        login();
      } else {
        register();
      }
    };

    let resetData = () => {
      registrationCompleteDialog.value = false;

      registrationValid.value = false;
      username.value = "";
      password.value = "";
      confirmPassword.value = "";
      firstName.value = "";
      lastName.value = "";
      email.value = "";
    };

    let onClickRegistrationDone = () => {
      router.push("/").then((r) => resetData());
    };

    watch(isRegister, () => {
      resetData();
    });

    onMounted(() => {
      let darkmode = localStorage.getItem("darkmodeVisible");
      let element = document.getElementById("app");

      if (darkmode === "active") {
        element!.classList.add("darkMode");
      } else {
        element!.classList.remove("darkMode");
      }
      systemApi
        .getSystemPreference("allowCreateUser")
        .then((result: AxiosResponse<SystemPreference>) => {
          if (result.data) {
            allowCreateAccount.value = <boolean>(<unknown>result.data.value);
          }
        });

      systemApi
        .getSystemPreference("brandingText")
        .then((result: AxiosResponse<SystemPreference>) => {
          if (result.data) {
            brandText.value = <string>(<unknown>result.data.value);
          }
        });

      if (route.query && route.query.token) {
        userApi
          .validateEmail(route.query.token.toString())
          .then((response: AxiosResponse<User>) => {
            if (response.data) {
              username.value = response.data.id!;
              firstName.value = response.data.firstname!;
              lastName.value = response.data.name!;

              registrationCompleteDialog.value = true;
            } else {
              router.push("/");
            }
          })
          .catch((error: any) => {
            console.log(error);
            store.commit("SHOW_SNACKBAR", {
              snackbarType: "error",
              snackbarContent: { t: "snackbar.tokenExpired" },
            });
            router.push("/");
          });
      }
    });

    return {
      onClickLoginOrRegister,
      username,
      password,
      submitted,
      keyPressedUsername,
      keyPressedPassword,
      valid,
      getLogoUrl,
      getHeaderUrl,
      onClickRegistrationDone,
      showPrivacy,
      showChangelog,
      isRegister,
      firstName,
      lastName,
      email,
      confirmPassword,
      registrationValid,
      registrationCompleteDialog,
      allowCreateAccount,
      brandText,
    };
  },
});

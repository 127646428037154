import {AssetItemApi, FieldApi, AssetApi} from '../../../target/api';
import {Asset, Field} from '../../../target/api/de/moovit/titletoolserver/model';
import videojs from 'video.js';
import {defineComponent, getCurrentInstance, onMounted, onUnmounted, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';

export default defineComponent({
  props: ['currentPage', 'transitionDir'],
  components: {},
  setup: function (props: any, context) {
    const router = useRouter();
    const route = useRoute();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    const assetApi: AssetApi = new AssetApi();
    const fieldApi: FieldApi = new FieldApi();
    const assetItemApi = new AssetItemApi();
    const store = useStore();

    let currentVideoWidth: any = ref(undefined);
    let asset = ref<Asset>();
    let hasAssetItemFields = ref<boolean>(false);
    let fieldIds = ref<Array<String>>([]);

    let finished = ref<boolean>(false);

    let player: any = ref();
    let videoPlayerRef: any = ref(null);
    let videoOptions: any = ref({
      controls: true,
      autoplay: true,
      sources: [],
    });

    let currentVideoTime = ref<string>("");

    let getVideoURL = (asset: Asset) => {
      if (asset && asset.masterAssetItem) {
        return (
          process.env.VUE_APP_TT_ENDPOINT +
          '/videostream?access_token=' +
          JSON.parse(localStorage.loginData).access_token +
          '&id=' +
          asset.masterAssetItem.id
        );
      } else {
        return null;
      }
    };

    let getCircleClass = (page: number, currentPage: number) => {
      if (page < currentPage) return ' pi-check-circle';
      if (page > currentPage) return ' pi-circle-on col-menu-color';
      if (page == currentPage) return ' pi-circle-on ';
    };

    let onClickFinish = async () => {
      console.debug('finish');
      finished.value = true;

      console.debug(asset.value)
      if (asset.value && asset.value.id) {
        assetApi.destroyAsset(asset.value.id, localStorage.sessionId);
      }

      emitter.emit('set-data-loaded', false);
      if (asset.value == null) {
        asset.value = {};
      }

      setTimeout(() => {
        router.push('/uploader');
        window.location.reload();
      }, 500);
    };

    let resize = () => {
      let addAssetPreviewVideoContainer: any = document.getElementById(
        'addAssetPreviewVideoContainer'
      );

      console.debug("resize", addAssetPreviewVideoContainer)

      if (addAssetPreviewVideoContainer) {
        let maxWidth = document.documentElement.clientWidth - 250;
        let maxHeight = addAssetPreviewVideoContainer.clientHeight;
        let quotient = maxWidth / 16 / (maxHeight / 9);

        if (quotient > 1) {
          currentVideoWidth.value = maxHeight * (16 / 9);
        } else {
          currentVideoWidth.value = document.documentElement.clientWidth - 250;
        }
      }
    };

    let onVideoUpdate = () => {
      currentVideoTime.value = videoPlayerRef.value.currentTime;
    }

    onMounted(async () => {
      player.value = videojs(videoPlayerRef.value, videoOptions.value);
      let getAssetResponse = await assetApi.getAssetById('' + route.query.id);
      asset.value = getAssetResponse.data;

      hasAssetItemFields.value = true; //(await assetItemApi.getAssetItemById(asset.value.masterAssetItem!.id!)).data.fields!.length != 0;

      if (hasAssetItemFields.value) {

        let assetItemFieldsResponse = await fieldApi.getAssetItemFieldsByAssetItemId(
          asset.value.masterAssetItem!.id!
        );

        let fields: Array<Field> = assetItemFieldsResponse.data;

        for (const field of fields) {
          fieldIds.value.push(field.id!);
        }
      }

      const url = getVideoURL(asset.value);
      player.value.src({
        type: 'video/mp4',
        src: url,
      });
      window.addEventListener('resize', function () {
        resize();
      });
      resize();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', function () {
        resize();
      });
    });

    return {
      getCircleClass,
      currentVideoWidth,
      player,
      asset,
      hasAssetItemFields,
      videoPlayerRef,
      videoOptions,
      currentVideoTime,
      fieldIds,
      finished,
      onClickFinish,
      onVideoUpdate,
    };
  },
});

import {defineComponent, onMounted, ref} from 'vue';
import {UserApi} from '../../../target/api';
import {
  PermissionsPreview,
  PermissionsPreviewListItem,
  UserRole
} from '../../../target/api/de/moovit/titletoolserver/model';
import {useStore} from "vuex";

export default defineComponent({
  components: {},
  setup: function () {
    const store = useStore();

    let userApi = new UserApi();

    let userRoles = ref<Array<string>>([]);

    let permissionsPreview = ref<PermissionsPreview>();

    let getHeader = (map: any, val: PermissionsPreviewListItem[]) => {
      return Object.keys(map)
        .find(key => map[key] === val);
    }

    let addUserRole = (role: string) => {
      if (!userRoles.value.includes(role)) {
        userRoles.value.push(role);
      }
    }

    let commitPermission = async (id: string, role: UserRole, value: boolean) => {
      permissionsPreview.value = (await userApi.updatePermissionsPreview(id, role, !value)).data;

      store.commit('SHOW_SNACKBAR', {
        snackbarContent: {t: 'snackbar.settingsSaved'},
      });
    }

    onMounted(async () => {
      permissionsPreview.value = (await userApi.getPermissionsPreview()).data
    });

    return {
      getHeader,
      commitPermission,
      addUserRole,
      userRoles,
      permissionsPreview
    };
  },
});

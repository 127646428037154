<template>
  <Button
    class="bor-transparent border-radius-8 bg-transparent"
    style="width: 42px; height: 42px"
    @click="toggleDialog()"
  >
    <i class="pi pi-code col-dark-blue" style="font-size: 20px" />
  </Button>

  <pDialog
    :header="'Terminal Password'"
    v-model:visible="isPasswordActive"
    :maximizable="false"
    :modal="true"
    id="terminal-password"
    style="background-color: white; width: 400px; box-shadow: 0px 0px 5px 5px var(--dark-blue-30);"
  >
    <div
      class="flex-center"
      style="flex-direction: column; height: 150px; width: 100%"
    >
      <!-- These two elements catch the autofill from the browser so we dont have to deal with this -->
      <InputText
        type="email"
        style="opacity: 0; height: 0; width: 0; position: absolute"
      ></InputText>
      <InputText
        type="password"
        style="opacity: 0; height: 0; width: 0; position: absolute"
      ></InputText>

      <CustomInputText
        v-model:model-value="currentPassword"
        type="password"
        label="Password"
        @keypress="keyPressedPassword($event, currentPassword)"
        style="width: 80%"
      ></CustomInputText>
      <Button
        @click="login(currentPassword)"
        :label="$t('general.login')"
        class="border-radius-8 col-white bor-transparent bg-dark-blue"
        style="width: 80%; height: 50px; margin-top: 15px"
      />
    </div>
  </pDialog>

  <pDialog
    :header="'Terminal'"
    v-model:visible="isActive"
    :maximizable="false"
    :modal="true"
    id="terminal"
    style="background-color: white; height: 80%; width: 70%"
  >
    <Terminal
      :welcomeMessage="toolName + ' Terminal'"
      :prompt="isCommandQueued ? 'Waiting... $ ' : 'TitleTurtle$ '"
      style="width: 100%; height: 100%"
      class="border-radius-16"
    />
  </pDialog>
</template>

<style>
#terminal .p-dialog-content {
  height: 100%;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
</style>

<script src="./TerminalDialog.ts"></script>

// tslint:disable
/**
 * titletool-nextgen-server
 * TitleTool NextGen Server
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: r.hutter@moovit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum AssetItemStatus {
    CLOSED = 'CLOSED',
    DELETED = 'DELETED',
    COMPLETE = 'COMPLETE',
    FAILED = 'FAILED',
    MOVIECREATED = 'MOVIECREATED',
    NEW = 'NEW',
    RENDERED = 'RENDERED',
    RENDERING = 'RENDERING',
    SCHEDULED = 'SCHEDULED',
    MAMUPLOADING = 'MAMUPLOADING',
    MAMUPLOADED = 'MAMUPLOADED',
    READYTOAPPROVE = 'READY_TO_APPROVE'
}




<template>
  <div style="padding-left:24px; height: 100%;  display:flex;">
    <transition-group :name="transitionDir">
      <AddAssetViewChooseFile
        style="position: absolute; width: calc(100vw - 150px); height:calc(100vh - 104px)"
        v-if="currentPage === 1 || currentPage === 2"
        v-model:currentPage="currentPage"
        v-model:currentFile="currentFile"
        v-model:transitionDir="transitionDir"
        v-model:propsValues="propsValues"
      />
      <AddAssetViewDetails
        style="position: absolute; width: calc(100vw - 150px); height:calc(100vh - 104px)"
        v-if="currentPage === 3"
        v-model:currentPage="currentPage"
        v-model:currentFile="currentFile"
        v-model:transitionDir="transitionDir"
        v-model:propsValues="propsValues"
      />
      <AddAssetViewCheckmark
        style="position: absolute; width: calc(100vw - 150px); height:calc(100vh - 104px)"
        v-if="currentPage === 4"
        v-model:currentPage="currentPage"
        v-model:currentFile="currentFile"
        v-model:transitionDir="transitionDir"
      />
      <AddAssetViewPreview
        style="position: absolute; width: calc(100vw - 150px); height:calc(100vh - 104px)"
        v-if="currentPage === 5"
        v-model:currentPage="currentPage"
        v-model:currentFile="currentFile"
        v-model:transitionDir="transitionDir"
        v-model:propsValues="propsValues"
      />
    </transition-group>
  </div>
</template>

<script src="./AddAssetView.ts"></script>

import {defineComponent, getCurrentInstance, nextTick, onMounted, onUnmounted, ref} from "vue";

import {MediaReplacementTemplate, SystemPreference, WsTopic} from '../../../target/api/de/moovit/titletoolserver/model';
import {AssetApi, AssetItemApi, UserApi, SystemApi} from "../../../target/api";
import {useStore} from "vuex";

export default defineComponent({
  components: {},
  setup: function () {
    const systemApi: SystemApi = new SystemApi();
    const assetApi: AssetApi = new AssetApi();
    const assetItemApi: AssetItemApi = new AssetItemApi();
    const userApi: UserApi = new UserApi();
    const app: any = getCurrentInstance();
    const store = useStore();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let replaceMediaTemplates = ref<Array<MediaReplacementTemplate>>([]);

    let fileInput = ref();

    let uploadReplaceMedia = async (event: any) => {
      store.commit("SHOW_SNACKBAR", {
        snackbarType: 'info',
        snackbarContent: {t: "snackbar.uploadStarted"},
      });

      addLoadingTemplate();

      systemApi.uploadAndSaveMediaReplacementTemplate(event.target.files[0]).then(response => {
        removeLoadingTemplate();
        updateReplaceMedia();

        store.commit("SHOW_SNACKBAR", {
          snackbarType: 'info',
          snackbarContent: {t: "snackbar.uploadFinished"},
        });
      });
    }

    let updateReplaceMedia = () => {
      replaceMediaTemplates.value = [];
      systemApi.getAllMediaReplacementTemplate().then(response => {
        if (response.data) {
          replaceMediaTemplates.value = response.data;
        }
      });
    }

    let addLoadingTemplate = () => {
      replaceMediaTemplates.value.push({
        name: "Loading...",
        mimeType: "Loading...",
        path: "",
        alternativeNames: [],
        replacements: [],
        id: "temporaryTemplate"
      });
    }

    let removeLoadingTemplate = () => {
      replaceMediaTemplates.value.pop();
    }

    onMounted(async () => {
      updateReplaceMedia();

      emitter.on("remove-template", (id: string) => {
        systemApi.removeMediaReplacementTemplate(id).then(_ => {
          updateReplaceMedia();
        });
      });

      emitter.on("update-template", () => {
        updateReplaceMedia();
      });

      emitter.on("ws" + WsTopic.THUMBNAILCREATED, (path: string) => {
        for (const template of replaceMediaTemplates.value) {
          if (path.includes(template.id!)) {
            emitter.emit("replaceMedia" + template.id);
          }
        }
      });
    });

    onUnmounted(() => {
      emitter.off("remove-template");
      emitter.off("update-template");

      emitter.off("ws" + WsTopic.THUMBNAILCREATED);
    });

    return {
      uploadReplaceMedia,
      replaceMediaTemplates,
      fileInput,
    };
  }
});
<template>
  <div
    class="col-dark-blue-30 bg-tt-white border-radius-16"
    style="
      width: 95%;
      height: 90%;
      box-shadow: 0 0 10px 0;
      border-color: transparent;
    "
  >
    <div
      style="
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      "
    >
      
      <div
        class="col-dark-blue"
        style="height:100px; display:flex; justify-content:center; align-items:center; font-size:24px; font-weight:600;"
      >
        {{$t("addAsset.uploadReady")}}
      </div>
      
      <div
        style="width:100%; height: calc(100vh - 300px); overflow-y:auto; overflow-x:hidden; display:flex; flex-direction:column; align-items:center"
      >
        <div
          class="col-dark-blue"
          style="min-height: 60px; font-size:16px; font-weight:300; text-align: center;"
        >
          {{$t('addAsset.checkmarkLayers')}}
        </div>
        
        <div v-if="items && items.length">
          <div style="width: 100%; height: calc(100vh - 380px); overflow-x: hidden; overflow-y:auto;" class="custom">
            <div v-for="(list, index) in draggableLists">
              <div v-if="list.draggable">
                <Draggable v-model="list.fields"
                           style="border-top: grey dotted 2px; border-bottom: grey dotted 2px; margin-top: 5px; margin-bottom: 5px">
                  <template v-slot:item="{ item }">
                    <div class="col-dark-blue bg-menu-color"
                         style="display:flex; align-items:center; justify-content:space-between; cursor:pointer; width:500px; height: 24px; border-radius:16px; padding: 16px; margin-top: 5px; margin-bottom: 5px;">
                      <Switch v-model="item.enabled" :value="item.enabled"/>
                      <div
                        style="margin:0 10px 0 10px; width:90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                        {{item.srcValue}}
                      </div>
                      <i class="pi pi-bars" style="font-size:24px;"></i>
                    </div>
                  </template>
                </Draggable>
              </div>
              <div v-else>
                <div v-for="field in list.fields">
                  <div
                    class="col-dark-blue bg-light-blue"
                    style="display:flex; align-items:center; justify-content:space-between; width:500px; height: 24px; border-radius:16px; padding: 16px; margin-top: 5px; margin-bottom: 5px;"
                  >
                    <Switch v-model="field.enabled" :value="field.enabled"/>
                    <div
                      style="margin:0 10px 0 10px; width:90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                    >
                      {{field.srcValue}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- FOOTER -->
      
      <div
        style="width: 90%; height: 80px; display:flex; align-items:center; justify-content:space-between;"
      >
        <Button
          v-if="currentPage > 1"
          @click="
            $emit('update:transitionDir', 'slide-left');
            $emit('update:currentPage', currentPage - 1);
            $router.push({
              query: {
                id: $route.query.id,
                page: parseInt($route.query.page) - 1,
              },
            });
          "
          label="Back"
          class="border-radius-8 col-dark-blue bor-transparent bg-tt-white"
          style="min-width:120px; height:40px; padding:7px 6px 6px 8px;"
        />
        <div style="width:300px; display:flex; align-items:center; justify-content:space-between;">
          <i :class="'col-dark-blue pi ' + getCircleClass(1, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(2, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(3, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(4, currentPage)"></i>
          <i :class="'col-dark-blue pi ' + getCircleClass(5, currentPage)"></i>
        </div>
        
        <Button
          @click="
            $emit('update:transitionDir', 'slide-right');
            $emit('update:currentPage', currentPage + 1);
            $router.push({
              query: {
                id: $route.query.id,
                page: parseInt($route.query.page) + 1,
              },
            });
          "
          label="Next"
          class="border-radius-8 col-white bor-transparent bg-dark-blue"
          style="min-width:120px; height:40px; width:120px; padding:7px 6px 6px 8px;"
        />
      </div>
    </div>
  </div>
</template>

<script src="./UploaderFieldPreview.ts"></script>

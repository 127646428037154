import {
  defineComponent,
  onMounted,
  ref,
  onUnmounted,
  getCurrentInstance, watch, nextTick
} from 'vue';
import {SystemApi} from '../../../target/api';
import {Replacement} from '../../../target/api/de/moovit/titletoolserver/model';
import {useStore} from "vuex";

export default defineComponent({
  props: ['template'],
  components: {},
  setup: function (props) {

    const systemApi = new SystemApi();
    const store = useStore();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let isActive = ref<boolean>(false);

    let replacements = ref<Array<Replacement>>([]);

    let currentLanguage = ref();
    let currentGroup = ref();

    let isWatchFree = ref<boolean>(true);
    let currentNames = ref<Array<string>>([]);

    let fileInput = ref();
    let fileUpdate = ref();
    let fileReplace = ref();

    let expandedRows = ref<any[]>([]);

    let showReplacement = ref<boolean>(false);

    let toggleDialog = (event: any) => {
      if (props.template.id === "temporaryTemplate") return;

      if (event && event.target &&
        !(event.target.localName === "i" || event.target.localName === "input")) {
        isActive.value = !isActive.value;
      }
    }

    let getThumbnail = (path: string) => {
      return `${process.env.VUE_APP_TT_ENDPOINT}/system/replaceMedia/thumbnail?path=${path}&access_token=${JSON.parse(localStorage.loginData).access_token}${"&ts=" + Date.now()}`
    }

    let save = async () => {
      for (let replacement of replacements.value) {
        await systemApi.saveReplacement(props.template.id, replacement);
      }
      await systemApi.saveMediaReplacementTemplate(props.template);
    }

    let addLoadingReplacement = () => {
      replacements.value.push({name: "Loading...", path: "", languages: [], groupIds: [], id: "temporaryReplacement"});
    }

    let removeLoadingReplacement = () => {
      replacements.value.pop();
    }

    let addTemplate = async (file: File) => {
      if (file.name === props.template.name) {
        store.commit("SHOW_SNACKBAR", {
          snackbarType: 'error',
          snackbarContent: {t: "snackbar.canNotUploadSame"},
        });
        return;
      }

      store.commit("SHOW_SNACKBAR", {
        snackbarType: 'info',
        snackbarContent: {t: "snackbar.uploadStarted"},
      });

      addLoadingReplacement();

      let replacement: Replacement = {};

      await systemApi.uploadAndSaveReplacement(props.template.id, file).then(result => {
        replacement = result.data;

        nextTick(() => {
          removeLoadingReplacement();
        }).then(() => {
          replacements.value.push(replacement);
          props.template.replacements.push(replacement.id);

          expandedRows.value = [];
          expandedRows.value.push(replacement);

          systemApi.saveMediaReplacementTemplate(props.template);
        });
      });


    }

    let replaceTemplate = (event: any) => {
      console.debug("replaceTemplate", event.target.files[0]);

      if (props.template.mimeType !== event.target.files[0].type) {
        store.commit("SHOW_SNACKBAR", {
          snackbarType: 'error',
          snackbarContent: {t: "snackbar.notSameMimeType"},
        });
      } else {
        systemApi.replaceTemplateFile(props.template.id, event.target.files[0]).then(() =>
          emitter.emit("update-template"));
      }

    }

    let onFilePicked = (event: any) => {
      addTemplate(event.target.files[0]);
    }

    let addLanguage = async (id: string) => {
      if (currentLanguage.value === "" || !currentLanguage.value) return;
      let replacement: Replacement = replacements.value.filter((value: Replacement) => value.id === id)[0];
      if (!replacement.languages!.includes(currentLanguage.value.name)) {
        replacement.languages!.push(currentLanguage.value.name);

        systemApi.saveReplacement(props.template.id, replacement);
      }

      setTimeout(() => {
        currentLanguage.value = "";
      }, 200);
    }

    let addGroup = async (id: string) => {
      if (currentGroup.value === "" || !currentGroup.value) return;
      let replacement: Replacement = replacements.value.filter((value: Replacement) => value.id === id)[0];
      if (!replacement.groupIds!.includes(currentGroup.value)) {
        replacement.groupIds!.push(currentGroup.value);

        systemApi.saveReplacement(props.template.id, replacement);
      }

      setTimeout(() => {
        currentGroup.value = "";
      }, 200);
    }

    watch(currentNames, async () => {
      if (isWatchFree.value) {
        isWatchFree.value = false;
        currentNames.value = [...new Set(currentNames.value)];

        props.template.alternativeNames = currentNames.value;

        await systemApi.saveMediaReplacementTemplate(props.template);
        isWatchFree.value = true;
      }
    });

    let onRowCollapse = async () => {
      currentLanguage.value = "";
      currentGroup.value = "";
    }

    let onRowExpand = async (event: any) => {
      expandedRows.value = [];
      if (event.data.id !== 'temporaryReplacement') {
        expandedRows.value.push(event.data);
      }
    }

    let removeReplacement = (replacement: Replacement) => {
      systemApi.removeReplacement(replacement.id!).then(value => {
        emitter.emit('update-template', null);
        init();
      });


    }

    let removeTemplate = (id: string) => {
      store.commit('SHOW_CONFIRM_DIALOG', {
        dialogTitle: 'dialog.areYouSure',
        dialogContent: {t: ''},
        confirmCallback: () => {
          emitter.emit('remove-template', id);
        }
      });
    }

    let onClickUpdateFile = (id: string, event: any) => {
      console.debug(id, event.target.files[0]);

      systemApi.replaceReplacementFile(id, event.target.files[0]).then(() => init());
    }

    let init = () => {
      replacements.value = [];

      if (props.template.replacements && props.template.replacements.length > 0) {
        systemApi.getReplacement(props.template.replacements).then(response => {
          replacements.value = response.data;
        });
      }

      currentNames.value = props.template.alternativeNames ? props.template.alternativeNames : [];
    }

    onMounted(() => {
      showReplacement.value = true;
      if (props.template.id !== "temporaryTemplate") {
        init();

        emitter.on("replaceMedia" + props.template.id, () => {
          console.debug("Reloading Thumbnail for " + props.template.id);
          setTimeout(() => {
            let templateThumb: any = document.getElementsByClassName("template-img" + props.template.id);
            if (templateThumb && templateThumb[0]) {
              templateThumb[0].src = getThumbnail(templateThumb[0].id);
            }

            let images: any = document.getElementsByClassName("replacement-img" + props.template.id);
            for (const image of images) {
              image.src = getThumbnail(image.id);
            }
          }, 200);
        });
      }
    });

    onUnmounted(() => {
      emitter.off("replaceMedia" + props.template.id);
    });

    return {
      isActive,
      replacements,
      expandedRows,
      currentLanguage,
      currentGroup,
      currentNames,
      fileInput,
      fileUpdate,
      fileReplace,
      showReplacement,
      save,
      getThumbnail,
      addLanguage,
      addGroup,
      toggleDialog,
      onRowCollapse,
      onRowExpand,
      onClickUpdateFile,
      onFilePicked,
      removeReplacement,
      removeTemplate,
      replaceTemplate,
    };
  },
});

<template>
  <Button class="bor-dark-blue border-radius-8 bg-transparent"
          style="width: 42px; height: 42px"
          @click="toggleDialog()">
    <i
      class="pi pi-plus col-dark-blue"
      style="font-size:16px; font-weight:600"
    />
  </Button>
  
  <pDialog :header="$t('general.addGroup')" v-model:visible="isActive" :draggable="false" :maximizable="false"
           :modal="true"
           style="width: 70%; margin-left: 50%; margin-right: 50%">
    <div class="two-column-table-container" style="margin-top: 20px">
      <div class="two-column-table-column">
        <div>
          <div v-if="existingName" style="position: absolute; color: red; margin-left: 10px; margin-top: -20px">
            {{errorMessage}}
          </div>
          <CustomInputText
            v-model="currentGroupName"
            :label="$t('general.groupName') + '*'"
            class="border-radius-12 col-dark-blue bor-dark-blue-60 bg-transparent"
            @input="verifyGroupName"
            :style="!submitted || currentGroupName ? 'bor-menu-color' : 'border-color: red'"
            style="width:228px; height:50px; margin: 10px"
          />
        </div>
        
        <CustomInputText
          v-model="currentCostCenter"
          :label="$t('Cost Center')"
          class="border-radius-12 col-dark-blue bor-dark-blue-60 bg-transparent"
          style="width:228px; height:50px; margin: 10px"
        />
        <Button
          @click="commitGroup(); valid ? $emit('update:modelValue', false) : ''"
          :label="$t('general.createGroup')"
          class="border-radius-12 col-white bor-transparent bg-dark-blue"
          style="width:228px; height:50px; margin-left: 10px; margin-top: 15px"
        />
      </div>
    </div>
  </pDialog>
</template>

<script src="./AddGroupDialog.ts"></script>

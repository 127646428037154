<template>
  <div style="position: relative">
    <Slider
      v-model="innerTimecode"
      :options="{ animationDuration: 0 }"
      :tooltips="false"
      style="width: 100%; height: 4px"
      @change="onChangeVideoSliderPosition"
    />

    <div
      v-if="!hideFrames"
      class="preview-image-container"
      id="image-visualizer-container"
    >
      <div v-for="previewFrame in previewFrames" :key="previewFrame.id">
        <img
          src="../../assets/map-marker.svg"
          class="image-item"
          :style="
            'margin-left: ' +
              (maxClientWidth / innerVideoLength) * previewFrame.time +
              'px'
          "
          @click="
            previewFrame.path
              ? openImage(previewFrame.id, previewFrame.time)
              : ''
          "
        />

        <div
          id="image-holder"
          :style="
            'margin-left: ' +
              ((maxClientWidth / innerVideoLength) * previewFrame.time - 77) +
              'px; '
          "
        >
          <img
            v-if="previewFrame.path"
            :src="getPreviewFrame(previewFrame.id)"
            class="preview-image"
          />
          <div v-else id="image-unavailable">
            {{ $t("job.previewFrameUnavailable") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.preview-image-container {
  position: absolute;
  height: 3px;
  margin-top: -23px;
  width: 100%;
  float: left;
}

.image-item {
  height: 22px;
  width: 22px;
  border: none;
  position: absolute;
  cursor: pointer;
  color: var(--dark-blue);
  overflow: hidden;
}

.image-item:hover {
  transform: scale(1.2);
  transition: all 50ms ease-in;
  z-index: 2;
  opacity: 1;
}

.image-item:hover + #image-holder {
  opacity: 1;
  z-index: 10;
  display: block;
  overflow: visible;
}

.preview-image,
#image-unavailable {
  border-radius: 6px;
  z-index: 3;
  height: 100px;
  width: 170px;
}

#image-unavailable {
  background-color: var(--menu-color);
  color: var(--dark-blue);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#image-holder {
  z-index: 3;
  height: 100px;
  width: 170px;
  margin-top: -102px;
  transition: all 0.2s;
  position: absolute;
  opacity: 0;
  display: none;
}
</style>

<script src="./Timeline.ts"></script>

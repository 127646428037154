import {defineComponent, getCurrentInstance, onMounted, onUnmounted, ref} from 'vue';

import {FontMapping, FontMappings, SearchFontMappingsObject} from '../../../target/api/de/moovit/titletoolserver/model';
import {SystemApi} from '../../../target/api';
import {useStore} from "vuex";

export default defineComponent({
  components: {},
  setup: function () {

    const store = useStore();

    const systemApi = new SystemApi();

    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let fontMappings = ref<Array<FontMappings>>();

    let addNew = ref(false);

    let fontMappingXmlUpload = ref();

    let newFontMappings = ref<FontMappings>({});
    let newFontMapping = ref<FontMapping>({});
    let newFontMappingLanguage = ref<{ name: string, value: string }>();

    let searchText = ref('');
    let sortColumn = ref('language');
    let sortAsc = ref(false);
    let fontMappingsRef: any | undefined;
    let page: number = 0;
    let pageSize: number = 20;
    let addingFontMappings = ref<boolean>(false);
    let added0FontMappings = ref<boolean>(false);

    let submitted = ref<boolean>(false);


    let addMappingToFontMappings = (language: string, group: string, mapping: FontMapping) => {
      if (fontMappings.value) {
        for (let i = 0; i < fontMappings.value.length; i++) {
          let fontMapping: FontMappings = fontMappings.value[i];

          if (fontMapping && fontMapping.group === group && fontMapping.language === language && fontMapping.fontMappings) {
            fontMapping.fontMappings.push(mapping);

            saveFontMappings(fontMapping);
            return;
          }
        }
      }
    }

    let updateMapping = (group: string, language: string, index: number, mapping: FontMapping) => {
      if (fontMappings.value) {
        for (let i = 0; i < fontMappings.value.length; i++) {
          let fontMapping: FontMappings = fontMappings.value[i];

          if (fontMapping && fontMapping.group === group && fontMapping.language === language && fontMapping.fontMappings) {
            if (fontMapping && fontMapping.fontMappings) {
              fontMapping.fontMappings[index] = mapping;
            }

            saveFontMappings(fontMappings.value[i]);
            return;
          }
        }
      }
    }

    let saveFontMappings = (fontMappings: FontMappings) => {
      systemApi.updateFontMapping(fontMappings);
    }

    let toggleNewFontMapping = (valid: boolean) => {
      if (addNew.value) {

        if (!valid) {
          newFontMappings.value = {};
          newFontMapping.value = {};
          newFontMappingLanguage.value = {name: "", value: ""};
          submitted.value = false;
          addNew.value = !addNew.value;
          return;
        }

        submitted.value = true;

        if (newFontMappings.value.group === 'All (*)') {
          newFontMappings.value.group = '*';
        }

        if (newFontMappingLanguage.value
          && newFontMappings.value.group
          && newFontMapping.value.newFullName
          && newFontMapping.value.oldFullName) {
          if (newFontMappingLanguage.value) {
            if (newFontMappingLanguage.value.name === 'All (*)') {
              newFontMappings.value.language = '*';
            } else {
              newFontMappings.value.language = newFontMappingLanguage.value.name;
            }
          }

          newFontMappings.value.id = (newFontMappings.value.group ? newFontMappings.value.group : "")
            + (newFontMappings.value.group && newFontMappings.value.language ? "&" : "")
            + (newFontMappings.value.language ? newFontMappings.value.language : "");
          newFontMappings.value.fontMappings = [];
          newFontMappings.value.fontMappings.push(newFontMapping.value);

          systemApi.addFontMapping(newFontMappings.value);

          if (fontMappings.value) {
            fontMappings.value.push(newFontMappings.value);
          }

          newFontMappings.value = {};
          newFontMapping.value = {};
          newFontMappingLanguage.value = {name: "", value: ""};

          submitted.value = false;
          addNew.value = !addNew.value;
        }
      } else {
        newFontMapping.value.exceptions = [];
        addNew.value = !addNew.value;
      }
    }

    let onClickUploadXml = (event: any) => {
      store.commit("SHOW_SNACKBAR", {
        snackbarType: 'info',
        snackbarContent: {t: "snackbar.fileUploaded", e: [event.target.files[0].name]},
      });

      systemApi.uploadFontMappingsXml(event.target.files[0]).then(response => {
        if (response.data) {
          searchFontMapping();
        } else {
          store.commit("SHOW_SNACKBAR", {
            snackbarType: 'error',
            snackbarContent: {t: "snackbar.uploadFailed"},
          });
        }
      });
    }

    let onBlurSearchField = () => {
      searchFontMapping();
    }

    let changeSort = (column: string) => {
      if (sortColumn.value == column) {
        sortAsc.value = !sortAsc.value;
      } else {
        sortColumn.value = column;
        sortAsc.value = true;
      }
      searchFontMapping();
    };

    let searchFontMapping = async () => {
      page = 0;
      fontMappings.value = await getFontMappings();
      if (fontMappingsRef) fontMappingsRef.scrollTop = 0;
      added0FontMappings.value = false;
    };

    let getFontMappings: any = async () => {
      let searchFontMappingsObject: SearchFontMappingsObject = {};
      searchFontMappingsObject.page = page;
      searchFontMappingsObject.pageSize = pageSize;
      searchFontMappingsObject.searchText = searchText.value;
      searchFontMappingsObject.ascSort = sortAsc.value;
      searchFontMappingsObject.sortColumn = sortColumn.value;

      for (let i in store.state.filterBreadcrumbs) {
        let filterBreadcrumb = store.state.filterBreadcrumbs[i];
        if (filterBreadcrumb.type == 'LANGUAGE')
          searchFontMappingsObject.language = filterBreadcrumb.name;
        if (filterBreadcrumb.type == 'GROUP')
          searchFontMappingsObject.group = filterBreadcrumb.name;
      }

      let searchFontMappingsResponse = await systemApi.searchFontMappings(searchFontMappingsObject);

      if (searchFontMappingsResponse) {
        console.debug('FontMappings - ITEMS', searchFontMappingsResponse.data);
        return searchFontMappingsResponse.data;
      }

    };

    let deleteMapping = async (group: string, language: string, index: number) => {
      await systemApi.deleteFontMappings(index, group, language);

      searchFontMapping();
    }

    onMounted(async () => {
      emitter.on('add-mapping', ({language, group, mapping}: any) => {
        addMappingToFontMappings(language, group, mapping);
      });

      emitter.on('update-mapping', ({group, language, index, mapping}: any) => {
        updateMapping(group, language, index, mapping);
      });

      emitter.on('delete-mapping', ({group, language, index}: any) => {
        deleteMapping(group, language, index);
      });

      emitter.on('search-font-mapping', () => {
        searchFontMapping();
      });

      fontMappings.value = (await systemApi.getAllFontMappings()).data;
      console.debug("Fontmappings", fontMappings.value);
    });

    onUnmounted(() => {
      emitter.off('add-mapping');
      emitter.off('update-mapping');
      emitter.off('delete-mapping');
      emitter.off('search-font-mapping');
    });

    return {
      searchText,
      fontMappings,
      newFontMapping,
      newFontMappings,
      addNew,
      newFontMappingLanguage,
      sortColumn,
      sortAsc,
      submitted,
      addMappingToFontMappings,
      toggleNewFontMapping,
      onBlurSearchField,
      changeSort,
      searchFontMapping,
      onClickUploadXml,
      fontMappingXmlUpload
    };
  },
});

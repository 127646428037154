// tslint:disable
/**
 * titletool-nextgen-server
 * TitleTool NextGen Server
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: r.hutter@moovit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { Field } from '../../../../de/moovit/titletoolserver/model';
/**
 * FieldApi - axios parameter creator
 * @export
 */
export const FieldApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addField
         * @param {Field} [field] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addField: async (field?: Field, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset/item/field`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof field !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(field !== undefined ? field : {}) : (field || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addOrRemoveVoiceOverField
         * @param {string} assetItemId 
         * @param {boolean} addOrRemove 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrRemoveVoiceOverField: async (assetItemId: string, addOrRemove: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetItemId' is not null or undefined
            if (assetItemId === null || assetItemId === undefined) {
                throw new RequiredError('assetItemId','Required parameter assetItemId was null or undefined when calling addOrRemoveVoiceOverField.');
            }
            // verify required parameter 'addOrRemove' is not null or undefined
            if (addOrRemove === null || addOrRemove === undefined) {
                throw new RequiredError('addOrRemove','Required parameter addOrRemove was null or undefined when calling addOrRemoveVoiceOverField.');
            }
            const localVarPath = `/asset/item/{assetItemId}/field/voiceOver/{addOrRemove}`
                .replace(`{${"assetItemId"}}`, encodeURIComponent(String(assetItemId)))
                .replace(`{${"addOrRemove"}}`, encodeURIComponent(String(addOrRemove)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteField
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteField: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteField.');
            }
            const localVarPath = `/asset/item/field`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAssetItemAudioTracksByAssetItemId
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetItemAudioTracksByAssetItemId: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAssetItemAudioTracksByAssetItemId.');
            }
            const localVarPath = `/asset/item/{id}/audioTracks`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAssetItemFieldsByAssetItemId
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetItemFieldsByAssetItemId: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAssetItemFieldsByAssetItemId.');
            }
            const localVarPath = `/asset/item/{id}/fields`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAssetItemVoiceRecorderFields
         * @param {string} assetItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetItemVoiceRecorderFields: async (assetItemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetItemId' is not null or undefined
            if (assetItemId === null || assetItemId === undefined) {
                throw new RequiredError('assetItemId','Required parameter assetItemId was null or undefined when calling getAssetItemVoiceRecorderFields.');
            }
            const localVarPath = `/asset/item/{assetItemId}/field/voiceOver/fields`
                .replace(`{${"assetItemId"}}`, encodeURIComponent(String(assetItemId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getField
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getField: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getField.');
            }
            const localVarPath = `/asset/item/field/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getFieldTimeline
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldTimeline: async (requestBody: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling getFieldTimeline.');
            }
            const localVarPath = `/asset/item/field/timeline/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getFieldsByIds
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldsByIds: async (ids: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling getFieldsByIds.');
            }
            const localVarPath = `/asset/item/field`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary hasAssetItemSubtitles
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasAssetItemSubtitles: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling hasAssetItemSubtitles.');
            }
            const localVarPath = `/asset/item/field/hasSubtitle/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateField
         * @param {string} id 
         * @param {Field} [field] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateField: async (id: string, field?: Field, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateField.');
            }
            const localVarPath = `/asset/item/field/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof field !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(field !== undefined ? field : {}) : (field || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FieldApi - functional programming interface
 * @export
 */
export const FieldApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addField
         * @param {Field} [field] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addField(field?: Field, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await FieldApiAxiosParamCreator(configuration).addField(field, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary addOrRemoveVoiceOverField
         * @param {string} assetItemId 
         * @param {boolean} addOrRemove 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrRemoveVoiceOverField(assetItemId: string, addOrRemove: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FieldApiAxiosParamCreator(configuration).addOrRemoveVoiceOverField(assetItemId, addOrRemove, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary deleteField
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteField(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FieldApiAxiosParamCreator(configuration).deleteField(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getAssetItemAudioTracksByAssetItemId
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetItemAudioTracksByAssetItemId(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Field>>> {
            const localVarAxiosArgs = await FieldApiAxiosParamCreator(configuration).getAssetItemAudioTracksByAssetItemId(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getAssetItemFieldsByAssetItemId
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetItemFieldsByAssetItemId(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Field>>> {
            const localVarAxiosArgs = await FieldApiAxiosParamCreator(configuration).getAssetItemFieldsByAssetItemId(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getAssetItemVoiceRecorderFields
         * @param {string} assetItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetItemVoiceRecorderFields(assetItemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<Field>>>> {
            const localVarAxiosArgs = await FieldApiAxiosParamCreator(configuration).getAssetItemVoiceRecorderFields(assetItemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getField
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getField(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Field>> {
            const localVarAxiosArgs = await FieldApiAxiosParamCreator(configuration).getField(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getFieldTimeline
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFieldTimeline(requestBody: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<Field>>>> {
            const localVarAxiosArgs = await FieldApiAxiosParamCreator(configuration).getFieldTimeline(requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getFieldsByIds
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFieldsByIds(ids: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Field>>> {
            const localVarAxiosArgs = await FieldApiAxiosParamCreator(configuration).getFieldsByIds(ids, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary hasAssetItemSubtitles
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasAssetItemSubtitles(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await FieldApiAxiosParamCreator(configuration).hasAssetItemSubtitles(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateField
         * @param {string} id 
         * @param {Field} [field] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateField(id: string, field?: Field, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await FieldApiAxiosParamCreator(configuration).updateField(id, field, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FieldApi - factory interface
 * @export
 */
export const FieldApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary addField
         * @param {Field} [field] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addField(field?: Field, options?: any): AxiosPromise<boolean> {
            return FieldApiFp(configuration).addField(field, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary addOrRemoveVoiceOverField
         * @param {string} assetItemId 
         * @param {boolean} addOrRemove 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrRemoveVoiceOverField(assetItemId: string, addOrRemove: boolean, options?: any): AxiosPromise<void> {
            return FieldApiFp(configuration).addOrRemoveVoiceOverField(assetItemId, addOrRemove, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteField
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteField(id: string, options?: any): AxiosPromise<void> {
            return FieldApiFp(configuration).deleteField(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAssetItemAudioTracksByAssetItemId
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetItemAudioTracksByAssetItemId(id: string, options?: any): AxiosPromise<Array<Field>> {
            return FieldApiFp(configuration).getAssetItemAudioTracksByAssetItemId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAssetItemFieldsByAssetItemId
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetItemFieldsByAssetItemId(id: string, options?: any): AxiosPromise<Array<Field>> {
            return FieldApiFp(configuration).getAssetItemFieldsByAssetItemId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAssetItemVoiceRecorderFields
         * @param {string} assetItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetItemVoiceRecorderFields(assetItemId: string, options?: any): AxiosPromise<Array<Array<Field>>> {
            return FieldApiFp(configuration).getAssetItemVoiceRecorderFields(assetItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getField
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getField(id: string, options?: any): AxiosPromise<Field> {
            return FieldApiFp(configuration).getField(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getFieldTimeline
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldTimeline(requestBody: Array<string>, options?: any): AxiosPromise<Array<Array<Field>>> {
            return FieldApiFp(configuration).getFieldTimeline(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getFieldsByIds
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldsByIds(ids: Array<string>, options?: any): AxiosPromise<Array<Field>> {
            return FieldApiFp(configuration).getFieldsByIds(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary hasAssetItemSubtitles
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasAssetItemSubtitles(id: string, options?: any): AxiosPromise<boolean> {
            return FieldApiFp(configuration).hasAssetItemSubtitles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateField
         * @param {string} id 
         * @param {Field} [field] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateField(id: string, field?: Field, options?: any): AxiosPromise<boolean> {
            return FieldApiFp(configuration).updateField(id, field, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FieldApi - object-oriented interface
 * @export
 * @class FieldApi
 * @extends {BaseAPI}
 */
export class FieldApi extends BaseAPI {
    /**
     * 
     * @summary addField
     * @param {Field} [field] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public addField(field?: Field, options?: any) {
        return FieldApiFp(this.configuration).addField(field, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary addOrRemoveVoiceOverField
     * @param {string} assetItemId 
     * @param {boolean} addOrRemove 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public addOrRemoveVoiceOverField(assetItemId: string, addOrRemove: boolean, options?: any) {
        return FieldApiFp(this.configuration).addOrRemoveVoiceOverField(assetItemId, addOrRemove, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteField
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public deleteField(id: string, options?: any) {
        return FieldApiFp(this.configuration).deleteField(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAssetItemAudioTracksByAssetItemId
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getAssetItemAudioTracksByAssetItemId(id: string, options?: any) {
        return FieldApiFp(this.configuration).getAssetItemAudioTracksByAssetItemId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAssetItemFieldsByAssetItemId
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getAssetItemFieldsByAssetItemId(id: string, options?: any) {
        return FieldApiFp(this.configuration).getAssetItemFieldsByAssetItemId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAssetItemVoiceRecorderFields
     * @param {string} assetItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getAssetItemVoiceRecorderFields(assetItemId: string, options?: any) {
        return FieldApiFp(this.configuration).getAssetItemVoiceRecorderFields(assetItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getField
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getField(id: string, options?: any) {
        return FieldApiFp(this.configuration).getField(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getFieldTimeline
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getFieldTimeline(requestBody: Array<string>, options?: any) {
        return FieldApiFp(this.configuration).getFieldTimeline(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getFieldsByIds
     * @param {Array<string>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public getFieldsByIds(ids: Array<string>, options?: any) {
        return FieldApiFp(this.configuration).getFieldsByIds(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary hasAssetItemSubtitles
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public hasAssetItemSubtitles(id: string, options?: any) {
        return FieldApiFp(this.configuration).hasAssetItemSubtitles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateField
     * @param {string} id 
     * @param {Field} [field] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public updateField(id: string, field?: Field, options?: any) {
        return FieldApiFp(this.configuration).updateField(id, field, options).then((request) => request(this.axios, this.basePath));
    }

}

import {defineComponent, getCurrentInstance, onMounted, ref} from 'vue';
import main from '../../../main';
import {useRoute, useRouter} from 'vue-router';
import {
  CreateJobObject,
  UserGroup,
  AssetStatus,
  CustomerMetadata
} from '../../../../target/api/de/moovit/titletoolserver/model';
import {useStore} from 'vuex';
import {useI18n} from "vue-i18n";
import {UserGroupApi, AssetApi, AssetItemApi, SystemApi} from "../../../../target/api";

export default defineComponent({
  props: [
    'modelValue',
    'currentPage',
    'transitionDir',
    'languages',
    'assetName',
    'selectedAudioTrack',
    'expirationDate',
    'subtitleOption',
    'translator',
    'groups',
    'approver'
  ],
  components: {},
  setup: function (props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const i18n = useI18n();
    const assetApi: AssetApi = new AssetApi();
    const userGroupApi: UserGroupApi = new UserGroupApi();
    const assetItemApi: AssetItemApi = new AssetItemApi();
    const systemApi: SystemApi = new SystemApi();

    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let currentAssetName = ref(undefined);
    let currentLanguage = ref(undefined);
    let currentExpirationDate = ref('');
    let creatingJob = ref<boolean>(false);
    let assetId = ref('');
    let currentTranslator = ref(undefined);
    let currentApprover = ref(undefined);
    let customerMetadata = ref<Array<CustomerMetadata>>([]);

    let getCircleClass = (page: number, currentPage: number) => {
      if (page < currentPage) return ' pi-check-circle col-complete';
      if (page > currentPage) return ' pi-circle-on col-menu-color';
      if (page == currentPage) return ' pi-circle-on ';
    };

    let openJob = async (job: any) => {
      let newJobId = assetId.value;
      console.debug(newJobId);
      router.push({ path: '/job', query: { id: job.id } });
      store.commit('HIDE_SNACKBAR');

      let asset = (await assetApi.getAssetById(job.id)).data;

      if (asset && asset.assetStatus == AssetStatus.NEW) {
        asset.assetStatus = AssetStatus.INPROGRESS;
        assetApi.updateAsset({ asset: asset, updateJobs: false });
      }
    };

    let createJob = async () => {
      creatingJob.value = true;
      let languagesArray = [];
      for (let i = 0; i < props.languages.length; i++) {
        if (
          props.languages[i] != null &&
          props.languages[i].name &&
          props.languages[i].name.length != 0
        ) {
          languagesArray.push({
            language: props.languages[i].name,
            autotranslate: props.languages[i].autoTranslate,
            autoTextToSpeech: props.languages[i].autoTextToSpeech
          });
        }
      }

      let createJobObject = <CreateJobObject>{
        expiration: props.expirationDate,
        translatorId: props.translator.id,
        userId: JSON.parse(localStorage.loginData).user.id,
        jobName: props.assetName,
        groupIds: props.groups,
        selectedAudioTrack: props.selectedAudioTrack ? props.selectedAudioTrack.id : undefined,
        subtitleOption: props.subtitleOption ? props.subtitleOption : undefined,
        languages: languagesArray
      };

      if (props.approver && props.approver.id) {
        createJobObject.approverId = props.approver.id;
      }

      let costCenter = "";
      for (let groupCostcenter of props.groups) {
        let group: UserGroup = (await userGroupApi.getUserGroupById(groupCostcenter)).data;
        if (group && group.costCenter) {
          costCenter = costCenter + group.costCenter + ", ";
        }
      }

      if (costCenter.length !== 0) {
        costCenter = costCenter.slice(0, -2);
      }

      createJobObject.costCenter = costCenter;

      if (costCenter.length === 0 && props.approver && props.approver.costCenter) {
        createJobObject.costCenter = props.approver.costCenter;
      }

      console.debug('createJobObject', createJobObject);

      assetApi.createJob(assetId.value, createJobObject).then(response => {
        if (createJobObject.groupIds) {
          for (let groupId of createJobObject.groupIds) {
            userGroupApi.getUserGroupById(groupId).then(response => {
              if (response.data) {
                response.data.jobs = (response.data.jobs ? response.data.jobs : 0) + 1;
                userGroupApi.updateUserGroup(response.data);
              }
            });
          }
        }

        if (response.data && response.data.id) {
          assetId.value = response.data.id;
          main.config.globalProperties.$emitter.emit('search-jobs', null);

          store.commit('SHOW_SNACKBAR', {
            snackbarContent: { t: 'snackbar.jobCreated' },
            snackbarBtnText: i18n.t('snackbar.open'),
            snackbarBtnCallback: () => {
              openJob(response.data);
            },
          });
          router.push({ path: '/jobs', query: { new: [response.data.id] } });
        }
      }).catch(reason => {
        store.commit('SHOW_SNACKBAR', {
          snackbarType: 'error',
          snackbarContent: { t: i18n.t('snackbar.creatingJobFailed') },
        });

        emitter.emit('update:transitionDir', 'slide-left');
        emitter.emit('update:currentPage', props.currentPage - 1);
      });
    };

    onMounted(() => {
      currentAssetName.value = props.assetName;
      currentTranslator.value = props.translator;
      currentApprover.value = props.approver;
      assetId.value = route.query.id + '';

      assetItemApi.getAssetItemById(assetId.value).then(result => {
        if (result.data.customerMetadata) {
          for (const key of result.data.customerMetadata) {
            systemApi.getCustomMetadataById(key).then(metadata => {
              customerMetadata.value.push(metadata.data);
            });
          }
        }
      });
    });

    return {
      currentAssetName,
      currentTranslator,
      currentApprover,
      currentExpirationDate,
      currentLanguage,
      creatingJob,
      assetId,
      openJob,
      createJob,
      getCircleClass,
      customerMetadata,
    };
  },
});

<template>
  <div style="padding-left: 24px;  height: calc(100vh - 140px); width: 100%">
    <div style="display: flex; justify-content: space-between">
      <div style="display: flex; align-items: center">
        <RoundedLabel
          @click="
            $store.commit('SHOW_ADD_FILTER_DIALOG', {
              hideLocked: true,
              hideCostCenter: true,
              hideRoleDropdown: true,
              hideDefaultLanguage: true,
              hideCustomDropdown: true,
              hideStatus: true,
            })
          "
          leftIcon="pi pi-filter"
          :text="$t('general.addFilter')"
          size="l"
          class="bor-dark-blue"
          style="cursor: pointer; height: 40px; margin-right: 24px"
        />
        <FilterBreadcrumb
          :items="$store.state.filterBreadcrumbs"
          maxWidth="calc(100vw - 555px)"
        />
      </div>
      <SearchField
        v-model="searchText"
        style="padding-right: 38px"
        :blurFunction="onBlurSearchField"
      />
    </div>
    
    <DataTable
      class="p-datatable-custom"
      :value="jobs"
      scrollable
      scrollHeight="flex"
      removableSort
      sortMode="multiple"
      selectionMode="single"
      @rowClick="setLastClickedItem"
      :rows="8"
    >
      <Column name="Video" class="col-approval">
        <template #body="slotProps">
          <div
            class="first-col col transitions flex-center"
            :id="'approval.' + slotProps.data.id"
          >
            <div
              style="overflow: hidden; border-radius: 10px"
              class="border-radius-8 bor-dark-blue-70"
              @mouseleave="pauseVideo(slotProps.data.id)"
              @mouseenter="playVideo(slotProps.data.id)"
              v-if="
                !slotProps.data.status ||
                  (slotProps.data.status.toUpperCase() !== 'APPROVED' &&
                    slotProps.data.status.toUpperCase() !== 'REJECTED')
              "
            >
              <video
                class="video-js"
                :id="'approvalVideo.' + slotProps.data.id"
                style="
                  width: 90px;
                  height: 60px;
                  background: transparent;
                  pointer-events: none;
                "
                muted
              >
                <source :src="getVideoURL(slotProps.data)" type="video/mp4"/>
              </video>
            </div>
          </div>
        </template>
      </Column>
      
      <Column sortable class="col-approval"
              :style="'width: ' + selectedColumns.length / 100 + '%'"
              field="assetName"
              :header="$t('general.name')">
        <template #body="slotProps">
          <div
            class="col transitions flex-center"
            :id="'approval.' + slotProps.data.id"
          >
            <div
              style="width: 200px"
              class="handle-font-overflow"
              :id="'approval.' + slotProps.data.id"
            >
              {{slotProps.data.assetName}}
            </div>
          </div>
        </template>
      </Column>
      
      <Column sortable class="col-approval"
              :style="'width: ' + selectedColumns.length / 100 + '%'"
              field="completed"
              v-if="selectedColumns.includes('general.completed')"
              :header="$t('general.completed')">
        <template #body="slotProps">
          <div
            class="col transitions flex-center"
            style="flex-direction: column"
            :id="'approval.' + slotProps.data.id"
          >
            {{slotProps.data.completed}}
          </div>
        </template>
      </Column>
      
      <Column sortable class="col-approval"
              :style="'width: ' + selectedColumns.length / 100 + '%'"
              field="language"
              v-if="selectedColumns.includes('general.language')"
              :header="$t('general.language')">
        <template #body="slotProps">
          <div
            class="col transitions flex-center"
            :id="'approval.' + slotProps.data.id"
            @click="
              $store.commit('ADD_FILTER_BREADCRUMB', {
                name: slotProps.data.language,
                type: 'LANGUAGE',
              });
              $emitter.emit('search-asset-items', null);
            "
          >
            <Flag
              :countryFlag="slotProps.data.language"
              class="hover transitions"
              v-tooltip="slotProps.data.language"
              style="margin: 4px; cursor: pointer"
            />
          </div>
        </template>
      </Column>
      
      <Column sortable class="col-approval"
              :style="'width: ' + selectedColumns.length / 100 + '%'"
              field="groupID"
              v-if="selectedColumns.includes('general.group')"
              :header="$t('general.group')">
        <template #body="slotProps">
          <div
            class="col transitions flex-center"
            :id="'approval.' + slotProps.data.id"
          >
            <RoundedLabel
              @click="
                $store.commit('ADD_FILTER_BREADCRUMB', {
                  name: slotProps.data.groupID,
                  type: 'GROUP',
                });
                $emitter.emit('search-asset-items', null);
              "
              :text="slotProps.data.groupID"
              class="bor-menu-color"
            />
          </div>
        </template>
      </Column>
      
      <Column sortable class="col-approval"
              :style="'width: ' + selectedColumns.length / 100 + '%'"
              field="translatorID"
              v-if="selectedColumns.includes('general.translator')"
              :header="$t('general.translator')">
        <template #body="slotProps">
          <div
            class="col transitions flex-center"
            :id="'approval.' + slotProps.data.id"
          >
            <RoundedLabel
              @click="
                $store.commit('ADD_FILTER_BREADCRUMB', {
                  name: slotProps.data.translatorID,
                  type: 'TRANSLATOR',
                });
                $emitter.emit('search-asset-items', null);
              "
              :text="slotProps.data.translatorID"
              class="bor-menu-color"
            />
          </div>
        </template>
      </Column>
      <Column header="" class="col-approval">
        <template #header="slotProps">
          <MultiSelect
            v-model:model-value="selectedColumns"
            style="width: 10px; height: 0"
            :options="allColumns"
          >
            <template #header="slotProps">
              <h4>
                Select Columns
              </h4>
            </template>
            <template #option="s">
              {{$t(s.option)}}
            </template>
          </MultiSelect>
        </template>
        <template #body="slotProps">
          <div
            class="col last-col transitions flex-center"
            :id="'approval.' + slotProps.data.id"
          >
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<style lang="scss">
.col-approval {
  display: flex;
  align-items: center;
  background-color: var(--white);
  padding: 10px;
  height: 90px;
  width: 100%;
}

.p-selectable-row:focus {
  outline: none !important;
}

.p-selectable-row td > div {
  box-shadow: 0 2px #0d1e4f3d;
}

.p-selectable-row:hover td > div {
  background-color: var(--dark-blue-05);
  transform: scale(1.02);
  box-shadow: none;
}
</style>

<script src="./JobApprovalsView.ts"></script>

import {defineComponent, getCurrentInstance, onMounted, ref, watch} from 'vue';
import {useStore} from "vuex";

export default defineComponent({
  props: ['modelValue', 'languages', 'submitted', 'maxItems', 'placeholder'],
  components: {},
  setup: function (props) {
    const store = useStore();
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let innerModelValue = ref<{
      name: string,
      value: string,
      autoTranslate: boolean,
      autoTranslateAvailable: boolean
    }[]>([]);
    let correctSelectedLanguages = ref<{
      name: string,
      value: string,
      autoTranslate: boolean,
      autoTranslateAvailable: boolean
    }[]>([]);

    let onSelectLanguage = (event: any) => {
      if (event.originalEvent.target.tagName == 'SPAN') {
        let addedItem = null;
        for (const key in innerModelValue.value) {
          let correctItem = innerModelValue.value[key];
          if (!correctSelectedLanguages.value.includes(correctItem) && correctItem.autoTranslate) {
            addedItem = correctItem;
            break;
          }
        }

        if (!addedItem) {
          innerModelValue.value = correctSelectedLanguages.value;
          return;
        }
      }
      if (event.value.length > props.maxItems) {
        event.value[event.value.length - 1].autoTranslate = false;
        event.value.pop();
        store.commit('SHOW_SNACKBAR', {
          snackbarContent: {t: 'snackbar.languageLimit', e: ["" + props.maxItems]},
        });
        return;
      }
      correctSelectedLanguages.value = innerModelValue.value!;

      emitter.emit('select-language', correctSelectedLanguages.value);
    }

    onMounted(() => {
    });

    watch(props, () => {
      innerModelValue.value = props.modelValue;
    });

    return {
      innerModelValue,
      onSelectLanguage
    };
  },
});
